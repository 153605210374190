<template>
  <el-container>
    <el-main>
      <el-row>
        <el-col :span="24">
          <h3 class="tit">项目性质</h3>
          <el-table :data="totalXmxz" size="small " border style="width: 100%">
            <el-table-column prop="xmxz" label="项目性质" width="150"> </el-table-column>
            <el-table-column prop="xmsl" label="项目数量（个）" width="150">
            </el-table-column>
            <el-table-column
              prop="ztz"
              label="总投资（亿元）"
              :formatter="sswtz_formatter"
              width="150"
            >
            </el-table-column>
            <el-table-column
              prop="sswtz"
              label="十四五投资（亿元）"
              :formatter="sswtz_formatter"
            >
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="24">
          <h3 class="tit">项目类型</h3>
          <el-table :data="totalXmlx" size="small " border style="width: 100%">
            <el-table-column prop="xmlx" label="项目类型" width="150"> </el-table-column>
            <el-table-column prop="xmsl" label="项目数量（个）" width="150">
            </el-table-column>
            <el-table-column
              prop="ztz"
              label="总投资（亿元）"
              :formatter="sswtz_formatter"
              width="150"
            >
            </el-table-column>
            <el-table-column
              prop="sswtz"
              label="十四五投资（亿元）"
              :formatter="sswtz_formatter"
            >
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>
<script>
export default {
  props: ["totalXmxz", "totalXmlx"],
  methods: {
    handleClose(done) {
      done();
    },
    formatter(row) {
      return parseFloat(row.jsgm).toFixed(1);
    },
    ztz_formatter(row) {
      return parseFloat(row.ztz).toFixed(1);
    },
    sswtz_formatter(row) {
      return parseFloat(row.sswtz).toFixed(1);
    },
  },
};
</script>
<style scoped>
</style>
