<template>
    <div  onselectstart="return false;">
        <div class="xmlist">
        <div v-for="(item,index) in tableData" :key="index" @click="changeXM(item.ID,index)" class="xmmc" :class="index == activeXm ? 'active' : '' ">
            {{item.XMMC}}
        </div>
        </div>

        <el-tabs v-model="Collapse" type="border-card" @tab-click="changeCollapse">
            <el-tab-pane name="1" :label="'工可研批复或项目申请报告核准批复'+gkwcd" class=" vue-drag-scroll-out-wrapper"  v-dragscroll>
                <div class="wrap"  :style="'transform: scale(' + scale / 100 + ');'">
                    <div class="bg" v-if="gg"><img src="../../../assets/images/flow/bg-1-1.png" alt=""></div>
                    <div class="bg" v-else><img src="../../../assets/images/flow/bg-1-1-1.png" alt=""></div>
                    <div class="row">
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item green" :style="{background:result.GK.ghxzyjs ? result.GK.ghxzyjs.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('规划选址意见书','ghxzyjs','GK')" >
                            <div class="content">
                                <p class="time" v-if="result.GK.ghxzyjs ? result.GK.ghxzyjs.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.GK.ghxzyjs.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.GK.ghxzyjs.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>规划选址意见书</p>
                                <p>(市或县自然资源局)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur"  v-if="result.GK.ghxzyjs ? result.GK.ghxzyjs.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.GK.ghxzyjs" :class= "result.GK.ghxzyjs.BLZT == '未开展' ?  'el-icon-error' : result.GK.ghxzyjs.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.GK.ghxzyjs.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.GK.ghxzyjs.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item green" :style="{background:result.GK.zdzb ? result.GK.zdzb.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('提供工可阶段占地坐标','zdzb','GK')">
                            <div class="content">
                                <p class="time" v-if="result.GK.zdzb ? result.GK.zdzb.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.GK.zdzb.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.GK.zdzb.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>提供工可阶段占地坐标</p>
                                <p>(编制单位)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.zdzb ? result.GK.zdzb.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.GK.zdzb" :class= "result.GK.zdzb.BLZT == '未开展' ?  'el-icon-error' : result.GK.zdzb.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.GK.zdzb.BLZT ==  '未开展' ? 'rgb(250, 75, 75)' : result.GK.zdzb.BLZT == '办理中' ? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item green" :style="{background:result.GK.zjcnh ? result.GK.zjcnh.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('地方配套资金承诺函','zjcnh','GK')" >
                            <div class="content">
                                <p class="time" v-if="result.GK.zjcnh ? result.GK.zjcnh.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.GK.zjcnh.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.GK.zjcnh.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>地方配套资金承诺函</p>
                                <p>(市或县财政局)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.zjcnh ? result.GK.zjcnh.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.GK.zjcnh" :class= "result.GK.zjcnh.BLZT == '未开展' ?  'el-icon-error' : result.GK.zjcnh.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.GK.zjcnh.BLZT ==  '未开展' ? 'rgb(250, 75, 75)' : result.GK.zjcnh.BLZT == '办理中' ? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item green" :style="{background:result.GK.zwfxpg ? result.GK.zwfxpg.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('债务风险评估','zwfxpg','GK')" >
                            <div class="content">
                                <p class="time" v-if="result.GK.zwfxpg ? result.GK.zwfxpg.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.GK.zwfxpg.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.GK.zwfxpg.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>债务风险评估</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.zwfxpg ? result.GK.zwfxpg.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.GK.zwfxpg" :class= "result.GK.zwfxpg.BLZT == '未开展' ?  'el-icon-error' : result.GK.zwfxpg.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.GK.zwfxpg.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.GK.zwfxpg.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item zw"></div>
                    </div>
                    <div class="row">
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item green" :style="{background:result.GK.tdys ? result.GK.tdys.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('土地预审编制及组卷','tdys','GK')">
                            <div class="content">
                                <p class="time" v-if="result.GK.tdys ? result.GK.tdys.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.GK.tdys.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.GK.tdys.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>土地预审编制及组卷</p>
                                <p>(业主委托有资质单位)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.tdys ? result.GK.tdys.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.GK.tdys" :class= "result.GK.tdys.BLZT == '未开展' ?  'el-icon-error' : result.GK.tdys.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.GK.tdys.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.GK.tdys.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item green" :style="{background:result.GK.zygcs ? result.GK.zygcs.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('中央购车税补贴文件的函','zygcs','GK')">
                            <div class="content">
                                <p class="time" v-if="result.GK.zygcs ? result.GK.zygcs.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.GK.zygcs.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.GK.zygcs.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>中央购车税补贴文件的函</p>
                                <p>(省交通运输厅)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.zygcs ? result.GK.zygcs.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.GK.zygcs" :class= "result.GK.zygcs.BLZT == '未开展' ?  'el-icon-error' : result.GK.zygcs.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.GK.zygcs.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.GK.zygcs.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item green" :style="{background:result.GK.zwfxpg ? result.GK.zwfxpg.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('债务风险评估的函','zwfxpg','GK')">
                            <div class="content">
                                <p class="time" v-if="result.GK.zwfxpg ? result.GK.zwfxpg.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.GK.zwfxpg.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.GK.zwfxpg.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>债务风险评估的函</p>
                                <p>市或县财政局</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.zwfxpg ? result.GK.zwfxpg.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.GK.zwfxpg" :class= "result.GK.zwfxpg.BLZT == '未开展' ?  'el-icon-error' : result.GK.zwfxpg.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.GK.zwfxpg.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.GK.zwfxpg.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item zw"></div>
                    </div>
                    <div class="row">
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item green" :style="{background:result.GK.ydcsyj ? result.GK.ydcsyj.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('用地初审意见','ydcsyj','GK')">
                            <div class="content">
                                <p class="time" v-if="result.GK.ydcsyj ? result.GK.ydcsyj.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.GK.ydcsyj.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.GK.ydcsyj.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>用地初审意见</p>
                                <p>(由市或县自然资源局)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.ydcsyj ? result.GK.ydcsyj.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.GK.ydcsyj" :class= "result.GK.ydcsyj.BLZT == '未开展' ?  'el-icon-error' : result.GK.ydcsyj.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.GK.ydcsyj.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.GK.ydcsyj.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item zw"></div>
                        <div class="item green" :style="{background:result.GK.dfzwfxpg ? result.GK.dfzwfxpg.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('地方债务风险评估报告','dfzwfxpg','GK')">
                            <div class="content">
                                <p class="time" v-if="result.GK.dfzwfxpg ? result.GK.dfzwfxpg.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.GK.dfzwfxpg.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.GK.dfzwfxpg.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>地方债务风险评估报告</p>
                                <p>省财政厅</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.dfzwfxpg ? result.GK.dfzwfxpg.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.GK.dfzwfxpg" :class= "result.GK.dfzwfxpg.BLZT == '未开展' ?  'el-icon-error' : result.GK.dfzwfxpg.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.GK.dfzwfxpg.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.GK.dfzwfxpg.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item zw"></div>
                    </div>
                    <div class="row">
                        <div class="item green" :style="{background:result.GK.gjghbzgkbg ? result.GK.gjghbzgkbg.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('根据规划编制工可报告','gjghbzgkbg','GK')">
                            <div class="content">
                                <p class="time" v-if="result.GK.gjghbzgkbg ? result.GK.gjghbzgkbg.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.GK.gjghbzgkbg.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.GK.gjghbzgkbg.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>根据规划编制工可报告</p>
                                <p>(业主招标或委托编制单位)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.gjghbzgkbg ? result.GK.gjghbzgkbg.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.GK.gjghbzgkbg != undefined" :class= "result.GK.gjghbzgkbg.BLZT == '未开展' ?  'el-icon-error' : result.GK.gjghbzgkbg.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.GK.gjghbzgkbg.BLZT == '未开展' ? 'rgb(250, 75, 75)' : result.GK.gjghbzgkbg.BLZT == '办理中' ? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item green" :style="{background:result.GK.sbgkbg ? result.GK.sbgkbg.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('上报工可报告审查','sbgkbg','GK')">
                            <div class="content">
                                <p class="time" v-if="result.GK.sbgkbg ? result.GK.sbgkbg.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.GK.sbgkbg.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.GK.sbgkbg.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>上报工可报告审查</p>
                                <p>(省交通运输厅)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sbgkbg ? result.GK.sbgkbg.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.GK.sbgkbg" :class= "result.GK.sbgkbg.BLZT == '未开展' ?  'el-icon-error' : result.GK.sbgkbg.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.GK.sbgkbg.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.GK.sbgkbg.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item zw"></div>
                        <div class="item green" :style="{background:result.GK.ydyspf ? result.GK.ydyspf.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('用地预审批复','ydyspf','GK')">
                            <div class="content">
                                <p class="time" v-if="result.GK.ydyspf ? result.GK.ydyspf.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.GK.ydyspf.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.GK.ydyspf.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>用地预审批复</p>
                                <p>(省自然资源厅或自然资源部)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.ydyspf ? result.GK.ydyspf.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.GK.ydyspf" :class= "result.GK.ydyspf.BLZT == '未开展' ?  'el-icon-error' : result.GK.ydyspf.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.GK.ydyspf.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.GK.ydyspf.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                    </div>
                    <div class="row">
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item green" :style="{background:result.GK.zxbg ? result.GK.zxbg.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('咨询报告、评估报告','zxbg','GK')">
                            <div class="content">
                                <p class="time" v-if="result.GK.zxbg ? result.GK.zxbg.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.GK.zxbg.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.GK.zxbg.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>咨询报告、评估报告</p>
                                <p>(咨询审查单位)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.zxbg ? result.GK.zxbg.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.GK.zxbg" :class= "result.GK.zxbg.BLZT == '未开展' ?  'el-icon-error' : result.GK.zxbg.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.GK.zxbg.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.GK.zxbg.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item green" :style="{background:result.GK.gkpsh ? result.GK.gkpsh.BLZT == '不涉及' ? '#dddddd' : '' :''}" v-if="!gg" @click="openEdit('工可评审会','gkpsh','GK')">
                            <div class="content" >
                                <p class="time" v-if="result.GK.gkpsh ? result.GK.gkpsh.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.GK.gkpsh.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.GK.gkpsh.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>工可评审会</p>
                                <p>(省发改委、省交通运输厅)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.gkpsh ? result.GK.gkpsh.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.GK.gkpsh" :class= "result.GK.gkpsh.BLZT == '未开展' ?  'el-icon-error' : result.GK.gkpsh.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.GK.gkpsh.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.GK.gkpsh.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item green" :style="{background:result.GK.gknsh ? result.GK.gknsh.BLZT == '不涉及' ? '#dddddd' : '' :''}" v-else @click="openEdit('工可内审会','gknsh','GK')">
                            <div class="content">
                                <p class="time" v-if="result.GK.gknsh ? result.GK.gknsh.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.GK.gknsh.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.GK.gknsh.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>工可内审会</p>
                                <p>(省发改委、省交通运输厅)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.gknsh ? result.GK.gknsh.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.GK.gknsh" :class= "result.GK.gknsh.BLZT == '未开展' ?  'el-icon-error' : result.GK.gknsh.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.GK.gknsh.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.GK.gknsh.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item green" :style="{background:result.GK.gkbgzg ? result.GK.gkbgzg.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('工可报告终稿','gkbgzg','GK')">
                            <div class="content">
                                <p class="time" v-if="result.GK.gkbgzg ? result.GK.gkbgzg.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.GK.gkbgzg.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.GK.gkbgzg.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>工可报告终稿</p>
                                <p>(编制单位)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.gkbgzg ? result.GK.gkbgzg.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.GK.gkbgzg" :class= "result.GK.gkbgzg.BLZT == '未开展' ?  'el-icon-error' : result.GK.gkbgzg.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.GK.gkbgzg.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.GK.gkbgzg.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item green" :style="{background:result.GK.pfgkbg ? result.GK.pfgkbg.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('批复工可报告','pfgkbg','GK')">
                            <div class="content">
                                <p class="time" v-if="result.GK.pfgkbg ? result.GK.pfgkbg.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.GK.pfgkbg.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.GK.pfgkbg.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>批复工可报告</p>
                                <p>(省发改委)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.pfgkbg ? result.GK.pfgkbg.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.GK.pfgkbg" :class= "result.GK.pfgkbg.BLZT == '未开展' ?  'el-icon-error' : result.GK.pfgkbg.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.GK.pfgkbg.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.GK.pfgkbg.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item zw"></div>
                    </div>
                    <div v-if="gg" class="row">
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item green" :style="{background:result.GK.gkdbsc ? result.GK.gkdbsc.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('工可代部审查','gkdbsc','GK')">
                            <div class="content">
                                <p class="time" v-if="result.GK.gkdbsc ? result.GK.gkdbsc.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.GK.gkdbsc.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.GK.gkdbsc.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>工可代部审查</p>
                                <p>(委托第三方)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.gkdbsc ? result.GK.gkdbsc.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.GK.gkdbsc" :class= "result.GK.gkdbsc.BLZT == '未开展' ?  'el-icon-error' : result.GK.gkdbsc.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.GK.gkdbsc.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.GK.gkdbsc.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                    </div>
                    <div v-if="gg" class="row">
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item green" :style="{background:result.GK.gkpsh2 ? result.GK.gkpsh2.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('工可评审会','gkpsh2','GK')">
                            <div class="content">
                                <p class="time" v-if="result.GK.gkpsh2 ? result.GK.gkpsh2.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.GK.gkpsh2.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.GK.gkpsh2.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>工可评审会</p>
                                <p>(建通运输部)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.gkpsh2 ? result.GK.gkpsh2.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.GK.gkpsh2" :class= "result.GK.gkpsh2.BLZT == '未开展' ?  'el-icon-error' : result.GK.gkpsh2.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.GK.gkpsh2.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.GK.gkpsh2.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane name="2"  :label="'初步设计批复'+cbsjwcd" class=" vue-drag-scroll-out-wrapper"  v-dragscroll>
                <div class="wrap"  :style="'transform: scale(' + scale / 100 + ');'">
                    <div class="bg"  style="width: 1840px" v-if="gg"><img style="width: 1840px"  src="../../../assets/images/flow/bg-1-2.png" alt=""></div>
                    <div class="bg"  style="width: 1840px;top: 35px" v-else><img style="width: 1840px" src="../../../assets/images/flow/bg-1-2-1.png" alt=""></div>
                    <div class="row">
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item blue" :style="{background:result.CBSJ.cbsj1 ? result.CBSJ.cbsj1.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('初步设计内审会','cbsj1','CBSJ')" v-if="gg">
                            <div class="content">
                                <p class="time" v-if="result.CBSJ.cbsj1 ? result.CBSJ.cbsj1.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.CBSJ.cbsj1.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.CBSJ.cbsj1.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>初步设计内审会</p>
                                <p>(省发改委、省交通运输厅)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.cbsj1 ? result.GK.cbsj1.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.CBSJ.cbsj1" :class= "result.CBSJ.cbsj1.BLZT == '未开展' ?  'el-icon-error' : result.CBSJ.cbsj1.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.CBSJ.cbsj1.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.CBSJ.cbsj1.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item blue" :style="{background:result.CBSJ.cbsj2 ? result.CBSJ.cbsj2.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('勘察设计招标','cbsj2','CBSJ')">
                            <div class="content">
                                <p class="time" v-if="result.CBSJ.cbsj2 ? result.CBSJ.cbsj2.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.CBSJ.cbsj2.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.CBSJ.cbsj2.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>勘察设计招标</p>
                                <p>(业主单位)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.cbsj2 ? result.GK.cbsj2.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.CBSJ.cbsj2" :class= "result.CBSJ.cbsj2.BLZT == '未开展' ?  'el-icon-error' : result.CBSJ.cbsj2.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.CBSJ.cbsj2.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.CBSJ.cbsj2.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item blue" :style="{background:result.CBSJ.cbsj3 ? result.CBSJ.cbsj3.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('初步设计代部审查','cbsj3','CBSJ')" v-if="gg">
                            <div class="content">
                                <p class="time" v-if="result.CBSJ.cbsj3 ? result.CBSJ.cbsj3.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.CBSJ.cbsj3.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.CBSJ.cbsj3.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>初步设计代部审查</p>
                                <p>(招标第三方)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.cbsj3 ? result.GK.cbsj3.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.CBSJ.cbsj3" :class= "result.CBSJ.cbsj3.BLZT == '未开展' ?  'el-icon-error' : result.CBSJ.cbsj3.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.CBSJ.cbsj3.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.CBSJ.cbsj3.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item blue" :style="{background:result.CBSJ.cbsj4 ? result.CBSJ.cbsj4.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('编制招标方案','cbsj4','CBSJ')">
                            <div class="content">
                                <p class="time" v-if="result.CBSJ.cbsj4 ? result.CBSJ.cbsj4.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.CBSJ.cbsj4.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.CBSJ.cbsj4.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>编制招标方案</p>
                                <p>(招标代理机构)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.cbsj4 ? result.GK.cbsj4.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.CBSJ.cbsj4" :class= "result.CBSJ.cbsj4.BLZT == '未开展' ?  'el-icon-error' : result.CBSJ.cbsj4.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.CBSJ.cbsj4.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.CBSJ.cbsj4.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="item blue" :style="{background:result.CBSJ.cbsj5 ? result.CBSJ.cbsj5.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('设计概算审查报告','cbsj5','CBSJ')">
                            <div class="content">
                                <p class="time" v-if="result.CBSJ.cbsj5 ? result.CBSJ.cbsj5.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.CBSJ.cbsj5.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.CBSJ.cbsj5.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>设计概算审查报告</p>
                                <p>(省造价站)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.cbsj5 ? result.GK.cbsj5.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.CBSJ.cbsj5" :class= "result.CBSJ.cbsj5.BLZT == '未开展' ?  'el-icon-error' : result.CBSJ.cbsj5.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.CBSJ.cbsj5.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.CBSJ.cbsj5.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item blue" :style="{background:result.CBSJ.cbsj6 ? result.CBSJ.cbsj6.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('初步设计终稿','cbsj6','CBSJ')">
                            <div class="content">
                                <p class="time" v-if="result.CBSJ.cbsj6 ? result.CBSJ.cbsj6.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.CBSJ.cbsj6.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.CBSJ.cbsj6.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>初步设计终稿</p>
                                <p>(设计单位)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.cbsj6 ? result.GK.cbsj6.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.CBSJ.cbsj6" :class= "result.CBSJ.cbsj6.BLZT == '未开展' ?  'el-icon-error' : result.CBSJ.cbsj6.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.CBSJ.cbsj6.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.CBSJ.cbsj6.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item blue" :style="{background:result.CBSJ.cbsj7 ? result.CBSJ.cbsj7.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('初步设计评审会','cbsj7','CBSJ')">
                            <div class="content">
                                <p class="time" v-if="result.CBSJ.cbsj7 ? result.CBSJ.cbsj7.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.CBSJ.cbsj7.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.CBSJ.cbsj7.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>初步设计评审会</p>
                                <p v-if="!gg">(省交通运输厅)</p>
                                <p v-else>(交通运输部)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.cbsj7 ? result.GK.cbsj7.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.CBSJ.cbsj7" :class= "result.CBSJ.cbsj7.BLZT == '未开展' ?  'el-icon-error' : result.CBSJ.cbsj7.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.CBSJ.cbsj7.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.CBSJ.cbsj7.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item blue" :style="{background:result.CBSJ.cbsj8 ? result.CBSJ.cbsj8.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('>查询报告、安全评价报告','cbsj8','CBSJ')">
                            <div class="content">
                                <p class="time" v-if="result.CBSJ.cbsj8 ? result.CBSJ.cbsj8.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.CBSJ.cbsj8.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.CBSJ.cbsj8.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>查询报告、安全评价报告</p>
                                <p>(审查单位、安评单位)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.cbsj8 ? result.GK.cbsj8.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.CBSJ.cbsj8" :class= "result.CBSJ.cbsj8.BLZT == '未开展' ?  'el-icon-error' : result.CBSJ.cbsj8.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.CBSJ.cbsj8.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.CBSJ.cbsj8.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item blue" :style="{background:result.CBSJ.cbsj9 ? result.CBSJ.cbsj9.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('编制初步设计文件','cbsj9','CBSJ')">
                            <div class="content">
                                <p class="time" v-if="result.CBSJ.cbsj9 ? result.CBSJ.cbsj9.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.CBSJ.cbsj9.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.CBSJ.cbsj9.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>编制初步设计文件</p>
                                <p>(设计单位)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.cbsj9 ? result.GK.cbsj9.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.CBSJ.cbsj9" :class= "result.CBSJ.cbsj9.BLZT == '未开展' ?  'el-icon-error' : result.CBSJ.cbsj9.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.CBSJ.cbsj9.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.CBSJ.cbsj9.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item blue" :style="{background:result.CBSJ.cbsj10 ? result.CBSJ.cbsj10.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('外业验收','cbsj10','CBSJ')">
                            <div class="content">
                                <p class="time" v-if="result.CBSJ.cbsj10 ? result.CBSJ.cbsj10.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.CBSJ.cbsj10.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.CBSJ.cbsj10.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>外业验收</p>
                                <p>(省交通运输厅)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.cbsj10 ? result.GK.cbsj10.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.CBSJ.cbsj10" :class= "result.CBSJ.cbsj10.BLZT == '未开展' ?  'el-icon-error' : result.CBSJ.cbsj10.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.CBSJ.cbsj10.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.CBSJ.cbsj10.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item blue" :style="{background:result.CBSJ.cbsj11 ? result.CBSJ.cbsj11.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('招标文件上报备案','cbsj11','CBSJ')">
                            <div class="content">
                                <p class="time" v-if="result.CBSJ.cbsj11 ? result.CBSJ.cbsj11.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.CBSJ.cbsj11.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.CBSJ.cbsj11.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>招标文件上报备案</p>
                                <p>(省交通运输厅或市交通运输局)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.cbsj11 ? result.GK.cbsj11.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.CBSJ.cbsj11" :class= "result.CBSJ.cbsj11.BLZT == '未开展' ?  'el-icon-error' : result.CBSJ.cbsj11.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.CBSJ.cbsj11.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.CBSJ.cbsj11.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item blue" :style="{background:result.CBSJ.cbsj12 ? result.CBSJ.cbsj12.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('水土保持报告','cbsj12','CBSJ')">
                            <div class="content">
                                <p class="time" v-if="result.CBSJ.cbsj12 ? result.CBSJ.cbsj12.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.CBSJ.cbsj12.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.CBSJ.cbsj12.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>水土保持报告</p>
                                <p>(水土保持编制单位)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.cbsj12 ? result.GK.cbsj12.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.CBSJ.cbsj12" :class= "result.CBSJ.cbsj12.BLZT == '未开展' ?  'el-icon-error' : result.CBSJ.cbsj12.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.CBSJ.cbsj12.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.CBSJ.cbsj12.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item blue" :style="{background:result.CBSJ.cbsj13 ? result.CBSJ.cbsj13.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('压覆重要矿床评估报告','cbsj13','CBSJ')">
                            <div class="content">
                                <p class="time" v-if="result.CBSJ.cbsj13 ? result.CBSJ.cbsj13.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.CBSJ.cbsj13.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.CBSJ.cbsj13.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>压覆重要矿床评估报告</p>
                                <p>(压覆矿产单位)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.cbsj13 ? result.GK.cbsj13.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.CBSJ.cbsj13" :class= "result.CBSJ.cbsj13.BLZT == '未开展' ?  'el-icon-error' : result.CBSJ.cbsj13.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.CBSJ.cbsj13.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.CBSJ.cbsj13.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item blue" :style="{background:result.CBSJ.cbsj14 ? result.CBSJ.cbsj14.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('地质灾害危险性评估报告','cbsj14','CBSJ')">
                            <div class="content">
                                <p class="time" v-if="result.CBSJ.cbsj14 ? result.CBSJ.cbsj14.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.CBSJ.cbsj14.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.CBSJ.cbsj14.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>地质灾害危险性评估报告</p>
                                <p>(地质灾害评估单位)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.cbsj14 ? result.GK.cbsj14.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.CBSJ.cbsj14" :class= "result.CBSJ.cbsj14.BLZT == '未开展' ?  'el-icon-error' : result.CBSJ.cbsj14.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.CBSJ.cbsj14.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.CBSJ.cbsj14.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item zw"></div>
                        <div class="item blue" :style="{background:result.CBSJ.cbsj15 ? result.CBSJ.cbsj15.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('规划选址意见书','cbsj15','CBSJ')">
                            <div class="content">
                                <p class="time" v-if="result.CBSJ.cbsj15 ? result.CBSJ.cbsj15.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.CBSJ.cbsj15.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.CBSJ.cbsj15.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>招标完成</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.cbsj15 ? result.GK.cbsj15.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.CBSJ.cbsj15" :class= "result.CBSJ.cbsj15.BLZT == '未开展' ?  'el-icon-error' : result.CBSJ.cbsj15.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.CBSJ.cbsj15.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.CBSJ.cbsj15.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item blue" :style="{background:result.CBSJ.cbsj16 ? result.CBSJ.cbsj16.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('水土保持方案准予水行政许可决定书','cbsj16','CBSJ')">
                            <div class="content">
                                <p class="time" v-if="result.CBSJ.cbsj16 ? result.CBSJ.cbsj16.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.CBSJ.cbsj16.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.CBSJ.cbsj16.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>水土保持方案准予水行政许可决定书</p>
                                <p>(省水利厅或市(县)水务局)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.cbsj16 ? result.GK.cbsj16.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.CBSJ.cbsj16" :class= "result.CBSJ.cbsj16.BLZT == '未开展' ?  'el-icon-error' : result.CBSJ.cbsj16.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.CBSJ.cbsj16.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.CBSJ.cbsj16.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item blue" :style="{background:result.CBSJ.cbsj17 ? result.CBSJ.cbsj17.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('压覆矿产资源储量情况的意见','cbsj17','CBSJ')">
                            <div class="content">
                                <p class="time" v-if="result.CBSJ.cbsj17 ? result.CBSJ.cbsj17.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.CBSJ.cbsj17.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.CBSJ.cbsj17.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>压覆矿产资源储量情况的意见</p>
                                <p>(市或县自然资源局)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.cbsj17 ? result.GK.cbsj17.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.CBSJ.cbsj17" :class= "result.CBSJ.cbsj17.BLZT == '未开展' ?  'el-icon-error' : result.CBSJ.cbsj17.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.CBSJ.cbsj17.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.CBSJ.cbsj17.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item blue" :style="{background:result.CBSJ.cbsj18 ? result.CBSJ.cbsj18.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('地质灾害危险性评估评审意见书及备案登记表','cbsj18','CBSJ')">
                            <div class="content">
                                <p class="time" v-if="result.CBSJ.cbsj18 ? result.CBSJ.cbsj18.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.CBSJ.cbsj18.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.CBSJ.cbsj18.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>地质灾害危险性评估评审意见书及备案登记表</p>
                                <p>(省或市自然资源厅组织召开专家评审会)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.cbsj18 ? result.GK.cbsj18.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.CBSJ.cbsj18" :class= "result.CBSJ.cbsj18.BLZT == '未开展' ?  'el-icon-error' : result.CBSJ.cbsj18.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.CBSJ.cbsj18.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.CBSJ.cbsj18.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item zw"></div>
                        <div class="item blue" :style="{background:result.CBSJ.cbsj19 ? result.CBSJ.cbsj19.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('初步设计外业勘察','cbsj19','CBSJ')">
                            <div class="content">
                                <p class="time" v-if="result.CBSJ.cbsj19 ? result.CBSJ.cbsj19.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.CBSJ.cbsj19.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.CBSJ.cbsj19.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>初步设计外业勘察</p>
                                <p>(设计单位)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.cbsj19 ? result.GK.cbsj19.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.CBSJ.cbsj19" :class= "result.CBSJ.cbsj19.BLZT == '未开展' ?  'el-icon-error' : result.CBSJ.cbsj19.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.CBSJ.cbsj19.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.CBSJ.cbsj19.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                    </div>
                    <div class="row">

                        <div class="item blue" :style="{background:result.CBSJ.cbsj20 ? result.CBSJ.cbsj20.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('批复初步设计','cbsj20','CBSJ')">
                            <div class="content">
                                <p class="time" v-if="result.CBSJ.cbsj20 ? result.CBSJ.cbsj20.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.CBSJ.cbsj20.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.CBSJ.cbsj20.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>批复初步设计</p>
                                <p>(省交通运输厅)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.cbsj20 ? result.GK.cbsj20.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.CBSJ.cbsj20" :class= "result.CBSJ.cbsj20.BLZT == '未开展' ?  'el-icon-error' : result.CBSJ.cbsj20.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.CBSJ.cbsj20.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.CBSJ.cbsj20.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i></div>
                        </div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane name="3"  :label="'施工图设计批复'+sgtsjwcd"  class=" vue-drag-scroll-out-wrapper"  v-dragscroll>
                <div class="wrap" :style="'transform: scale(' + scale / 100 + ');'">
                    <div class="bg" style="width: 1840px"><img style="width: 1840px"  src="../../../assets/images/flow/bg-1-3.png" alt=""></div>
                    <div class="row">
                        <div class="item zw"></div>
                        <div class="item orange" :style="{background:result.SGTZB.sgt1 ? result.SGTZB.sgt1.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('施工图设计外业勘察','sgt1','SGTZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGTZB.sgt1 ? result.SGTZB.sgt1.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGTZB.sgt1.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGTZB.sgt1.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>施工图设计外业勘察</p>
                                <p>(设计单位)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgt1 ? result.GK.sgt1.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGTZB.sgt1" :class= "result.SGTZB.sgt1.BLZT == '未开展' ?  'el-icon-error' : result.SGTZB.sgt1.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGTZB.sgt1.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGTZB.sgt1.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item orange" :style="{background:result.SGTZB.sgt2 ? result.SGTZB.sgt2.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('编制施工图文件','sgt2','SGTZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGTZB.sgt2 ? result.SGTZB.sgt2.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGTZB.sgt2.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGTZB.sgt2.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>编制施工图文件</p>
                                <p>(设计单位)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgt2 ? result.GK.sgt2.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGTZB.sgt2" :class= "result.SGTZB.sgt2.BLZT == '未开展' ?  'el-icon-error' : result.SGTZB.sgt2.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGTZB.sgt2.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGTZB.sgt2.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item orange" :style="{background:result.SGTZB.sgt3 ? result.SGTZB.sgt3.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('咨询报告、安全评价报告','sgt3','SGTZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGTZB.sgt3 ? result.SGTZB.sgt3.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGTZB.sgt3.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGTZB.sgt3.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>咨询报告、安全评价报告</p>
                                <p>(审查单位、安评单位)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgt3 ? result.GK.sgt3.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGTZB.sgt3" :class= "result.SGTZB.sgt3.BLZT == '未开展' ?  'el-icon-error' : result.SGTZB.sgt3.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGTZB.sgt3.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGTZB.sgt3.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item orange" :style="{background:result.SGTZB.sgt4 ? result.SGTZB.sgt4.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('施工图设计评审会','sgt4','SGTZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGTZB.sgt4 ? result.SGTZB.sgt4.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGTZB.sgt4.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGTZB.sgt4.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>施工图设计评审会</p>
                                <p>(省交通运输厅)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgt4 ? result.GK.sgt4.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGTZB.sgt4" :class= "result.SGTZB.sgt4.BLZT == '未开展' ?  'el-icon-error' : result.SGTZB.sgt4.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGTZB.sgt4.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGTZB.sgt4.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item orange" :style="{background:result.SGTZB.sgt5 ? result.SGTZB.sgt5.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('施工图最终稿','sgt5','SGTZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGTZB.sgt5 ? result.SGTZB.sgt5.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGTZB.sgt5.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGTZB.sgt5.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>施工图最终稿</p>
                                <p>(设计单位)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgt5 ? result.GK.sgt5.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGTZB.sgt5" :class= "result.SGTZB.sgt5.BLZT == '未开展' ?  'el-icon-error' : result.SGTZB.sgt5.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGTZB.sgt5.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGTZB.sgt5.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item orange" :style="{background:result.SGTZB.sgt6 ? result.SGTZB.sgt6.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('施工图预算审查报告','sgt6','SGTZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGTZB.sgt6 ? result.SGTZB.sgt6.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGTZB.sgt6.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGTZB.sgt6.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>施工图预算审查报告</p>
                                <p>(省造价站)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgt6 ? result.GK.sgt6.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGTZB.sgt6" :class= "result.SGTZB.sgt6.BLZT == '未开展' ?  'el-icon-error' : result.SGTZB.sgt6.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGTZB.sgt6.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGTZB.sgt6.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="item orange" :style="{background:result.SGTZB.sgt7 ? result.SGTZB.sgt7.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('草原占地可研','sgt7','SGTZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGTZB.sgt7 ? result.SGTZB.sgt7.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGTZB.sgt7.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGTZB.sgt7.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>草原占地可研</p>
                                <p>(招标或委托有资质单位)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgt7 ? result.GK.sgt7.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGTZB.sgt7" :class= "result.SGTZB.sgt7.BLZT == '未开展' ?  'el-icon-error' : result.SGTZB.sgt7.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGTZB.sgt7.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGTZB.sgt7.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item orange" :style="{background:result.SGTZB.sgt8 ? result.SGTZB.sgt8.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('林地占地可研','sgt8','SGTZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGTZB.sgt8 ? result.SGTZB.sgt8.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGTZB.sgt8.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGTZB.sgt8.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>林地占地可研</p>
                                <p>(招标或委托有资质单位)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgt8 ? result.GK.sgt8.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGTZB.sgt8" :class= "result.SGTZB.sgt8.BLZT == '未开展' ?  'el-icon-error' : result.SGTZB.sgt8.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGTZB.sgt8.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGTZB.sgt8.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item orange" :style="{background:result.SGTZB.sgt9 ? result.SGTZB.sgt9.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('临时占地土地复垦方案','sgt9','SGTZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGTZB.sgt9 ? result.SGTZB.sgt9.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGTZB.sgt9.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGTZB.sgt9.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>临时占地土地复垦方案</p>
                                <p>调查及编制</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgt9 ? result.GK.sgt9.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGTZB.sgt9" :class= "result.SGTZB.sgt9.BLZT == '未开展' ?  'el-icon-error' : result.SGTZB.sgt9.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGTZB.sgt9.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGTZB.sgt9.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item orange" :style="{background:result.SGTZB.sgt10 ? result.SGTZB.sgt10.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('环评报告','sgt10','SGTZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGTZB.sgt10 ? result.SGTZB.sgt10.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGTZB.sgt10.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGTZB.sgt10.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>环评报告</p>
                                <p>(环评编制单位)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgt10 ? result.GK.sgt10.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGTZB.sgt10" :class= "result.SGTZB.sgt10.BLZT == '未开展' ?  'el-icon-error' : result.SGTZB.sgt10.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGTZB.sgt10.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGTZB.sgt10.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item orange" :style="{background:result.SGTZB.sgt11 ? result.SGTZB.sgt11.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('文物调查','sgt11','SGTZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGTZB.sgt11 ? result.SGTZB.sgt11.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGTZB.sgt11.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGTZB.sgt11.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>文物调查</p>
                                <p>(勘察设计单位提供占地坐标)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgt11 ? result.GK.sgt11.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGTZB.sgt11" :class= "result.SGTZB.sgt11.BLZT == '未开展' ?  'el-icon-error' : result.SGTZB.sgt11.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGTZB.sgt11.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGTZB.sgt11.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item orange" :style="{background:result.SGTZB.sgt12 ? result.SGTZB.sgt12.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('编制土地组卷','sgt12','SGTZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGTZB.sgt12 ? result.SGTZB.sgt12.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGTZB.sgt12.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGTZB.sgt12.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>编制土地组卷</p>
                                <p>(市或县自然资源局)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgt12 ? result.GK.sgt12.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGTZB.sgt12" :class= "result.SGTZB.sgt12.BLZT == '未开展' ?  'el-icon-error' : result.SGTZB.sgt12.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGTZB.sgt12.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGTZB.sgt12.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item orange" :style="{background:result.SGTZB.sgt13 ? result.SGTZB.sgt13.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('批复施工图设计','sgt13','SGTZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGTZB.sgt13 ? result.SGTZB.sgt13.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGTZB.sgt13.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGTZB.sgt13.JHWCSJ,this.getNowFormatDate()).year}}</p>

                                <p>批复施工图设计</p>
                                <p>(省交通运输厅)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgt13 ? result.GK.sgt13.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGTZB.sgt13" :class= "result.SGTZB.sgt13.BLZT == '未开展' ?  'el-icon-error' : result.SGTZB.sgt13.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGTZB.sgt13.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGTZB.sgt13.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="item orange" :style="{background:result.SGTZB.sgt14 ? result.SGTZB.sgt14.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('初审或批复','sgt14','SGTZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGTZB.sgt14 ? result.SGTZB.sgt14.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGTZB.sgt14.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGTZB.sgt14.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>初审或批复</p>
                                <p>(省或市林业和草原局)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgt14 ? result.GK.sgt14.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGTZB.sgt14" :class= "result.SGTZB.sgt14.BLZT == '未开展' ?  'el-icon-error' : result.SGTZB.sgt14.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGTZB.sgt14.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGTZB.sgt14.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                            <div class="sthx">如有生态红线</div>
                        </div>
                        <div class="item orange" :style="{background:result.SGTZB.sgt15 ? result.SGTZB.sgt15.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('初审或批复','sgt15','SGTZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGTZB.sgt15 ? result.SGTZB.sgt15.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGTZB.sgt15.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGTZB.sgt15.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>初审或批复</p>
                                <p>(省或市林业和草原局)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgt15 ? result.GK.sgt15.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGTZB.sgt15" :class= "result.SGTZB.sgt15.BLZT == '未开展' ?  'el-icon-error' : result.SGTZB.sgt15.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGTZB.sgt15.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGTZB.sgt15.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                            <div class="sthx">如有生态红线</div>
                        </div>
                        <div class="item orange" :style="{background:result.SGTZB.sgt16 ? result.SGTZB.sgt16.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('初审或批复','sgt16','SGTZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGTZB.sgt16 ? result.SGTZB.sgt16.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGTZB.sgt16.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGTZB.sgt16.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>初审或批复</p>
                                <p>(省或市林业和草原局)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgt16 ? result.GK.sgt16.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGTZB.sgt16" :class= "result.SGTZB.sgt16.BLZT == '未开展' ?  'el-icon-error' : result.SGTZB.sgt16.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGTZB.sgt16.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGTZB.sgt16.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item orange" :style="{background:result.SGTZB.sgt17 ? result.SGTZB.sgt17.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('环评报告批复','sgt17','SGTZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGTZB.sgt17 ? result.SGTZB.sgt17.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGTZB.sgt17.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGTZB.sgt17.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>环评报告批复</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgt17 ? result.GK.sgt17.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGTZB.sgt17" :class= "result.SGTZB.sgt17.BLZT == '未开展' ?  'el-icon-error' : result.SGTZB.sgt17.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGTZB.sgt17.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGTZB.sgt17.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item orange" :style="{background:result.SGTZB.sgt18 ? result.SGTZB.sgt18.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('省文化和旅游厅出具文件','sgt18','SGTZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGTZB.sgt18 ? result.SGTZB.sgt18.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGTZB.sgt18.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGTZB.sgt18.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>省文化和旅游厅出具文件</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgt18 ? result.GK.sgt18.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGTZB.sgt18" :class= "result.SGTZB.sgt18.BLZT == '未开展' ?  'el-icon-error' : result.SGTZB.sgt18.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGTZB.sgt18.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGTZB.sgt18.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item orange" :style="{background:result.SGTZB.sgt19 ? result.SGTZB.sgt19.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('建设用地批复','sgt19','SGTZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGTZB.sgt19 ? result.SGTZB.sgt19.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGTZB.sgt19.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGTZB.sgt19.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>建设用地批复</p>
                                <p>(省国土厅)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgt19 ? result.GK.sgt19.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGTZB.sgt19" :class= "result.SGTZB.sgt19.BLZT == '未开展' ?  'el-icon-error' : result.SGTZB.sgt19.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGTZB.sgt19.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGTZB.sgt19.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item zw">
                        </div>
                    </div>
                    <div class="row">
                        <div class="item orange" :style="{background:result.SGTZB.sgt20 ? result.SGTZB.sgt20.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('勘察设计招标','sgt20','SGTZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGTZB.sgt20 ? result.SGTZB.sgt20.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGTZB.sgt20.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGTZB.sgt20.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>勘察设计招标</p>
                                <p>(业主单位)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgt20 ? result.GK.sgt20.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGTZB.sgt20" :class= "result.SGTZB.sgt20.BLZT == '未开展' ?  'el-icon-error' : result.SGTZB.sgt20.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGTZB.sgt20.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGTZB.sgt20.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item orange" :style="{background:result.SGTZB.sgt21 ? result.SGTZB.sgt21.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('勘察设计招标','sgt21','SGTZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGTZB.sgt21 ? result.SGTZB.sgt21.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGTZB.sgt21.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGTZB.sgt21.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>勘察设计招标</p>
                                <p>(业主单位)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgt21 ? result.GK.sgt21.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGTZB.sgt21" :class= "result.SGTZB.sgt21.BLZT == '未开展' ?  'el-icon-error' : result.SGTZB.sgt21.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGTZB.sgt21.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGTZB.sgt21.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>

                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane name="4" :label="'施工前准备'+shqzbwcd"  class=" vue-drag-scroll-out-wrapper"  v-dragscroll>
                <div class="wrap " :style="'transform: scale(' + scale / 100 + ');'">
                    <div class="bg" ><img   src="../../../assets/images/flow/bg-1-4.png" alt=""></div>
                    <div class="row">
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item purple" :style="{background:result.SGQZB.sgqzb1? result.SGQZB.sgqzb1.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('监理、施工招标完成','sgqzb1','SGQZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGQZB.sgqzb1 ? result.SGQZB.sgqzb1.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGQZB.sgqzb1.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGQZB.sgqzb1.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>监理、施工招标完成</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgqzb1 ? result.GK.sgqzb1.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGQZB.sgqzb1" :class= "result.SGQZB.sgqzb1.BLZT == '未开展' ?  'el-icon-error' : result.SGQZB.sgqzb1.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGQZB.sgqzb1.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGQZB.sgqzb1.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>

                    </div>
                    <div class="row">
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item purple" :style="{background:result.SGQZB.sgqzb2? result.SGQZB.sgqzb2.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('监理、施工招标完成','sgqzb2','SGQZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGQZB.sgqzb2 ? result.SGQZB.sgqzb2.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGQZB.sgqzb2.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGQZB.sgqzb2.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>资金计划</p>
                                <p>(省交通运输厅及地方政府)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgqzb2 ? result.GK.sgqzb2.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGQZB.sgqzb2" :class= "result.SGQZB.sgqzb2.BLZT == '未开展' ?  'el-icon-error' : result.SGQZB.sgqzb2.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGQZB.sgqzb2.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGQZB.sgqzb2.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>

                    </div>
                    <div class="row">
                        <div class="item purple" :style="{background:result.SGQZB.sgqzb3? result.SGQZB.sgqzb3.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('项目开工','sgqzb3','SGQZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGQZB.sgqzb3 ? result.SGQZB.sgqzb3.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGQZB.sgqzb3.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGQZB.sgqzb3.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p v-if="result.SGQZB.sgqzb3 ? result.SGQZB.sgqzb3.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGQZB.sgqzb3.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGQZB.sgqzb3.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>项目开工</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgqzb3 ? result.GK.sgqzb3.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGQZB.sgqzb3" :class= "result.SGQZB.sgqzb3.BLZT == '未开展' ?  'el-icon-error' : result.SGQZB.sgqzb3.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGQZB.sgqzb3.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGQZB.sgqzb3.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item purple" :style="{background:result.SGQZB.sgqzb4? result.SGQZB.sgqzb4.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('施工许可','sgqzb4','SGQZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGQZB.sgqzb4 ? result.SGQZB.sgqzb4.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGQZB.sgqzb4.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGQZB.sgqzb4.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>施工许可</p>
                                <p>(省交通运输厅或市交通运输局)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgqzb4 ? result.GK.sgqzb4.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGQZB.sgqzb4" :class= "result.SGQZB.sgqzb4.BLZT == '未开展' ?  'el-icon-error' : result.SGQZB.sgqzb4.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGQZB.sgqzb4.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGQZB.sgqzb4.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                    </div>
                    <div class="row">
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item purple" :style="{background:result.SGQZB.sgqzb5? result.SGQZB.sgqzb5.BLZT == '不涉及' ? '#dddddd' : '' :''}" @click="openEdit('质量监督手续','sgqzb5','SGQZB')">
                            <div class="content">
                                <p class="time" v-if="result.SGQZB.sgqzb5 ? result.SGQZB.sgqzb5.JHWCSJ ? true : false :false" :style="{color:this.DateDiff(result.SGQZB.sgqzb5.JHWCSJ,this.getNowFormatDate()).color}">{{this.DateDiff(result.SGQZB.sgqzb5.JHWCSJ,this.getNowFormatDate()).year}}</p>
                                <p>质量监督手续</p>
                                <p>(业主上报省交通运输综合行政执法局或市交通运输综合行政执法局)</p>
                            </div>
                            <div class="mask"></div>
                            <div class="sur" v-if="result.GK.sgqzb5 ? result.GK.sgqzb5.BLZT == '不涉及' ? false : true :true">
                                <i v-if="result.SGQZB.sgqzb5" :class= "result.SGQZB.sgqzb5.BLZT == '未开展' ?  'el-icon-error' : result.SGQZB.sgqzb5.BLZT == '办理中' ? 'el-icon-warning' : 'el-icon-success'" :style="{color: result.SGQZB.sgqzb5.BLZT == '未开展'? 'rgb(250, 75, 75)' : result.SGQZB.sgqzb5.BLZT == '办理中'? 'rgb(243 129 32)' : '#6dae48'}"></i>
                                <i v-else class= "el-icon-error" style="color:rgb(250, 75, 75)"></i>
                            </div>
                        </div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>
                        <div class="item zw"></div>

                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
        <el-drawer :append-to-body="true" :visible.sync="showAddModel" size="1183px" :title="title"  class="editModal">
            <el-main>

                <el-form :hide-required-asterisk="true" :model="detail" :inline-message="true" ref="detailForm"  :inline="true" class="form-inline" v-loading="uploadLoading">
                    <el-row style="margin-top: 32px">
                        <el-form-item label="批复单位" label-width="168px">
                            <el-input placeholder="请输批复单位" v-model="detail.PFDW"></el-input>
                        </el-form-item>
                        <el-form-item label="批复时间" label-width="168px">
                            <el-date-picker  style="width: 178px"

                                             format="yyyy-MM-dd"
                                             value-format="yyyy-MM-dd"
                                             v-model="detail.PFSJ" type="date" placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="批复文件标题" label-width="168px">
                            <el-input placeholder="请输入批复文件标题" v-model="detail.PFWJBT"></el-input>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="批复文号" label-width="168px">
                            <el-input placeholder="请输入批复文号" v-model="detail.PFWH"></el-input>
                        </el-form-item>
                        <el-form-item label="办理状态" label-width="168px">
                            <el-select @change="changeStatus" placeholder="请选择办理状态" v-model="detail.BLZT">
                                <el-option label="未开展" value="未开展"></el-option>
                                <el-option label="办理中"  value="办理中"></el-option>
                                <el-option label="已办结"  value="已办结"></el-option>
                                <el-option label="不涉及"  value="不涉及"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item v-if="dealStatus != '不涉及'" :disabled="detail.JHWCSJZT == 1" inline-message="false" label="计划完成时间" label-width="168px" prop="JHWCSJ" :rules="[
                                    { required: true, validator: (rule, value, callback)=>{checkNull(rule, value, callback)}, trigger: 'blur' },
                                ]">
                            <el-date-picker
                                            @change="changeTime"
                                            style="width: 178px"
                                             format="yyyy-MM-dd"
                                             value-format="yyyy-MM-dd"
                                             v-model="detail.JHWCSJ" type="date" placeholder="选择日期" >
                            </el-date-picker>
                        </el-form-item>
                    </el-row>
                    <el-row v-if="(changeTimeReason != '' && changeTimeReason != 'same') && newAdd">
                        <el-form-item label="时间调整原因" prop="JHWCSJTZYY" label-width="168px" :rules="[
                                    { required: true,message: '请输入时间调整原因', trigger: 'blur' },
                                ]">
                            <el-input autosize style="width: 548px" placeholder="请输入时间调整原因" type="textarea" v-model="detail.JHWCSJTZYY"></el-input>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="工作进展描述" label-width="168px">
                            <el-input autosize style="width: 548px" placeholder="请输入描述内容" type="textarea" v-model="detail.GZJZMS"></el-input>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="附件" label-width="168px">
                            <label  class="fright">
                                <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                <input type="file" v-show="false"  name="file" ref="file" id="fileInput"  multiple="multiple" @change="onSubmit($event,'jsxmydys')" />
                            </label>
                        </el-form-item>
                        <div :span="3" class="file" v-for="(item,index) in detail.FJ" :key="index">
                            <a target="view_window" :href="baseUrl+item.url+item.name">
                                <i class="el-icon-paperclip" style="color: #409EFF"></i><span  style="margin-right: 6px;color: #409eff">{{item.name}} </span>
                            </a>
                            <i style="color: #409EFF" class="el-icon-delete" @click.stop="deleted(index,'jsxmydys')"></i>
                        </div>
                    </el-row>
                    <div style="color: red">* 以下三种文件不要上传：（1）密级为【秘密】及以上、（2）文件中含省领导签字、（3）军用图、边防图。</div>
                </el-form>
            <div class="footer">
                <el-button type="primary" @click="save">保存</el-button>
                <el-button @click="cancel">取消</el-button>
            </div>
            </el-main>

        </el-drawer>

    </div>
</template>

<script>
    export default {
        name: "Flow",
        props:{
            id:{ type: String },
            qqjzid: { type: String },
            GKWCD:  { type: String },
            CBSJWCD:  { type: String },
            SGTSJWCD:  { type: String },
            SGQZBWCD:  { type: String },
            isGg: { type: Boolean },
        },
        data(){
            return{
                activeXm:0,
                tableData:[],
                confirmChange: false,
                baseUrl: "http://82.156.50.94:8002/",
                status:'1',
                showAddModel: false,
                uploadLoading: false,
                detail:{
                    fj:[]
                },
                title:'',
                gg: true,
                percent:'80%',
                scale: 100,
                Collapse: '1',
                jdmc:'',
                lctfl:'',
                qqId:'',
                listData:[],
                result:{
                    GK:{},
                    CBSJ:{},
                    SGTZB:{},
                    SGQZB:{}
                },
                gkwcd:'',
                cbsjwcd:'',
                sgtsjwcd:'',
                shqzbwcd:'',
                dealStatus: '',
                changeDateSwich:true,
                orignDate:'',
                changeTimeReason:'',
                newAdd:true,
            }
        },
        mounted() {

        },
        watch:{
            id:{
                handler(val){
                    this.getXm(val)
                },
                immediate: true
            },
            GKWCD(val){
                this.gkwcd = val
            },
            CBSJWCD(val){
                this.cbsjwcd = val
            },
            SGTSJWCD(val){
                this.sgtsjwcd = val
            },
            SGQZBWCD(val){
                this.shqzbwcd = val
            },
            isGg(val){
                this.gg  =val
            },
            qqjzid(val){
                this.qqId = val
                this.getData(val)
            }
        },
        methods:{
            changeXM(id,index){
                this.activeXm = index
                this.getData(id)
            },
            getXm(id){
                this.http
                    .post(
                        "/api/Plan_high_national_early/GetData",
                        {
                            page: 1,
                            rows: 1000,
                            Sort: "DSBM",
                            Order: "asc",
                            wheres: JSON.stringify([
                                { Name: "SSWGHXMID",
                                    Value: id,
                                    DisplayType: "equal"
                                }

                            ])},
                        "正在请求数据，请稍候..."
                    )
                    .then((res) => {
                        if (res.status == 0) {
                            this.total = res.total;
                            this.tableData = res.rows;
                            this.getData(res.rows[0].ID)
                        }
                    });
            },
            changeTime(val){
                if (this.detail.ID){
                    this.changeTimeReason = 'little'
                }
                if (this.orignDate){
                    this.changeTimeReason  = this.tab(this.orignDate.slice(0,10),val)
                }
            },
            changeStatus(val){
                this.dealStatus = val
            },
            // 比较大小
             tab(date1,date2){
                 var oDate1 = new Date(date1);
                var oDate2 = new Date(date2);
                if(oDate1.getTime() > oDate2.getTime()){
                    return 'little'
                } else if(oDate1.getTime() == oDate2.getTime()) {
                   return 'same'
                }else{
                    return 'big'
                }
            },
            getNowFormatDate() {
                var date = new Date();
                var seperator1 = "-";
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                var strDate = date.getDate();
                if (month >= 1 && month <= 9) {
                    month = "0" + month;
                }
                if (strDate >= 0 && strDate <= 9) {
                    strDate = "0" + strDate;
                }
                var currentdate = year + seperator1 + month  + seperator1 + strDate;
                return currentdate;
            },
            //计算差值

            DateDiff(date1,date2){ //sDate1和sDate2是2006-12-18格式
                let _this = this;
                let flag = [1, 3, 5, 7, 8, 10, 12, 4, 6, 9, 11, 2];
                var cah =  this.tab(date1,date2)
                let start = new Date(date1);
                let end = new Date(date2);
                let year =end.getFullYear() - start.getFullYear();
var day, month;
                if (cah == 'big'){
                     month =end.getMonth() - start.getMonth();
                     day =end.getDate() - start.getDate();
                    if (month < 0) {
                        year--;
                        month = end.getMonth() + (12 - start.getMonth());
                    }
                    if (day < 0) {
                        month--;
                        let index = flag.findIndex((temp) => {
                            return temp === start.getMonth() + 1
                        });
                        let monthLength;
                        if (index <= 6) {
                            monthLength = 31;
                        } else if (index > 6 && index <= 10) {
                            monthLength = 30;
                        } else {
                            monthLength = 28;
                        }
                        day = end.getDate() + (monthLength - start.getDate());
                    }
                }else{
                     month =start.getMonth() - end.getMonth();
                     day = start.getDate() -end.getDate();
                    if (day < 0) {
                       if (month > 0){
                           month --
                       }
                        let index = flag.findIndex((temp) => {
                            return temp === start.getMonth() + 1
                        });
                        let monthLength;
                        if (index <= 6) {
                            monthLength = 31;
                        } else if (index > 6 && index <= 10) {
                            monthLength = 30;
                        } else {
                            monthLength = 28;
                        }
                        day =start.getDate()+ (monthLength -  end.getDate());
                    }
                }
                var obj = {
                    year: (year == 0?'':  Math.abs(year)+'年') + (month == 0?'':  Math.abs(month)+'月')+ (day == 0?'':  Math.abs(day)+'天'),
                }
                //小于半个月
                if(-15<day<0 && year == 0 && month == 0 ){
                    obj.color='#E6FF00'
                }

                //一个月到半个月之间
                if(year == 0 && month == 0 && (-15>= day >= -30)){
                    obj.color='#00FF77'
                }
                //延期
                if (cah == 'big'){
                    if((day >= 0 && month >= 0 && year >= 0) || (month > 0 && year >= 0) || year > 0){
                        obj.color='#FB0A0A'
                    }
                }


                return obj
                // if (year == 0 && month == 0){
                //     if(day > 0 ){
                //         color = '#FB0A0A'
                //     }else if(0>day>-15){
                //         color = '#E6FF00'
                //     }else if (-15>day>-30 || month == -1){
                //         color = '#00FF77'
                //     }
                //      obj = {
                //         year:`${day}天`,
                //         color:' #00FF77'
                //     }
                //     return obj
                //
                // }else if (year == 0 && month == 0 && day == 0){
                //      obj = {
                //         year:'0天',
                //         color:'#E6FF00'
                //     }
                //     return obj
                // }else if (year == 0){
                //     // return `${month}月${day}天`
                //     if(day > 0 ){
                //         color = '#FB0A0A'
                //     }else if(0>day>-15){
                //         color = '#E6FF00'
                //     }else if (-15>day>-30 || month == -1){
                //         color = '#00FF77'
                //     }
                //     obj = {
                //         year:`${month}月${day}天`,
                //         color:' #00FF77'
                //     }
                //     return obj
                // }else if (month == 0){
                //     // return `${year}年${day}天`
                    //     console.log(day,year,'121212121')
                //     if(day > 0 ){
                //         color = '#FB0A0A'
                //     }else if(0>day>-15){
                //         color = '#E6FF00'
                //     }else if (-15>day>-30 && year < 0){
                //         color = '#00FF77'
                //     }
                //     obj = {
                //         year:`${year}年${day}天`,
                //         color:' #00FF77'
                //     }
                //     return obj
                // }else if (day == 0){
                //     if( month == -1){
                //         color = '#00FF77'
                //     }
                //     obj = {
                //         year:`${year}年${month}月`,
                //         color:' #00FF77'
                //     }
                //     return obj
                //     // return `${year}年${month}月`
                // }else if (day == 0 && month == 0){
                //     obj = {
                //         year:`${year}年`,
                //         color:'#ffffff'
                //     }
                //     return obj
                //     // return `${year}年`
                // }else{
                //     // return '<span style="color: #00FF77">{{${year}年${month}月${day}天}}</span>'
                // }

                //
            },

            deleted(index,type){
                this.detail.FJ.splice(index,1)
            },
            getData(id){
                this.result = {GK:{}, CBSJ:{}, SGTZB:{}, SGQZB:{}}
                this.http.post('/api/Plan_high_national_early_node/GetPageData',{Wheres:JSON.stringify([{Name:'QQJZID',Value:id,DisplayType: "Equal"}])}).then(res=>{
                    this.listData = res.rows
                    this.listData.map((item,index)=>{
                        var a = []
                        if(item.FJ != ''){
                            item.FJ = item.FJ.split(';')
                            item.FJ.map((subItem)=>{
                                subItem = {url:subItem.substring(0,subItem.lastIndexOf("/") + 1),name:subItem.substring(subItem.lastIndexOf("/") + 1,subItem.length)}
                                a.push(subItem)
                            })
                            item.FJ = a
                        }else {
                            item.FJ = []
                        }

                    })
                    this.listData.map((item,index)=>{
                        switch (item.LCTFL) {
                            case 'GK':
                                this.result.GK[item.JDMC] = item
                                break;
                            case 'CBSJ':
                                this.result.CBSJ[item.JDMC] = item
                                break;
                            case 'SGTZB':
                                this.result.SGTZB[item.JDMC] = item
                                break;
                            case 'SGQZB':
                                this.result.SGQZB[item.JDMC] = item
                                break;
                        }
                    })
                    this.detail = {FJ:[]}
                })
            },
            onSubmit(e, type) {
                this.uploadLoading = true
                let file = e.target.files;
                let form = new FormData();
                let arrs = [];
                file.forEach((element) => {
                    form.append("fileInput", element);
                    arrs.push(element.name);
                });
                this.http.post("/api/Plan_high_national_early_node/upload", form)
                    .then((res) => {
                        this.uploadLoading = false
                        arrs.forEach((v) => {
                            // this.details[type].push(res.data + v);
                            this.detail.FJ.push({url:res.data, name:v})

                        });
                        // this.detail[type].pfwj.push({url:res.data, name:file[0].name})
                    });

            },
            save(){
                this.$refs.detailForm.validate((valid) => {
                    if (valid) {
                        var params = JSON.parse(JSON.stringify(this.detail))
                        var pfwjString = []
                        params.FJ.map((item)=>{
                            pfwjString.push(item.url+item.name)
                        })
                        params.FJ = pfwjString.join(';')
                        params.QQJZID = this.qqId
                        params.JDMC = this.jdmc
                        params.LCTFL = this.lctfl
                        if(!params.ID){
                            params.ID = '00000000-0000-0000-0000-000000000000'
                            this.http.post('/api/Plan_high_national_early_node/AddPlanHighNationalEarlyNode',params).then(res=>{
                                if(res.status){
                                    this.dealStatus = ''
                                    this.$message.success('添加成功')
                                    this.showAddModel = false
                                    this.detail ={FJ:[]}
                                    this.getData(this.qqId)
                                    this.$parent.$parent.closeInnerDrawer();
                                }
                            })

                        }else{
                            this.http.post('/api/Plan_high_national_early_node/UpdatePlanHighNationalEarlyNode',params).then(res=>{
                                if(res.status){
                                    this.$message.success('保存成功')
                                    this.showAddModel = false
                                    this.dealStatus = ''
                                    this.detail ={FJ:[]}
                                    this.getData(this.qqId)
                                    this.$parent.$parent.closeInnerDrawer();
                                }
                            })
                        }
                        }
                    })

            },
            checkNull(rule, value, callback){
                if (!value) {
                    callback(new Error('请选择时间'));
                }else{
                    callback();
                }
            },
            cancel(){},
            openEdit(name,jdmc,lctfl){
                var that = this
                this.changeTimeReason = ''
                this.detail = {FJ:[]}
                this.title = name
                this.jdmc = jdmc
                this.lctfl =  lctfl
                if ( this.result[lctfl][jdmc] ){
                    this.detail =  JSON.parse(JSON.stringify(this.result[lctfl][jdmc]))
                    this.dealStatus =  this.detail.BLZT
                    this.orignDate = this.detail.JHWCSJ
                    this.newAdd = true
                    // this.changeDateSwich = this.detail.JHWCSJ ? true
                }else{
                    this.newAdd = false

                }
            },
            changeCollapse(){

            },
        }
    }
</script>

<style scoped lang="less">
    /deep/.v-modal {
        z-index: 2001 !important;
    }
    /deep/.el-tabs__content{
        /*overflow: auto;*/
        /*width: 1800px;*/
        height: 100vh;
        /*width: 1900px;*/
    }
    .wrap{
        width: 1800px;
        height: 90vh;
        position: relative;
        padding: 20px;
        .bg{
            position: absolute;
            width: 1800px;
            height: auto;
            left: 0;
            top: 0;
            img{
                width: 1800px;
                height: auto;
            }
        }
    }
    .row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .zw{
            background: #666;
            text-align: center;
            opacity: 0;
            cursor: default;
        }
        .item{
            position: relative;
            flex-shrink: 0;
            width: 180px;
            min-height: 60px;
            color: #fff;
            padding: 5px;
            margin-top: 50px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            cursor: pointer;
            .content{
                text-align: center;
                .time{
                    position: absolute;
                    left: 0;
                    top: 0;
                    font-size: 12px;
                    background: rgba(0,0,0,.4);
                    padding: 0px 5px;
                    border-radius: 0 0 10px 0;
                }
                .little{
                    color: #00FF77;
                }

            }
            .sthx{
                position: absolute;
                top: 85px;
                left: 55px;
                color: #409eff;
            }
            .mask {
                position: absolute;
                width: 180px;
                right: -50%;
                top: 34%;
                background: #000;
                height: 180px;
                transform: rotate(
                        45deg);
                opacity: 0.6;
            }
            .sur{
                position: absolute;
                right: 10px;
                bottom: 10px;
                font-size: 24px;
                color: rgb(250, 75, 75);
                background: #fff;
                text-align: center;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                line-height: 24px;
            }

        }

        .green {
            background: #6dae48;
        }
        .blue{
            background:#4670c2;
        }
        .purple{
            background:#dc5dc1;
        }
        .orange{
            background:  #f16e20;
        }
    }
    /deep/.el-tabs__content{
    }
    /deep/.el-form-item__label{
        margin-right: 4px;
        color: #000;
        text-align: center;
        background: #FAFAFA;
    }

    .editModal{
        /deep/.el-input__inner{
            width: 178px;
        }

    }
    /deep/.el-form--inline .el-form-item{
        margin-right: 20px;
    }
    /deep/.el-collapse-item__content {
        padding:10px 15px 20px 15px  !important;
    }
    .footer{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 47px;
        background: #F8F8F8;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .file{
        margin-right: 10px;
        display: inline-block;
        margin-top: 5px;
        padding: 0 6px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #B3D8FF;
        opacity: 1;
        border-radius: 4px;
        color: #409EFF;
        a{
            height: 32px;
            line-height: 32px;
        }
    }
    /deep/.el-form-item__error--inline {
        display: block;
    }
    .xmlist{
        display: flex;
        align-items: center;
        position: absolute;
        top: 110px;
        left: 20px;
        z-index: 2001;
        .xmmc{
            cursor: pointer;
            background: #e6effc;
            color: #666;
            padding: 5px 10px;
            margin-right: 5px;
        }
        .active{
            background: #4c98da;
            color: #ffffff;
        }
    }
</style>

