<template>
    <el-container>
        <el-main>
            <el-row>
                <el-col :span="24">
                    <h3 class="tit">项目类型</h3>
                    <!-- <el-table :data="totalXmlx" size="small " border style="width: 100%">
                        <el-table-column prop="xmlx" label="项目类型"></el-table-column>
                        <el-table-column prop="xmsl" label="项目数量（个）" width="120"></el-table-column>
                        <el-table-column prop="sswtz" label="十四五投资（亿元）" :formatter="sswtz_formatter" width="150"></el-table-column>
                    </el-table> -->
                    <el-table :data="totalXmlxs" ref="theTable" size="small" style="width: 100%;margin-bottom: 20px;" row-key="id" border default-expand-all :default-sort="{ prop: 'jsxz', order: 'descending' }" :tree-props="{children: 'children',hasChildren: 'hasChildren'}">
                        <el-table-column prop="sscs" label="所属城市"></el-table-column>
                        <el-table-column prop="key" label="项目类型"></el-table-column>
                        <el-table-column prop="xmsl" label="项目数量（个）" width="120"></el-table-column>
                        <el-table-column prop="sswtz" label="十四五投资（亿元）" :formatter="sswtz_formatter" width="150"></el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="24">
                    <h3 class="tit">项目性质</h3>
                    <!-- <el-table :data="totalXmxz" size="small " border style="width: 100%">
                        <el-table-column prop="xmxz" label="项目性质"></el-table-column>
                        <el-table-column prop="xmsl" label="项目数量（个）" width="120"></el-table-column>
                        <el-table-column prop="sswtz" label="十四五投资（亿元）" :formatter="sswtz_formatter" width="150"></el-table-column>
                    </el-table> -->
                    <el-table :data="totalXmxzs" ref="theTable1" size="small" style="width: 100%;margin-bottom: 20px;" row-key="id" border default-expand-all :default-sort="{ prop: 'jsxz', order: 'descending' }" :tree-props="{children: 'children',hasChildren: 'hasChildren'}">
                        <el-table-column prop="sscs" label="所属城市"></el-table-column>
                        <el-table-column prop="key" label="项目类型"></el-table-column>
                        <el-table-column prop="xmsl" label="项目数量（个）" width="120"></el-table-column>
                        <el-table-column prop="sswtz" label="十四五投资（亿元）" :formatter="sswtz_formatter" width="150"></el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="24">
                    <h3 class="tit">行政区划</h3>
                    <el-table :data="totalXzqh" size="small " border style="width: 100%">
                        <el-table-column prop="xzqh" label="行政区划"></el-table-column>
                        <el-table-column prop="xmsl" label="项目数量（个）" width="120"></el-table-column>
                        <el-table-column prop="ztz" label="十四五投资（亿元）" :formatter="sswtz_formatter" width="150"></el-table-column>
                    </el-table>
                </el-col>
            </el-row>
        </el-main>
    </el-container>
</template>
<script>
export default {
    props: ["totalXmlx", "totalXmxz", "totalXzqh"],
    data() {
        return {
            totalXmlxs: [],
            totalXmxzs: [],
        };
    },
    mounted() {
        this.totalXmlxs = this.totalXmlx;
        this.totalXmxzs = this.totalXmxz;
    },
    watch: {
        totalXmlx(e) {
            this.totalXmlxs = e;
            var that = this;
            if (this.totalXmlxs) {
                setTimeout(() => {
                    that.totalXmlxs.forEach((i) => {
                        that.$refs.theTable.toggleRowExpansion(i, false);
                    });
                }, 100);
            }
        },
        totalXmxz(e) {
            this.totalXmxzs = e;
            var that = this;
            setTimeout(() => {
                that.totalXmxzs.forEach((i) => {
                    that.$refs.theTable1.toggleRowExpansion(i, false);
                });
            }, 100);
        },
    },
    methods: {
        handleClose(done) {
            done();
        },
        formatter(row) {
            return parseFloat(row.jsgm).toFixed(1);
        },
        ztz_formatter(row) {
            return parseFloat(row.ztz).toFixed(1);
        },
        sswtz_formatter(row) {
            return parseFloat(row.ztz / 10000).toFixed(1);
        },
    },
};
</script>
<style scoped>
</style>
