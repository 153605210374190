<template>
    <div>
        <el-container class="ova-y">
            <el-main class="p0">
                <el-row>
                    <el-col :span="24">
                        <div class="box" ref="init">
                            <div class="box-content">
                                <el-form :inline="true" class="form-inline formBox">
                                    <el-form-item label="项目名称">
                                        <el-input style="width: 200px" size="medium" v-model="xmmc" placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="项目性质">
                                        <el-cascader size="medium" style="width: 200px" v-model="checkedXmxz" :options="xmxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目类型">
                                        <el-cascader size="medium" style="width: 200px" v-model="checkedXmlx" :options="xmlxList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-button type="primary" style="margin-top:19px" size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                    <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">清空</el-button>
                                </el-form>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="plr12">
                    <el-col :span="24">
                        <div class="box">
                            <div class="box-title">
                                <span class="fright">
                                    <el-button @click="exportData" type="primary" size="mini" round icon="el-icon-upload2">导出</el-button>
                                    <el-button @click="baobiao = true" type="primary2" size="mini" round icon="el-icon-share">报表</el-button>
                                    <el-button @click="drawer = true;getTotal();" type="primary" size="mini" round icon="el-icon-s-data">汇总</el-button>
                                </span>
                                <h3 class="tit">水运列表</h3>
                            </div>
                            <div class="box-content">
                                <div class="tj">
                                    <div class="total-item total-item-1">
                                        <img src="../../assets/icon/tj1.png" alt="">
                                        <div class="t">
                                            <h5>项目数量<small>（个）</small></h5>
                                            <b class="date-num">{{ total }}</b>
                                        </div>
                                    </div>
                                    <div class="total-item total-item-2">
                                        <img src="../../assets/icon/tj5.png" alt="">
                                        <div class="t">
                                            <h5>总投资<small>（亿元）</small></h5>
                                            <b class="date-num">{{ ztz.toFixed(1) }}</b>
                                        </div>
                                    </div>

                                    <div class="total-item total-item-5">
                                        <img src="../../assets/icon/tj5.png" alt="">
                                        <div class="t">
                                            <h5>十四五投资<small>（亿元）</small></h5>
                                            <b class="date-num">{{ sswtz.toFixed(1) }}</b>
                                        </div>
                                    </div>
                                </div>

                                <el-table @sort-change="changeSort" @row-dblclick="openDetails" :data="tableData" size="small " :height="tableHeight" border :default-sort="{ prop: 'XH', order: 'ascending' }" style="width: 100%">
                                    <el-table-column fixed prop="" label="操作" :width="80" show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <!-- <i class="el-icon-location icons iconsyes" @click="showMaps(scope.row)"></i> -->
                                            <span class="newicon iconsyes" @click="openDetails(scope.row)"><i class="el-icon-info icons"></i>详情</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="XMMC" label="项目名称" show-overflow-tooltip :width="350" sortable></el-table-column>
                                    <el-table-column prop="XMXZ" label="项目性质" :width="150" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="XMLX" label="项目类型" :width="150" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="ZTZ" label="总投资（亿元）" :width="150" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="SSWTZ" label="“十四五”投资（亿元）" :width="180" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="JSGM" label="建设规模" show-overflow-tooltip></el-table-column>
                                </el-table>
                                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                                </el-pagination>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
        <el-drawer title="汇总" :visible.sync="drawer" :direction="direction" size="650px">
            <Hz :totalXmxz="totalXmxz" :totalXmlx="totalXmlx" />
        </el-drawer>

        <el-dialog :title="dialogDetails.XMMC" :visible.sync="dialogTableVisible">
            <div class="mb15">
                <el-button type="primary" v-if="btnviewshow" @click="handleChange1" size="small">完善信息</el-button>
                <el-button type="primary" v-if="btneditshow" @click="handleChange2(), submit()" size="small">保存</el-button>
                <el-button v-if="btneditshow" size="small" @click="handleChange2">取消</el-button>
            </div>
            <div class="view-card-info" v-if="viewshow">
                <div class="mycard-lg">
                    <table class="table">
                        <tr>
                            <th width="150">项目名称</th>
                            <td colspan="3">{{ dialogDetails.XMMC }}</td>
                        </tr>
                        <tr>
                            <th width="150">项目性质</th>
                            <td>{{ dialogDetails.XMXZ }}</td>
                            <th width="150">项目类型</th>
                            <td>{{ dialogDetails.XMLX }}</td>
                        </tr>
                        <tr>
                            <th>总投资</th>
                            <td>{{ dialogDetails.ZTZ }}</td>
                            <th>“十四五”投资</th>
                            <td>{{ dialogDetails.SSWZTZ }}</td>
                        </tr>
                        <tr>
                            <th>建设规模</th>
                            <td colspan="3">{{ dialogDetails.JSGM }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="edit-card-info" v-if="editshow">
                <el-alert title="填报完信息记得点击保存按钮" type="error" class="mb15">
                </el-alert>
                <el-form ref="formdata" :model="formdata">
                    <div class="mycard-lg">
                        <table class="table">
                            <tr>
                                <th width="150">项目名称</th>
                                <td colspan="3">
                                    <el-input v-model="formdata.XMMC" disabled></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th width="150">项目性质</th>
                                <td>
                                    <el-input v-model="formdata.XMXZ" disabled> </el-input>
                                </td>
                                <th width="150">项目类型</th>
                                <td>
                                    <el-input v-model="formdata.XMLX" disabled></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>总投资</th>
                                <td>
                                    <el-input v-model="formdata.ZTZ"></el-input>
                                </td>
                                <th>“十四五”投资</th>
                                <td>
                                    <el-input v-model="formdata.SSWZTZ"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>建设规模</th>
                                <td colspan="3">
                                    <el-input v-model="formdata.JSGM"></el-input>
                                </td>
                            </tr>
                        </table>
                    </div>
                </el-form>
            </div>
        </el-dialog>

        <el-drawer title="报表" :visible.sync="baobiao" :direction="'rtl'" size="100%">
            <BbWater v-if="baobiao" />
        </el-drawer>
    </div>
</template>
<script>
import Hz from "@/components/Query/Water/Hz.vue"; //汇总
import { exportData } from "../../js/util/export.js"; //导出表格
import BbWater from "./BbWaters.vue";

export default {
    name: "Home",
    components: {
        Hz,
        BbWater,
    },
    data() {
        return {
            sortData: {
                field: "XH",
                sort: "asc",
            },
            isedit: false,
            viewshow: true,
            editshow: false,
            btnviewshow: true,
            btneditshow: false,
            formdata: {
                XMMC: "",
                XMXZ: "",
                XMLX: "",
                ZTZ: "",
                SSWTZ: "",
                JSGM: "",
            },
            baobiao: false,
            tableHeight: 0, //表格高度
            activeIndex: true, //显隐高级搜索
            dialogTableVisible: false,
            dialogDetails: {},
            activecollapse: "1",
            /**查询条件 begin*/
            xmmc: "", //项目名称
            xmnd: "", //项目年度
            checkedXmxz: [], //项目性质
            checkedXmlx: [], //项目类型
            /**查询条件 end*/
            xmxzList: [
                {
                    value: "续建项目",
                    label: "续建项目",
                    disabled: false,
                },
                {
                    value: "“十四五”新开工项目",
                    label: "“十四五”新开工项目",
                    disabled: false,
                },
                {
                    value: "“十四五”跨“十五五”",
                    label: "“十四五”跨“十五五”",
                    disabled: false,
                },
                {
                    value: "谋划项目",
                    label: "谋划项目",
                    disabled: false,
                },
            ],
            xmlxList: [
                {
                    value: "航道项目",
                    label: "航道项目",
                    disabled: false,
                },
                {
                    value: "港口项目",
                    label: "港口项目",
                    disabled: false,
                },
                {
                    value: "航电枢纽",
                    label: "航电枢纽",
                    disabled: false,
                },
                {
                    value: "支持系统",
                    label: "支持系统",
                    disabled: false,
                },
            ],
            currentPage: 1, //当前页
            pageSize: 30, //每页记录数
            isIndeterminateXmxz: true,
            isIndeterminateXmlx: true,
            drawer: false,
            drawer1: false,
            direction: "rtl",
            props: { multiple: true },
            tableData: [], //请求列表
            total: 0, //请求记录数
            ztz: 0,
            sswtz: 0,
            totalXmxz: [], //汇总按项目性质
            totalXmlx: [], //汇总按项目类型
            xmlx: "航道项目",
        };
    },
    mounted() {
        var that = this;
        let height = this.$refs.init.offsetHeight;
        setTimeout(() => {
            that.tableHeight = document.body.offsetHeight - height - 265;
        }, 100);
        this.search();
    },
    methods: {
        changeSort(val) {
            if (val.order) {
                this.sortData = {
                    field: val.prop,
                    sort: val.order == "ascending" ? "asc" : "desc",
                };
            } else {
                this.sortData = {
                    field: "XMXH",
                    sort: "asc",
                };
            }
            this.tableData = [];
            this.search();
        },
        handleChange1() {
            this.viewshow = false;
            this.editshow = true;
            this.btneditshow = true;
            this.btnviewshow = false;
        },
        handleChange2() {
            this.viewshow = true;
            this.editshow = false;
            this.btneditshow = false;
            this.btnviewshow = true;
        },
        submit() {
            this.http
                .post("/api/Plan_water/UpdateWater", this.formdata)
                .then((res) => {
                    if (res.message == "修改成功") {
                        this.$message("修改成功");
                        this.search();
                        this.handleChange2();
                        this.openDetails(this.formdata);
                    }
                });
        },
        openDetails(row) {
            this.xmlx = row.XMLX;
            this.dialogDetails = row;
            this.dialogTableVisible = true;
            this.isedit = false;
            this.formdata = JSON.parse(JSON.stringify(row));
            this.handleChange2();
        },
        exportData() {
            exportData("Plan_water", this.postData());
        },
        //查询
        search() {
            this.getPageTotal(); //查汇总
            this.http
                .post(
                    "/api/Plan_water/GetPageData",
                    this.postData(),
                    "正在请求数据，请稍候..."
                )
                .then((res) => {
                    if (res.status == 0) {
                        this.total = res.total;
                        this.tableData = res.rows;

                        console.log(res);
                    }
                });
        },
        //汇总
        getPageTotal() {
            this.http
                .post("/api/Plan_water/GetPageTotal", this.postData())
                .then((res) => {
                    console.log(res);
                    this.ztz = res.ztz;
                    this.sswtz = res.sswtz;
                });
        },
        postData() {
            //项目名称
            var query_xmmc = {
                Name: "XMMC",
                Value: this.xmmc,
                DisplayType: "like",
            };
            //项目年度
            var query_xmnd = {
                Name: "XMND",
                Value: this.xmnd,
                DisplayType: "Equal",
            };

            //项目性质
            var query_xmxz = {
                Name: "XMXZ",
                Value: this.checkedXmxz.join(","),
                DisplayType: "checkbox",
            };
            //项目类型
            var query_xmlx = {
                Name: "XMLX",
                Value: this.checkedXmlx.join(","),
                DisplayType: "checkbox",
            };

            var postData = {
                page: this.currentPage,
                rows: this.pageSize,
                Sort: this.sortData.field,
                Order: this.sortData.sort,
                wheres: JSON.stringify([
                    query_xmmc,
                    query_xmnd,
                    query_xmxz,
                    query_xmlx,
                ]),
            };
            return postData;
        },
        clearCondition() {
            this.xmmc = "";
            this.xmnd = "";
            this.checkedXmxz = [];
            this.checkedXmlx = [];
        },
        getTotal() {
            console.log(this.postData());
            //汇总
            this.http
                .post("/api/Plan_water/GetTotal", this.postData())
                .then((total) => {
                    this.totalXmxz = total.xmxz;
                    this.totalXmlx = total.xmlx;
                });
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.search();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.search();
        },
    },
    watch: {},
};
</script>
