<template>
    <div>
        <el-container class="ova-y">
            <el-main class="p0">
                <el-row>
                    <el-col :span="24">
                        <div class="box" ref="init">
                            <div class="box-content">
                                <el-form :inline="true" class="form-inline formBox">
                                    <el-form-item label="行政区划">
                                        <el-cascader style="width: 200px" size="medium" v-model="region" :options="regions" :props="{ expandTrigger: 'hover' }" @change="handleChange"></el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目名称">
                                        <el-input style="width: 200px" size="medium" v-model="xmmc" placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="建设类别">
                                        <el-select v-model="gllx" placeholder="请选择" style="width: 200px">
                                            <el-option label="" value="">全部</el-option>
                                            <el-option label="高速公路" value="高速公路">高速公路</el-option>
                                            <el-option label="普通国道" value="普通国道">普通国道</el-option>
                                            <el-option label="普通省道" value="普通省道">普通省道</el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="项目性质">
                                        <el-cascader size="medium" style="width: 200px" v-model="checkedXmxz" :options="xmxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="建设性质">
                                        <el-cascader size="medium" style="width: 200px" v-model="checkedJsxz" :options="jsxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目功能">
                                        <el-cascader size="medium" style="width: 200px" v-model="checkedXmgn" :options="xmgnList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-button type="primary" style="margin-top:19px" size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                    <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">清空</el-button>
                                </el-form>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <div class="plr12">
                    <el-row>
                        <el-col :span="24">
                            <div class="box">
                                <div class="box-title">
                                    <span class="fright">
                                        <el-button @click="exportData" type="primary" size="mini" round icon="el-icon-upload2">导出</el-button>
                                        <el-button @click="baobiao = true" type="primary2" size="mini" round icon="el-icon-share">报表</el-button>
                                        <el-button @click="drawer = true;getTotal();" type="primary" size="mini" round icon="el-icon-s-data">汇总</el-button>
                                        <el-button @click="drawer1 = true;getRoadTotal();" type="primary" size="mini" round icon="el-icon-s-flag">公路现状</el-button>
                                        <!-- <el-button @click="ischecks=!ischecks" type="primary" size="mini" round icon="el-icon-s-operation"></el-button> -->
                                    </span>
                                    <h3 class="tit">公路列表</h3>
                                </div>
                                <div class="fieldList" :class="{active:ischecks}">
                                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAllField" @change="CheckAllFieldChange">全选</el-checkbox>
                                    <el-checkbox-group v-model="checkFieldList" @change="CheckedFieldChange">
                                        <el-checkbox v-for="item in fieldList" :label="item" :key="item">{{item}}</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                                <div class="box-content">
                                    <div class="tj">
                                        <div class="total-item total-item-1">
                                            <img src="../../assets/icon/tj1.png" alt="">
                                            <div class="t">
                                                <h5>项目数量<small>（个）</small></h5>
                                                <b class="date-num">{{ xmsl }}</b>
                                            </div>
                                        </div>

                                        <div class="total-item total-item-2">
                                            <img src="../../assets/icon/tj2.png" alt="">
                                            <div class="t">
                                                <h5>建设规模-公路<small>（公里）</small></h5>
                                                <b class="date-num">{{ jsgm }}</b>
                                            </div>
                                        </div>

                                        <div class="total-item total-item-3">
                                            <img src="../../assets/icon/tj3.png" alt="">
                                            <div class="t">
                                                <h5>建设规模-桥隧<small>（延米）</small></h5>
                                                <b class="date-num">{{ jsqmqs }}</b>
                                            </div>
                                        </div>

                                        <div class="total-item total-item-4">
                                            <img src="../../assets/icon/tj4.png" alt="">
                                            <div class="t">
                                                <h5>总投资<small>（亿元）</small></h5>
                                                <b class="date-num">{{ ztz.toFixed(1) }}</b>
                                            </div>
                                        </div>

                                        <div class="total-item total-item-5">
                                            <img src="../../assets/icon/tj5.png" alt="">
                                            <div class="t">
                                                <h5>十四五投资<small>（亿元）</small></h5>
                                                <b class="date-num">{{ sswtz.toFixed(1) }}</b>
                                            </div>
                                        </div>
                                    </div>
                                    <el-table @sort-change="changeSort" @row-dblclick="openDetails" ref="table" :data="tableData" size="small" :height="tableHeight" border style="width: 100%">
                                        <el-table-column fixed prop="" label="操作" :width="210">
                                            <template slot-scope="scope">
                                                <span class="newicon iconsyes" :class="{iconsyes1:!scope.row.XH}" @click="showMaps(scope.row)"><i class="el-icon-location icons"></i>定位</span>
                                                <span class="newicon iconsyes" @click="openDetails(scope.row)"><i class="el-icon-info icons"></i>详情</span>
                                                <span class="newicon iconsyes" :class="{iconsyes1:scope.row.SFQQXM =='否'}" @click="openLct(scope.row)"><i class="el-icon-share icons"></i>前期流程</span>
                                            </template>
                                        </el-table-column>
                                        <!-- v-if="checkFieldList.indexOf('所在地市') >= 0" -->
                                        <el-table-column fixed prop="SSDS" label="所在地市" sortable :width="100" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="SSX" label="所属县（市）" sortable :width="150" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="XMMC" label="项目名称" sortable show-overflow-tooltip :width="320"></el-table-column>
                                        <el-table-column prop="LXBH" label="路线编号" sortable :width="150" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="XMXZ" label="项目性质" sortable :width="150" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="JSXZ" label="建设性质" sortable :width="150" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="XMLX" label="项目类型" sortable :width="150" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="GLLX" label="建设类别" sortable :width="150" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="KGN" label="开工年" sortable :width="150" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="WGN" label="完工年" sortable :width="150" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="JSGMGS" label="建设规模高速（公里）" sortable :width="170" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="JSGMYJ" label="建设规模一级（公里）" sortable :width="170" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="JSGMEJ" label="建设规模二级（公里）" sortable :width="170" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="JSGMSJ" label="建设规模三级（公里）" sortable :width="170" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="JSGMQS" label="建设规模桥隧（数量）" sortable :width="170" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="ZTZ" label="总投资" sortable :width="150" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="ZYCGS" label="中央车购税" sortable :width="150" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="SAWZTJ" label="“十三五”投资（亿元）" sortable :width="170" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="SAWZYCGS" label="“十三五”中央车购税（亿元）" sortable :width="210" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="SSWZTZ" label="“十四五”投资" sortable :width="160" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="SSWZYCGS" label="“十四五”中央车购税" sortable :width="210" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="SSWSJZJ" label="“十四五”省级资金" sortable :width="170" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="SSWSXZCTZ" label="“十四五”市县征拆投资" sortable :width="170" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="SWWZTZ" label="“十五五”投资" sortable :width="160" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="SWWZYCGS" label="“十五五”中央车购税" sortable :width="170" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="SFYDSXW" label="是否地市行文" sortable :width="160" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="XMLY" label="项目来源" sortable :width="160" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="QQGZJZ" label="前期工作进展" sortable :width="160" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="XMGN" label="项目功能" sortable :width="160" show-overflow-tooltip></el-table-column>
                                        <el-table-column prop="BZ" label="备注" show-overflow-tooltip></el-table-column>
                                    </el-table>
                                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                                    </el-pagination>
                                </div>
                            </div>
                        </el-col>
                    </el-row>

                </div>
            </el-main>
        </el-container>
        <el-drawer title="汇总" :visible.sync="drawer" :direction="direction" size="910px">
            <Hz v-if="drawer" :totalXmxz="totalXmxz" :totalJsxz="totalJsxz" :totalXzqh="totalXzqh" />
        </el-drawer>
        <el-drawer title="公路现状" :visible.sync="drawer1" :direction="direction" size="600px">
            <Glxz :total_glxz_xzdj="total_glxz_xzdj" :total_glxz_jsdj="total_glxz_jsdj" :total_glxz_gsdjgl="total_glxz_gsdjgl" :total_glxz_xycdjgl="total_glxz_xycdjgl" />
        </el-drawer>

        <el-dialog :title="dialogDetails.XMMC" :visible.sync="dialogTableVisible" class="dialog-md">
            <div class="mb15">
                <el-button type="primary" v-if="btnviewshow" @click="handleChange1" size="small">完善信息</el-button>
                <el-button type="primary" v-if="btneditshow" @click="handleChange2(), submit1()" size="small">保存</el-button>
                <el-button v-if="btneditshow" size="small" @click="handleChange2">取消</el-button>
            </div>
            <div class="view-card-info" v-if="viewshow">
                <div class="mycard-lg">
                    <table class="table">
                        <tr>
                            <th width="150">项目名称</th>
                            <td colspan="3">{{ dialogDetails.XMMC }}</td>
                            <th width="150">路线编号</th>
                            <td>{{ dialogDetails.LXBH || "-" }}</td>
                        </tr>
                        <tr>
                            <th>所在地市</th>
                            <td>{{ dialogDetails.SSDS || "-" }}</td>
                            <th width="190">所属县（市）</th>
                            <td>{{ dialogDetails.SSX || "-" }}</td>
                            <th>项目性质</th>
                            <td>{{ dialogDetails.XMXZ || "-" }}</td>
                        </tr>
                        <tr>
                            <th>建设性质</th>
                            <td>{{ dialogDetails.JSXZ || "-" }}</td>
                            <th>项目类型</th>
                            <td>{{ dialogDetails.XMLX || "-" }}</td>
                            <th>建设类别</th>
                            <td>{{ dialogDetails.GLLX || "-" }}</td>
                        </tr>
                        <tr>
                            <th>开工年</th>
                            <td>{{ dialogDetails.KGN || "-" }}</td>
                            <th>完工年</th>
                            <td>{{ dialogDetails.WGN || "-" }}</td>
                            <th>建设规模高速（公里）</th>
                            <td>{{ dialogDetails.JSGMGS || "-" }}</td>
                        </tr>
                        <tr>
                            <th>建设规模一级（公里）</th>
                            <td>{{ dialogDetails.JSGMYJ || "-" }}</td>
                            <th>建设规模二级（公里）</th>
                            <td>{{ dialogDetails.JSGMEJ || "-" }}</td>
                            <th>建设规模三级（公里）</th>
                            <td>{{ dialogDetails.JSGMSJ || "-" }}</td>
                        </tr>
                        <tr>
                            <th>建设规模桥隧（数量）</th>
                            <td>{{ dialogDetails.JSGMQS || "-" }}</td>
                            <th>总投资</th>
                            <td>{{ dialogDetails.ZTZ || "-" }}</td>
                            <th>中央车购税</th>
                            <td>{{ dialogDetails.ZYCGS || "-" }}</td>
                        </tr>
                        <tr>
                            <th>“十三五”投资（亿元）</th>
                            <td>{{ dialogDetails.SAWZTJ || "-" }}</td>
                            <th>“十三五”中央车购税（亿元）</th>
                            <td>{{ dialogDetails.SAWZYCGS || "-" }}</td>
                            <th>“十四五”投资</th>
                            <td>{{ dialogDetails.SSWZTZ || "-" }}</td>
                        </tr>
                        <tr>
                            <th>“十四五”中央车购税</th>
                            <td>{{ dialogDetails.SSWZYCGS || "-" }}</td>
                            <th>“十四五”省级资金</th>
                            <td>{{ dialogDetails.SSWSJZJ || "-" }}</td>
                            <th>“十四五”市县征拆投资</th>
                            <td>{{ dialogDetails.SSWSXZCTZ || "-" }}</td>
                        </tr>
                        <tr>
                            <th>“十五五”投资</th>
                            <td>{{ dialogDetails.SWWZTZ || "-" }}</td>
                            <th>“十五五”中央车购税</th>
                            <td>{{ dialogDetails.SWWZYCGS || "-" }}</td>
                            <th>是否地市行文</th>
                            <td>{{ dialogDetails.SFYDSXW || "-" }}</td>
                        </tr>
                        <tr>
                            <th>项目来源</th>
                            <td>{{ dialogDetails.XMLY || "-" }}</td>
                            <th>前期工作进展</th>
                            <td>{{ dialogDetails.QQGZJZ || "-" }}</td>
                            <th>备注</th>
                            <td colspan="3">{{ dialogDetails.BZ || "-" }}</td>
                        </tr>
                        <tr></tr>
                    </table>
                </div>
            </div>
            <div class="edit-card-info" v-if="editshow">
                <el-alert title="填报完信息记得点击保存按钮" type="error" class="mb15">
                </el-alert>
                <el-form ref="formdata" :model="formdata">
                    <div class="mycard-lg">
                        <table class="table">
                            <tr>
                                <th width="150">项目名称</th>
                                <td colspan="3">
                                    <el-input v-model="formdata.XMMC" disabled></el-input>
                                </td>
                                <th width="150">路线编号</th>
                                <td>
                                    <el-input v-model="formdata.LXBH"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>所在地市</th>
                                <td>
                                    <el-input v-model="formdata.SSDS" disabled></el-input>
                                </td>
                                <th width="190">所属县（市）</th>
                                <td>
                                    <el-input v-model="formdata.SSX" disabled></el-input>
                                </td>
                                <th>项目性质</th>
                                <td>
                                    <el-input v-model="formdata.XMXZ" disabled></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>建设性质</th>
                                <td>
                                    <!-- <el-input v-model="formdata.JSXZ"></el-input> -->
                                    <el-select v-model="formdata.JSXZ" placeholder="请选择" :disabled="formdata.GLLX == '高速公路'">
                                        <el-option label="新建" value="新建"></el-option>
                                        <el-option label="升级改造" value="升级改造"></el-option>
                                        <el-option label="原级改造" value="原级改造"></el-option>
                                        <el-option label="路面改造" value="路面改造"></el-option>
                                        <el-option label="改扩建" value="改扩建"></el-option>
                                    </el-select>
                                </td>
                                <th>项目类型</th>
                                <td>
                                    <el-input v-model="formdata.XMLX"></el-input>
                                </td>
                                <th>建设类别</th>
                                <td>
                                    <el-input v-model="formdata.GLLX" disabled></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>开工年</th>
                                <td>
                                    <el-input v-model="formdata.KGN"></el-input>
                                </td>
                                <th>完工年</th>
                                <td>
                                    <el-input v-model="formdata.WGN"></el-input>
                                </td>
                                <th>建设规模高速（公里）</th>
                                <td>
                                    <el-input v-model="formdata.JSGMGS"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>建设规模一级（公里）</th>
                                <td>
                                    <el-input v-model="formdata.JSGMYJ"></el-input>
                                </td>
                                <th>建设规模二级（公里）</th>
                                <td>
                                    <el-input v-model="formdata.JSGMEJ"></el-input>
                                </td>
                                <th>建设规模三级（公里）</th>
                                <td>
                                    <el-input v-model="formdata.JSGMSJ"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>建设规模桥隧（数量）</th>
                                <td>
                                    <el-input v-model="formdata.JSGMQS"></el-input>
                                </td>
                                <th>总投资</th>
                                <td>
                                    <el-input v-model="formdata.ZTZ"></el-input>
                                </td>
                                <th>中央车购税</th>
                                <td>
                                    <el-input v-model="formdata.ZYCGS"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>“十三五”投资（亿元）</th>
                                <td>
                                    <el-input v-model="formdata.SAWZTJ"></el-input>
                                </td>
                                <th>“十三五”中央车购税（亿元）</th>
                                <td>
                                    <el-input v-model="formdata.SAWZYCGS"></el-input>
                                </td>
                                <th>“十四五”投资</th>
                                <td>
                                    <el-input v-model="formdata.SSWZTZ"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>“十四五”中央车购税</th>
                                <td>
                                    <el-input v-model="formdata.SSWZYCGS"></el-input>
                                </td>
                                <th>“十四五”省级资金</th>
                                <td>
                                    <el-input v-model="formdata.SSWSJZJ"></el-input>
                                </td>
                                <th>“十四五”市县征拆投资</th>
                                <td>
                                    <el-input v-model="formdata.SSWSXZCTZ"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>“十五五”投资</th>
                                <td>
                                    <el-input v-model="formdata.SWWZTZ"></el-input>
                                </td>
                                <th>“十五五”中央车购税</th>
                                <td>
                                    <el-input v-model="formdata.SWWZYCGS"></el-input>
                                </td>
                                <th>是否地市行文</th>
                                <td>
                                    <el-input v-model="formdata.SFYDSXW"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>项目来源</th>
                                <td>
                                    <el-input v-model="formdata.XMLY"></el-input>
                                </td>
                                <th>前期工作进展</th>
                                <td>
                                    <el-input v-model="formdata.QQGZJZ"></el-input>
                                </td>
                                <th>备注</th>
                                <td colspan="3">
                                    <el-input v-model="formdata.BZ"></el-input>
                                </td>
                            </tr>
                            <tr></tr>
                        </table>
                    </div>
                </el-form>
            </div>

        </el-dialog>
        <el-dialog :title="dialogTableVisibleData.XMMC" :visible.sync="dialogTableVisibleMap" class="mapdialog mapdialog1">
            <!-- <Map v-if="dialogTableVisibleMap" :dialogData="dialogTableVisibleData" /> -->
            <AMap v-if="dialogTableVisibleMap" :dialogData="dialogTableVisibleData" />
        </el-dialog>
        <!-- <el-dialog :title="'报表'" :visible.sync="baobiao" class="mapdialog mapdialog1">
            <BbRoad v-if="baobiao" />
        </el-dialog> -->
        <el-drawer title="报表" :visible.sync="baobiao" :direction="'rtl'" size="100%">
            <BbRoad v-if="baobiao" />
        </el-drawer>
        <!-- <el-drawer :title="lctname" :visible.sync="lct" :direction="'rtl'" size="100%">
            <LctGsgl v-if="lct && rowlct == '高速公路'" />
            <LctGsd v-if="lct && rowlct != '高速公路'" />
        </el-drawer> -->
        <el-drawer onselectstart="return false;" :title="lctname" size="100%" style="overflow:auto;padding:0" :append-to-body="true" :visible.sync="lct">
            <FlowXmk :id="SSWXMID"></FlowXmk>
        </el-drawer>
    </div>
</template>
<script>
import Hz from "@/components/Query/Road/Hz.vue"; //汇总
import Glxz from "@/components/Query/Road/Glxz.vue"; //公路现状
import { exportData } from "../../js/util/export.js"; //导出表格
// import Map from "../Map/Index.vue";
import AMap from "../AMap/Box.vue";
import BbRoad from "./BbRoads.vue";
// import LctGsgl from "./LctGsgl.vue";
// import LctGsd from "./LctGsd.vue";
import FlowXmk from "../../components/Query/Road/FlowXmk";

export default {
    name: "Home",
    components: {
        Hz,
        Glxz,
        // Map,
        AMap,
        BbRoad,
        // LctGsgl,
        // LctGsd,
        FlowXmk,
    },
    data() {
        return {
            ischecks: false,
            isIndeterminate: false,
            checkAllField: true,
            checkFieldList: [],
            fieldList: [
                "所在地市",
                "所属县（市）",
                "项目名称",
                "路线编号",
                "项目性质",
                "建设性质",
                "项目类型",
                "建设类别",
                "开工年",
                "完工年",
                "建设规模高速（公里）",
                "建设规模一级（公里）",
                "建设规模二级（公里）",
                "建设规模三级（公里）",
                "建设规模桥隧（数量）",
                "总投资",
                "中央车购税",
                "“十三五”投资（亿元）",
                "“十三五”中央车购税（亿元）",
                "“十四五”投资",
                "“十四五”中央车购税",
                "“十四五”省级资金",
                "“十四五”市县征拆投资",
                "“十五五”投资",
                "“十五五”中央车购税",
                "是否地市行文",
                "项目来源",
                "前期工作进展",
                "项目功能",
                "备注",
            ],
            SSWXMID: "",
            formdata: {
                XMMC: "",
                LXBH: "",
                SSDS: "",
                SSX: "",
                XMXZ: "",
                JSXZ: "",
                XMLX: "",
                GLLX: "",
                KGN: "",
                WGN: "",
                JSGMGS: "",
                JSGMYJ: "",
                JSGMEJ: "",
                JSGMSJ: "",
                JSGMQS: "",
                ZTZ: "",
                ZYCGS: "",
                SSWZTZ: "",
                SSWZYCGS: "",
                SSWSJZJ: "",
                SSWSXZCTZ: "",
                SWWZTZ: "",
                SWWZYCGS: "",
                SFYDSXW: "",
                XMLY: "",
                QQGZJZ: "",
                BZ: "",
            },
            viewshow: true,
            editshow: false,
            btnviewshow: true,
            btneditshow: false,
            formdata1: {},
            formdata2: {},
            isedit: false,
            lct: false,
            lctname: "",
            baobiao: false,
            dialogTableVisibleMap: false,
            dialogTableVisibleData: {},
            activecollapse: "1",
            dialogTableVisible: false,
            dialogDetails: {},
            tableHeight: 0, //表格高度
            /**查询条件 begin*/
            xmmc: "", //项目名称
            xmnd: "", //项目年度
            gllx: "", //建设类别
            checkedXmxz: [], //项目性质
            checkedJsxz: [], //建设性质
            checkedXmlx: [], //项目类型
            checkedXmgn: [], //项目类型
            region: [""], //已选中行政区划
            qqgzjz: [], //前期工作进展
            /**查询条件 end*/

            xmxzShow: true, //项目性质查询条件显隐
            jsxzShow: true, //建设性质查询条件显隐
            xmgnShow: true, //建设性质查询条件显隐
            xmlxShow: true, //项目类型查询条件显隐
            xmgnList: [
                { value: "出省通道", label: "出省通道", disabled: false },
                {
                    value: "区域内连通农林场、乡镇、景区",
                    label: "区域内连通农林场、乡镇、景区",
                    disabled: false,
                },
                { value: "城市过境段", label: "城市过境段", disabled: false },
                { value: "瓶颈路段", label: "瓶颈路段", disabled: false },
                { value: "砂石路", label: "砂石路", disabled: false },
                { value: "跨区域通道", label: "跨区域通道", disabled: false },
                {
                    value: "重要节点连接国省干线",
                    label: "重要节点连接国省干线",
                    disabled: false,
                },
            ],
            jsxzList: [
                { value: "新建", label: "新建", disabled: false },
                { value: "升级改造", label: "升级改造", disabled: false },
                { value: "原级改造", label: "原级改造", disabled: false },
                { value: "路面改造", label: "路面改造", disabled: false },
                { value: "改扩建", label: "改扩建", disabled: false },
            ],
            xmxzList: [
                {
                    value: "“十三五”续建项目",
                    label: "“十三五”续建项目",
                    disabled: false,
                },
                {
                    value: "“十四五”谋划项目",
                    label: "“十四五”谋划项目",
                    disabled: false,
                },
                {
                    value: "“十四五”新开工项目",
                    label: "“十四五”新开工项目",
                    disabled: false,
                },
                {
                    value: "谋划项目",
                    label: "谋划项目",
                    disabled: false,
                },
                {
                    value: "2021年新开工项目",
                    label: "2021年新开工项目",
                    disabled: false,
                },
                {
                    value: "“十四五”其他项目",
                    label: "“十四五”其他项目",
                    disabled: false,
                },
            ],
            xmlxList: ["正选项目", "备选项目"],
            currentPage: 1, //当前页
            pageSize: 30, //每页记录数
            isIndeterminateXmxz: true,
            isIndeterminateXmgn: true,
            isIndeterminateJsxz: true,
            isIndeterminateXmlx: true,
            drawer: false,
            drawer1: false,
            direction: "rtl",
            props: { multiple: true },
            rowlct: "",
            regions: [
                {
                    value: "",
                    label: "黑龙江省",
                },
            ],
            tableData: [], //请求列表
            total: 0, //请求记录数
            xmsl: 0,
            jsgm: 0,
            jsqmqs: 0,
            ztz: 0,
            sswtz: 0,
            totalXmxz: [], //汇总按项目性质
            totalJsxz: [], //汇总按建设性质
            totalXzqh: [], //汇总按行政区划
            total_glxz_xzdj: [], //公路现状行政等级
            total_glxz_jsdj: [], //公路现状技术等级
            total_glxz_gsdjgl: [], //公路等级按国省道
            total_glxz_xycdjgl: [], //公路等级按县乡村道
            sortData: {
                field: "XMXH",
                sort: "asc",
            },
        };
    },
    mounted() {
        var that = this;
        let height = this.$refs.init.offsetHeight;
        setTimeout(() => {
            that.tableHeight = document.body.offsetHeight - height - 265;
        }, 100);
        // console.log(this.tableHeight, 456);
        this.checkFieldList = this.fieldList;
        this.getRegion();
        this.search();
    },
    methods: {
        changeSort(val) {
            if (val.order) {
                this.sortData = {
                    field: val.prop,
                    sort: val.order == "ascending" ? "asc" : "desc",
                };
                if (val.prop == "SSDS") {
                    this.sortData.field = "DSBM";
                }
                if (val.prop == "SSX") {
                    this.sortData.field = "QXBM";
                }
            } else {
                this.sortData = {
                    field: "XMXH",
                    sort: "asc",
                };
            }
            this.tableData = [];
            this.search();
        },
        columnWith(v) {
            // console.log(v, 4516);
        },
        CheckAllFieldChange(val) {
            this.checkFieldList = val ? this.fieldList : [];
            this.isIndeterminate = false;
        },
        CheckedFieldChange(value) {
            let checkedCount = value.length;
            this.checkAllField = checkedCount === this.fieldList.length;
            this.isIndeterminate =
                checkedCount > 0 && checkedCount < this.fieldList.length;
        },
        handleChange1() {
            this.viewshow = false;
            this.editshow = true;
            this.btneditshow = true;
            this.btnviewshow = false;
        },
        handleChange2() {
            this.viewshow = true;
            this.editshow = false;
            this.btneditshow = false;
            this.btnviewshow = true;
        },
        submit1() {
            this.http
                .post("/a" + "pi/Plan_road/UpdateRoad", this.formdata)
                .then((res) => {
                    if (res.message == "修改成功") {
                        this.$message("修改成功");
                        this.search();
                        this.openDetails(this.formdata);
                    }
                });
        },
        chechfieldxmlx() {
            if (this.gllx == "普通省道") {
                return false;
            } else {
                return true;
            }
        },
        checkfieldjsxz() {
            if (this.gllx == "高速公路") {
                return true;
            } else {
                return false;
            }
        },
        checkfieldxmxz(e) {
            var arr = [];
            if (this.gllx == "高速公路") {
                arr = [
                    "“十三五”续建项目",
                    "2021年新开工项目",
                    "“十四五”其他项目",
                    "“十四五”谋划项目",
                ];
                if (arr.indexOf(e) >= 0) {
                    return false;
                } else {
                    return true;
                }
            }
            if (this.gllx == "普通国道") {
                arr = [
                    "“十三五”续建项目",
                    "“十四五”新开工项目",
                    "谋划项目",
                    "“十四五”谋划项目",
                ];
                if (arr.indexOf(e) >= 0) {
                    return false;
                } else {
                    return true;
                }
            }
            if (this.gllx == "普通省道") {
                arr = [
                    "“十三五”续建项目",
                    "“十四五”新开工项目",
                    "“十四五”备选项目",
                    // "2021年已安排计划项目",
                    // "2022年建设项目",
                    // "2023年建设项目",
                    // "2024-2025年建设项目",
                ];
                if (arr.indexOf(e) >= 0) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        showMaps(row) {
            if (row.XH) {
                this.dialogTableVisibleData = row;
                this.dialogTableVisibleMap = true;
            }
        },
        openLct(row) {
            if (row.SFQQXM == "否") {
                return false;
            }
            this.rowlct = row.GLLX;
            this.lctname =
                row.GLLX == "高速公路"
                    ? "国家高速公路项目前期审批流程图"
                    : "黑龙江省国省道建设项目前期工作审批环节流程图";
            this.http
                .post(
                    "/api/Plan_high_national_early/GetData",
                    {
                        page: 1,
                        rows: 1000,
                        Sort: "DSBM",
                        Order: "asc",
                        wheres: JSON.stringify([
                            {
                                Name: "SSWGHXMID",
                                Value: row.ID,
                                DisplayType: "equal",
                            },
                        ]),
                    },
                    "正在请求数据，请稍候..."
                )
                .then((res) => {
                    if (res.status == 0) {
                        if (res.rows.length != 0) {
                            this.SSWXMID = row.ID;
                            this.lct = true;
                        } else {
                            this.$message.warning("该项目暂未绑定前期项目");
                        }
                    }
                });

            // console.log(row.GLLX);console
            // this.rowlct = row.GLLX;
            // if (row.GLLX == "高速公路") {
            //     this.lctname = "国家高速公路项目前期审批流程图";
            // } else {
            //     this.lctname = "黑龙江省国省道建设项目前期工作审批环节流程图";
            // }
            // this.lct = true;
        },
        openDetails(row) {
            this.isedit = false;

            // console.log(row);
            this.isedit = false;
            this.activecollapse = "1";
            this.dialogDetails = {};
            this.dialogDetails = row;
            this.dialogTableVisible = true;
            if (row.GLLX == "高速公路") {
                this.formdata1 = row;
            } else {
                this.formdata2 = row;
            }
            this.formdata = JSON.parse(JSON.stringify(row));
            // this.row = row;
            // this.$router.push({
            //     path: "/tabs",
            //     query: { dis: this.row.id },
            // });
            this.handleChange2();
        },
        exportData() {
            exportData("Plan_road", this.postData());
        },
        getRegion() {
            var postData = { SSDS: "", SSX: "" };
            this.http
                .post("/api/Base_area/getAdminDivTree", postData)
                .then((res) => {
                    // console.log(res);
                    res.map((r) => {
                        r.value = r.label;
                        r.children.map((rr) => {
                            rr.value = rr.label;
                        });
                    });
                    this.regions = res;
                });

            // this.http.post("/api/Plan_road/getRegion", postData).then((res) => {
            //     //遍历地市
            //     res.forEach((v) => {
            //         var item = {};
            //         var qxList = [];
            //         //遍历区县
            //         for (var i = 0; i < v.length; i++) {
            //             var qx = v[i];
            //             //设置地市名称
            //             if (i == 0) {
            //                 item.value = qx.ssds;
            //                 item.label = qx.ssds;
            //                 qxList.push({ value: "", label: "全部" });
            //             }

            //             qxList.push({ value: qx.ssx, label: qx.ssx });
            //             item.children = qxList;
            //         }
            //         this.regions.push(item);
            //     });
            // });
        },
        //查询
        search() {
            this.$refs.table.bodyWrapper.scrollTop = 0; //滚动条回顶部
            this.getPageTotal(); //查汇总
            // /api/Plan_high_national_early/GetPageData
            // "/api/Plan_road/GetPageData",
            this.http
                .post(
                    "/api/Plan_road/GetPageData",
                    this.postData(),
                    "正在请求数据，请稍候..."
                )
                .then((res) => {
                    if (res.status == 0) {
                        // res.rows.forEach(r=>{
                        //     r.width = 100;
                        // })
                        this.total = res.total;
                        this.tableData = res.rows;

                        // console.log(res);
                    }
                });
        },
        //汇总
        getPageTotal() {
            this.http
                .post("/api/Plan_road/GetPageTotal", this.postData())
                .then((res) => {
                    // console.log(res);
                    this.xmsl = res.xmsl;
                    this.jsgm = res.jsgm;
                    this.jsqmqs = res.jsqmqs;
                    this.ztz = res.ztz;
                    this.sswtz = res.sswtz;
                });
        },
        postData() {
            var ssds = "";
            var ssx = "";
            console.log(this.region);
            if (this.region.length > 0) {
                ssds = this.region[0];
                if (this.region.length > 1) {
                    ssx = this.region[1];
                }
            }

            //项目名称
            var query_xmmc = {
                Name: "XMMC",
                Value: this.xmmc,
                DisplayType: "like",
            };
            //所属地市
            var query_ssds = {
                Name: "SSDS",
                Value: ssds,
                DisplayType: "Equal",
            };
            //所属县
            var query_ssx = {
                Name: "SSX",
                Value: ssx,
                DisplayType: "Equal",
            };
            //项目年度
            var query_xmnd = {
                Name: "XMND",
                Value: this.xmnd,
                DisplayType: "Equal",
            };

            //前期工作进展
            var qqgzjzArray = [];
            this.qqgzjz.forEach((element) => {
                if (element.length > 0) {
                    qqgzjzArray.push(element[0]);
                    //当前期工作为进行中时，包括两个状态，用|分隔，保存时同样采用此方式
                    if (element.length > 1) {
                        qqgzjzArray.push(element[0] + "|" + element[1]);
                    }
                }
            });
            var query_qqgzjz = {
                Name: "QQGZJZ",
                Value: qqgzjzArray.join(","),
                DisplayType: "checkbox",
            };

            //建设类别
            var query_gllx = {
                Name: "GLLX",
                Value: this.gllx,
                DisplayType: "Equal",
            };

            //G331特殊处理
            if (this.gllx == "G331") {
                query_gllx = {
                    Name: "LXBH",
                    Value: this.gllx,
                    DisplayType: "Equal",
                };
            }

            //项目性质
            var xmxzArray = [];
            this.checkedXmxz.forEach((element) => {
                // xmxzArray.push(this.tranXmxz(element));
                xmxzArray.push(element);
            });
            var query_xmxz = {
                Name: "XMXZ",
                Value: xmxzArray.join(","),
                DisplayType: "checkbox",
            };

            //建设性质
            var query_jsxz = {
                Name: "JSXZ",
                Value: this.checkedJsxz.join(","),
                DisplayType: "checkbox",
            };

            //项目类型
            var xmlxArray = [];
            this.checkedXmlx.forEach((element) => {
                // xmlxArray.push(this.tranXmlx(element));
                xmlxArray.push(element);
            });
            var query_xmlx = {
                Name: "XMLX",
                Value: xmlxArray.join(","),
                DisplayType: "checkbox",
            };
            var xmgnArray = [];
            this.checkedXmgn.forEach((element) => {
                // xmlxArray.push(this.tranXmlx(element));
                xmgnArray.push(element);
            });
            var query_xmgn = {
                Name: "XMGN",
                Value: xmgnArray.join(","),
                DisplayType: "checkbox",
            };
            var postData = {
                page: this.currentPage,
                rows: this.pageSize,
                Sort: this.sortData.field,
                Order: this.sortData.sort,
                wheres: JSON.stringify([
                    query_xmmc,
                    query_xmgn,
                    query_ssds,
                    query_ssx,
                    query_xmnd,
                    query_qqgzjz,
                    query_gllx,
                    query_xmxz,
                    query_jsxz,
                    query_xmlx,
                ]),
            };
            return postData;
        },
        clearCondition() {
            this.region = [""];
            this.xmmc = "";
            this.xmnd = "";
            this.qqgzjz = [];
            this.gllx = "";
            this.checkedXmxz = [];
            this.checkedJsxz = [];
            this.checkedXmlx = [];
        },
        //公路现状汇总
        getRoadTotal() {
            var ds = "";
            var qx = "";
            if (this.region.length > 0) {
                ds = this.region[0];
                if (this.region.length > 1) {
                    qx = this.region[1];
                }
            }
            //所属地市
            var query_ssds = {
                Name: "Ds",
                Value: ds,
                DisplayType: "like",
            };
            //所属县
            var query_ssx = {
                Name: "Qx",
                Value: qx,
                DisplayType: "like",
            };

            var postData = {
                wheres: JSON.stringify([query_ssds, query_ssx]),
            };
            //汇总
            this.http
                .post("/api/Base_road/GetTotal", postData)
                .then((total) => {
                    // console.log(total);
                    this.total_glxz_xzdj = total.xzdj;
                    this.total_glxz_jsdj = total.jsdj;
                    this.total_glxz_gsdjgl = total.gldj.filter(
                        (v) => v.xzdj == "G" || v.xzdj == "S"
                    );
                    this.total_glxz_xycdjgl = total.gldj.filter(
                        (v) => v.xzdj == "X" || v.xzdj == "Y" || v.xzdj == "C"
                    );
                });
        },

        getTotal() {
            //汇总
            this.http
                .post("/api/Plan_road/GetTotal", this.postData())
                .then((total) => {
                    // console.log(total);
                    // this.setTotalJsxz(total.jsxz);

                    this.totalJsxz = total.jsxz;
                    this.totalXmxz = total.xmxz;
                    this.totalXzqh = total.xzqh;
                    this.totalXmxz.forEach((res, index) => {
                        res.xmsl = 0;
                        res.jsgm = 0;
                        res.jsgm_qs = 0;
                        res.ztz = 0;
                        res.sswtz = 0;
                        res.children = res.xmxz;
                        res.xmxz = "";
                        res.id = (index + 1) * 10;
                        res.children.forEach((v, i) => {
                            v.id = parseInt(index + 1 + "" + (i + 1));
                            v.ssds = "";
                            v.jsgm =
                                v.jsgm_ej +
                                v.jsgm_ej60 +
                                v.jsgm_gs +
                                v.jsgm_sj +
                                v.jsgm_yj;
                            res.jsgm += v.jsgm;
                            res.ztz += v.ztz;
                            res.sswtz += v.sswtz;
                            res.jsgm_qs += v.jsgm_qs;
                            res.xmsl += v.xmsl;
                        });
                    });
                    this.totalJsxz.forEach((res, index) => {
                        res.xmsl = 0;
                        res.jsgm = 0;
                        res.jsgm_qs = 0;
                        res.ztz = 0;
                        res.sswtz = 0;
                        res.children = res.jsxz;
                        res.id = (index + 1) * 10;
                        res.children.forEach((v, i) => {
                            v.id = parseInt(index + 1 + "" + (i + 1));
                            v.ssds = "";
                            v.jsgm =
                                v.jsgm_ej +
                                v.jsgm_ej60 +
                                v.jsgm_gs +
                                v.jsgm_sj +
                                v.jsgm_yj;
                            res.jsgm += v.jsgm;
                            res.ztz += v.ztz;
                            res.sswtz += v.sswtz;
                            res.jsgm_qs += v.jsgm_qs;
                            res.xmsl += v.xmsl;
                        });
                    });
                    // console.log(this.totalJsxz, 123);

                    this.totalXzqh.forEach((element) => {
                        element.jsgm =
                            (element.jsgm_gs != null ? element.jsgm_gs : 0) +
                            (element.jsgm_yj != null ? element.jsgm_yj : 0) +
                            (element.jsgm_ej != null ? element.jsgm_ej : 0) +
                            (element.jsgm_sj != null ? element.jsgm_sj : 0);
                    });
                });
        },
        handleChange(value) {
            // console.log(value);
            // this.checkedXmxz = [];
            // this.search();
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.search();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.search();
        },
    },
    watch: {},
};
</script>

<style lang="less" scoped>
</style>
