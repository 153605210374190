<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
          <el-tab-pane label="规划项目库" name="first">
            <h2 style="margin-bottom: 10px; text-align: center">黑龙江省“十四五”机场规划建设项目表<span @click="wordDetail()" class="xzecl" >黑龙江省“十四五”机场规划建设项目表</span> </h2>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <td :width="38">序号</td>
                    <td :width="268">项目名称</td>
                    <td :width="427">建设内容</td>
                    <td :width="211">&ldquo;十四五&rdquo;投资（亿元）</td>
                    <td :width="199">备注</td>
                  </tr>
                </thead>
                <tbody>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="306">合计</td>
                    <td :width="427"></td>
                    <td :width="211">282</td>
                    <td :width="199"></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="306">一、续建项目</td>
                    <td :width="427"></td>
                    <td :width="211">38.1</td>
                    <td :width="199"></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="306">（一）枢纽机场</td>
                    <td :width="427"></td>
                    <td :width="211">10.8</td>
                    <td :width="199"></td>
                  </tr>
                  <tr>
                    <td :width="38">1</td>
                    <td :width="268">哈尔滨机场T1航站楼安全提升工程</td>
                    <td :width="427">完成本期扩建工程中不包含的T1航站楼安全服务提升改造漏项工程。</td>
                    <td :width="211">7.8</td>
                    <td :width="199"></td>
                  </tr>
                  <tr>
                    <td :width="38">2</td>
                    <td :width="268">哈尔滨机场机坪扩建项目</td>
                    <td :width="427">扩建机坪0.71万平方米，布置1个C类机位以及配套设施。</td>
                    <td :width="211">0.7</td>
                    <td :width="199"></td>
                  </tr>
                  <tr>
                    <td :width="38">3</td>
                    <td :width="268">哈尔滨太平国际机场扩建工程</td>
                    <td :width="427">T1航站楼改造、现有跑道延长400米、Ⅱ类盲降改造等。</td>
                    <td :width="211">2.3</td>
                    <td :width="199"></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="306">（二）支线机场</td>
                    <td :width="427"></td>
                    <td :width="211">23.0</td>
                    <td :width="199"></td>
                  </tr>
                  <tr>
                    <td :width="38">1</td>
                    <td :width="268">绥芬河支线机场</td>
                    <td :width="427">新建跑道长2500米、新建航站楼5000平方米。</td>
                    <td :width="211">14.7</td>
                    <td :width="199"></td>
                  </tr>
                  <tr>
                    <td :width="38">2</td>
                    <td :width="268">鸡西支线机场改扩建</td>
                    <td :width="427">跑道延长500米至2800米，新建航站楼1.2万平方米。</td>
                    <td :width="211">7.2</td>
                    <td :width="199"></td>
                  </tr>
                  <tr>
                    <td :width="38">3</td>
                    <td :width="268">大庆机场改扩建</td>
                    <td :width="427">扩建航站楼、联检业务用房特种车库、动力站。</td>
                    <td :width="211">0.7</td>
                    <td :width="199"></td>
                  </tr>
                  <tr>
                    <td :width="38">4</td>
                    <td :width="268">伊春机场改扩建</td>
                    <td :width="427">跑道延长500米至2800米，新建航站楼1.2万平方米。</td>
                    <td :width="211">0.4</td>
                    <td :width="199"></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="306">（三）通用机场</td>
                    <td :width="427"></td>
                    <td :width="211">4.3</td>
                    <td :width="199"></td>
                  </tr>
                  <tr>
                    <td :width="38">1</td>
                    <td :width="268">富裕通用机场</td>
                    <td :width="427">新建跑道1200米。</td>
                    <td :width="211">2.2</td>
                    <td :width="199"></td>
                  </tr>
                  <tr>
                    <td :width="38">2</td>
                    <td :width="268">木兰通用机场</td>
                    <td :width="427">新建跑道长900米，新建航站楼4000平方米。</td>
                    <td :width="211">2.1</td>
                    <td :width="199"></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="306">二、新开工项目</td>
                    <td :width="427"></td>
                    <td :width="211">218.5</td>
                    <td :width="199"></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="306">（一）枢纽机场</td>
                    <td :width="427"></td>
                    <td :width="211">102.7</td>
                    <td :width="199"></td>
                  </tr>
                  <tr>
                    <td :width="38">1</td>
                    <td :width="268">哈尔滨国际机场二期（东二跑道）</td>
                    <td :width="427">新建一条长3600米、宽45米东二跑道及滑行道系统，90个机位站坪。</td>
                    <td :width="211">98.1</td>
                    <td :width="199"></td>
                  </tr>
                  <tr>
                    <td :width="38">2</td>
                    <td :width="268">哈尔滨太平国际机场东一跑道及老站坪翻修工程</td>
                    <td :width="427">对现有3200米跑道、平行滑行道及联络道，以及500-507远机位区域破损老旧道面进行翻建，配套改造助航灯光系统。</td>
                    <td :width="211">4.6</td>
                    <td :width="199"></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="306">（二）民用运输机场</td>
                    <td :width="427"></td>
                    <td :width="211">85.8</td>
                    <td :width="199"></td>
                  </tr>
                  <tr>
                    <td :width="38">1</td>
                    <td :width="268">新建绥化机场</td>
                    <td :width="427">新建1条长2600米、宽45米的跑道；建设8000平方米的航站楼和9个C类机位的站坪。</td>
                    <td :width="211">10.6</td>
                    <td :width="199"></td>
                  </tr>
                  <tr>
                    <td :width="38">2</td>
                    <td :width="268">新建鹤岗机场</td>
                    <td :width="427">新建1条长2500米、宽45米的跑道；建设6500平方米的航站楼和6个C类机位的站坪。</td>
                    <td :width="211">11.0</td>
                    <td :width="199"></td>
                  </tr>
                  <tr>
                    <td :width="38">3</td>
                    <td :width="268">齐齐哈尔支线机场改扩建</td>
                    <td :width="427">军民共建跑道延长600米至3200米，新建航站楼1.8万平方米。</td>
                    <td :width="211">11.6</td>
                    <td :width="199"></td>
                  </tr>
                  <tr>
                    <td :width="38">4</td>
                    <td :width="268">佳木斯支线机场改扩建</td>
                    <td :width="427">改扩建航站楼3.59万平方米，扩建停机坪及建设相关配套设施。</td>
                    <td :width="211">29.9</td>
                    <td :width="199"></td>
                  </tr>
                  <tr>
                    <td :width="38">5</td>
                    <td :width="268">漠河机场改扩建</td>
                    <td :width="427">跑道延长600m、扩建站坪2个。</td>
                    <td :width="211">8.1</td>
                    <td :width="199"></td>
                  </tr>
                  <tr>
                    <td :width="38">6</td>
                    <td :width="268">黑河机场改扩建</td>
                    <td :width="427">扩建航站楼10000㎡，新建航管楼1000㎡，站坪扩建4个C类机位。</td>
                    <td :width="211">6.6</td>
                    <td :width="199"></td>
                  </tr>
                  <tr>
                    <td :width="38">7</td>
                    <td :width="268">加格达奇机场改扩建</td>
                    <td :width="427">跑道延长至2600米，候机楼改扩建、新建停机坪、廊桥、围界巡场路、净空障碍处理及其他附属工程。</td>
                    <td :width="211">8.0</td>
                    <td :width="199"></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="306">（三）通用机场</td>
                    <td :width="427">新建呼玛、嘉荫、塔河、同江等一批通用机场。</td>
                    <td :width="211">30.0</td>
                    <td :width="199"></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="306">三、谋划项目</td>
                    <td :width="427"></td>
                    <td :width="211">25.7</td>
                    <td :width="199"></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="306">（一）枢纽机场</td>
                    <td :width="427"></td>
                    <td :width="211">0.0</td>
                    <td :width="199"></td>
                  </tr>
                  <tr>
                    <td :width="38">1</td>
                    <td :width="268">哈尔滨机场三期工程</td>
                    <td :width="427">谋划推动T3航站楼及第三跑道建设。</td>
                    <td :width="211"></td>
                    <td :width="199"></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="306">（二）民用运输机场</td>
                    <td :width="427"></td>
                    <td :width="211">25.7</td>
                    <td :width="199"></td>
                  </tr>
                  <tr>
                    <td :width="38">1</td>
                    <td :width="268">迁建牡丹江机场</td>
                    <td :width="427">飞行区指标4D，新建2500米长的跑道、1.87万平方米的航站楼及相关配套设施。</td>
                    <td :width="211">25.7</td>
                    <td :width="199"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
    name: "Home",
    components: {},
    data() {
        return {
            activeName: "first",
        };
    },
    mounted() {},
    methods: {
        wordDetail() {
            // 微软提供的方法
            window.open(
                "http://zzgroup.hljzztech.com/黑龙江省“十四五”机场规划建设项目表.xls"
            );
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
    },
    watch: {},
};
</script>
<style lang="less">
</style>
