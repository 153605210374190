<template>
    <div>
        <el-row>
            <el-col :span="24">

                <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
                    <el-tab-pane label="高速公路" name="first">
                        <h2 style="margin-bottom: 10px; text-align: center">
                            黑龙江省“十四五”公路规划建设项目表
<span @click="wordDetail(1)" class="xzecl" >十四五高速公路项目</span>
                        </h2>
                        <div class="table-responsive">

                            <table class="table" width="auto">
                                <thead>
                                    <tr>
                                        <td rowspan="2" :width="56">序号</td>
                                        <td rowspan="2" :width="308">项目名称</td>
                                        <td rowspan="2" :width="93">所属地市</td>
                                        <td rowspan="2" :width="157">所属县市</td>
                                        <td rowspan="2" :width="116">建设规模(公里)</td>
                                        <td rowspan="2" :width="116">总投资（亿元）</td>
                                        <td colspan="5" :width="458">&ldquo;十四五&rdquo;投资（亿元）</td>
                                        <td colspan="3" :width="256">前期进展情况</td>
                                        <td rowspan="2" :width="69">备注</td>
                                    </tr>
                                    <tr>
                                        <td :width="116">小计</td>
                                        <td :width="85">车购税</td>
                                        <td :width="85">省市县</td>
                                        <td :width="85">企业投资</td>
                                        <td :width="85">社会资本方</td>
                                        <td :width="85">可研</td>
                                        <td :width="85">初步设计</td>
                                        <td :width="85">施工图设计</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style="font-weight: bold">
                                        <td colspan="2" :width="364">一、高速公路</td>
                                        <td :width="93"></td>
                                        <td :width="157"></td>
                                        <td :width="116">3110</td>
                                        <td :width="116">2791</td>
                                        <td :width="116">1239</td>
                                        <td :width="85">258</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr style="font-weight: bold">
                                        <td colspan="2" :width="364">（一）&ldquo;十三五&rdquo;续建项目</td>
                                        <td :width="93"></td>
                                        <td :width="157"></td>
                                        <td :width="116">591</td>
                                        <td :width="116">591</td>
                                        <td :width="116">237</td>
                                        <td :width="85">42</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">1</td>
                                        <td :width="308">吉黑高速山河(吉黑省界)至哈尔滨(永源镇)段</td>
                                        <td :width="93">哈尔滨</td>
                                        <td :width="157">市辖区、五常</td>
                                        <td :width="116">181</td>
                                        <td :width="116">181</td>
                                        <td :width="116">89</td>
                                        <td :width="85">30</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">2</td>
                                        <td :width="308">鹤大高速佳木斯过境段</td>
                                        <td :width="93">佳木斯</td>
                                        <td :width="157">市辖区</td>
                                        <td :width="116">26</td>
                                        <td :width="116">26</td>
                                        <td :width="116">13</td>
                                        <td :width="85">2</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">3</td>
                                        <td :width="308">绥满高速公路卧里屯至白家窑段</td>
                                        <td :width="93">大庆</td>
                                        <td :width="157">市辖区</td>
                                        <td :width="116">10</td>
                                        <td :width="116">10</td>
                                        <td :width="116">1</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">4</td>
                                        <td :width="308">绥化至大庆高速</td>
                                        <td :width="93">绥化、大庆</td>
                                        <td :width="157">绥化市辖区、望奎、青冈、安达、大庆市辖区</td>
                                        <td :width="116">184</td>
                                        <td :width="116">184</td>
                                        <td :width="116">70</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">5</td>
                                        <td :width="308">哈尔滨至肇源高速</td>
                                        <td :width="93">哈尔滨、大庆</td>
                                        <td :width="157">哈尔滨市辖区、肇东、肇州、肇源</td>
                                        <td :width="116">119</td>
                                        <td :width="116">119</td>
                                        <td :width="116">41</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">6</td>
                                        <td :width="308">京哈高速哈尔滨至拉林河段扩容改造</td>
                                        <td :width="93">哈尔滨</td>
                                        <td :width="157">市辖区</td>
                                        <td :width="116">71</td>
                                        <td :width="116">71</td>
                                        <td :width="116">23</td>
                                        <td :width="85">10</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr style="font-weight: bold">
                                        <td colspan="2" :width="364">（二）2021年新开工项目</td>
                                        <td :width="93"></td>
                                        <td :width="157"></td>
                                        <td :width="116">684</td>
                                        <td :width="116">652</td>
                                        <td :width="116">652</td>
                                        <td :width="85">144</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">7</td>
                                        <td :width="308">鹤哈高速鹤岗至伊春段</td>
                                        <td :width="93">鹤岗、伊春</td>
                                        <td :width="157">鹤岗市辖区、伊春市辖区</td>
                                        <td :width="116">161</td>
                                        <td :width="116">163.3</td>
                                        <td :width="116">163</td>
                                        <td :width="85">39</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56"></td>
                                        <td :width="308">鹤哈高速鹤岗至苔青段工程分项目</td>
                                        <td :width="93"></td>
                                        <td :width="157"></td>
                                        <td :width="116">98.5</td>
                                        <td :width="116">95</td>
                                        <td :width="116">95</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56"></td>
                                        <td :width="308">鹤哈高速苔青至伊春段工程分项目</td>
                                        <td :width="93"></td>
                                        <td :width="157"></td>
                                        <td :width="116">62.44</td>
                                        <td :width="116">68.33</td>
                                        <td :width="116">68.33</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">8</td>
                                        <td :width="308">哈尔滨都市圈环线（西南环、北环）</td>
                                        <td :width="93">哈尔滨、绥化</td>
                                        <td :width="157">哈尔滨市辖区、五常、宾县、肇东</td>
                                        <td :width="116">181</td>
                                        <td :width="116">211</td>
                                        <td :width="116">211</td>
                                        <td :width="85">43</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56"></td>
                                        <td :width="308">哈尔滨都市圈环线西南环段</td>
                                        <td :width="93"></td>
                                        <td :width="157"></td>
                                        <td :width="116">91</td>
                                        <td :width="116">112</td>
                                        <td :width="116">112</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56"></td>
                                        <td :width="308">哈尔滨都市圈环线北环永源至双井段</td>
                                        <td :width="93"></td>
                                        <td :width="157"></td>
                                        <td :width="116">41</td>
                                        <td :width="116">44</td>
                                        <td :width="116">44</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56"></td>
                                        <td :width="308">哈尔滨都市圈环线北环双井至里木店段</td>
                                        <td :width="93"></td>
                                        <td :width="157"></td>
                                        <td :width="116">50</td>
                                        <td :width="116">54</td>
                                        <td :width="116">54</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">9</td>
                                        <td :width="308">大广高速大庆过境段</td>
                                        <td :width="93">大庆</td>
                                        <td :width="157">市辖区</td>
                                        <td :width="116">75.6</td>
                                        <td :width="116">66.7</td>
                                        <td :width="116">67</td>
                                        <td :width="85">15</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">10</td>
                                        <td :width="308">铁科高速方正至尚志至五常省界段</td>
                                        <td :width="93">哈尔滨</td>
                                        <td :width="157">方正、延寿、尚志、五常</td>
                                        <td :width="116">173.6</td>
                                        <td :width="116">144.3</td>
                                        <td :width="116">144</td>
                                        <td :width="85">33</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56"></td>
                                        <td :width="308">铁科高速方正至延寿尚志界段</td>
                                        <td :width="93"></td>
                                        <td :width="157"></td>
                                        <td :width="116">77</td>
                                        <td :width="116">61.7</td>
                                        <td :width="116">61.7</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56"></td>
                                        <td :width="308">铁科高速延寿尚志界至五常段</td>
                                        <td :width="93"></td>
                                        <td :width="157"></td>
                                        <td :width="116">86</td>
                                        <td :width="116">69.5</td>
                                        <td :width="116">69.5</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56"></td>
                                        <td :width="308">铁科高速五常至拉林河（吉黑省界）段</td>
                                        <td :width="93"></td>
                                        <td :width="157"></td>
                                        <td :width="116">11</td>
                                        <td :width="116">13.1</td>
                                        <td :width="116">13.1</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">11</td>
                                        <td :width="308">北漠高速五大连池至嫩江段</td>
                                        <td :width="93">黑河、齐齐哈尔</td>
                                        <td :width="157">五大连池、嫩江、讷河</td>
                                        <td :width="116">93</td>
                                        <td :width="116">67</td>
                                        <td :width="116">67</td>
                                        <td :width="85">15</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr style="font-weight: bold">
                                        <td colspan="2" :width="364">（三）&ldquo;十四五&rdquo;其他项目</td>
                                        <td :width="93"></td>
                                        <td :width="157"></td>
                                        <td :width="116">465</td>
                                        <td :width="116">351</td>
                                        <td :width="116">351</td>
                                        <td :width="85">71</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">12</td>
                                        <td :width="308">吉黑高速哈尔滨至北安段</td>
                                        <td :width="93">哈尔滨、绥化、齐齐哈尔、黑河</td>
                                        <td :width="157">哈尔滨市辖区、兰西、青冈、明水、拜泉、克东、北安</td>
                                        <td :width="116">305</td>
                                        <td :width="116">229</td>
                                        <td :width="116">229</td>
                                        <td :width="85">46</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">13</td>
                                        <td :width="308">铁科高速铁力至方正段</td>
                                        <td :width="93">哈尔滨、伊春</td>
                                        <td :width="157">铁力、通河、方正</td>
                                        <td :width="116">159.7</td>
                                        <td :width="116">122.2</td>
                                        <td :width="116">122</td>
                                        <td :width="85">26</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56"></td>
                                        <td :width="308">铁科高速凤阳至方正段</td>
                                        <td :width="93"></td>
                                        <td :width="157"></td>
                                        <td :width="116">74</td>
                                        <td :width="116">64.0</td>
                                        <td :width="116">64.0</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56"></td>
                                        <td :width="308">铁科高速铁力至凤阳段</td>
                                        <td :width="93"></td>
                                        <td :width="157"></td>
                                        <td :width="116">86</td>
                                        <td :width="116">58.2</td>
                                        <td :width="116">58.2</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr style="font-weight: bold">
                                        <td colspan="2" :width="364">（四）&ldquo;十四五&rdquo;谋划项目</td>
                                        <td :width="93"></td>
                                        <td :width="157"></td>
                                        <td :width="116">1370</td>
                                        <td :width="116">1197</td>
                                        <td :width="116">0</td>
                                        <td :width="85">0</td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">14</td>
                                        <td :width="308">依兴高速七台河至密山段</td>
                                        <td :width="93">七台河、鸡西</td>
                                        <td :width="157">七台河市辖区、密山</td>
                                        <td :width="116">96</td>
                                        <td :width="116">77</td>
                                        <td :width="116"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">15</td>
                                        <td :width="308">绥满高速齐齐哈尔至黑蒙界段</td>
                                        <td :width="93">齐齐哈尔</td>
                                        <td :width="157">市辖区、龙江</td>
                                        <td :width="116">120</td>
                                        <td :width="116">108</td>
                                        <td :width="116"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">16</td>
                                        <td :width="308">绥满高速公路哈尔滨至阿城段扩容改造</td>
                                        <td :width="93">哈尔滨</td>
                                        <td :width="157">市辖区</td>
                                        <td :width="116">21</td>
                                        <td :width="116">20</td>
                                        <td :width="116"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">17</td>
                                        <td :width="308">绥满高速哈尔滨至大庆段扩容改造</td>
                                        <td :width="93">哈尔滨、绥化、大庆</td>
                                        <td :width="157">哈尔滨市辖区、肇东、安达、大庆市辖区</td>
                                        <td :width="116">132</td>
                                        <td :width="116">119</td>
                                        <td :width="116"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">18</td>
                                        <td :width="308">双鸭山至宝清（八五二）高速</td>
                                        <td :width="93">双鸭山</td>
                                        <td :width="157">市辖区、宝清</td>
                                        <td :width="116">170</td>
                                        <td :width="116">153</td>
                                        <td :width="116"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">19</td>
                                        <td :width="308">北漠高速嫩江至加格达奇段</td>
                                        <td :width="93">黑河、大兴安岭</td>
                                        <td :width="157">大兴安岭市辖区、嫩江</td>
                                        <td :width="116">170</td>
                                        <td :width="116">145</td>
                                        <td :width="116"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">20</td>
                                        <td :width="308">宁延高速杏山至省界段</td>
                                        <td :width="93">牡丹江</td>
                                        <td :width="157">宁安</td>
                                        <td :width="116">32</td>
                                        <td :width="116">27</td>
                                        <td :width="116"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">21</td>
                                        <td :width="308">伊春至嘉荫高速</td>
                                        <td :width="93">伊春</td>
                                        <td :width="157">市辖区、嘉荫、汤旺、丰林</td>
                                        <td :width="116">220</td>
                                        <td :width="116">187</td>
                                        <td :width="116"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">22</td>
                                        <td :width="308">鸡西至穆棱高速</td>
                                        <td :width="93">鸡西</td>
                                        <td :width="157">市辖区、穆棱</td>
                                        <td :width="116">90</td>
                                        <td :width="116">81</td>
                                        <td :width="116"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">23</td>
                                        <td :width="308">绥芬河至东宁高速</td>
                                        <td :width="93">牡丹江</td>
                                        <td :width="157">东宁、绥芬河</td>
                                        <td :width="116">41</td>
                                        <td :width="116">37</td>
                                        <td :width="116"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">24</td>
                                        <td :width="308">金林至铁力高速</td>
                                        <td :width="93">伊春</td>
                                        <td :width="157">市辖区、铁力、大箐山、南岔</td>
                                        <td :width="116">150</td>
                                        <td :width="116">135</td>
                                        <td :width="116"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">25</td>
                                        <td :width="308">红旗岭至饶河高速</td>
                                        <td :width="93">双鸭山</td>
                                        <td :width="157">饶河</td>
                                        <td :width="116">63</td>
                                        <td :width="116">54</td>
                                        <td :width="116"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                    <tr>
                                        <td :width="56">26</td>
                                        <td :width="308">鹤岗至萝北高速</td>
                                        <td :width="93">鹤岗</td>
                                        <td :width="157">市辖区、萝北</td>
                                        <td :width="116">65</td>
                                        <td :width="116">55</td>
                                        <td :width="116"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="85"></td>
                                        <td :width="69"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="国道" name="second">
                        <h2 style="margin-bottom: 10px; text-align: center">黑龙江省“十四五”普通国道重点建设项目表

<span @click="wordDetail(2)" class="xzecl" >十四五普通国道项目表</span>
                        </h2>
                        <div class="table-responsive">

                            <table class="table table-line1">
                                <thead>
                                    <tr>
                                        <td rowspan="3" :width="36">序号</td>
                                        <td rowspan="3" :width="58">所属地市</td>
                                        <td rowspan="3" :width="72">所属县（市）</td>
                                        <td rowspan="3" :width="281">项目名称</td>
                                        <td rowspan="3" :width="55">路线编号</td>
                                        <td rowspan="3" :width="55">建设性质</td>
                                        <td colspan="2" :width="106">建设期</td>
                                        <td colspan="4" :width="206">现状等级（公里）</td>
                                        <td colspan="5" :width="315">建设规模（公里）</td>
                                        <td colspan="8" :width="689">投资（万元）</td>
                                        <td rowspan="3" :width="51">备注</td>
                                    </tr>
                                    <tr>
                                        <td rowspan="2" :width="55">开工年</td>
                                        <td rowspan="2" :width="51">完工年</td>
                                        <td rowspan="2" :width="51">一级</td>
                                        <td rowspan="2" :width="51">二级</td>
                                        <td rowspan="2" :width="51">三级</td>
                                        <td rowspan="2" :width="51">四级</td>
                                        <td rowspan="2" :width="65">小计</td>
                                        <td rowspan="2" :width="65">一级</td>
                                        <td rowspan="2" :width="65">二级</td>
                                        <td rowspan="2" :width="65">三级</td>
                                        <td rowspan="2" :width="51">桥隧(延米)</td>
                                        <td rowspan="2" :width="71">总投资</td>
                                        <td rowspan="2" :width="91">中央车购税</td>
                                        <td colspan="2" :width="183">&ldquo;十三五&rdquo;</td>
                                        <td rowspan="2" :width="67">&ldquo;十四五&rdquo;投资</td>
                                        <td :width="91"></td>
                                        <td colspan="2" :width="183">&ldquo;十五五&rdquo;</td>
                                    </tr>
                                    <tr>
                                        <td :width="91">总投资</td>
                                        <td :width="91">中央车购税</td>
                                        <td :width="91">中央车购税</td>
                                        <td :width="91">总投资</td>
                                        <td :width="91">中央车购税</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style="font-weight: bold">
                                        <td colspan="4" :width="349">合计</td>
                                        <td :width="55"></td>
                                        <td :width="55"></td>
                                        <td :width="55"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">4825.1</td>
                                        <td :width="65">1470.9</td>
                                        <td :width="65">3242.1</td>
                                        <td :width="65">93.0</td>
                                        <td :width="51">19095</td>
                                        <td :width="71">9065672</td>
                                        <td :width="91">4029821</td>
                                        <td :width="91">1013418</td>
                                        <td :width="91">506905</td>
                                        <td :width="67">6806920</td>
                                        <td :width="91">2962345</td>
                                        <td :width="91">1260034</td>
                                        <td :width="91">560571</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr style="font-weight: bold">
                                        <td colspan="4" :width="349">一、&ldquo;十三五&rdquo;续建项目</td>
                                        <td :width="55"></td>
                                        <td :width="55"></td>
                                        <td :width="55"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">738.8</td>
                                        <td :width="65">522.5</td>
                                        <td :width="65">216.3</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">1933770</td>
                                        <td :width="91">966986</td>
                                        <td :width="91">1008418</td>
                                        <td :width="91">506905</td>
                                        <td :width="67">925352</td>
                                        <td :width="91">460081</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td :width="58">齐齐哈尔</td>
                                        <td :width="72">泰来县</td>
                                        <td :width="181">国道嫩江至双辽公路泰来至黑吉省界段</td>
                                        <td :width="55">G231</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2020</td>
                                        <td :width="51">2022</td>
                                        <td :width="51">3.7</td>
                                        <td :width="51">7.3</td>
                                        <td :width="51">16.2</td>
                                        <td :width="51"></td>
                                        <td :width="65">27.2</td>
                                        <td :width="65"></td>
                                        <td :width="65">27.2</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">22983</td>
                                        <td :width="91">15094</td>
                                        <td :width="91">6016</td>
                                        <td :width="91">7500</td>
                                        <td :width="67">16967</td>
                                        <td :width="91">7594</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td :width="58">大庆</td>
                                        <td :width="72">杜蒙县</td>
                                        <td :width="181">国道牙克石至四平公路大齐界至杜尔伯特段</td>
                                        <td :width="55">G232</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2020</td>
                                        <td :width="51">2022</td>
                                        <td :width="51">2.8</td>
                                        <td :width="51"></td>
                                        <td :width="51">43.4</td>
                                        <td :width="51"></td>
                                        <td :width="65">46.2</td>
                                        <td :width="65"></td>
                                        <td :width="65">46.2</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">43300</td>
                                        <td :width="91">22054</td>
                                        <td :width="91">8000</td>
                                        <td :width="91"></td>
                                        <td :width="67">35300</td>
                                        <td :width="91">22054</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td :width="58">黑河</td>
                                        <td :width="72">爱辉区</td>
                                        <td :width="181">国道丹东至阿勒泰公路黑河至卧牛湖段</td>
                                        <td :width="55">G331</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2020</td>
                                        <td :width="51">2022</td>
                                        <td :width="51"></td>
                                        <td :width="51">13.4</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">13.4</td>
                                        <td :width="65">13.4</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">85300</td>
                                        <td :width="91">60402</td>
                                        <td :width="91">13400</td>
                                        <td :width="91"></td>
                                        <td :width="67">71900</td>
                                        <td :width="91">60402</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td :width="58">哈尔滨</td>
                                        <td :width="72">市区</td>
                                        <td :width="181">国道北京至抚远公路双城至三环路（王岗镇）段</td>
                                        <td :width="55">G102</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2019</td>
                                        <td :width="51">2022</td>
                                        <td :width="51"></td>
                                        <td :width="51">37.0</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">37.0</td>
                                        <td :width="65">37.0</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">145035</td>
                                        <td :width="91">36985</td>
                                        <td :width="91">81700</td>
                                        <td :width="91">17640</td>
                                        <td :width="67">63335</td>
                                        <td :width="91">19345</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td :width="58">哈尔滨</td>
                                        <td :width="72">通河县</td>
                                        <td :width="181">国道北京至抚远公路通河过境段</td>
                                        <td :width="55">G102</td>
                                        <td :width="55">新建</td>
                                        <td :width="55">2020</td>
                                        <td :width="51">2021</td>
                                        <td :width="51">3.4</td>
                                        <td>5.5</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">8.9</td>
                                        <td :width="65">8.9</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">25300</td>
                                        <td :width="91">8943</td>
                                        <td :width="91">13900</td>
                                        <td :width="91">8943</td>
                                        <td :width="67">11400</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td :width="58">哈尔滨</td>
                                        <td :width="72">依兰县</td>
                                        <td :width="181">国道同江至哈尔滨公路佳木斯依兰界至依兰方正界段</td>
                                        <td :width="55">G221</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2019</td>
                                        <td :width="51">2021</td>
                                        <td :width="51"></td>
                                        <td :width="51">6.0</td>
                                        <td :width="51">64.6</td>
                                        <td :width="51"></td>
                                        <td :width="65">70.6</td>
                                        <td :width="65"></td>
                                        <td :width="65">70.6</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">56735</td>
                                        <td :width="91">35287</td>
                                        <td :width="91">52235</td>
                                        <td :width="91">23000</td>
                                        <td :width="67">4500</td>
                                        <td :width="91">12287</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td :width="58">伊春</td>
                                        <td :width="72">嘉荫县、汤旺河区</td>
                                        <td :width="181">国道嘉荫至临江公路嘉荫至汤旺河段</td>
                                        <td :width="55">G222</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2019</td>
                                        <td :width="51">2022</td>
                                        <td :width="51"></td>
                                        <td>94.3</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">94.3</td>
                                        <td :width="65">86.6</td>
                                        <td :width="65">7.7</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">243711</td>
                                        <td :width="91">162300</td>
                                        <td :width="91">100900</td>
                                        <td :width="91">88263</td>
                                        <td :width="67">142811</td>
                                        <td :width="91">74037</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td :width="58">双鸭山</td>
                                        <td :width="72">饶河县</td>
                                        <td :width="181">国道饶河至盖州公路大岱林场至红旗岭段</td>
                                        <td :width="55">G229</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2019</td>
                                        <td :width="51">2022</td>
                                        <td :width="51"></td>
                                        <td :width="51">55.4</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">55.4</td>
                                        <td :width="65">55.4</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">120662</td>
                                        <td :width="91">95200</td>
                                        <td :width="91">21600</td>
                                        <td :width="91">50000</td>
                                        <td :width="67">99062</td>
                                        <td :width="91">45200</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td :width="58">大庆</td>
                                        <td :width="72">肇源县</td>
                                        <td :width="181">国道牙克石至四平公路新站至大安嫩江大桥段</td>
                                        <td :width="55">G232</td>
                                        <td :width="55">新建</td>
                                        <td :width="55">2019</td>
                                        <td :width="51">2022</td>
                                        <td :width="51"></td>
                                        <td :width="51">1.9</td>
                                        <td :width="51">23.6</td>
                                        <td :width="51"></td>
                                        <td :width="65">25.5</td>
                                        <td :width="65">18.2</td>
                                        <td :width="65">7.3</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">75719</td>
                                        <td :width="91">21737</td>
                                        <td :width="91">32000</td>
                                        <td :width="91">21737</td>
                                        <td :width="67">43719</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td :width="58">牡丹江</td>
                                        <td :width="72">穆棱市</td>
                                        <td :width="181">国道丹东至阿勒泰公路八面通镇至鸡穆界段</td>
                                        <td :width="55">G331</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2018</td>
                                        <td :width="51">2021</td>
                                        <td :width="51"></td>
                                        <td :width="51">17.4</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">17.4</td>
                                        <td :width="65">17.4</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">48604</td>
                                        <td :width="91">37700</td>
                                        <td :width="91">36800</td>
                                        <td :width="91">30000</td>
                                        <td :width="67">11804</td>
                                        <td :width="91">7700</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>11</td>
                                        <td :width="58">鸡西</td>
                                        <td :width="72">市区</td>
                                        <td :width="181">国道丹东至阿勒泰公路鸡穆界至鸡西段</td>
                                        <td :width="55">G331</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2018</td>
                                        <td :width="51">2021</td>
                                        <td :width="51">9.8</td>
                                        <td :width="51">41.5</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">51.3</td>
                                        <td :width="65">51.3</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">213977</td>
                                        <td :width="91">132200</td>
                                        <td :width="91">147200</td>
                                        <td :width="91">93600</td>
                                        <td :width="67">66777</td>
                                        <td :width="91">38600</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>12</td>
                                        <td :width="58">鸡西</td>
                                        <td :width="72">市区</td>
                                        <td :width="181">国道丹东至阿勒泰公路滴道至鸡西兴凯湖机场段</td>
                                        <td :width="55">G331</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2020</td>
                                        <td :width="51">2022</td>
                                        <td :width="51">11.4</td>
                                        <td :width="51">10.3</td>
                                        <td :width="51">10.6</td>
                                        <td :width="51"></td>
                                        <td :width="65">32.3</td>
                                        <td :width="65">32.3</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">178200</td>
                                        <td :width="91">129360</td>
                                        <td :width="91">14700</td>
                                        <td :width="91"></td>
                                        <td :width="67">163500</td>
                                        <td :width="91">129360</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>13</td>
                                        <td :width="58">哈尔滨</td>
                                        <td :width="72">延寿县</td>
                                        <td :width="181">国道三合至莫力达瓦旗公路延寿至胜利段</td>
                                        <td :width="55">G333</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2020</td>
                                        <td :width="51">2022</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">51.9</td>
                                        <td :width="51"></td>
                                        <td :width="65">51.9</td>
                                        <td :width="65">51.9</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">170998</td>
                                        <td :width="91">51935</td>
                                        <td :width="91">30000</td>
                                        <td :width="91">25000</td>
                                        <td :width="67">140998</td>
                                        <td :width="91">26935</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>14</td>
                                        <td :width="58">哈尔滨</td>
                                        <td :width="72">市辖区</td>
                                        <td :width="181">国道北京至抚远公路哈尔滨四环路至呼兰段</td>
                                        <td :width="55">G102</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2016</td>
                                        <td :width="51">2021</td>
                                        <td :width="51">13.8</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">13.8</td>
                                        <td :width="65">13.8</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">109603</td>
                                        <td :width="91">8265</td>
                                        <td :width="91">99903</td>
                                        <td :width="91">8265</td>
                                        <td :width="67">9700</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>15</td>
                                        <td :width="58">大庆</td>
                                        <td :width="72">肇源县</td>
                                        <td :width="181">国道绥化至沈阳公路肇源至松花江大桥段</td>
                                        <td :width="55">G203</td>
                                        <td :width="55">新建</td>
                                        <td :width="55">2017</td>
                                        <td :width="51">2022</td>
                                        <td :width="51">9.7</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">9.7</td>
                                        <td :width="65"></td>
                                        <td :width="65">9.7</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">13684</td>
                                        <td :width="91">4600</td>
                                        <td :width="91">6000</td>
                                        <td :width="91">4600</td>
                                        <td :width="67">7684</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>16</td>
                                        <td :width="58">双鸭山</td>
                                        <td :width="72">集贤县</td>
                                        <td :width="181">国道同江至哈尔滨公路集贤至太保段</td>
                                        <td :width="55">G221</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2013</td>
                                        <td :width="51">2021</td>
                                        <td :width="51"></td>
                                        <td :width="51">9.9</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">9.9</td>
                                        <td :width="65">9.9</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">41428</td>
                                        <td :width="91">4158</td>
                                        <td :width="91">26900</td>
                                        <td :width="91">4158</td>
                                        <td :width="67">14528</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>17</td>
                                        <td :width="58">双鸭山</td>
                                        <td :width="72">友谊县</td>
                                        <td :width="181">国道同江至哈尔滨公路友谊至集贤段</td>
                                        <td :width="55">G221</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2017</td>
                                        <td :width="51">2021</td>
                                        <td :width="51"></td>
                                        <td :width="51">44.4</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">44.4</td>
                                        <td :width="65">44.4</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">82900</td>
                                        <td :width="91">44309</td>
                                        <td :width="91">80100</td>
                                        <td :width="91">44309</td>
                                        <td :width="67">2800</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>18</td>
                                        <td :width="58">牡丹江</td>
                                        <td :width="72">宁安市</td>
                                        <td :width="181">国道三合至莫旗公路杏山至老松岭段</td>
                                        <td :width="55">G333</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2014</td>
                                        <td :width="51">2021</td>
                                        <td :width="51"></td>
                                        <td :width="51">42.2</td>
                                        <td :width="51"></td>
                                        <td :width="51">5.4</td>
                                        <td :width="65">47.6</td>
                                        <td :width="65"></td>
                                        <td :width="65">47.6</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">29790</td>
                                        <td :width="91">11890</td>
                                        <td :width="91">27790</td>
                                        <td :width="91">11890</td>
                                        <td :width="67">2000</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>19</td>
                                        <td :width="58">双鸭山</td>
                                        <td :width="72">宝山区、宝清县</td>
                                        <td :width="181">国道集贤至当壁公路宝山区至宝清段</td>
                                        <td :width="55">G501</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2018</td>
                                        <td :width="51">2021</td>
                                        <td :width="51"></td>
                                        <td :width="51">82.0</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">82.0</td>
                                        <td :width="65">82.0</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">225841</td>
                                        <td :width="91">84567</td>
                                        <td :width="91">209274</td>
                                        <td :width="91">68000</td>
                                        <td :width="67">16567</td>
                                        <td :width="91">16567</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr style="font-weight: bold">
                                        <td colspan="4" :width="349">二、&ldquo;十四五&rdquo;新开工项目</td>
                                        <td :width="55"></td>
                                        <td :width="55"></td>
                                        <td :width="55"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">2868.2</td>
                                        <td :width="65">425.4</td>
                                        <td :width="65">2331.4</td>
                                        <td :width="65">93.0</td>
                                        <td :width="51">18382</td>
                                        <td :width="71">4206581</td>
                                        <td :width="91">1844548</td>
                                        <td :width="91">5000</td>
                                        <td :width="91"></td>
                                        <td :width="67">2956247</td>
                                        <td :width="91">1283978</td>
                                        <td :width="91">1260034</td>
                                        <td :width="91">560571</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td :width="58">牡丹江</td>
                                        <td :width="72">穆棱市</td>
                                        <td :width="181">国道丹东至阿勒泰公路绥阳至马桥河段</td>
                                        <td :width="55">G331</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2021</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">36.8</td>
                                        <td :width="51"></td>
                                        <td :width="65">36.8</td>
                                        <td :width="65"></td>
                                        <td :width="65">36.8</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">76985</td>
                                        <td :width="91">32384</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">76985</td>
                                        <td :width="91">32384</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td :width="58">黑河</td>
                                        <td :width="72">爱辉区、孙吴县</td>
                                        <td :width="181">国道丹东至阿勒泰公路逊孙界至黑河段</td>
                                        <td :width="55">G331</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2021</td>
                                        <td :width="51">2023</td>
                                        <td :width="51">1.1</td>
                                        <td :width="51">10.4</td>
                                        <td :width="51">102.0</td>
                                        <td :width="51">1.4</td>
                                        <td :width="65">112.0</td>
                                        <td :width="65">33.9</td>
                                        <td :width="65">78.1</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">266000</td>
                                        <td :width="91">128392</td>
                                        <td :width="91">5000</td>
                                        <td :width="91"></td>
                                        <td :width="67">261000</td>
                                        <td :width="91">128392</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td :width="58">牡丹江</td>
                                        <td :width="72">东宁市</td>
                                        <td :width="181">国道绥芬河至满洲里公路绥阳镇至东穆界段</td>
                                        <td :width="55">G301</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2021</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">34.7</td>
                                        <td :width="51"></td>
                                        <td :width="65">34.7</td>
                                        <td :width="65"></td>
                                        <td :width="65">34.7</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">54655</td>
                                        <td :width="91">24290</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">54655</td>
                                        <td :width="91">24290</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td :width="58">七台河</td>
                                        <td :width="72">勃利县</td>
                                        <td :width="181">国道鹤岗至大连公路小五站镇至勃利鸡东界段</td>
                                        <td :width="55">G201</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2021</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">17.7</td>
                                        <td :width="51"></td>
                                        <td :width="65">17.7</td>
                                        <td :width="65"></td>
                                        <td :width="65">17.7</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">18702</td>
                                        <td :width="91">12390</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">18702</td>
                                        <td :width="91">12390</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td :width="58">七台河</td>
                                        <td :width="72">勃利县</td>
                                        <td :width="181">国道饶河至盖州公路勃利过境段</td>
                                        <td :width="55">G229</td>
                                        <td :width="55">新建</td>
                                        <td :width="55">2021</td>
                                        <td :width="51">2022</td>
                                        <td :width="51"></td>
                                        <td :width="51">9.2</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">9.2</td>
                                        <td :width="65">9.2</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">29439</td>
                                        <td :width="91">12851</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">29439</td>
                                        <td :width="91">12851</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td :width="58">佳木斯</td>
                                        <td :width="72">富锦（建三江管理局）</td>
                                        <td :width="181">国道北京至抚远公路二龙山镇至七星精准农机中心段</td>
                                        <td :width="55">G102</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2021</td>
                                        <td :width="51">2023</td>
                                        <td :width="51">1.6</td>
                                        <td :width="51">17.3</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">18.9</td>
                                        <td :width="65">18.9</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">36357</td>
                                        <td :width="91">26460</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">36357</td>
                                        <td :width="91">26460</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td :width="58">绥化</td>
                                        <td :width="72">庆安县</td>
                                        <td :width="181">国道嘉荫至临江公路庆铁界至绥化段</td>
                                        <td :width="55">G222</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2021</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">65.8</td>
                                        <td :width="51"></td>
                                        <td :width="65">65.8</td>
                                        <td :width="65"></td>
                                        <td :width="65">65.8</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">66797</td>
                                        <td :width="91">46060</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">66797</td>
                                        <td :width="91">46060</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td :width="58">牡丹江</td>
                                        <td :width="72">爱民区</td>
                                        <td :width="181">国道绥芬河至满洲里公路黄花至牡海界段</td>
                                        <td :width="55">G301</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2021</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">10.6</td>
                                        <td :width="51"></td>
                                        <td :width="65">10.6</td>
                                        <td :width="65"></td>
                                        <td :width="65">10.6</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">13781</td>
                                        <td :width="91">6366</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">13781</td>
                                        <td :width="91">6366</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td :width="58">齐齐哈尔</td>
                                        <td :width="72">市区</td>
                                        <td :width="181">国道嫩江至双辽公路齐富界至齐齐哈尔段</td>
                                        <td :width="55">G231</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2021</td>
                                        <td :width="51">2022</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">9.6</td>
                                        <td :width="51"></td>
                                        <td :width="65">9.6</td>
                                        <td :width="65"></td>
                                        <td :width="65">9.6</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">9247</td>
                                        <td :width="91">5760</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">9247</td>
                                        <td :width="91">5760</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td :width="58">牡丹江</td>
                                        <td :width="72">阳明区</td>
                                        <td :width="181">国道鹤岗至大连公路林牡界至柴河道口段</td>
                                        <td :width="55">G201</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2021</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">19.9</td>
                                        <td :width="51"></td>
                                        <td :width="65">19.9</td>
                                        <td :width="65"></td>
                                        <td :width="65">19.9</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">30406</td>
                                        <td :width="91">11940</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">30406</td>
                                        <td :width="91">11940</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>11</td>
                                        <td :width="58">七台河</td>
                                        <td :width="72">勃利县</td>
                                        <td :width="181">国道鹤岗至大连公路桦南勃利界至新民村(小五站镇)段</td>
                                        <td :width="55">G201</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2021</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">43.1</td>
                                        <td :width="51"></td>
                                        <td :width="65">43.1</td>
                                        <td :width="65"></td>
                                        <td :width="65">43.1</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">51720</td>
                                        <td :width="91">25860</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">51720</td>
                                        <td :width="91">25860</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>12</td>
                                        <td :width="58">黑河</td>
                                        <td :width="72">五大连池市</td>
                                        <td :width="181">国道黑河至大连公路孙吴五大连池界至五大连池北安界段</td>
                                        <td :width="55">G202</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2021</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">95.6</td>
                                        <td :width="51"></td>
                                        <td :width="65">95.6</td>
                                        <td :width="65"></td>
                                        <td :width="65">95.6</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">99317</td>
                                        <td :width="91">57360</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">99317</td>
                                        <td :width="91">57360</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>13</td>
                                        <td :width="58">牡丹江</td>
                                        <td :width="72">阳明区</td>
                                        <td :width="181">国道绥芬河至满洲里公路穆牡界至代马沟段</td>
                                        <td :width="55">G301</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2021</td>
                                        <td :width="51">2022</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">8.6</td>
                                        <td :width="51"></td>
                                        <td :width="65">8.6</td>
                                        <td :width="65"></td>
                                        <td :width="65">8.6</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">15483</td>
                                        <td :width="91">5163</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">15483</td>
                                        <td :width="91">5163</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>14</td>
                                        <td :width="58">佳木斯</td>
                                        <td :width="72">桦南县</td>
                                        <td :width="181">国道鹤岗至大连公路佳桦界至桦勃界段</td>
                                        <td :width="55">G201</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2021</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td :width="51">15.0</td>
                                        <td :width="51">71.0</td>
                                        <td :width="51"></td>
                                        <td :width="65">80.0</td>
                                        <td :width="65"></td>
                                        <td :width="65">80.0</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">96000</td>
                                        <td :width="91">48000</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">96000</td>
                                        <td :width="91">48000</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>15</td>
                                        <td :width="58">齐齐哈尔</td>
                                        <td :width="72">讷河市</td>
                                        <td :width="181">国道嫩江至双辽公路拉哈过境段</td>
                                        <td :width="55">G231</td>
                                        <td :width="55">新建</td>
                                        <td :width="55">2021</td>
                                        <td :width="51">2022</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">11.6</td>
                                        <td :width="65"></td>
                                        <td :width="65">11.6</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">13920</td>
                                        <td :width="91">6960</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">13920</td>
                                        <td :width="91">6960</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>16</td>
                                        <td :width="58">伊春</td>
                                        <td :width="72">嘉荫县</td>
                                        <td :width="181">国道丹东至阿勒泰公路萝嘉界至嘉荫段</td>
                                        <td :width="55">G331</td>
                                        <td :width="55">原级改造</td>
                                        <td :width="55">2022</td>
                                        <td :width="51">2024</td>
                                        <td :width="51"></td>
                                        <td :width="51">96.2</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">96.2</td>
                                        <td :width="65"></td>
                                        <td :width="65">96.2</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">86599</td>
                                        <td :width="91">33677</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">86599</td>
                                        <td :width="91">33677</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>17</td>
                                        <td :width="58">伊春</td>
                                        <td :width="72">嘉荫县</td>
                                        <td :width="181">国道丹东至阿勒泰公路嘉荫(稻田村)至嘉逊界段</td>
                                        <td :width="55">G331</td>
                                        <td :width="55">原级改造</td>
                                        <td :width="55">2022</td>
                                        <td :width="51">2024</td>
                                        <td :width="51"></td>
                                        <td :width="51">116.8</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">116.8</td>
                                        <td :width="65"></td>
                                        <td :width="65">116.8</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">105120</td>
                                        <td :width="91">40880</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">105120</td>
                                        <td :width="91">40880</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>18</td>
                                        <td :width="58">双鸭山</td>
                                        <td :width="72">饶河县</td>
                                        <td :width="181">国道丹东至阿勒泰公路五林洞镇至大岱林场段</td>
                                        <td :width="55">G331</td>
                                        <td :width="55">原级改造</td>
                                        <td :width="55">2022</td>
                                        <td :width="51">2024</td>
                                        <td :width="51"></td>
                                        <td :width="51">40.2</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">40.2</td>
                                        <td :width="65"></td>
                                        <td :width="65">40.2</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">36180</td>
                                        <td :width="91">14070</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">36180</td>
                                        <td :width="91">14070</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>19</td>
                                        <td :width="58">鸡西</td>
                                        <td :width="72">密山市</td>
                                        <td :width="181">国道丹东至阿勒泰公路鸡东密山界至新华村段</td>
                                        <td :width="55">G331</td>
                                        <td :width="55">路面改造</td>
                                        <td :width="55">2022</td>
                                        <td :width="51">2024</td>
                                        <td :width="51"></td>
                                        <td :width="51">40.0</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">40.0</td>
                                        <td :width="65"></td>
                                        <td :width="65">40.0</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">16000</td>
                                        <td :width="91">7040</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">16000</td>
                                        <td :width="91">7040</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>20</td>
                                        <td :width="58">鸡西</td>
                                        <td :width="72">密山市</td>
                                        <td :width="181">国道丹东至阿勒泰公路东胜村至密山虎林界段</td>
                                        <td :width="55">G331</td>
                                        <td :width="55">路面改造</td>
                                        <td :width="55">2022</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td :width="51">21.8</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">21.8</td>
                                        <td :width="65"></td>
                                        <td :width="65">21.8</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">8720</td>
                                        <td :width="91">3837</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">8720</td>
                                        <td :width="91">3837</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>21</td>
                                        <td :width="58">鸡西</td>
                                        <td :width="72">虎林市</td>
                                        <td :width="181">国道丹东至阿勒泰公路富庆屯至八五零农场十九队段</td>
                                        <td :width="55">G331</td>
                                        <td :width="55">路面改造</td>
                                        <td :width="55">2022</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td :width="51">14.0</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">14.0</td>
                                        <td :width="65"></td>
                                        <td :width="65">14.0</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">5600</td>
                                        <td :width="91">2464</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">5600</td>
                                        <td :width="91">2464</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>22</td>
                                        <td :width="58">鸡西</td>
                                        <td :width="72">虎林市</td>
                                        <td :width="181">国道丹东至阿勒泰公路凉水桥至义和村段</td>
                                        <td :width="55">G331</td>
                                        <td :width="55">路面改造</td>
                                        <td :width="55">2022</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td :width="51">24.0</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">24.0</td>
                                        <td :width="65"></td>
                                        <td :width="65">24.0</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">9600</td>
                                        <td :width="91">4224</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">9600</td>
                                        <td :width="91">4224</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>23</td>
                                        <td :width="58">佳木斯</td>
                                        <td :width="72">同江市</td>
                                        <td :width="181">国道同江至哈尔滨公路哈鱼岛至三村镇（新富村）段</td>
                                        <td :width="55">G221</td>
                                        <td :width="55">新建</td>
                                        <td :width="55">2022</td>
                                        <td :width="51">2024</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">29.2</td>
                                        <td :width="65"></td>
                                        <td :width="65">20.2</td>
                                        <td :width="65"></td>
                                        <td :width="51">9000</td>
                                        <td :width="71">84042</td>
                                        <td :width="91">46770</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">84042</td>
                                        <td :width="91">46770</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>24</td>
                                        <td :width="58">哈尔滨</td>
                                        <td :width="72">通河县</td>
                                        <td :width="181">国道北京至抚远公路通河段</td>
                                        <td :width="55">G102</td>
                                        <td :width="55">路面改造</td>
                                        <td :width="55">2022</td>
                                        <td :width="51">2023</td>
                                        <td :width="51">3.4</td>
                                        <td :width="51">110.0</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">113.4</td>
                                        <td :width="65">3.4</td>
                                        <td :width="65">110.0</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">45360</td>
                                        <td :width="91">16352</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">45360</td>
                                        <td :width="91">16352</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>25</td>
                                        <td :width="58">哈尔滨</td>
                                        <td :width="72">依兰县</td>
                                        <td :width="181">国道北京至抚远公路依兰段</td>
                                        <td :width="55">G102</td>
                                        <td :width="55">路面改造</td>
                                        <td :width="55">2022</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td :width="51">35.2</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">35.2</td>
                                        <td :width="65"></td>
                                        <td :width="65">35.2</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">14080</td>
                                        <td :width="91">4928</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">14080</td>
                                        <td :width="91">4928</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>26</td>
                                        <td :width="58">伊春</td>
                                        <td :width="72">铁力市</td>
                                        <td :width="181">国道嘉荫至临江公路铁力过境段</td>
                                        <td :width="55">G222</td>
                                        <td :width="55">新建、升级改造、原级改造</td>
                                        <td :width="55">2022</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">26.0</td>
                                        <td :width="65">14.4</td>
                                        <td :width="65">11.6</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">45102</td>
                                        <td :width="91">19667</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">45102</td>
                                        <td :width="91">19667</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>27</td>
                                        <td :width="58">双鸭山</td>
                                        <td :width="72">友谊县</td>
                                        <td :width="181">国道同江至哈尔滨公路友谊过境段</td>
                                        <td :width="55">G221</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2022</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">15.8</td>
                                        <td :width="65">15.8</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">47490</td>
                                        <td :width="91">22120</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">47490</td>
                                        <td :width="91">22120</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>28</td>
                                        <td :width="58">齐齐哈尔</td>
                                        <td :width="72">讷河市</td>
                                        <td :width="181">国道三合至莫力达瓦旗公路全胜村至讷河西出口段</td>
                                        <td :width="55">G333</td>
                                        <td :width="55">新建、升级改造</td>
                                        <td :width="55">2022</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td :width="51">11.0</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">11.0</td>
                                        <td :width="65">11.0</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">44000</td>
                                        <td :width="91">15400</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">44000</td>
                                        <td :width="91">15400</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>29</td>
                                        <td :width="58">大庆</td>
                                        <td :width="72">肇源县</td>
                                        <td :width="181">国道绥化至沈阳公路肇源过境段</td>
                                        <td :width="55">G203</td>
                                        <td :width="55">新建</td>
                                        <td :width="55">2022</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">11.6</td>
                                        <td :width="65">11.6</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">46400</td>
                                        <td :width="91">16240</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">46400</td>
                                        <td :width="91">16240</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>30</td>
                                        <td :width="58">绥化</td>
                                        <td :width="72">绥化市</td>
                                        <td :width="181">国道嘉荫至临江公路绥化过境段</td>
                                        <td :width="55">G222</td>
                                        <td :width="55">新建</td>
                                        <td :width="55">2022</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">21.2</td>
                                        <td :width="65">21.2</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">84800</td>
                                        <td :width="91">29680</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">84800</td>
                                        <td :width="91">29680</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>31</td>
                                        <td :width="58">哈尔滨</td>
                                        <td :width="72">尚志市</td>
                                        <td :width="181">国道绥芬河至满洲里公路尚志过境段</td>
                                        <td :width="55">G301</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2022</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">6.6</td>
                                        <td :width="51"></td>
                                        <td :width="65">6.6</td>
                                        <td :width="65">6.6</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">26400</td>
                                        <td :width="91">9240</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">26400</td>
                                        <td :width="91">9240</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>32</td>
                                        <td :width="58">哈尔滨</td>
                                        <td :width="72">尚志市</td>
                                        <td :width="181">国道饶河至盖州公路尚志过境段</td>
                                        <td :width="55">G229</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2022</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">5.1</td>
                                        <td :width="65">5.1</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">20400</td>
                                        <td :width="91">7140</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">20400</td>
                                        <td :width="91">7140</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>33</td>
                                        <td :width="58">绥化</td>
                                        <td :width="72">绥棱县</td>
                                        <td :width="181">国道三合至莫力达瓦旗公路绥棱至海南乡绥北高速段</td>
                                        <td :width="55">G333</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2022</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td>16.4</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">16.4</td>
                                        <td :width="65">16.4</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">65600</td>
                                        <td :width="91">22960</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">65600</td>
                                        <td :width="91">22960</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>34</td>
                                        <td :width="58">哈尔滨</td>
                                        <td :width="72">五常市</td>
                                        <td :width="181">国道嘉荫至临江公路五常过境段</td>
                                        <td :width="55">G222</td>
                                        <td :width="55">新建</td>
                                        <td :width="55">2022</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">14.4</td>
                                        <td :width="65"></td>
                                        <td>14.4</td>
                                        <td></td>
                                        <td></td>
                                        <td :width="71">31464</td>
                                        <td :width="91">8640</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">31464</td>
                                        <td :width="91">8640</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>35</td>
                                        <td :width="58">大庆</td>
                                        <td :width="72">林甸县</td>
                                        <td :width="181">国道绥芬河至满洲里公路黄牛场至林富界段</td>
                                        <td :width="55">G301</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2022</td>
                                        <td :width="51">2024</td>
                                        <td :width="51">4.0</td>
                                        <td :width="51"></td>
                                        <td :width="51">69.2</td>
                                        <td :width="51"></td>
                                        <td :width="65">73.2</td>
                                        <td :width="65">4.0</td>
                                        <td :width="65">69.2</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">128906</td>
                                        <td :width="91">42640</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">128906</td>
                                        <td :width="91">42640</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>36</td>
                                        <td :width="58">齐齐哈尔</td>
                                        <td :width="72">富裕县</td>
                                        <td :width="181">国道绥芬河至满洲里公路林富界至嫩双公路段</td>
                                        <td :width="55">G301</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2022</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">9.2</td>
                                        <td :width="51"></td>
                                        <td :width="65">9.2</td>
                                        <td :width="65"></td>
                                        <td :width="65">9.2</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">27384</td>
                                        <td :width="91">5520</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">27384</td>
                                        <td :width="91">5520</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>37</td>
                                        <td :width="58">牡丹江</td>
                                        <td :width="72">海林市</td>
                                        <td :width="181">国道绥芬河至满洲里公路牡海界至海林段</td>
                                        <td :width="55">G301</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2022</td>
                                        <td :width="51">2022</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">3.7</td>
                                        <td :width="51"></td>
                                        <td :width="65">3.7</td>
                                        <td :width="65"></td>
                                        <td :width="65">3.7</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">5162</td>
                                        <td :width="91">2245</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">5162</td>
                                        <td :width="91">2245</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>38</td>
                                        <td :width="58">双鸭山</td>
                                        <td :width="72">饶河县</td>
                                        <td :width="181">国道丹东至阿勒泰公路饶河农场至前锋农场段</td>
                                        <td :width="55">G331</td>
                                        <td :width="55">原级改造</td>
                                        <td :width="55">2023</td>
                                        <td :width="51">2025</td>
                                        <td :width="51"></td>
                                        <td :width="51">67.8</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">67.8</td>
                                        <td :width="65"></td>
                                        <td :width="65">67.8</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">61020</td>
                                        <td :width="91">23730</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">61020</td>
                                        <td :width="91">23730</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>39</td>
                                        <td :width="58">哈尔滨</td>
                                        <td :width="72">五常市</td>
                                        <td :width="181">国道饶河至盖州公路五常过境段</td>
                                        <td :width="55">G229</td>
                                        <td :width="55">新建</td>
                                        <td :width="55">2023</td>
                                        <td :width="51">2024</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">6.5</td>
                                        <td :width="65"></td>
                                        <td>6.5</td>
                                        <td></td>
                                        <td></td>
                                        <td :width="71">13546</td>
                                        <td :width="91">3900</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">13546</td>
                                        <td :width="91">3900</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>40</td>
                                        <td :width="58">齐齐哈尔</td>
                                        <td :width="72">依安县</td>
                                        <td :width="181">国道三合至莫力达瓦旗公路依安北出口段</td>
                                        <td :width="55">G333</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2023</td>
                                        <td :width="51">2024</td>
                                        <td :width="51"></td>
                                        <td>2.2</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">2.2</td>
                                        <td :width="65">2.2</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">8692</td>
                                        <td :width="91">3042</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">8692</td>
                                        <td :width="91">3042</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>41</td>
                                        <td :width="58">齐齐哈尔</td>
                                        <td :width="72">碾子山区</td>
                                        <td :width="181">国道牙克石至四平公路碾子山至省界段</td>
                                        <td :width="55">G232</td>
                                        <td :width="55">改扩建</td>
                                        <td :width="55">2023</td>
                                        <td :width="51">2024</td>
                                        <td :width="51"></td>
                                        <td>19.5</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">19.5</td>
                                        <td :width="65">19.5</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">78000</td>
                                        <td :width="91">27300</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">78000</td>
                                        <td :width="91">27300</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>42</td>
                                        <td :width="58">哈尔滨</td>
                                        <td :width="72">双城区</td>
                                        <td :width="181">国道北京至抚远公路双城至黑吉省界段</td>
                                        <td :width="55">G102</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2023</td>
                                        <td :width="51">2024</td>
                                        <td :width="51"></td>
                                        <td>30.1</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">30.1</td>
                                        <td :width="65">30.1</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">120400</td>
                                        <td :width="91">42140</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">120400</td>
                                        <td :width="91">42140</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>43</td>
                                        <td :width="58">牡丹江</td>
                                        <td :width="72">海林市</td>
                                        <td :width="181">国道绥芬河至满洲里公路平合村至横道河子镇段</td>
                                        <td :width="55">G301</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2023</td>
                                        <td :width="51">2024</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">29.7</td>
                                        <td :width="51"></td>
                                        <td :width="65">29.7</td>
                                        <td :width="65"></td>
                                        <td :width="65">29.7</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">40146</td>
                                        <td :width="91">17839</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">40146</td>
                                        <td :width="91">17839</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>44</td>
                                        <td :width="58">哈尔滨</td>
                                        <td :width="72">阿城区</td>
                                        <td :width="181">国道绥芬河至满洲里公路尚志阿城界至平山镇段</td>
                                        <td :width="55">G301</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2023</td>
                                        <td :width="51">2024</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">2.9</td>
                                        <td :width="51"></td>
                                        <td :width="65">2.9</td>
                                        <td :width="65"></td>
                                        <td :width="65">2.9</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">8777</td>
                                        <td :width="91">1740</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">8777</td>
                                        <td :width="91">1740</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>45</td>
                                        <td :width="58">牡丹江</td>
                                        <td :width="72">西安区</td>
                                        <td :width="181">国道鹤岗至大连公路牡丹江至牡宁界段</td>
                                        <td :width="55">G201</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2023</td>
                                        <td :width="51">2024</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">15.6</td>
                                        <td :width="51"></td>
                                        <td :width="65">21.1</td>
                                        <td :width="65"></td>
                                        <td :width="65">21.1</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">25320</td>
                                        <td :width="91">12660</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">25320</td>
                                        <td :width="91">12660</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>46</td>
                                        <td :width="58">牡丹江</td>
                                        <td :width="72">宁安市</td>
                                        <td :width="181">国道鹤岗至大连公路牡宁界至宁安段</td>
                                        <td :width="55">G201</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2023</td>
                                        <td :width="51">2023</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">1.5</td>
                                        <td :width="51"></td>
                                        <td :width="65">1.5</td>
                                        <td :width="65"></td>
                                        <td :width="65">1.5</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">1800</td>
                                        <td :width="91">900</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">1800</td>
                                        <td :width="91">900</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>47</td>
                                        <td :width="58">齐齐哈尔</td>
                                        <td :width="72">甘南县</td>
                                        <td :width="181">国道绥芬河至满洲里公路齐甘界至长山收费站段</td>
                                        <td :width="55">G301</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2023</td>
                                        <td :width="51">2024</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">7.9</td>
                                        <td :width="51"></td>
                                        <td :width="65">7.9</td>
                                        <td :width="65"></td>
                                        <td :width="65">7.9</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">8206</td>
                                        <td :width="91">4746</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">8206</td>
                                        <td :width="91">4746</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>48</td>
                                        <td :width="58">佳木斯</td>
                                        <td :width="72">市区</td>
                                        <td :width="181">国道鹤岗至大连公路草帽村至佳桦界段</td>
                                        <td :width="55">G201</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2023</td>
                                        <td :width="51">2025</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">28.8</td>
                                        <td :width="51"></td>
                                        <td :width="65">28.8</td>
                                        <td :width="65"></td>
                                        <td :width="65">28.8</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">34560</td>
                                        <td :width="91">17280</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">34560</td>
                                        <td :width="91">17280</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>49</td>
                                        <td :width="58">齐齐哈尔</td>
                                        <td :width="72">讷河市</td>
                                        <td :width="181">国道嫩江至双辽公路讷河至讷河富裕界段</td>
                                        <td :width="55">G231</td>
                                        <td :width="55">原级改造</td>
                                        <td :width="55">2024</td>
                                        <td :width="51">2026</td>
                                        <td :width="51"></td>
                                        <td>49.0</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">49.0</td>
                                        <td :width="65"></td>
                                        <td :width="65">49.0</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">29400</td>
                                        <td :width="91">17150</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">30870</td>
                                        <td :width="91">12005</td>
                                        <td :width="91">13230</td>
                                        <td :width="91">5145</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>50</td>
                                        <td :width="58">鸡西</td>
                                        <td :width="72">虎林市</td>
                                        <td :width="181">国道丹东至阿勒泰公路虎头镇至虎饶界段</td>
                                        <td :width="55">G331</td>
                                        <td :width="55">原级改造</td>
                                        <td :width="55">2024</td>
                                        <td :width="51">2026</td>
                                        <td :width="51"></td>
                                        <td :width="51">72.2</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">72.2</td>
                                        <td :width="65"></td>
                                        <td :width="65">72.2</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">64980</td>
                                        <td :width="91">25270</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">45486</td>
                                        <td :width="91">17689</td>
                                        <td :width="91">19494</td>
                                        <td :width="91">7581</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>51</td>
                                        <td :width="58">大兴安岭</td>
                                        <td :width="72">漠河市</td>
                                        <td :width="181">国道丹东至阿勒泰公路漠河口岸路至洛古河段</td>
                                        <td :width="55">G331</td>
                                        <td :width="55">路面改造</td>
                                        <td :width="55">2024</td>
                                        <td :width="51">2025</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">29.8</td>
                                        <td :width="51"></td>
                                        <td :width="65">29.8</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="65">29.8</td>
                                        <td :width="51"></td>
                                        <td :width="71">8940</td>
                                        <td :width="91">4470</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">8940</td>
                                        <td :width="91">4470</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>52</td>
                                        <td :width="58">大庆</td>
                                        <td :width="72">市区</td>
                                        <td :width="181">国道绥芬河至满洲里公路大庆段</td>
                                        <td :width="55">G301</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2024</td>
                                        <td :width="51">2026</td>
                                        <td :width="51"></td>
                                        <td :width="51">3.8</td>
                                        <td :width="51">23.6</td>
                                        <td :width="51"></td>
                                        <td :width="65">27.4</td>
                                        <td :width="65"></td>
                                        <td :width="65">27.4</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">32880</td>
                                        <td :width="91">14692</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">23016</td>
                                        <td :width="91">10284</td>
                                        <td :width="91">9864</td>
                                        <td :width="91">4408</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>53</td>
                                        <td :width="58">鹤岗</td>
                                        <td :width="72">绥滨县</td>
                                        <td :width="181">国道丹东至阿勒泰公路同绥界至绥滨段</td>
                                        <td :width="55">G331</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2027</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">33.6</td>
                                        <td :width="51">29.6</td>
                                        <td :width="65">63.2</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="65">63.2</td>
                                        <td :width="51"></td>
                                        <td :width="71">27840</td>
                                        <td :width="91">13328</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">8352</td>
                                        <td :width="91">3998</td>
                                        <td :width="91">19488</td>
                                        <td :width="91">9330</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>54</td>
                                        <td :width="58">鸡西</td>
                                        <td :width="72">密山市</td>
                                        <td :width="181">国道集贤至当壁公路连珠山至当壁镇口岸段</td>
                                        <td :width="55">G501</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2026</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">10.2</td>
                                        <td :width="51"></td>
                                        <td :width="65">11.0</td>
                                        <td :width="65"></td>
                                        <td :width="65">10.2</td>
                                        <td :width="65"></td>
                                        <td :width="51">800</td>
                                        <td :width="71">13200</td>
                                        <td :width="91">9200</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">6600</td>
                                        <td :width="91">4600</td>
                                        <td :width="91">6600</td>
                                        <td :width="91">4600</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>55</td>
                                        <td :width="58">齐齐哈尔</td>
                                        <td :width="72">富裕县</td>
                                        <td :width="181">国道嫩江至双辽公路大马岗至冯屯段</td>
                                        <td :width="55">G231</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2024</td>
                                        <td :width="51">2025</td>
                                        <td :width="51"></td>
                                        <td :width="51">10.0</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">10.0</td>
                                        <td :width="65">10.0</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">40000</td>
                                        <td :width="91">14000</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">40000</td>
                                        <td :width="91">14000</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>56</td>
                                        <td :width="58">鹤岗</td>
                                        <td :width="72">绥滨县</td>
                                        <td :width="181">国道丹东至阿勒泰公路同江松花江公路大桥及引道</td>
                                        <td :width="55">G331</td>
                                        <td :width="55">新建</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2026</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">17.0</td>
                                        <td :width="65"></td>
                                        <td :width="65">13.0</td>
                                        <td :width="65"></td>
                                        <td :width="51">4000</td>
                                        <td :width="71">39600</td>
                                        <td :width="91">24600</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">19800</td>
                                        <td :width="91">12300</td>
                                        <td :width="91">19800</td>
                                        <td :width="91">12300</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>57</td>
                                        <td :width="58">七台河</td>
                                        <td :width="72">市区</td>
                                        <td :width="181">国道饶河至盖州公路七台河过境段</td>
                                        <td :width="55">G229</td>
                                        <td :width="55">新建</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2027</td>
                                        <td :width="51">27.5</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">37.5</td>
                                        <td :width="65">37.5</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">119230</td>
                                        <td :width="91">52500</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">35769</td>
                                        <td :width="91">15750</td>
                                        <td :width="91">83461</td>
                                        <td :width="91">36750</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>58</td>
                                        <td :width="58">哈尔滨</td>
                                        <td :width="72">呼兰区</td>
                                        <td :width="181">国道嘉荫至临江公路西长发镇至呼兰段</td>
                                        <td :width="55">G222</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2027</td>
                                        <td :width="51"></td>
                                        <td>3.6</td>
                                        <td :width="51">48.4</td>
                                        <td :width="51"></td>
                                        <td :width="65">52.0</td>
                                        <td :width="65"></td>
                                        <td :width="65">52.0</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">62400</td>
                                        <td :width="91">31200</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">18720</td>
                                        <td :width="91">9360</td>
                                        <td :width="91">43680</td>
                                        <td :width="91">21840</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>59</td>
                                        <td :width="58">牡丹江</td>
                                        <td :width="72">穆棱市</td>
                                        <td :width="181">国道绥芬河至满洲里公路东穆界至穆牡界段</td>
                                        <td :width="55">G301</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2024</td>
                                        <td :width="51">2026</td>
                                        <td :width="51"></td>
                                        <td :width="51">8.9</td>
                                        <td :width="51">41.8</td>
                                        <td :width="51"></td>
                                        <td :width="65">50.7</td>
                                        <td :width="65"></td>
                                        <td :width="65">50.7</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">67062</td>
                                        <td :width="91">30420</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">46943</td>
                                        <td :width="91">21294</td>
                                        <td :width="91">20119</td>
                                        <td :width="91">9126</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>60</td>
                                        <td :width="58">鸡西</td>
                                        <td :width="72">市区、鸡东县</td>
                                        <td :width="181">国道鹤岗至大连公路勃利鸡东界至城子河段</td>
                                        <td :width="55">G201</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2024</td>
                                        <td :width="51">2026</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">40.6</td>
                                        <td :width="51"></td>
                                        <td :width="65">41.5</td>
                                        <td :width="65"></td>
                                        <td :width="65">41.5</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">49800</td>
                                        <td :width="91">24900</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">34860</td>
                                        <td :width="91">17430</td>
                                        <td :width="91">14940</td>
                                        <td :width="91">7470</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>61</td>
                                        <td :width="58">黑河</td>
                                        <td :width="72">逊克县</td>
                                        <td :width="181">国道丹东至阿勒泰公路嘉逊界至逊克段</td>
                                        <td :width="55">G331</td>
                                        <td :width="55">原级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2027</td>
                                        <td :width="51"></td>
                                        <td :width="51">60.7</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">60.7</td>
                                        <td :width="65"></td>
                                        <td :width="65">60.7</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">54630</td>
                                        <td :width="91">21245</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">16389</td>
                                        <td :width="91">6374</td>
                                        <td :width="91">38241</td>
                                        <td :width="91">14872</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>62</td>
                                        <td :width="58">黑河、大兴安岭</td>
                                        <td :width="72">爱辉区、呼玛县</td>
                                        <td :width="181">国道丹东至阿勒泰公路卧牛湖至呼玛段</td>
                                        <td :width="55">G331</td>
                                        <td :width="55">原级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2028</td>
                                        <td :width="51"></td>
                                        <td>196.4</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">196.4</td>
                                        <td :width="65"></td>
                                        <td :width="65">196.4</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">176760</td>
                                        <td :width="91">68740</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">35352</td>
                                        <td :width="91">13748</td>
                                        <td :width="91">141408</td>
                                        <td :width="91">54992</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>63</td>
                                        <td :width="58">齐齐哈尔</td>
                                        <td :width="72">市区</td>
                                        <td :width="181">国道牙克石至四平公路齐杜界至昂昂溪大阿拉街段</td>
                                        <td :width="55">G232</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2026</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">17.0</td>
                                        <td :width="51"></td>
                                        <td :width="65">17.0</td>
                                        <td :width="65"></td>
                                        <td :width="65">17.0</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">15300</td>
                                        <td :width="91">10200</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">7650</td>
                                        <td :width="91">5100</td>
                                        <td :width="91">7650</td>
                                        <td :width="91">5100</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>64</td>
                                        <td :width="58">佳木斯</td>
                                        <td :width="72">市区</td>
                                        <td :width="181">国道鹤岗至大连公路佳木斯至草帽村段</td>
                                        <td :width="55">G201</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2026</td>
                                        <td :width="51"></td>
                                        <td>9.0</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">9.0</td>
                                        <td>9.0</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">36000</td>
                                        <td :width="91">12600</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">18000</td>
                                        <td :width="91">6300</td>
                                        <td :width="91">18000</td>
                                        <td :width="91">6300</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>65</td>
                                        <td :width="58">哈尔滨</td>
                                        <td :width="72">尚志市</td>
                                        <td :width="181">国道绥芬河至满洲里公路海林尚志界至尚志阿城界段</td>
                                        <td :width="55">G301</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2028</td>
                                        <td :width="51"></td>
                                        <td :width="51">7.3</td>
                                        <td :width="51">175.4</td>
                                        <td :width="51"></td>
                                        <td :width="65">179.4</td>
                                        <td :width="65"></td>
                                        <td :width="65">179.4</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">132335</td>
                                        <td :width="91">105340</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">26467</td>
                                        <td :width="91">21068</td>
                                        <td :width="91">105868</td>
                                        <td :width="91">84272</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>66</td>
                                        <td :width="58">黑河</td>
                                        <td :width="72">市区</td>
                                        <td :width="181">国道黑河至大连公路西岗子镇至黑河孙吴界段</td>
                                        <td :width="55">G202</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2027</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">38.6</td>
                                        <td :width="51"></td>
                                        <td :width="65">38.6</td>
                                        <td :width="65"></td>
                                        <td :width="65">38.6</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">46320</td>
                                        <td :width="91">23160</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">13896</td>
                                        <td :width="91">6948</td>
                                        <td :width="91">32424</td>
                                        <td :width="91">16212</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>67</td>
                                        <td :width="58">黑河</td>
                                        <td :width="72">孙吴县</td>
                                        <td :width="181">国道黑河至大连公路黑河孙吴界至孙吴五大连池界段</td>
                                        <td :width="55">G202</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2028</td>
                                        <td :width="51"></td>
                                        <td :width="51">6.2</td>
                                        <td :width="51">79.1</td>
                                        <td :width="51"></td>
                                        <td :width="65">85.3</td>
                                        <td :width="65"></td>
                                        <td :width="65">85.3</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">102360</td>
                                        <td :width="91">48328</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">20472</td>
                                        <td :width="91">9666</td>
                                        <td :width="91">81888</td>
                                        <td :width="91">38662</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>68</td>
                                        <td :width="58">齐齐哈尔</td>
                                        <td :width="72">泰来县</td>
                                        <td :width="181">国道嫩江至双辽公路泰来（克利）至泰昂界段</td>
                                        <td :width="55">G231</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2027</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">75.0</td>
                                        <td :width="51"></td>
                                        <td :width="65">75.0</td>
                                        <td :width="65"></td>
                                        <td :width="65">75.0</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">90000</td>
                                        <td :width="91">45000</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">18000</td>
                                        <td :width="91">9000</td>
                                        <td :width="91">72000</td>
                                        <td :width="91">36000</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>69</td>
                                        <td :width="58">齐齐哈尔</td>
                                        <td :width="72">昂昂溪区</td>
                                        <td :width="181">国道嫩江至双辽公路新合村至泰昂界段</td>
                                        <td :width="55">G231</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2025</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">3.9</td>
                                        <td :width="51"></td>
                                        <td :width="65">3.8</td>
                                        <td :width="65"></td>
                                        <td :width="65">3.8</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">4560</td>
                                        <td :width="91">2280</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">4560</td>
                                        <td :width="91">2280</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>70</td>
                                        <td :width="58">齐齐哈尔</td>
                                        <td :width="72">泰来县</td>
                                        <td :width="181">国道嫩江至双辽公路嫩江大桥</td>
                                        <td :width="55">G231</td>
                                        <td :width="55">新建</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2027</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">1.8</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51">1754</td>
                                        <td :width="71">30000</td>
                                        <td :width="91">6753</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">9000</td>
                                        <td :width="91">2026</td>
                                        <td :width="91">21000</td>
                                        <td :width="91">4727</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>71</td>
                                        <td :width="58">绥化</td>
                                        <td :width="72">青冈县</td>
                                        <td :width="181">国道绥化至沈阳公路青冈过境段</td>
                                        <td :width="55">G203</td>
                                        <td :width="55">新建</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2026</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">12.3</td>
                                        <td :width="65"></td>
                                        <td :width="65">12.3</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">14760</td>
                                        <td :width="91">7380</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">7380</td>
                                        <td :width="91">3690</td>
                                        <td :width="91">7380</td>
                                        <td :width="91">3690</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>72</td>
                                        <td :width="58">哈尔滨</td>
                                        <td :width="72">木兰县</td>
                                        <td :width="181">国道北京至抚远公路木兰过境段</td>
                                        <td :width="55">G102</td>
                                        <td :width="55">新建</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2026</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">14.0</td>
                                        <td :width="65"></td>
                                        <td>14.0</td>
                                        <td></td>
                                        <td></td>
                                        <td :width="71">16800</td>
                                        <td :width="91">8400</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">8400</td>
                                        <td :width="91">4200</td>
                                        <td :width="91">8400</td>
                                        <td :width="91">4200</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>73</td>
                                        <td :width="58">鸡西</td>
                                        <td :width="72">市区</td>
                                        <td :width="181">国道鹤岗至大连公路滴道过境段</td>
                                        <td :width="55">G201</td>
                                        <td :width="55">新建</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2026</td>
                                        <td :width="51"></td>
                                        <td></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">7.3</td>
                                        <td>7.3</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">29200</td>
                                        <td :width="91">10220</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">14600</td>
                                        <td :width="91">5110</td>
                                        <td :width="91">14600</td>
                                        <td :width="91">5110</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>74</td>
                                        <td :width="58">牡丹江</td>
                                        <td :width="72">海林市</td>
                                        <td :width="181">国道三合至莫力达瓦旗公路海林农场至长汀镇段</td>
                                        <td :width="55">G333</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2026</td>
                                        <td :width="51"></td>
                                        <td></td>
                                        <td :width="51">12.5</td>
                                        <td :width="51"></td>
                                        <td :width="65">12.5</td>
                                        <td :width="65"></td>
                                        <td :width="65">12.5</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">8750</td>
                                        <td :width="91">7500</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">7500</td>
                                        <td :width="91">3750</td>
                                        <td :width="91">1250</td>
                                        <td :width="91">3750</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>75</td>
                                        <td :width="58">齐齐哈尔</td>
                                        <td :width="72">龙江县、市辖区</td>
                                        <td :width="181">国道牙克石至四平公路碾子山至富拉尔基段</td>
                                        <td :width="55">G232</td>
                                        <td :width="55">改扩建</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2027</td>
                                        <td :width="51"></td>
                                        <td>75.0</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">75.0</td>
                                        <td :width="65">75.0</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">300000</td>
                                        <td :width="91">105000</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">60000</td>
                                        <td :width="91">21000</td>
                                        <td :width="91">240000</td>
                                        <td :width="91">84000</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>76</td>
                                        <td :width="58">齐齐哈尔</td>
                                        <td :width="72">富拉尔基区、昂昂溪区</td>
                                        <td :width="181">国道牙克石至四平公路富拉尔基至昂昂溪段</td>
                                        <td :width="55">G232</td>
                                        <td :width="55">新建</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2026</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">17.2</td>
                                        <td :width="51"></td>
                                        <td :width="65">17.2</td>
                                        <td :width="65"></td>
                                        <td :width="65">15.5</td>
                                        <td :width="65"></td>
                                        <td :width="51">1680</td>
                                        <td :width="71">58014</td>
                                        <td :width="91">17318</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">29007</td>
                                        <td :width="91">8659</td>
                                        <td :width="91">29007</td>
                                        <td :width="91">8659</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>77</td>
                                        <td :width="58">齐齐哈尔</td>
                                        <td :width="72">依安县</td>
                                        <td :width="181">国道三合至莫力达瓦旗公路乌裕尔河大桥</td>
                                        <td :width="55">G333</td>
                                        <td :width="55">新建</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2027</td>
                                        <td :width="51"></td>
                                        <td></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">1.1</td>
                                        <td></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51">1148</td>
                                        <td :width="71">18175</td>
                                        <td :width="91">4420</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">5453</td>
                                        <td :width="91">1326</td>
                                        <td :width="91">12723</td>
                                        <td :width="91">3094</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>78</td>
                                        <td :width="58">鸡西</td>
                                        <td :width="72">鸡东县</td>
                                        <td :width="181">国道丹东至阿勒泰公路兴凯湖机场至鸡东密山界段</td>
                                        <td :width="55">G331</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2027</td>
                                        <td :width="51"></td>
                                        <td :width="51">27.8</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">24.8</td>
                                        <td :width="65">24.8</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">99200</td>
                                        <td :width="91">43648</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">29760</td>
                                        <td :width="91">13094</td>
                                        <td :width="91">69440</td>
                                        <td :width="91">30554</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>79</td>
                                        <td :width="58">佳木斯</td>
                                        <td :width="72">市区</td>
                                        <td :width="181">国道鹤岗至大连公路江北转盘至鹤立段</td>
                                        <td :width="55">G201</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2027</td>
                                        <td :width="51"></td>
                                        <td>27.6</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">27.6</td>
                                        <td>27.6</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">110400</td>
                                        <td :width="91">38640</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">33120</td>
                                        <td :width="91">11592</td>
                                        <td :width="91">77280</td>
                                        <td :width="91">27048</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>80</td>
                                        <td :width="58">佳木斯</td>
                                        <td :width="72">市辖区</td>
                                        <td :width="181">国道同江至哈尔滨公路安庆至万发段</td>
                                        <td :width="55">G221</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2027</td>
                                        <td :width="51">7.8</td>
                                        <td :width="51"></td>
                                        <td :width="51">2.3</td>
                                        <td :width="51"></td>
                                        <td :width="65">11.0</td>
                                        <td :width="65">11.0</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">44000</td>
                                        <td :width="91">15400</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">13200</td>
                                        <td :width="91">4620</td>
                                        <td :width="91">30800</td>
                                        <td :width="91">10780</td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr style="font-weight: bold">
                                        <td colspan="4" :width="349">三、谋划项目</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td :width="65">1218.1</td>
                                        <td :width="65">523.0</td>
                                        <td :width="65">694.4</td>
                                        <td :width="65"></td>
                                        <td :width="51">713</td>
                                        <td :width="71">2925321</td>
                                        <td :width="91">1218286</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">2925321</td>
                                        <td :width="91">1218286</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td :width="58">牡丹江</td>
                                        <td :width="72">林口县</td>
                                        <td :width="181">国道鹤岗至大连公路林口（龙爪镇）至林牡界段</td>
                                        <td :width="55">G201</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2024</td>
                                        <td :width="51">2026</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">59.2</td>
                                        <td :width="51"></td>
                                        <td :width="65">59.2</td>
                                        <td :width="65"></td>
                                        <td :width="65">59.2</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">71040</td>
                                        <td :width="91">41440</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">71040</td>
                                        <td :width="91">41440</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td :width="58">绥化</td>
                                        <td :width="72">绥棱县</td>
                                        <td :width="181">国道萝北至额布都格公路伊绥界至绥北界段</td>
                                        <td :width="55">G332</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2027</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">52.3</td>
                                        <td :width="65">52.3</td>
                                        <td :width="65"></td>
                                        <td :width="65">52.3</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">62760</td>
                                        <td :width="91">36610</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">62760</td>
                                        <td :width="91">36610</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td :width="58">哈尔滨</td>
                                        <td :width="72">尚志市</td>
                                        <td :width="181">国道三合至莫力达瓦旗公路国光村至尚延界段</td>
                                        <td :width="55">G333</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2027</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">26.0</td>
                                        <td :width="51">23.3</td>
                                        <td :width="65">49.3</td>
                                        <td :width="65"></td>
                                        <td :width="65">49.3</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">59160</td>
                                        <td :width="91">34510</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">59160</td>
                                        <td :width="91">34510</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td :width="58">伊春</td>
                                        <td :width="72">乌翠区</td>
                                        <td :width="181">国道萝北至额布都格公路翠峦至乌绥界段</td>
                                        <td :width="55">G332</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2027</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">19.2</td>
                                        <td :width="51">33.5</td>
                                        <td :width="65">52.7</td>
                                        <td :width="65"></td>
                                        <td :width="65">52.7</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">63240</td>
                                        <td :width="91">36890</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">63240</td>
                                        <td :width="91">36890</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td :width="58">齐齐哈尔</td>
                                        <td :width="72">市区</td>
                                        <td :width="181">国道绥芬河至满洲里公路奈门沁分离桥至哈拉海农场段</td>
                                        <td :width="55">G301</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2024</td>
                                        <td :width="51">2026</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">43.5</td>
                                        <td :width="51"></td>
                                        <td :width="65">43.5</td>
                                        <td :width="65"></td>
                                        <td :width="65">43.5</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">52200</td>
                                        <td :width="91">30450</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">52200</td>
                                        <td :width="91">30450</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td :width="58">哈尔滨</td>
                                        <td :width="72">方正县</td>
                                        <td :width="181">国道同江至哈尔滨公路得莫利镇至方正镇段</td>
                                        <td :width="55">G221</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2026</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">17.5</td>
                                        <td :width="51"></td>
                                        <td :width="65">17.5</td>
                                        <td :width="65"></td>
                                        <td :width="65">17.5</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">21000</td>
                                        <td :width="91">12250</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">21000</td>
                                        <td :width="91">12250</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td :width="58">哈尔滨</td>
                                        <td :width="72">宾县</td>
                                        <td :width="181">国道同江至哈尔滨公路宾州镇至方宾界段</td>
                                        <td :width="55">G221</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2028</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">66.6</td>
                                        <td :width="51"></td>
                                        <td :width="65">66.6</td>
                                        <td :width="65"></td>
                                        <td :width="65">65.9</td>
                                        <td :width="65"></td>
                                        <td :width="51">713</td>
                                        <td :width="71">79064</td>
                                        <td :width="91">46121</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">79064</td>
                                        <td :width="91">46121</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td :width="58">佳木斯</td>
                                        <td :width="72">富锦市</td>
                                        <td :width="181">国道同江至哈尔滨公路二十五米桥至二龙山镇段</td>
                                        <td :width="55">G221</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2026</td>
                                        <td :width="51"></td>
                                        <td :width="51">3.8</td>
                                        <td :width="51">11.1</td>
                                        <td :width="51"></td>
                                        <td :width="65">14.9</td>
                                        <td :width="65"></td>
                                        <td :width="65">14.9</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">17861</td>
                                        <td :width="91">10419</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">17861</td>
                                        <td :width="91">10419</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td :width="58">佳木斯</td>
                                        <td :width="72">富锦<br>
                                            市</td>
                                        <td :width="181">国道同江至哈尔滨公路二龙山镇至大榆树镇段</td>
                                        <td :width="55">G221</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2027</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">18.9</td>
                                        <td :width="51"></td>
                                        <td :width="65">18.9</td>
                                        <td :width="65"></td>
                                        <td :width="65">18.9</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">22680</td>
                                        <td :width="91">13230</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">22680</td>
                                        <td :width="91">13230</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td :width="58">绥化</td>
                                        <td :width="72">安达市</td>
                                        <td :width="181">国道绥芬河至满洲里公路北环路至安大界段</td>
                                        <td :width="55">G301</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2026</td>
                                        <td :width="51">4.6</td>
                                        <td :width="51">3.0</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">7.6</td>
                                        <td :width="65">7.6</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">30476</td>
                                        <td :width="91">10667</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">30476</td>
                                        <td :width="91">10667</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>11</td>
                                        <td :width="58">哈尔滨</td>
                                        <td :width="72">方正县</td>
                                        <td :width="181">国道同江至哈尔滨公路方正镇绕城南段</td>
                                        <td :width="55">G221</td>
                                        <td :width="55">新建</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2026</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">5.9</td>
                                        <td :width="65">5.9</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">23600</td>
                                        <td :width="91">8260</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">23600</td>
                                        <td :width="91">8260</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>12</td>
                                        <td :width="58">牡丹江</td>
                                        <td :width="72">宁安市</td>
                                        <td :width="181">国道鹤岗至大连公路兰岗镇过境段</td>
                                        <td :width="55">G201</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2026</td>
                                        <td :width="51"></td>
                                        <td></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">16.2</td>
                                        <td :width="65">16.2</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">64800</td>
                                        <td :width="91">22680</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">64800</td>
                                        <td :width="91">22680</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>13</td>
                                        <td :width="58">绥化</td>
                                        <td :width="72">市区</td>
                                        <td :width="181">国道嘉荫至临江公路绥化至西长发段</td>
                                        <td :width="55">G222</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2027</td>
                                        <td :width="51"></td>
                                        <td :width="51">35.0</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">35.0</td>
                                        <td :width="65">35.0</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">140000</td>
                                        <td :width="91">49000</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">140000</td>
                                        <td :width="91">49000</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>14</td>
                                        <td :width="58">绥化</td>
                                        <td :width="72">庆安县</td>
                                        <td :width="181">国道三合至莫力达瓦旗公路木庆界至庆安段</td>
                                        <td :width="55">G333</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2027</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">47.4</td>
                                        <td :width="51"></td>
                                        <td :width="65">45.0</td>
                                        <td :width="65"></td>
                                        <td :width="65">45.0</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">54000</td>
                                        <td :width="91">31500</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">54000</td>
                                        <td :width="91">31500</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>15</td>
                                        <td :width="58">黑河</td>
                                        <td :width="72">北安市</td>
                                        <td :width="181">国道萝北至额布都格公路北安水稻基地至北安段</td>
                                        <td :width="55">G332</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2026</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">24.2</td>
                                        <td :width="51"></td>
                                        <td :width="65">24.2</td>
                                        <td :width="65"></td>
                                        <td :width="65">24.2</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">29040</td>
                                        <td :width="91">16940</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">29040</td>
                                        <td :width="91">16940</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>16</td>
                                        <td :width="58">黑河</td>
                                        <td :width="72">五大连池市</td>
                                        <td :width="181">国道萝北至额布都格公路青石村至格球山农场段</td>
                                        <td :width="55">G332</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2027</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">30.7</td>
                                        <td :width="51"></td>
                                        <td :width="65">30.7</td>
                                        <td :width="65"></td>
                                        <td :width="65">30.7</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">36840</td>
                                        <td :width="91">21490</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">36840</td>
                                        <td :width="91">21490</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>17</td>
                                        <td :width="58">哈尔滨</td>
                                        <td :width="72">延寿县</td>
                                        <td :width="181">国道三合至莫力达瓦旗公路尚志延寿界至延中公路段</td>
                                        <td :width="55">G333</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2027</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">21.0</td>
                                        <td :width="51">27.7</td>
                                        <td :width="65">48.7</td>
                                        <td :width="65"></td>
                                        <td :width="65">48.7</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">58440</td>
                                        <td :width="91">34090</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">58440</td>
                                        <td :width="91">34090</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>18</td>
                                        <td :width="58">哈尔滨</td>
                                        <td :width="72">木兰县</td>
                                        <td :width="181">国道三合至莫力达瓦旗公路木兰至木庆界段</td>
                                        <td :width="55">G333</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2028</td>
                                        <td :width="51"></td>
                                        <td></td>
                                        <td :width="51">79.0</td>
                                        <td :width="51"></td>
                                        <td :width="65">79.0</td>
                                        <td :width="65"></td>
                                        <td :width="65">79.0</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">94800</td>
                                        <td :width="91">55300</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">94800</td>
                                        <td :width="91">55300</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>19</td>
                                        <td :width="58">黑河</td>
                                        <td :width="72">北安市</td>
                                        <td :width="181">国道萝北至额布都格公路绥北界至北安水稻基地段</td>
                                        <td :width="55">G332</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2028</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="51">92.6</td>
                                        <td :width="65">92.6</td>
                                        <td :width="65"></td>
                                        <td :width="65">92.6</td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">111120</td>
                                        <td :width="91">64820</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">111120</td>
                                        <td :width="91">64820</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                    <tr>
                                        <td>20</td>
                                        <td :width="58">大兴安岭</td>
                                        <td :width="72">市本级</td>
                                        <td :width="181">国道北京至漠河公路加格达奇至漠河段</td>
                                        <td :width="55">G111</td>
                                        <td :width="55">升级改造</td>
                                        <td :width="55">2025</td>
                                        <td :width="51">2028</td>
                                        <td :width="51">9.3</td>
                                        <td :width="51">449.0</td>
                                        <td :width="51"></td>
                                        <td :width="51"></td>
                                        <td :width="65">458.3</td>
                                        <td :width="65">458.3</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="51"></td>
                                        <td :width="71">1833200</td>
                                        <td :width="91">641620</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="67">1833200</td>
                                        <td :width="91">641620</td>
                                        <td :width="91"></td>
                                        <td :width="91"></td>
                                        <td :width="51"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="省道" name="third">
                        <h2 style="margin-bottom: 10px; text-align: center">
                            黑龙江省“十四五”普通省道重点建设项目表
<span @click="wordDetail(3)" class="xzecl" >十四五普通省道项目表</span>

                        </h2>
                        <div class="table-responsive">

                            <table class="table table-line1">
                                <thead>
                                    <tr>
                                        <td rowspan="4" :width="35">序号</td>
                                        <td rowspan="4" :width="49">所属地市</td>
                                        <td rowspan="4" :width="49">所属县（市）</td>
                                        <td rowspan="4" :width="281" style="width: 280px!important">项目名称</td>
                                        <td rowspan="4" :width="47">路线编号</td>
                                        <td rowspan="4" :width="137">建设性质</td>
                                        <td colspan="2" rowspan="2" :width="95">建设期</td>
                                        <td colspan="6" rowspan="2" :width="346">现状等级（公里）</td>
                                        <td colspan="16" :width="1052">建议标准</td>
                                        <td colspan="3" rowspan="2" :width="174">2021年投资（万元）</td>
                                        <td colspan="2" rowspan="2" :width="116">2022年投资<br>
                                            （万元）</td>
                                        <td rowspan="4" :width="87">是否地市行文</td>
                                        <td rowspan="4" :width="144">项目功能</td>
                                        <td rowspan="4" :width="166">备注</td>
                                    </tr>
                                    <tr>
                                        <td colspan="6" :width="368">建设规模（公里）</td>
                                        <td colspan="10" :width="683">投资（万元）</td>
                                    </tr>
                                    <tr>
                                        <td rowspan="2" :width="47">开工年</td>
                                        <td rowspan="2" :width="47">完工年</td>
                                        <td rowspan="2" :width="58">小计</td>
                                        <td rowspan="2" :width="53">一级</td>
                                        <td rowspan="2" :width="58">二级</td>
                                        <td rowspan="2" :width="58">三级</td>
                                        <td rowspan="2" :width="58">四级</td>
                                        <td rowspan="2" :width="58">等外</td>
                                        <td rowspan="2" :width="64">小计</td>
                                        <td rowspan="2" :width="56">一级</td>
                                        <td rowspan="2" :width="56">二级(80km/h)</td>
                                        <td rowspan="2" :width="61">二级(60km/h)</td>
                                        <td rowspan="2" :width="65">三级</td>
                                        <td rowspan="2" :width="65">桥隧(延米)</td>
                                        <td rowspan="2" :width="69">总投资</td>
                                        <td rowspan="2" :width="66">中央车购税</td>
                                        <td rowspan="2" :width="69">省财政</td>
                                        <td colspan="2" :width="134">&ldquo;十三五&rdquo;</td>
                                        <td colspan="3" :width="201">&ldquo;十四五&rdquo;</td>
                                        <td colspan="2" :width="143">&ldquo;十五五&rdquo;投资</td>
                                        <td rowspan="2" :width="58">小计</td>
                                        <td rowspan="2" :width="58">其中车购税</td>
                                        <td rowspan="2" :width="58">其中省财政</td>
                                        <td rowspan="2" :width="58">小计</td>
                                        <td rowspan="2" :width="58">其中省财政</td>
                                    </tr>
                                    <tr>
                                        <td :width="67">投资</td>
                                        <td :width="67">中央车购税</td>
                                        <td :width="67">投资</td>
                                        <td :width="67">中央车购税</td>
                                        <td :width="67">省财政</td>
                                        <td :width="71">投资</td>
                                        <td :width="71">省财政</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style="font-weight: bold">
                                        <td colspan="4" :width="331">合计</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58">5835.5</td>
                                        <td :width="53"></td>
                                        <td :width="58">483.5</td>
                                        <td :width="58">3944.7</td>
                                        <td :width="58">1333.4</td>
                                        <td :width="58">102.1</td>
                                        <td :width="64">6052.6</td>
                                        <td :width="56">328.1</td>
                                        <td :width="56">793.0</td>
                                        <td :width="61">1274.5</td>
                                        <td :width="65">3653.0</td>
                                        <td :width="65">4038</td>
                                        <td :width="69">5381499</td>
                                        <td :width="66">30151</td>
                                        <td :width="69">2098898</td>
                                        <td :width="67">19393</td>
                                        <td :width="67">15561</td>
                                        <td :width="67">4624752</td>
                                        <td :width="67">14590</td>
                                        <td :width="67">1773897</td>
                                        <td :width="71">737354</td>
                                        <td :width="71">325001</td>
                                        <td :width="58">117774</td>
                                        <td :width="58">14590</td>
                                        <td :width="58">37775</td>
                                        <td :width="58">568901</td>
                                        <td :width="58">296190</td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr style="font-weight: bold">
                                        <td colspan="4" :width="331">一、正选项目</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58">4794.7</td>
                                        <td :width="53"></td>
                                        <td :width="58">268.2</td>
                                        <td :width="58">3251.7</td>
                                        <td :width="58">1201.0</td>
                                        <td :width="58">102.1</td>
                                        <td :width="64">5012.7</td>
                                        <td :width="56">140.0</td>
                                        <td :width="56">706.0</td>
                                        <td :width="61">1189.1</td>
                                        <td :width="65">2973.7</td>
                                        <td :width="65">3983</td>
                                        <td :width="69">4040000</td>
                                        <td :width="66">30151</td>
                                        <td :width="69">1647148</td>
                                        <td :width="67">19393</td>
                                        <td :width="67">15561</td>
                                        <td :width="67">3283253</td>
                                        <td :width="67">14590</td>
                                        <td :width="67">1322147</td>
                                        <td :width="71">737354</td>
                                        <td :width="71">325001</td>
                                        <td :width="58">117774</td>
                                        <td :width="58">14590</td>
                                        <td :width="58">37775</td>
                                        <td :width="58">568901</td>
                                        <td :width="58">296190</td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr style="font-weight: bold">
                                        <td colspan="4" :width="331">（一）&ldquo;十三五&rdquo;续建项目</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58">91.8</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">91.8</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">91.8</td>
                                        <td :width="56"></td>
                                        <td :width="56">11.0</td>
                                        <td :width="61">80.8</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">67882</td>
                                        <td :width="66">30151</td>
                                        <td :width="69"></td>
                                        <td :width="67">18093</td>
                                        <td :width="67">15561</td>
                                        <td :width="67">49789</td>
                                        <td :width="67">14590</td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58">24774</td>
                                        <td :width="58">14590</td>
                                        <td :width="58"></td>
                                        <td :width="58">25015</td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">1</td>
                                        <td :width="49">双鸭山</td>
                                        <td :width="49">集贤县</td>
                                        <td :width="198">省道同江至汪清公路集贤镇至福利镇段</td>
                                        <td :width="47">S203</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2017</td>
                                        <td :width="47">2021</td>
                                        <td :width="58">11.0</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">11.0</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td>11.0</td>
                                        <td :width="56"></td>
                                        <td :width="56">11.0</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td>10600</td>
                                        <td :width="66">3826</td>
                                        <td :width="69"></td>
                                        <td :width="67">9700</td>
                                        <td :width="67">3826</td>
                                        <td :width="67">900</td>
                                        <td :width="67"></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>900</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">2</td>
                                        <td :width="49">黑河</td>
                                        <td :width="49">逊克县</td>
                                        <td :width="198">省道逊克至孙吴公路逊克口岸至逊克（靠山村）段</td>
                                        <td :width="47">S516</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2017</td>
                                        <td :width="47">2021</td>
                                        <td :width="58">5.8</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">5.8</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td>5.8</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">5.8</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td>4667</td>
                                        <td :width="66">2035</td>
                                        <td :width="69"></td>
                                        <td :width="67">3793</td>
                                        <td :width="67">2035</td>
                                        <td :width="67">874</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58">874</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td></td>
                                        <td></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">3</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">尚志市</td>
                                        <td :width="198">省道哈尔滨至亚布力公路尚志镇至一面坡镇段</td>
                                        <td :width="47">S102</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2020</td>
                                        <td :width="47">2022</td>
                                        <td :width="58">27.3</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">27.3</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td>27.3</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">27.3</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td>16020</td>
                                        <td :width="66">7581</td>
                                        <td :width="69"></td>
                                        <td :width="67">3600</td>
                                        <td :width="67">3000</td>
                                        <td :width="67">12420</td>
                                        <td :width="67">4581</td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58">5000</td>
                                        <td :width="58">4581</td>
                                        <td :width="58"></td>
                                        <td :width="58">7420</td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">4</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">桦南县</td>
                                        <td :width="198">省道同江至汪清公路桦双界至桦南（驼腰子镇）段</td>
                                        <td :width="47">S203</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2020</td>
                                        <td :width="47">2022</td>
                                        <td :width="58">47.7</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">47.7</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td>47.7</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">47.7</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td>36595</td>
                                        <td :width="66">16709</td>
                                        <td :width="69"></td>
                                        <td :width="67">1000</td>
                                        <td :width="67">6700</td>
                                        <td :width="67">35595</td>
                                        <td :width="67">10009</td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58">18000</td>
                                        <td :width="58">10009</td>
                                        <td :width="58"></td>
                                        <td :width="58">17595</td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr style="font-weight: bold">
                                        <td colspan="4" :width="331">（二）2021年已安排计划项目</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58">449.9</td>
                                        <td :width="53"></td>
                                        <td :width="58">9.9</td>
                                        <td :width="58">353.2</td>
                                        <td :width="58">86.8</td>
                                        <td :width="58"></td>
                                        <td :width="64">453.2</td>
                                        <td :width="56">12.2</td>
                                        <td :width="56">128.5</td>
                                        <td :width="61">312.1</td>
                                        <td :width="65"></td>
                                        <td :width="65">350</td>
                                        <td :width="69">444257</td>
                                        <td :width="66"></td>
                                        <td :width="69">185695</td>
                                        <td :width="67">1300</td>
                                        <td :width="67"></td>
                                        <td :width="67">442957</td>
                                        <td :width="67"></td>
                                        <td :width="67">185695</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58">93000</td>
                                        <td :width="58"></td>
                                        <td :width="58">37775</td>
                                        <td :width="58">211405</td>
                                        <td :width="58">147920</td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">1</td>
                                        <td :width="49">双鸭山</td>
                                        <td :width="49">宝清县</td>
                                        <td :width="198">省道友谊至宝清公路高家村至永宁村段</td>
                                        <td :width="47">S501</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2020</td>
                                        <td :width="47">2022</td>
                                        <td :width="58">15.4</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">15.4</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">15.4</td>
                                        <td :width="56">3.7</td>
                                        <td :width="56">11.7</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">28296</td>
                                        <td :width="66"></td>
                                        <td :width="69">9560</td>
                                        <td :width="67">200</td>
                                        <td :width="67"></td>
                                        <td :width="67">28096</td>
                                        <td :width="67"></td>
                                        <td :width="67">9560</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58">20000</td>
                                        <td :width="58"></td>
                                        <td :width="58">6805</td>
                                        <td :width="58">8096</td>
                                        <td :width="58">2755</td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166">工可研、初步设计已批复</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">2</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">汤原县</td>
                                        <td :width="198">省道小佳河至亮子河公路鹤立至胜利段</td>
                                        <td :width="47">S308</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2020</td>
                                        <td :width="47">2022</td>
                                        <td :width="58">44.8</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">44.8</td>
                                        <td :width="58"></td>
                                        <td :width="64">44.7</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">44.7</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">46922</td>
                                        <td :width="66"></td>
                                        <td :width="69">15645</td>
                                        <td :width="67">1100</td>
                                        <td :width="67"></td>
                                        <td :width="67">45822</td>
                                        <td :width="67"></td>
                                        <td :width="67">15645</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58">9000</td>
                                        <td :width="58"></td>
                                        <td :width="58">3073</td>
                                        <td :width="58">36822</td>
                                        <td :width="58">12572</td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166">工可研、初步设计已批复</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">3</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">拜泉县</td>
                                        <td :width="198">省道黑河至双城公路三道镇至拜明界段</td>
                                        <td :width="47">S211</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2021</td>
                                        <td :width="47">2022</td>
                                        <td :width="58">7.8</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">7.8</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">7.7</td>
                                        <td :width="56"></td>
                                        <td :width="56">7.7</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">7057</td>
                                        <td :width="66"></td>
                                        <td :width="69">3850</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">7057</td>
                                        <td :width="67"></td>
                                        <td :width="67">3850</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58">1000</td>
                                        <td :width="58"></td>
                                        <td :width="58">546</td>
                                        <td :width="58">6057</td>
                                        <td :width="58">3304</td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166">工可研、初步设计已批复</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">4</td>
                                        <td :width="49">绥化</td>
                                        <td :width="49">安达市</td>
                                        <td :width="198">省道兰西至民意公路昌德镇至安大界段</td>
                                        <td :width="47">S213</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2021</td>
                                        <td :width="47">2022</td>
                                        <td :width="58">11.7</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">11.7</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">11.7</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">11.7</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">8997</td>
                                        <td :width="66"></td>
                                        <td :width="69">4095</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">8997</td>
                                        <td :width="67"></td>
                                        <td :width="67">4095</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58">2000</td>
                                        <td :width="58"></td>
                                        <td :width="58">910</td>
                                        <td :width="58">6997</td>
                                        <td :width="58">3185</td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166">工可研、初步设计、施工图已批复</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">5</td>
                                        <td :width="49">绥化</td>
                                        <td :width="49">明水县</td>
                                        <td :width="198">省道黑河至双城公路拜明界至明水段</td>
                                        <td :width="47">S211</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2021</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">43.2</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">43.2</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">45.0</td>
                                        <td :width="56">1.3</td>
                                        <td :width="56"></td>
                                        <td :width="61">43.7</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">50528</td>
                                        <td :width="66"></td>
                                        <td :width="69">16595</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">50528</td>
                                        <td :width="67"></td>
                                        <td :width="67">16595</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58">7000</td>
                                        <td :width="58"></td>
                                        <td :width="58">2299</td>
                                        <td :width="58">21764</td>
                                        <td :width="58">14296</td>
                                        <td :width="87">明水正式行文。</td>
                                        <td :width="144"></td>
                                        <td :width="166">工可、初步设计已批复</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">6</td>
                                        <td :width="49">鸡西</td>
                                        <td :width="49">鸡东县</td>
                                        <td :width="198">省道虎林至鸡西公路密山鸡东界至鸡东段</td>
                                        <td :width="47">S314</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2021</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">48.8</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">48.8</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">48.9</td>
                                        <td :width="56">7.2</td>
                                        <td :width="56">41.7</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">46201</td>
                                        <td :width="66"></td>
                                        <td :width="69">28050</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">46201</td>
                                        <td :width="67"></td>
                                        <td :width="67">28050</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58">9000</td>
                                        <td :width="58"></td>
                                        <td :width="58">5464</td>
                                        <td :width="58">26041</td>
                                        <td :width="58">22586</td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166">工可研、初步设计已批复</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">7</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">依安县</td>
                                        <td :width="198">省道依安至四方台公路依安至依拜界段</td>
                                        <td :width="47">S212</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2021</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">59.9</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">59.9</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">60.3</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">59.9</td>
                                        <td :width="65"></td>
                                        <td :width="65">350.3</td>
                                        <td :width="69">58224</td>
                                        <td :width="66"></td>
                                        <td :width="69">20965</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">58224</td>
                                        <td :width="67"></td>
                                        <td :width="67">20965</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58">15000</td>
                                        <td :width="58"></td>
                                        <td :width="58">5401</td>
                                        <td :width="58">21612</td>
                                        <td :width="58">15564</td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166">工可研、初步设计已批复</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">8</td>
                                        <td :width="49">大兴安岭</td>
                                        <td :width="49">新林区<br>
                                            呼玛县</td>
                                        <td :width="198">省道连崟至兴华公路新林至兴华段</td>
                                        <td :width="47">S218</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2021</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">152.0</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">110.0</td>
                                        <td :width="58">42.0</td>
                                        <td :width="58"></td>
                                        <td :width="64">152.1</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">152.1</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">120287</td>
                                        <td :width="66"></td>
                                        <td :width="69">53235</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">120287</td>
                                        <td :width="67"></td>
                                        <td :width="67">53235</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58">30000</td>
                                        <td :width="58"></td>
                                        <td :width="58">13277</td>
                                        <td :width="58">45144</td>
                                        <td :width="58">39958</td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166">工可已批复，初步设计已完成审查</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">9</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">巴彦县</td>
                                        <td :width="198">省道哈尔滨至北安公路巴彦至绥巴界段</td>
                                        <td :width="47">S101</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2021</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">66.3</td>
                                        <td :width="53"></td>
                                        <td :width="58">9.9</td>
                                        <td :width="58">56.4</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">67.4</td>
                                        <td :width="56"></td>
                                        <td :width="56">67.4</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">77745</td>
                                        <td :width="66"></td>
                                        <td :width="69">33700</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">77745</td>
                                        <td :width="67"></td>
                                        <td :width="67">33700</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">38873</td>
                                        <td :width="58">33700</td>
                                        <td :width="87">巴彦正式行文。</td>
                                        <td :width="144"></td>
                                        <td :width="166">工可、初步设计已批复</td>
                                    </tr>
                                    <tr style="font-weight: bold">
                                        <td colspan="4" :width="331">（三）2022年建设项目</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58">1193.0</td>
                                        <td :width="53"></td>
                                        <td :width="58">63.7</td>
                                        <td :width="58">763.9</td>
                                        <td :width="58">360.2</td>
                                        <td :width="58">5.2</td>
                                        <td :width="64">1202.9</td>
                                        <td :width="56">24.8</td>
                                        <td :width="56">198.0</td>
                                        <td :width="61">491.7</td>
                                        <td :width="65">488.4</td>
                                        <td :width="65"></td>
                                        <td :width="69">1011889</td>
                                        <td :width="66"></td>
                                        <td :width="69">432643</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">1011889</td>
                                        <td :width="67"></td>
                                        <td :width="67">432643</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">332482</td>
                                        <td :width="58">148270</td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">1</td>
                                        <td :width="49">大庆</td>
                                        <td :width="49">市辖区</td>
                                        <td :width="198">省道兰西至民意公路安大界至大兴段</td>
                                        <td :width="47">S213</td>
                                        <td :width="137">升级改造</td>
                                        <td>2022</td>
                                        <td>2024</td>
                                        <td :width="58">51.1</td>
                                        <td></td>
                                        <td></td>
                                        <td>51.1</td>
                                        <td></td>
                                        <td></td>
                                        <td :width="64">51.1</td>
                                        <td></td>
                                        <td>51.1</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td :width="69">44059</td>
                                        <td :width="66"></td>
                                        <td :width="69">25550</td>
                                        <td></td>
                                        <td></td>
                                        <td>44059</td>
                                        <td></td>
                                        <td :width="67">25550</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td :width="58"></td>
                                        <td>13218</td>
                                        <td :width="58">7665</td>
                                        <td></td>
                                        <td>瓶颈路段</td>
                                        <td :width="166">工可已完成审查，正在办理土地预审审批手续</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">2</td>
                                        <td :width="49">绥化</td>
                                        <td :width="49">绥棱县</td>
                                        <td :width="198">省道绥棱至拉哈公路绥棱至四海店镇段</td>
                                        <td>S304</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">47.7</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">47.7</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">47.7</td>
                                        <td :width="56"></td>
                                        <td :width="56">47.7</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">40800</td>
                                        <td :width="66"></td>
                                        <td :width="69">23850</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td>40800</td>
                                        <td></td>
                                        <td :width="67">23850</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">20000</td>
                                        <td :width="58">11691</td>
                                        <td :width="87">绥棱正式行文</td>
                                        <td :width="144">区域内连通农林场、乡镇</td>
                                        <td :width="166">工可已完成审查，正在办理土地预审审批手续</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">3</td>
                                        <td :width="49">双鸭山</td>
                                        <td :width="49">宝清县</td>
                                        <td :width="198">省道宝清至八五二公路万金山至八五二七分场段</td>
                                        <td :width="47">S506</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">26.0</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">26.0</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">26.0</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">26.0</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">23400</td>
                                        <td :width="66"></td>
                                        <td :width="69">9100</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td>23400</td>
                                        <td></td>
                                        <td :width="67">9100</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td>11700</td>
                                        <td :width="58">4550</td>
                                        <td :width="87"></td>
                                        <td :width="144">区域内连通农林场、乡镇</td>
                                        <td :width="166">工可已完成审查，正在办理土地预审审批手续</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">4</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">汤原县</td>
                                        <td :width="198">省道小佳河至亮子河公路振兴乡至鹤立镇段</td>
                                        <td :width="47">S308</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">41.3</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">39.6</td>
                                        <td :width="58">1.7</td>
                                        <td :width="58"></td>
                                        <td :width="64">41.6</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">41.6</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">48159</td>
                                        <td :width="66"></td>
                                        <td :width="69">14560</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td>48159</td>
                                        <td></td>
                                        <td :width="67">14560</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td>24080</td>
                                        <td :width="58">7280</td>
                                        <td :width="87"></td>
                                        <td :width="144">区域内连通农林场、乡镇</td>
                                        <td :width="166">工可研已编制完成，近期安排审查</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">5</td>
                                        <td :width="49">大兴安岭</td>
                                        <td :width="49">呼玛县</td>
                                        <td :width="198">省道呼玛至嫩江公路老道店村至北疆村段</td>
                                        <td :width="47">S215</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">77.6</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">15.0</td>
                                        <td :width="58">62.6</td>
                                        <td :width="58"></td>
                                        <td :width="64">77.6</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">77.6</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">74291</td>
                                        <td :width="66"></td>
                                        <td :width="69">27160</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td>74291</td>
                                        <td></td>
                                        <td :width="67">27160</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td>22287</td>
                                        <td :width="58">8148</td>
                                        <td :width="87">呼玛正式行文。</td>
                                        <td :width="144">瓶颈路段，跨区域通道</td>
                                        <td :width="166">工可研已编制完成，近期安排审查</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">6</td>
                                        <td :width="49">黑河</td>
                                        <td :width="49">嫩江市</td>
                                        <td :width="198">省道呼玛至嫩江公路嫩呼界至多宝山镇段</td>
                                        <td :width="47">S215</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">99.5</td>
                                        <td :width="53"></td>
                                        <td :width="58">46.2</td>
                                        <td :width="58">2.0</td>
                                        <td :width="58">51.3</td>
                                        <td :width="58"></td>
                                        <td :width="64">99.5</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">99.5</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">89550</td>
                                        <td :width="66"></td>
                                        <td :width="69">34825</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">89550</td>
                                        <td :width="67"></td>
                                        <td :width="67">34825</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td>26865</td>
                                        <td :width="58">10448</td>
                                        <td :width="87">嫩江、黑河交通局行文</td>
                                        <td :width="144">瓶颈路段，跨区域通道</td>
                                        <td :width="166">工可研已编制完成，近期安排审查</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">7</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">龙江县</td>
                                        <td :width="198">省道查哈阳至扎赉特旗公路富拉尔基至景星段</td>
                                        <td :width="47">S217</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">36.5</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">36.5</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">37.1</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">37.1</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">28600</td>
                                        <td :width="66"></td>
                                        <td :width="69">12985</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">28600</td>
                                        <td :width="67"></td>
                                        <td :width="67">12985</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">14888</td>
                                        <td :width="58">6759</td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166">工可已完成审查，正在办理土地预审审批手续</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">8</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">桦南县</td>
                                        <td :width="198">省道同江至汪清公路桦南至桦勃界段</td>
                                        <td :width="47">S203</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">47.2</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">16.8</td>
                                        <td :width="58">30.4</td>
                                        <td :width="58"></td>
                                        <td :width="64">47.6</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">47.6</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">37740</td>
                                        <td :width="66"></td>
                                        <td :width="69">16660</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">37740</td>
                                        <td :width="67"></td>
                                        <td :width="67">16660</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td>11322</td>
                                        <td :width="58">4998</td>
                                        <td :width="87"></td>
                                        <td :width="144">瓶颈路段，连接县级行政节点</td>
                                        <td :width="166">可研编制完成</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">9</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">道里区</td>
                                        <td :width="198">哈尔滨经济圈环线道里段</td>
                                        <td :width="47">S001</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">5.2</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">5.2</td>
                                        <td :width="64">5.2</td>
                                        <td :width="56">5.2</td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">10400</td>
                                        <td :width="66"></td>
                                        <td :width="69">5200</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">10400</td>
                                        <td :width="67"></td>
                                        <td :width="67">5200</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td>5200</td>
                                        <td :width="58">2600</td>
                                        <td :width="87"></td>
                                        <td :width="144">区域内连通景区</td>
                                        <td :width="166">工可编制中</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">10</td>
                                        <td :width="49">大庆</td>
                                        <td :width="49">林甸县</td>
                                        <td :width="198">省道建兴至新林公路林甸镇至林富界段</td>
                                        <td :width="47">S309</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2022</td>
                                        <td :width="58">28.5</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">28.5</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">20.5</td>
                                        <td :width="56"></td>
                                        <td :width="56">20.5</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td></td>
                                        <td :width="69">36930</td>
                                        <td :width="66"></td>
                                        <td :width="69">10250</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">36930</td>
                                        <td :width="67"></td>
                                        <td :width="67">10250</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td>18465</td>
                                        <td :width="58">5125</td>
                                        <td :width="87">林甸正式行文</td>
                                        <td :width="144">瓶颈路段，跨区域通道</td>
                                        <td :width="166">工可编制中</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">11</td>
                                        <td :width="49">大庆</td>
                                        <td :width="49">林甸县</td>
                                        <td :width="198">省道前进至胡吉吐莫公路林甸镇过境段</td>
                                        <td :width="47">S214</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">15.9</td>
                                        <td :width="56"></td>
                                        <td :width="56">15.9</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td></td>
                                        <td :width="69">25966</td>
                                        <td :width="66"></td>
                                        <td :width="69">7950</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">25966</td>
                                        <td :width="67"></td>
                                        <td :width="67">7950</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td>12983</td>
                                        <td :width="58">3975</td>
                                        <td :width="87">林甸正式行文</td>
                                        <td :width="144">城市过境段</td>
                                        <td :width="166">工可编制中</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">12</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">富拉尔基区</td>
                                        <td :width="198">省道查哈阳至扎赉特旗公路富拉尔基至龙江界段</td>
                                        <td :width="47">S217</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">14.0</td>
                                        <td :width="53"></td>
                                        <td :width="58">2.7</td>
                                        <td :width="58">11.3</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">14.3</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">14.3</td>
                                        <td :width="65"></td>
                                        <td :width="69">8580</td>
                                        <td :width="66"></td>
                                        <td :width="69">4004</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">8580</td>
                                        <td :width="67"></td>
                                        <td :width="67">4004</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">4290</td>
                                        <td :width="58">2002</td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166">工可编制中</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">13</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">讷河市</td>
                                        <td :width="198">省道绥棱至拉哈公路讷河段</td>
                                        <td :width="47">S304</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47" align="right">2023</td>
                                        <td :width="58">47.6</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">47.6</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">47.6</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">47.6</td>
                                        <td :width="65"></td>
                                        <td :width="69">28560</td>
                                        <td :width="66"></td>
                                        <td :width="69">13328</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">28560</td>
                                        <td :width="67"></td>
                                        <td :width="67">13328</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">14280</td>
                                        <td :width="58">6664</td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166">工可编制中</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">14</td>
                                        <td :width="49">鸡西</td>
                                        <td :width="49">虎林市</td>
                                        <td :width="198">省道珍宝岛至当壁镇公路皖峰至虎林段</td>
                                        <td :width="47">S201</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">49.2</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">49.2</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">49.2</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">49.2</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">34440</td>
                                        <td :width="66"></td>
                                        <td :width="69">17233</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">34440</td>
                                        <td :width="67"></td>
                                        <td :width="67">17233</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td>17220</td>
                                        <td :width="58">8616</td>
                                        <td :width="87">虎林正式行文。</td>
                                        <td :width="144">区域内连通农林场、乡镇</td>
                                        <td :width="166">工可编制中</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">15</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">依安县</td>
                                        <td :width="198">省道前进至胡吉吐莫公路依龙至依安林甸界段</td>
                                        <td :width="47">S214</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">19.5</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">19.5</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">19.5</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">19.5</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">22378</td>
                                        <td :width="66"></td>
                                        <td :width="69">6825</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">22378</td>
                                        <td :width="67"></td>
                                        <td :width="67">6825</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td>11189</td>
                                        <td :width="58">3413</td>
                                        <td :width="87">依安正式行文</td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166">工可编制中</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">16</td>
                                        <td>绥化</td>
                                        <td :width="49">海伦市</td>
                                        <td :width="198">省道哈尔滨至北安公路海伦至北安段</td>
                                        <td :width="47">S101</td>
                                        <td :width="137">原级改造、升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">38.0</td>
                                        <td :width="53"></td>
                                        <td :width="58">14.8</td>
                                        <td :width="58">23.2</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">38.0</td>
                                        <td :width="56"></td>
                                        <td :width="56">38.0</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">20746</td>
                                        <td :width="66"></td>
                                        <td :width="69">18992</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">20746</td>
                                        <td :width="67"></td>
                                        <td :width="67">18992</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td>10373</td>
                                        <td :width="58">9496</td>
                                        <td :width="87">海伦正式行文。</td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166">工可编制中</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">17</td>
                                        <td :width="49">双鸭山</td>
                                        <td :width="49">友谊县</td>
                                        <td :width="198">省道友谊至宝清公路友谊至宝清界段</td>
                                        <td :width="47">S501</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">23.1</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">23.1</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">23.1</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">23.1</td>
                                        <td :width="65"></td>
                                        <td :width="69">13860</td>
                                        <td :width="66"></td>
                                        <td :width="69">6468</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">13860</td>
                                        <td :width="67"></td>
                                        <td :width="67">6468</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td>6930</td>
                                        <td :width="58">3234</td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166">工可编制中</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">18</td>
                                        <td :width="49">双鸭山</td>
                                        <td :width="49">宝清县</td>
                                        <td :width="198">省道友谊至宝清公路友宝界至高家段</td>
                                        <td :width="47">S501</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">28.7</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">28.7</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">28.7</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">28.7</td>
                                        <td :width="65"></td>
                                        <td :width="69">17222</td>
                                        <td :width="66"></td>
                                        <td :width="69">8037</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">17222</td>
                                        <td :width="67"></td>
                                        <td :width="67">8037</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td>8611</td>
                                        <td :width="58">4019</td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166">工可编制中</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">19</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">克东县</td>
                                        <td :width="198">省道北安至富裕公路宝泉镇至克克界段</td>
                                        <td :width="47">S305</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">13.2</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">13.2</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">13.2</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">13.2</td>
                                        <td :width="65"></td>
                                        <td :width="69">3964</td>
                                        <td :width="66"></td>
                                        <td :width="69">3696</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">3964</td>
                                        <td :width="67"></td>
                                        <td :width="67">3696</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">1982</td>
                                        <td :width="58">1848</td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166">工可编制中</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">20</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">汤原县</td>
                                        <td :width="198">省道南岔至孟家岗公路浩良河至香兰段</td>
                                        <td :width="47">S205</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">13.9</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">13.9</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">13.9</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">13.9</td>
                                        <td :width="65"></td>
                                        <td :width="69">4170</td>
                                        <td :width="66"></td>
                                        <td :width="69">3892</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">4170</td>
                                        <td :width="67"></td>
                                        <td :width="67">3892</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">2085</td>
                                        <td :width="58">1946</td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166">工可编制中</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">21</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">五常市</td>
                                        <td :width="198">省道五常至蛟河公路凤白八号桥至省界段</td>
                                        <td :width="47">S208</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">14.4</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">14.4</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">14.4</td>
                                        <td :width="56"></td>
                                        <td :width="56">14.4</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">17280</td>
                                        <td :width="66"></td>
                                        <td :width="69">7200</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">17280</td>
                                        <td :width="67"></td>
                                        <td :width="67">7200</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">8640</td>
                                        <td :width="58">3600</td>
                                        <td :width="87"></td>
                                        <td :width="144">出省通道</td>
                                        <td :width="166">工可编制中</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">22</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">克山县</td>
                                        <td :width="198">省道黑河至双城公路克山镇至克山农场段</td>
                                        <td :width="47">S211</td>
                                        <td :width="137">升级改造、原级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">48.4</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">38.3</td>
                                        <td :width="58">10.1</td>
                                        <td :width="58"></td>
                                        <td :width="64">48.4</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">48.4</td>
                                        <td :width="65"></td>
                                        <td :width="69">24216</td>
                                        <td :width="66"></td>
                                        <td :width="69">13552</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">24216</td>
                                        <td :width="67"></td>
                                        <td :width="67">13552</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">12108</td>
                                        <td :width="58">6776</td>
                                        <td :width="87">克山正式行文。</td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166">工可已完成审查，正在办理土地预审审批手续</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">23</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">讷河市</td>
                                        <td :width="198">省道长水河农场至讷河公路讷河段</td>
                                        <td :width="47">S303</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">47.5</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">47.5</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">47.5</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">47.5</td>
                                        <td :width="65"></td>
                                        <td :width="69">28500</td>
                                        <td :width="66"></td>
                                        <td :width="69">13300</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">28500</td>
                                        <td :width="67"></td>
                                        <td :width="67">13300</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">8550</td>
                                        <td :width="58">3990</td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166">工可编制中</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">24</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">讷河市</td>
                                        <td :width="198">省道黑河至双城公路红五月农场至克山农场一分场段</td>
                                        <td :width="47">S211</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">81.4</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">29.4</td>
                                        <td :width="58">52.0</td>
                                        <td :width="58"></td>
                                        <td :width="64">81.4</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">81.4</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">73000</td>
                                        <td :width="66"></td>
                                        <td :width="69">28490</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">73000</td>
                                        <td :width="67"></td>
                                        <td :width="67">28490</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">21900</td>
                                        <td :width="58">8547</td>
                                        <td :width="87">讷河正式行文。</td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166">红五月农场讷河界至长讷公路段工可编制中</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">25</td>
                                        <td :width="49">绥化</td>
                                        <td :width="49">绥棱县</td>
                                        <td :width="198">省道建兴至新林公路绥棱段</td>
                                        <td :width="47">S309</td>
                                        <td :width="137">升级改造、原级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">78.2</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">5.0</td>
                                        <td :width="58">73.2</td>
                                        <td :width="58"></td>
                                        <td :width="64">78.2</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">78.2</td>
                                        <td :width="65"></td>
                                        <td :width="69">46920</td>
                                        <td :width="66"></td>
                                        <td :width="69">21896</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">46920</td>
                                        <td :width="67"></td>
                                        <td :width="67">21896</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">14076</td>
                                        <td :width="58">6569</td>
                                        <td :width="87">绥棱正式行文</td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166">工可编制中</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">26</td>
                                        <td :width="49">双鸭山</td>
                                        <td :width="49">宝清县</td>
                                        <td :width="198">省道宝清至八五二农场公路八五二农场段</td>
                                        <td :width="47">S506</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">30.8</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">30.8</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">30.8</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">30.8</td>
                                        <td :width="65"></td>
                                        <td :width="69">18480</td>
                                        <td :width="66"></td>
                                        <td :width="69">8624</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">18480</td>
                                        <td :width="67"></td>
                                        <td :width="67">8624</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">9240</td>
                                        <td :width="58">4312</td>
                                        <td :width="87"></td>
                                        <td :width="144">区域内连通农林场、乡镇</td>
                                        <td :width="166">工可编制中</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">27</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">桦南县</td>
                                        <td :width="198">省道佳木斯至桦南公路佳桦界至桦南段</td>
                                        <td :width="47">S513</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">26.9</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">1.3</td>
                                        <td :width="58">25.6</td>
                                        <td :width="58"></td>
                                        <td :width="64">26.9</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">26.9</td>
                                        <td :width="65"></td>
                                        <td :width="69">16140</td>
                                        <td :width="66"></td>
                                        <td :width="69">7519</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">16140</td>
                                        <td :width="67"></td>
                                        <td :width="67">7519</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166">工可编制中</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">28</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">桦南县</td>
                                        <td :width="198">省道南岔至孟家岗公路桦依界至孟家岗镇段</td>
                                        <td :width="47">S205</td>
                                        <td :width="137">升级改造<br>
                                            、原级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">44.8</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">26.0</td>
                                        <td :width="58">18.8</td>
                                        <td :width="58"></td>
                                        <td :width="64">44.8</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">44.8</td>
                                        <td :width="65"></td>
                                        <td :width="69">26880</td>
                                        <td :width="66"></td>
                                        <td :width="69">12548</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">26880</td>
                                        <td :width="67"></td>
                                        <td :width="67">12548</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166">工可编制中</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">29</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">克东县</td>
                                        <td :width="198">省道绥棱至拉哈公路克北界至克克界段</td>
                                        <td :width="47">S304</td>
                                        <td :width="137">原级改造<br>
                                            升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">28.8</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">25.0</td>
                                        <td :width="58">3.8</td>
                                        <td :width="58"></td>
                                        <td :width="64">29.3</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">6.0</td>
                                        <td :width="65">23.3</td>
                                        <td :width="65"></td>
                                        <td :width="69">11801</td>
                                        <td :width="66"></td>
                                        <td :width="69">8624</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">11801</td>
                                        <td :width="67"></td>
                                        <td :width="67">8624</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166">工可已批复</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">30</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">依安县</td>
                                        <td :width="198">省道前进至胡吉吐莫公路依龙至依安拜泉界段</td>
                                        <td :width="47">S214</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">25.1</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">2.485</td>
                                        <td :width="58">22.658</td>
                                        <td :width="58"></td>
                                        <td :width="64">25.1</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">25.1</td>
                                        <td :width="65"></td>
                                        <td :width="69">15086</td>
                                        <td :width="66"></td>
                                        <td :width="69">7028</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">15086</td>
                                        <td :width="67"></td>
                                        <td :width="67">7028</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166">工可编制中</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">31</td>
                                        <td :width="49">鹤岗</td>
                                        <td :width="49">鹤岗市</td>
                                        <td :width="198">省道四季屯至鹤岗公路鹤北至鹤岗段</td>
                                        <td :width="47">S204</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">30.0</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">21.9</td>
                                        <td :width="58">8.1</td>
                                        <td :width="58"></td>
                                        <td :width="64">30.0</td>
                                        <td :width="56">19.6</td>
                                        <td :width="56">10.4</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">85200</td>
                                        <td :width="66"></td>
                                        <td :width="69">24800</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">85200</td>
                                        <td :width="67"></td>
                                        <td :width="67">24800</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">区域内连通农林场、乡镇</td>
                                        <td :width="166">工可已完成审查，正在办理土地预审审批手续</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">32</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">道里区</td>
                                        <td :width="198">省道哈尔滨经济圈环线江堤路至机场高速段</td>
                                        <td :width="47">S001</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2021</td>
                                        <td :width="47">2022</td>
                                        <td :width="58">6.2</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">6.2</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">6.2</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">6.2</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">23250</td>
                                        <td :width="66"></td>
                                        <td :width="69">2170</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">23250</td>
                                        <td :width="67"></td>
                                        <td :width="67">2170</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">区域内连通景区</td>
                                        <td :width="166">工可编制中</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">33</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">克山县</td>
                                        <td :width="198">省道长水河农场至讷河公路克山段</td>
                                        <td :width="47">S303</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">22.6</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">22.6</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">22.6</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">22.6</td>
                                        <td :width="65"></td>
                                        <td :width="69">11321</td>
                                        <td :width="66"></td>
                                        <td :width="69">6328</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">11321</td>
                                        <td :width="67"></td>
                                        <td :width="67">6328</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">克山正式行文。</td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166">工可编制中</td>
                                    </tr>
                                    <tr style="font-weight: bold">
                                        <td colspan="4" :width="331">（四）2023年建设项目</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58">1138.3</td>
                                        <td :width="53"></td>
                                        <td :width="58">131.5</td>
                                        <td :width="58">779.6</td>
                                        <td :width="58">208.5</td>
                                        <td :width="58">47.0</td>
                                        <td :width="64">1275.8</td>
                                        <td :width="56">82.2</td>
                                        <td :width="56">230.6</td>
                                        <td :width="61">229.0</td>
                                        <td :width="65">730.5</td>
                                        <td :width="65">3633</td>
                                        <td :width="69">1217222</td>
                                        <td :width="66"></td>
                                        <td :width="69">459479</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">1217222</td>
                                        <td :width="67"></td>
                                        <td :width="67">459479</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">1</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">甘南县</td>
                                        <td :width="198">省道查哈阳至扎赉特旗公路查哈阳乡至内蒙界段</td>
                                        <td :width="47">S217</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">3.6</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">3.6</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">3.6</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">3.6</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">3240</td>
                                        <td :width="66"></td>
                                        <td :width="69">1260</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">3240</td>
                                        <td :width="67"></td>
                                        <td :width="67">1260</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">出省通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">2</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">双城区</td>
                                        <td :width="198">省道哈尔滨至松原公路太平镇至万隆乡（黑吉界）段</td>
                                        <td :width="47">S103</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">49.4</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">49.4</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">51.0</td>
                                        <td :width="56"></td>
                                        <td :width="56">49.4</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td>1600</td>
                                        <td :width="69">59280</td>
                                        <td :width="66"></td>
                                        <td :width="69">24700</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">59280</td>
                                        <td :width="67"></td>
                                        <td :width="67">24700</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">出省通道，网络舆情关注</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">3</td>
                                        <td :width="49">大兴安岭</td>
                                        <td :width="49">呼中区</td>
                                        <td :width="198">省道十八站至室韦公路飞虎山入口至省界段</td>
                                        <td :width="47">S301</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">30</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">30.0</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">30.0</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">30.0</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">27000</td>
                                        <td :width="66"></td>
                                        <td :width="69">10500</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">27000</td>
                                        <td :width="67"></td>
                                        <td :width="67">10500</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">出省通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">4</td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">东宁市</td>
                                        <td :width="198">省道东宁（老黑山）至汪清公路黑营桥至省界段</td>
                                        <td :width="47">S318</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">36.8</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">36.8</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">36.8</td>
                                        <td :width="56"></td>
                                        <td :width="56">36.8</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">44160</td>
                                        <td :width="66"></td>
                                        <td :width="69">18400</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">44160</td>
                                        <td :width="67"></td>
                                        <td :width="67">18400</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">出省通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">5</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">龙江县</td>
                                        <td :width="198">省道建兴至新林公路碾子山至内蒙界段</td>
                                        <td :width="47">S309</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">49.7</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">48.1</td>
                                        <td :width="58">1.6</td>
                                        <td :width="58"></td>
                                        <td :width="64">49.7</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">49.7</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">44730</td>
                                        <td :width="66"></td>
                                        <td :width="69">17395</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">44730</td>
                                        <td :width="67"></td>
                                        <td :width="67">17395</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">出省通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">6</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">双城区</td>
                                        <td :width="198">省道阿城至扶余公路拉林河大桥</td>
                                        <td :width="47">S210</td>
                                        <td :width="137">新建</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">1.6</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65">1600</td>
                                        <td :width="69">12000</td>
                                        <td :width="66"></td>
                                        <td :width="69">6720</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">12000</td>
                                        <td :width="67"></td>
                                        <td :width="67">6720</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">出省通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">7</td>
                                        <td :width="49">大兴安岭</td>
                                        <td :width="49">塔河县</td>
                                        <td :width="198">省道十八站至室韦公路塔河至十八站段</td>
                                        <td :width="47">S301</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">59.4</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">59.4</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">59.8</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">59.4</td>
                                        <td :width="65">433.0</td>
                                        <td :width="69">35900</td>
                                        <td :width="66"></td>
                                        <td :width="69">16632</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">35900</td>
                                        <td :width="67"></td>
                                        <td :width="67">16632</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">塔河正式行文。</td>
                                        <td :width="144">区域内连通农林场、乡镇</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">8</td>
                                        <td :width="49">鹤岗</td>
                                        <td :width="49">绥滨县</td>
                                        <td :width="198">省道小佳河至亮子河公路绥滨至鹤立镇段</td>
                                        <td :width="47">S308</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">110.0</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">107.0</td>
                                        <td :width="58">3.0</td>
                                        <td :width="58"></td>
                                        <td :width="64">110.0</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">110.0</td>
                                        <td :width="65"></td>
                                        <td :width="69">66000</td>
                                        <td :width="66"></td>
                                        <td :width="69">30800</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">66000</td>
                                        <td :width="67"></td>
                                        <td :width="67">30800</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">绥滨正式行文。</td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">9</td>
                                        <td :width="49">伊春</td>
                                        <td :width="49">汤旺县</td>
                                        <td :width="198">省道四季屯至鹤岗公路汤旺河至乌伊岭段</td>
                                        <td :width="47">S204</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">26.7</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">26.7</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">26.7</td>
                                        <td :width="56"></td>
                                        <td :width="56">26.7</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">32040</td>
                                        <td :width="66"></td>
                                        <td :width="69">13350</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">32040</td>
                                        <td :width="67"></td>
                                        <td :width="67">13350</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">汤旺、伊春正式行文</td>
                                        <td :width="144">连接县级行政节点</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">10</td>
                                        <td :width="49">伊春</td>
                                        <td :width="49">南岔县</td>
                                        <td :width="198">省道南岔至孟家岗公路浩良河镇至汤原段</td>
                                        <td :width="47">S205</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">8.5</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">8.5</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">8.5</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>8.5</td>
                                        <td></td>
                                        <td :width="69">5100</td>
                                        <td :width="66"></td>
                                        <td :width="69">2380</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">5100</td>
                                        <td :width="67"></td>
                                        <td :width="67">2380</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">伊春交通局行文。</td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">11</td>
                                        <td :width="49">黑河</td>
                                        <td :width="49">孙吴县、逊克县</td>
                                        <td :width="198">省道四季屯至鹤岗公路小队至逊克农场段</td>
                                        <td :width="47">S204</td>
                                        <td :width="137">升级改造、原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">52.9</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">36.9</td>
                                        <td :width="58">16.0</td>
                                        <td :width="58"></td>
                                        <td :width="64">52.9</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">52.9</td>
                                        <td :width="65"></td>
                                        <td :width="69">31740</td>
                                        <td :width="66"></td>
                                        <td :width="69">14812</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">31740</td>
                                        <td :width="67"></td>
                                        <td :width="67">14812</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">黑河交通局行文。</td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">12</td>
                                        <td :width="49">大兴安岭</td>
                                        <td :width="49">呼玛县</td>
                                        <td :width="198">呼玛县通用机场连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137">新建</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">1.9</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">1.9</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">1720</td>
                                        <td :width="66"></td>
                                        <td :width="69">669</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">1720</td>
                                        <td :width="67"></td>
                                        <td :width="67">669</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">重要节点连接国省干线</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">13</td>
                                        <td :width="49">大庆</td>
                                        <td :width="49">林甸县</td>
                                        <td :width="198">G10高速林甸出口至林甸经济开发区公路</td>
                                        <td :width="47"></td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">28.3</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">28.3</td>
                                        <td :width="56"></td>
                                        <td :width="56">28.3</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">33960</td>
                                        <td :width="66"></td>
                                        <td :width="69">14150</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">33960</td>
                                        <td :width="67"></td>
                                        <td :width="67">14150</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">重要节点连接国省干线</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">14</td>
                                        <td :width="49">绥化</td>
                                        <td :width="49">肇东市</td>
                                        <td :width="198">省道哈尔滨至大安公路哈尔滨至东五路段</td>
                                        <td :width="47">S104</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">8.9</td>
                                        <td :width="56">8.9</td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">35600</td>
                                        <td :width="66"></td>
                                        <td :width="69">8900</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">35600</td>
                                        <td :width="67"></td>
                                        <td :width="67">8900</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">重要节点连接国省干线</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">15</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">桦川县</td>
                                        <td :width="198">四马架至佳木斯双合路公路</td>
                                        <td :width="47"></td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">14.2</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">14.2</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">12780</td>
                                        <td :width="66"></td>
                                        <td :width="69">4970</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">12780</td>
                                        <td :width="67"></td>
                                        <td :width="67">4970</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">重要节点连接国省干线</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">16</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">松北区</td>
                                        <td :width="198">哈尔滨经济圈环线春雷桥至西码头段</td>
                                        <td :width="47">S001</td>
                                        <td :width="137">原级改造<br>
                                            原级改造<br>
                                            升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">5.9</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">5.9</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">5.9</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">5.9</td>
                                        <td :width="65"></td>
                                        <td :width="69">3540</td>
                                        <td :width="66"></td>
                                        <td :width="69">1652</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">3540</td>
                                        <td :width="67"></td>
                                        <td :width="67">1652</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">17</td>
                                        <td :width="49">黑河</td>
                                        <td :width="49">嫩江市</td>
                                        <td :width="198">省道黑河至双城公路跃进加油站至弯道村段</td>
                                        <td :width="47">S211</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">5.4</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">5.4</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">5.4</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">5.4</td>
                                        <td :width="65"></td>
                                        <td :width="69">3240</td>
                                        <td :width="66"></td>
                                        <td :width="69">1512</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">3240</td>
                                        <td :width="67"></td>
                                        <td :width="67">1512</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">18</td>
                                        <td :width="49">黑河</td>
                                        <td :width="49">嫩江市</td>
                                        <td :width="198">省道黑河至双城公路马铃薯原种繁殖场出点至重复路段始点段</td>
                                        <td :width="47">S211</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">3</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">3.0</td>
                                        <td :width="58"></td>
                                        <td :width="64">3.0</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">3.0</td>
                                        <td :width="65"></td>
                                        <td :width="69">1800</td>
                                        <td :width="66"></td>
                                        <td :width="69">840</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">1800</td>
                                        <td :width="67"></td>
                                        <td :width="67">840</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">19</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">桦川县</td>
                                        <td :width="198">省道名山至兴凯湖公路二九一渡口南码头至二九一农场一分场段</td>
                                        <td :width="47">S202</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">3.5</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">3.5</td>
                                        <td :width="58"></td>
                                        <td :width="64">3.5</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">3.5</td>
                                        <td :width="65"></td>
                                        <td :width="69">2100</td>
                                        <td :width="66"></td>
                                        <td :width="69">980</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">2100</td>
                                        <td :width="67"></td>
                                        <td :width="67">980</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">20</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">同江市</td>
                                        <td :width="198">省道同江至汪清公路曙光大堤至团发段</td>
                                        <td :width="47">S203</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">13.9</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">10.3</td>
                                        <td :width="58">3.6</td>
                                        <td :width="64">13.9</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">13.9</td>
                                        <td :width="65"></td>
                                        <td :width="69">8340</td>
                                        <td :width="66"></td>
                                        <td :width="69">3892</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">8340</td>
                                        <td :width="67"></td>
                                        <td :width="67">3892</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">21</td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">穆棱市</td>
                                        <td :width="198">省道方正至珲春公路牛心山经营所至新合桥段</td>
                                        <td :width="47">S206</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">19.4</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">19.4</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">19.4</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">19.4</td>
                                        <td :width="65"></td>
                                        <td :width="69">11640</td>
                                        <td :width="66"></td>
                                        <td :width="69">5432</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">11640</td>
                                        <td :width="67"></td>
                                        <td :width="67">5432</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">22</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">尚志市</td>
                                        <td :width="198">省道伊春至牡丹江公路林场食用基地至食用基地段</td>
                                        <td :width="47">S207</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">1.3</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">1.3</td>
                                        <td :width="58"></td>
                                        <td :width="64">1.3</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">1.3</td>
                                        <td :width="65"></td>
                                        <td :width="69">780</td>
                                        <td :width="66"></td>
                                        <td :width="69">364</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">780</td>
                                        <td :width="67"></td>
                                        <td :width="67">364</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">23</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">五常市</td>
                                        <td :width="198">省道五常至蛟河公路凤白1号桥至白石砬出口段</td>
                                        <td :width="47">S208</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">15.1</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">15.1</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">15.1</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">15.1</td>
                                        <td :width="65"></td>
                                        <td :width="69">9060</td>
                                        <td :width="66"></td>
                                        <td :width="69">4228</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">9060</td>
                                        <td :width="67"></td>
                                        <td :width="67">4228</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">24</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">阿城区</td>
                                        <td :width="198">省道哈尔滨至亚布力公路阿城至松峰山镇段</td>
                                        <td :width="47">S102</td>
                                        <td :width="137">路面改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">53.4</td>
                                        <td :width="53"></td>
                                        <td :width="58">53.4</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">54.1</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">54.1</td>
                                        <td :width="65"></td>
                                        <td></td>
                                        <td :width="69">21640</td>
                                        <td :width="66"></td>
                                        <td :width="69">8115</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">21640</td>
                                        <td :width="67"></td>
                                        <td :width="67">8115</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">阿城正式行文</td>
                                        <td :width="144">区域内连通景区</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">25</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">阿城区</td>
                                        <td :width="198">省道哈尔滨至亚布力公路阿城至新华段</td>
                                        <td :width="47">S102</td>
                                        <td :width="137">路面改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">13.7</td>
                                        <td :width="53"></td>
                                        <td :width="58">13.7</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">13.7</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">13.7</td>
                                        <td :width="65"></td>
                                        <td></td>
                                        <td :width="69">5480</td>
                                        <td :width="66"></td>
                                        <td :width="69">2055</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">5480</td>
                                        <td :width="67"></td>
                                        <td :width="67">2055</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">阿城正式行文</td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">26</td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">穆棱市</td>
                                        <td :width="198">省道方正至珲春（长岭）公路下城子镇至花海道口段</td>
                                        <td :width="47">S206</td>
                                        <td :width="137">路面改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">1.8</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">1.8</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">1.8</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">1.8</td>
                                        <td :width="65"></td>
                                        <td :width="69">539</td>
                                        <td :width="66"></td>
                                        <td :width="69">153</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">539</td>
                                        <td :width="67"></td>
                                        <td :width="67">153</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">穆棱正式行文。</td>
                                        <td :width="144">区域内连通景区</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">27</td>
                                        <td :width="49">双鸭山</td>
                                        <td :width="49">集贤县</td>
                                        <td :width="198">省道同江至汪清公路富锦至集贤段</td>
                                        <td :width="47">S203</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">31.0</td>
                                        <td :width="53"></td>
                                        <td :width="58">31.0</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">31.0</td>
                                        <td :width="56"></td>
                                        <td :width="56">31.0</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">27900</td>
                                        <td :width="66"></td>
                                        <td :width="69">15500</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">27900</td>
                                        <td :width="67"></td>
                                        <td :width="67">15500</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">集贤正式行文</td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">28</td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">穆棱市</td>
                                        <td :width="198">省道方正至珲春公路穆棱镇至共和乡</td>
                                        <td :width="47">S206</td>
                                        <td :width="137">路面改造、升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">71.9</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">19.398</td>
                                        <td :width="58">52.52</td>
                                        <td :width="58"></td>
                                        <td :width="64">71.9</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">71.9</td>
                                        <td :width="65"></td>
                                        <td :width="69">37331</td>
                                        <td :width="66"></td>
                                        <td :width="69">16354</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">37331</td>
                                        <td :width="67"></td>
                                        <td :width="67">16354</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">29</td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">宁安市</td>
                                        <td :width="198">省道海林至山河镇公路海宁界至福荣加油站</td>
                                        <td :width="47">S316</td>
                                        <td :width="137">路面改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">11.8</td>
                                        <td :width="53"></td>
                                        <td :width="58">11.8</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">11.8</td>
                                        <td :width="56"></td>
                                        <td :width="56">11.8</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">4720</td>
                                        <td :width="66"></td>
                                        <td :width="69">1770</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">4720</td>
                                        <td :width="67"></td>
                                        <td :width="67">1770</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">区域内连通景区</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">30</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">市本级</td>
                                        <td :width="198">省道扎龙至景星公路宛屯至扎龙乡段</td>
                                        <td :width="47">S310</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">21.6</td>
                                        <td :width="53"></td>
                                        <td :width="58">21.6</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">21.6</td>
                                        <td :width="56"></td>
                                        <td :width="56">21.6</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">19440</td>
                                        <td :width="66"></td>
                                        <td :width="69">3240</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">19440</td>
                                        <td :width="67"></td>
                                        <td :width="67">3240</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">区域内连通景区</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">31</td>
                                        <td :width="49">鸡西</td>
                                        <td :width="49">虎林市</td>
                                        <td :width="198">省道珍宝岛至当壁镇公路兴凯湖农场段</td>
                                        <td :width="47">S201</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">9.6</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">9.6</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">9.6</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>9.6</td>
                                        <td></td>
                                        <td :width="69">5760</td>
                                        <td :width="66"></td>
                                        <td :width="69">2688</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">5760</td>
                                        <td :width="67"></td>
                                        <td :width="67">2688</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">区域内连通农林场、乡镇</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">32</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">道里区</td>
                                        <td :width="198">省道哈尔滨至松原公路道里段</td>
                                        <td :width="47">S103</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">16.0</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">16</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">16.0</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">16</td>
                                        <td></td>
                                        <td :width="69">9600</td>
                                        <td :width="66"></td>
                                        <td :width="69">4480</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">9600</td>
                                        <td :width="67"></td>
                                        <td :width="67">4480</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">33</td>
                                        <td :width="49">鹤岗</td>
                                        <td :width="49">萝北县</td>
                                        <td :width="198">省道环山乡至团结镇公路</td>
                                        <td :width="47">S511</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">49.5</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">49.5</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">49.5</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">49.5</td>
                                        <td :width="65"></td>
                                        <td :width="69">29700</td>
                                        <td :width="66"></td>
                                        <td :width="69">13860</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">29700</td>
                                        <td :width="67"></td>
                                        <td :width="67">13860</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">萝北正式行文</td>
                                        <td :width="144">区域内连通农林场、乡镇</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">34</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">富裕县</td>
                                        <td :width="198">省道建兴至新林公路富裕段</td>
                                        <td :width="47">S309</td>
                                        <td :width="137">新建</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">9.9</td>
                                        <td :width="56"></td>
                                        <td :width="56">9.9</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">11880</td>
                                        <td :width="66"></td>
                                        <td :width="69">4950</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">11880</td>
                                        <td :width="67"></td>
                                        <td :width="67">4950</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">城市过境段</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">35</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">尚志市</td>
                                        <td :width="198">省道哈尔滨至亚布力公路尚志过境段</td>
                                        <td :width="47">S102</td>
                                        <td :width="137">新建</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">5.0</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">5.0</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">4500</td>
                                        <td :width="66"></td>
                                        <td :width="69">1750</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">4500</td>
                                        <td :width="67"></td>
                                        <td :width="67">1750</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">尚志正式行文。</td>
                                        <td :width="144">城市过境段</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">36</td>
                                        <td :width="49">大庆</td>
                                        <td :width="49">杜蒙县</td>
                                        <td :width="198">省道前进至胡吉吐莫公路杜尔伯特过境段</td>
                                        <td :width="47">S214</td>
                                        <td :width="137">新建</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">23.5</td>
                                        <td :width="56">23.5</td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">78800</td>
                                        <td :width="66"></td>
                                        <td :width="69">23500</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">78800</td>
                                        <td :width="67"></td>
                                        <td :width="67">23500</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">杜蒙正式行文</td>
                                        <td :width="144">城市过境段</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">37</td>
                                        <td :width="49">大庆</td>
                                        <td :width="49">杜蒙县</td>
                                        <td :width="198">省道大庆至杜尔伯特公路杜尔伯特过境段</td>
                                        <td :width="47">S505</td>
                                        <td :width="137">新建</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">10.5</td>
                                        <td :width="56">10.5</td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">44300</td>
                                        <td :width="66"></td>
                                        <td :width="69">10500</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">44300</td>
                                        <td :width="67"></td>
                                        <td :width="67">10500</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">杜蒙正式行文</td>
                                        <td :width="144">城市过境段</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">38</td>
                                        <td :width="49">双鸭山</td>
                                        <td :width="49">宝清县</td>
                                        <td :width="198">省道友谊至宝清公路永宁村至方盛村段</td>
                                        <td :width="47">S501</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">11.1</td>
                                        <td :width="56">11.1</td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">44240</td>
                                        <td :width="66"></td>
                                        <td :width="69">11060</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">44240</td>
                                        <td :width="67"></td>
                                        <td :width="67">11060</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">宝清正式行文</td>
                                        <td :width="144">城市过境段</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">39</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">抚远市</td>
                                        <td :width="198">省道四合至勤得利农场公路海青至寒葱沟段</td>
                                        <td :width="47">S306</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">43.7</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">43.7</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">43.7</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">43.7</td>
                                        <td :width="65"></td>
                                        <td :width="69">26220</td>
                                        <td :width="66"></td>
                                        <td :width="69">12236</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">26220</td>
                                        <td :width="67"></td>
                                        <td :width="67">12236</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">书记、市长找处长</td>
                                        <td :width="144">区域内连通农林场、乡镇</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">40</td>
                                        <td :width="49">伊春</td>
                                        <td :width="49">汤旺县</td>
                                        <td :width="198">省道四季屯至鹤岗公路乌伊岭至乌伊岭逊克界段</td>
                                        <td :width="47">S204</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">35.1</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">35.1</td>
                                        <td :width="58"></td>
                                        <td :width="64">35.1</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">35.1</td>
                                        <td :width="65"></td>
                                        <td :width="69">21051</td>
                                        <td :width="66"></td>
                                        <td :width="69">9824</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">21051</td>
                                        <td :width="67"></td>
                                        <td :width="67">9824</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">汤旺正式行文</td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">41</td>
                                        <td :width="49">黑河</td>
                                        <td :width="49">北安市</td>
                                        <td :width="198">省道哈尔滨至北安公路北海界至北安段</td>
                                        <td :width="47">S101</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">61.5</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">61.5</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">61.5</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">61.5</td>
                                        <td :width="65"></td>
                                        <td :width="69">36889</td>
                                        <td :width="66"></td>
                                        <td :width="69">17215</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">36889</td>
                                        <td :width="67"></td>
                                        <td :width="67">17215</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">黑河交通局行文。</td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">42</td>
                                        <td :width="49">绥化</td>
                                        <td :width="49">安达市</td>
                                        <td :width="198">省道兰西至民意公路安达至安达兰西界段</td>
                                        <td :width="47">S213</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">30.2</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">30.2</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">30.2</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">30.2</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">27180</td>
                                        <td :width="66"></td>
                                        <td :width="69">10570</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">27180</td>
                                        <td :width="67"></td>
                                        <td :width="67">10570</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">网络舆情关注</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">43</td>
                                        <td :width="49">黑河</td>
                                        <td :width="49">逊克县</td>
                                        <td :width="198">省道逊克至孙吴公路小队至孙吴段</td>
                                        <td :width="47">S516</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">21.6</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">21.6</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">21.6</td>
                                        <td :width="56">21.6</td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">86400</td>
                                        <td :width="66"></td>
                                        <td :width="69">21600</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">86400</td>
                                        <td :width="67"></td>
                                        <td :width="67">21600</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">黑河交通局行文。</td>
                                        <td :width="144">跨区域通道，连接矿区</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">44</td>
                                        <td :width="49">七台河</td>
                                        <td :width="49">勃利县</td>
                                        <td :width="198">省道同江至汪清公路勃利至桦南段</td>
                                        <td :width="47">S203</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">23.3</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">23.3</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">23.3</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">23.3</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">20952</td>
                                        <td :width="66"></td>
                                        <td :width="69">8148</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">20952</td>
                                        <td :width="67"></td>
                                        <td :width="67">8148</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">瓶颈路段，连接县级行政节点</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">45</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">松北区</td>
                                        <td :width="198">省道哈尔滨至大安公路世贸大道至哈肇界段</td>
                                        <td :width="47">S104</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">6.6</td>
                                        <td :width="56">6.6</td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">60000</td>
                                        <td :width="66"></td>
                                        <td :width="69">6600</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">60000</td>
                                        <td :width="67"></td>
                                        <td :width="67">6600</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">重要节点连接国省干线</td>
                                        <td :width="166">工可研编制中</td>
                                    </tr>
                                    <tr>
                                        <td :width="35">46</td>
                                        <td :width="49">伊春</td>
                                        <td :width="49">乌翠区</td>
                                        <td :width="198">哈伊高铁伊春站与哈伊高速连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137">新建</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">5.0</td>
                                        <td :width="56"></td>
                                        <td :width="56">5.0</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">6000</td>
                                        <td :width="66"></td>
                                        <td :width="69">2500</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">6000</td>
                                        <td :width="67"></td>
                                        <td :width="67">2500</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">重要节点连接国省干线</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">47</td>
                                        <td :width="49">大兴安岭</td>
                                        <td :width="49">塔河县</td>
                                        <td :width="198">塔河县通用机场连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">1.1</td>
                                        <td :width="56"></td>
                                        <td :width="56">1.1</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">1320</td>
                                        <td :width="66"></td>
                                        <td :width="69">550</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">1320</td>
                                        <td :width="67"></td>
                                        <td :width="67">550</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">重要节点连接国省干线</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">48</td>
                                        <td :width="49">伊春</td>
                                        <td :width="49">伊美区</td>
                                        <td :width="198">伊春林都机场与鹤哈高速连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">4.0</td>
                                        <td :width="56"></td>
                                        <td :width="56">4.0</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">4800</td>
                                        <td :width="66"></td>
                                        <td :width="69">2000</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">4800</td>
                                        <td :width="67"></td>
                                        <td :width="67">2000</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">重要节点连接国省干线</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">49</td>
                                        <td :width="49">伊春</td>
                                        <td :width="49">嘉荫县</td>
                                        <td :width="198">嘉荫通用机场连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">5</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">5.0</td>
                                        <td :width="58"></td>
                                        <td :width="64">5.0</td>
                                        <td :width="56"></td>
                                        <td :width="56">5.0</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">6000</td>
                                        <td :width="66"></td>
                                        <td :width="69">2500</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">6000</td>
                                        <td :width="67"></td>
                                        <td :width="67">2500</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">重要节点连接国省干线</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">50</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">汤原县</td>
                                        <td :width="198">黑龙江汤原经济开发区连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">3.3</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">3.3</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">2970</td>
                                        <td :width="66"></td>
                                        <td :width="69">1155</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">2970</td>
                                        <td :width="67"></td>
                                        <td :width="67">1155</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">重要节点连接国省干线</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">51</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">依兰县</td>
                                        <td :width="198">省道南岔至孟家岗公路东方红林场至锦山村段</td>
                                        <td :width="47">S205</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">43.4</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">43.4</td>
                                        <td :width="64">43.4</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">43.4</td>
                                        <td :width="65"></td>
                                        <td :width="69">26040</td>
                                        <td :width="66"></td>
                                        <td :width="69">12152</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">26040</td>
                                        <td :width="67"></td>
                                        <td :width="67">12152</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">52</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">同江市</td>
                                        <td :width="198">省道东安镇至同江公路同江养护工区至头屯道口段</td>
                                        <td :width="47">S307</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">0.8</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">0.8</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">0.8</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">0.8</td>
                                        <td :width="65"></td>
                                        <td :width="69">480</td>
                                        <td :width="66"></td>
                                        <td :width="69">224</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">480</td>
                                        <td :width="67"></td>
                                        <td :width="67">224</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">53</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">甘南县</td>
                                        <td :width="198">省道建兴至新林公路富甘界至重复路段始点段</td>
                                        <td :width="47">S309</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">9.3</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">9.3</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">9.3</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">9.3</td>
                                        <td :width="65"></td>
                                        <td :width="69">5580</td>
                                        <td :width="66"></td>
                                        <td :width="69">2604</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">5580</td>
                                        <td :width="67"></td>
                                        <td :width="67">2604</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">54</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">碾子山区</td>
                                        <td :width="198">省道建兴至新林公路养殖场至庆山加油站段</td>
                                        <td :width="47">S309</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">0.9</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">0.9</td>
                                        <td :width="58"></td>
                                        <td :width="64">0.9</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">0.9</td>
                                        <td :width="65"></td>
                                        <td :width="69">540</td>
                                        <td :width="66"></td>
                                        <td :width="69">252</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">540</td>
                                        <td :width="67"></td>
                                        <td :width="67">252</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">55</td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">林口县</td>
                                        <td :width="198">省道绥芬河至柳树公路穆林界至万寿段</td>
                                        <td :width="47">S315</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">11.9</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">11.9</td>
                                        <td :width="58"></td>
                                        <td :width="64">11.9</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">11.9</td>
                                        <td :width="65"></td>
                                        <td :width="69">7140</td>
                                        <td :width="66"></td>
                                        <td :width="69">3332</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">7140</td>
                                        <td :width="67"></td>
                                        <td :width="67">3332</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">56</td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">宁安市</td>
                                        <td :width="198">省道海林至山河镇公路北湖1号桥至海宁界段</td>
                                        <td :width="47">S316</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">7</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">7.0</td>
                                        <td :width="58"></td>
                                        <td :width="64">7.0</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">7.0</td>
                                        <td :width="65"></td>
                                        <td :width="69">4200</td>
                                        <td :width="66"></td>
                                        <td :width="69">1960</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">4200</td>
                                        <td :width="67"></td>
                                        <td :width="67">1960</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">57</td>
                                        <td :width="49">大兴安岭</td>
                                        <td :width="49">呼玛县</td>
                                        <td :width="198">省道丹阿公路欧浦乡支线白银纳鄂伦春族乡至鸥浦乡段</td>
                                        <td :width="47">S517</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">57.4</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">57.4</td>
                                        <td :width="58"></td>
                                        <td :width="64">57.4</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">57.4</td>
                                        <td :width="65"></td>
                                        <td :width="69">34440</td>
                                        <td :width="66"></td>
                                        <td :width="69">16072</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">34440</td>
                                        <td :width="67"></td>
                                        <td :width="67">16072</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">58</td>
                                        <td :width="49">鸡西</td>
                                        <td :width="49">虎林市</td>
                                        <td :width="198">省道八五零农场至云山农场公路云山农场至云山农场7队段</td>
                                        <td :width="47">S519</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">8.9</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">8.9</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">8.9</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">8.9</td>
                                        <td :width="65"></td>
                                        <td :width="69">5340</td>
                                        <td :width="66"></td>
                                        <td :width="69">2492</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">5340</td>
                                        <td :width="67"></td>
                                        <td :width="67">2492</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">59</td>
                                        <td :width="49">鸡西</td>
                                        <td :width="49">虎林市</td>
                                        <td :width="198">省道八五零农场至云山农场公路八五零14队桥至辉崔段</td>
                                        <td :width="47">S519</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">3.5</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">3.5</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">3.5</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">3.5</td>
                                        <td :width="65"></td>
                                        <td :width="69">2100</td>
                                        <td :width="66"></td>
                                        <td :width="69">980</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">2100</td>
                                        <td :width="67"></td>
                                        <td :width="67">980</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr style="font-weight: bold">
                                        <td colspan="4" :width="331">（五）2024-2025年建设项目</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58">1921.6</td>
                                        <td :width="53"></td>
                                        <td :width="58">63.0</td>
                                        <td :width="58">1263.2</td>
                                        <td :width="58">545.5</td>
                                        <td :width="58">49.9</td>
                                        <td :width="64">1989.0</td>
                                        <td :width="56">20.8</td>
                                        <td :width="56">137.9</td>
                                        <td :width="61">75.4</td>
                                        <td :width="65">1754.9</td>
                                        <td :width="65"></td>
                                        <td :width="69">1298750</td>
                                        <td :width="66"></td>
                                        <td :width="69">569331</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">561396</td>
                                        <td :width="67"></td>
                                        <td :width="67">244330</td>
                                        <td :width="71">737354</td>
                                        <td :width="71">325001</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">1</td>
                                        <td :width="49">大兴安岭</td>
                                        <td :width="49">塔河、呼中</td>
                                        <td :width="198">省道十八站至室韦公路塔河至呼中镇段</td>
                                        <td :width="47">S301</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">118.4</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">118.4</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">118.4</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">118.4</td>
                                        <td :width="65"></td>
                                        <td :width="69">71040</td>
                                        <td :width="66"></td>
                                        <td :width="69">33152</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">71040</td>
                                        <td :width="67"></td>
                                        <td :width="67">33152</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">2</td>
                                        <td :width="49">绥化</td>
                                        <td :width="49">肇东市</td>
                                        <td :width="198">省道黑河至双城公路肇东昌五至德昌段</td>
                                        <td :width="47">S211</td>
                                        <td :width="137">路面改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">16.0</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">16.0</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">16.0</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">16.0</td>
                                        <td :width="65"></td>
                                        <td :width="69">4800</td>
                                        <td :width="66"></td>
                                        <td :width="69">1360</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">4800</td>
                                        <td :width="67"></td>
                                        <td :width="67">1360</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">区域内连通农林场、乡镇</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">3</td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">穆棱市</td>
                                        <td :width="198">省道绥芬河至柳树公路福禄乡至八面通林业局</td>
                                        <td :width="47">S315</td>
                                        <td :width="137">路面改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">4.1</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">4.1</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">4.1</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">4.1</td>
                                        <td :width="65"></td>
                                        <td :width="69">1230</td>
                                        <td :width="66"></td>
                                        <td :width="69">349</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">1230</td>
                                        <td :width="67"></td>
                                        <td :width="67">349</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">区域内连通农林场、乡镇</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">4</td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">穆棱市</td>
                                        <td :width="198">省道绥芬河至柳树公路河西镇至八林交界</td>
                                        <td :width="47">S315</td>
                                        <td :width="137">路面改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">36.6</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">36.6</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">36.6</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">36.6</td>
                                        <td :width="65"></td>
                                        <td :width="69">10980</td>
                                        <td :width="66"></td>
                                        <td :width="69">3111</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">10980</td>
                                        <td :width="67"></td>
                                        <td :width="67">3111</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">5</td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">穆棱市</td>
                                        <td :width="198">省道方正至珲春（长岭）公路孤榆树道口段至兴源镇段</td>
                                        <td :width="47">S206</td>
                                        <td :width="137">路面改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">10.1</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">10.07</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">10.1</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">10.1</td>
                                        <td :width="65"></td>
                                        <td :width="69">3021</td>
                                        <td :width="66"></td>
                                        <td :width="69">856</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">3021</td>
                                        <td :width="67"></td>
                                        <td :width="67">856</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">区域内连通农林场、乡镇</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">6</td>
                                        <td :width="49">双鸭山</td>
                                        <td :width="49">饶河县</td>
                                        <td :width="198">省道小佳河至亮子河公路小佳河镇至河北大桥段</td>
                                        <td :width="47">S308</td>
                                        <td :width="137">路面改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">45.8</td>
                                        <td :width="53"></td>
                                        <td :width="58">45.8</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">45.8</td>
                                        <td :width="56"></td>
                                        <td :width="56">45.8</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">18320</td>
                                        <td :width="66"></td>
                                        <td :width="69">6870</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">18320</td>
                                        <td :width="67"></td>
                                        <td :width="67">6870</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">饶河正式行文</td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">7</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">克山县</td>
                                        <td :width="198">省道绥棱至拉哈公路克山段</td>
                                        <td :width="47">S304</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">75.2</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">75.2</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">75.2</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">75.2</td>
                                        <td :width="65"></td>
                                        <td :width="69">45120</td>
                                        <td :width="66"></td>
                                        <td :width="69">21056</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">18048</td>
                                        <td :width="67"></td>
                                        <td :width="67">8422</td>
                                        <td :width="71">27072</td>
                                        <td :width="71">12634</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">克山正式行文。</td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">8</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">宾县</td>
                                        <td :width="198">省道绥化至尚志公路明西园路口至南阳村庆荣屯段</td>
                                        <td :width="47">S209</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">8.7</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">8.7</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">8.7</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">8.7</td>
                                        <td :width="65"></td>
                                        <td></td>
                                        <td :width="69">7830</td>
                                        <td :width="66"></td>
                                        <td :width="69">3045</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">3132</td>
                                        <td :width="67"></td>
                                        <td :width="67">1218</td>
                                        <td :width="71">4698</td>
                                        <td :width="71">1827</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">哈尔滨交通局正式行文。</td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">9</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">尚志市</td>
                                        <td :width="198">省道伊春至牡丹江公路致富乡至尚延界段</td>
                                        <td :width="47">S207</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">34.5</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">34.5</td>
                                        <td :width="58"></td>
                                        <td :width="64">34.5</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">34.5</td>
                                        <td :width="65"></td>
                                        <td :width="69">20700</td>
                                        <td :width="66"></td>
                                        <td :width="69">9660</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">8280</td>
                                        <td :width="67"></td>
                                        <td :width="67">3864</td>
                                        <td :width="71">12420</td>
                                        <td :width="71">5796</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">尚志正式行文。</td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">10</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">尚志市</td>
                                        <td :width="198">省道绥化至尚志公路宾县尚志界至尚志段</td>
                                        <td :width="47">S209</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">43.7</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">43.7</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">43.7</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">43.7</td>
                                        <td :width="65"></td>
                                        <td :width="69">26220</td>
                                        <td :width="66"></td>
                                        <td :width="69">12236</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">10488</td>
                                        <td :width="67"></td>
                                        <td :width="67">4894</td>
                                        <td :width="71">15732</td>
                                        <td :width="71">7342</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">尚志正式行文。</td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">11</td>
                                        <td :width="49">绥化</td>
                                        <td :width="49">绥棱县</td>
                                        <td :width="198">省道上集镇至秦家镇绥棱段</td>
                                        <td :width="47">S510</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">9.9</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">9.9</td>
                                        <td :width="58"></td>
                                        <td :width="64">9.9</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">9.9</td>
                                        <td :width="65"></td>
                                        <td :width="69">5940</td>
                                        <td :width="66"></td>
                                        <td :width="69">2772</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">2376</td>
                                        <td :width="67"></td>
                                        <td :width="67">1109</td>
                                        <td :width="71">3564</td>
                                        <td :width="71">1663</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">绥棱正式行文</td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">12</td>
                                        <td :width="49">伊春</td>
                                        <td :width="49">嘉荫县、丰林县</td>
                                        <td :width="198">省道保兴乡至新青区公路</td>
                                        <td :width="47">S512</td>
                                        <td :width="137">升级改造、原级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">96.9</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">36.5</td>
                                        <td :width="58">60.4</td>
                                        <td :width="58"></td>
                                        <td :width="64">96.9</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">96.9</td>
                                        <td :width="65"></td>
                                        <td :width="69">58133</td>
                                        <td :width="66"></td>
                                        <td :width="69">27132</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">23253</td>
                                        <td :width="67"></td>
                                        <td :width="67">10853</td>
                                        <td :width="71">34880</td>
                                        <td :width="71">16279</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">伊春交通局行文。</td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">13</td>
                                        <td :width="49">黑河</td>
                                        <td :width="49">逊克县</td>
                                        <td :width="198">省道四季屯至鹤岗公路逊克农场19队至大平台段</td>
                                        <td :width="47">S204</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2026</td>
                                        <td :width="58">46.0</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">46.0</td>
                                        <td :width="58"></td>
                                        <td :width="64">46.0</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">46.0</td>
                                        <td :width="65"></td>
                                        <td :width="69">27600</td>
                                        <td :width="66"></td>
                                        <td :width="69">12880</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">11040</td>
                                        <td :width="67"></td>
                                        <td :width="67">5152</td>
                                        <td :width="71">16560</td>
                                        <td :width="71">7728</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">黑河交通局行文。</td>
                                        <td :width="144">区域内连通农林场、乡镇</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">14</td>
                                        <td :width="49">黑河</td>
                                        <td :width="49">北安市</td>
                                        <td :width="198">省道前进至胡吉吐莫公路通北至北拜界段</td>
                                        <td :width="47">S214</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">25.1</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">5.0</td>
                                        <td :width="58">20.1</td>
                                        <td :width="58"></td>
                                        <td :width="64">25.1</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">25.1</td>
                                        <td :width="65"></td>
                                        <td :width="69">15060</td>
                                        <td :width="66"></td>
                                        <td :width="69">7028</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">6024</td>
                                        <td :width="67"></td>
                                        <td :width="67">2811</td>
                                        <td :width="71">9036</td>
                                        <td :width="71">4217</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">黑河交通局行文。</td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">15</td>
                                        <td :width="49">黑河</td>
                                        <td :width="49">孙吴县</td>
                                        <td :width="198">省道四季屯至鹤岗公路小桦林子村至小队段</td>
                                        <td :width="47">S204</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2026</td>
                                        <td :width="58">20.8</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">20.8</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">20.8</td>
                                        <td :width="56">20.8</td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">83200</td>
                                        <td :width="66"></td>
                                        <td :width="69">20800</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">33280</td>
                                        <td :width="67"></td>
                                        <td :width="67">8320</td>
                                        <td :width="71">49920</td>
                                        <td :width="71">12480</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">黑河交通局行文。</td>
                                        <td :width="144">跨区域通道，连接矿区</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">16</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">富锦市</td>
                                        <td :width="198">省道同江至汪清公路富锦至二九一农场段</td>
                                        <td :width="47">S203</td>
                                        <td :width="137">升级改造、原级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">52.7</td>
                                        <td :width="53"></td>
                                        <td :width="58">17.2</td>
                                        <td :width="58">35.4</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">52.7</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">35.7</td>
                                        <td :width="65">17.0</td>
                                        <td :width="65"></td>
                                        <td :width="69">42330</td>
                                        <td :width="66"></td>
                                        <td :width="69">17255</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">16932</td>
                                        <td :width="67"></td>
                                        <td :width="67">6902</td>
                                        <td :width="71">25398</td>
                                        <td :width="71">10353</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">富锦正式行文。</td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">17</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">汤原县</td>
                                        <td :width="198">汤原县鹤立铁路货场连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">1.1</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">1.1</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">990</td>
                                        <td :width="66"></td>
                                        <td :width="69">385</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">396</td>
                                        <td :width="67"></td>
                                        <td :width="67">154</td>
                                        <td :width="71">594</td>
                                        <td :width="71">231</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">重要节点连接国省干线</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">18</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">汤原县</td>
                                        <td :width="198">汤原县香兰铁路货场连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">1.6</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">1.6</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">1440</td>
                                        <td :width="66"></td>
                                        <td :width="69">560</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">576</td>
                                        <td :width="67"></td>
                                        <td :width="67">224</td>
                                        <td :width="71">864</td>
                                        <td :width="71">336</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">重要节点连接国省干线</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">19</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">尚志市</td>
                                        <td :width="198">省道哈尔滨至亚布力公路一面坡镇至亚布力镇段</td>
                                        <td :width="47">S102</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">50.0</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">50.0</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">50.0</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">50.0</td>
                                        <td :width="65"></td>
                                        <td :width="69">30000</td>
                                        <td :width="66"></td>
                                        <td :width="69">14000</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">12000</td>
                                        <td :width="67"></td>
                                        <td :width="67">5600</td>
                                        <td :width="71">18000</td>
                                        <td :width="71">8400</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">尚志正式行文。</td>
                                        <td :width="144">区域内连通景区</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">20</td>
                                        <td :width="49">鸡西</td>
                                        <td :width="49">密山市</td>
                                        <td :width="198">省道珍宝岛至当壁镇公路兴凯湖农场至承紫河乡段</td>
                                        <td :width="47">S201</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">70.6</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">70.6</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">70.6</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">70.6</td>
                                        <td :width="65"></td>
                                        <td :width="69">42360</td>
                                        <td :width="66"></td>
                                        <td :width="69">19768</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">16944</td>
                                        <td :width="67"></td>
                                        <td :width="67">7907</td>
                                        <td :width="71">25416</td>
                                        <td :width="71">11861</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">鸡西交通局正式行文</td>
                                        <td :width="144">区域内连通景区</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">21</td>
                                        <td :width="49">鸡西</td>
                                        <td :width="49">虎林市</td>
                                        <td :width="198">省道珍宝岛至当壁镇公路虎林至吉祥口岸段</td>
                                        <td :width="47">S201</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">45.0</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">45.0</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">45.0</td>
                                        <td :width="56"></td>
                                        <td :width="56">45.0</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">54000</td>
                                        <td :width="66"></td>
                                        <td :width="69">22500</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">21600</td>
                                        <td :width="67"></td>
                                        <td :width="67">9000</td>
                                        <td :width="71">32400</td>
                                        <td :width="71">13500</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">连通口岸，网络舆情关注</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">22</td>
                                        <td :width="49">黑河</td>
                                        <td :width="49">逊克县</td>
                                        <td :width="198">省道四季屯至鹤岗公路逊克农场至逊克农场19队段</td>
                                        <td :width="47">S204</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2025</td>
                                        <td :width="47">2027</td>
                                        <td :width="58">59.8</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">53.9</td>
                                        <td :width="58">5.9</td>
                                        <td :width="58"></td>
                                        <td :width="64">59.8</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">59.8</td>
                                        <td :width="65"></td>
                                        <td :width="69">35880</td>
                                        <td :width="66"></td>
                                        <td :width="69">16744</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">14352</td>
                                        <td :width="67"></td>
                                        <td :width="67">6698</td>
                                        <td :width="71">21528</td>
                                        <td :width="71">10046</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">黑河交通局行文。</td>
                                        <td :width="144">区域内连通农林场、乡镇</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">23</td>
                                        <td :width="49">黑河</td>
                                        <td :width="49">逊克县</td>
                                        <td :width="198">省道四季屯至鹤岗公路逊克大平台至逊乌界段</td>
                                        <td :width="47">S204</td>
                                        <td :width="137">升级改造、原级改造</td>
                                        <td :width="47">2025</td>
                                        <td :width="47">2027</td>
                                        <td :width="58">48.7</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">19.1</td>
                                        <td :width="58">29.6</td>
                                        <td :width="58"></td>
                                        <td :width="64">48.7</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">48.7</td>
                                        <td :width="65"></td>
                                        <td :width="69">29220</td>
                                        <td :width="66"></td>
                                        <td :width="69">13636</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">11688</td>
                                        <td :width="67"></td>
                                        <td :width="67">5454</td>
                                        <td :width="71">17532</td>
                                        <td :width="71">8182</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">黑河交通局行文。</td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">24</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">讷河市</td>
                                        <td :width="198">讷河市通用机场连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137">新建</td>
                                        <td :width="47">2025</td>
                                        <td :width="47">2025</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">24.0</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">24.0</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">21600</td>
                                        <td :width="66"></td>
                                        <td :width="69">8400</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">8640</td>
                                        <td :width="67"></td>
                                        <td :width="67">3360</td>
                                        <td :width="71">12960</td>
                                        <td :width="71">5040</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">重要节点连接国省干线</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">25</td>
                                        <td :width="49">鹤岗</td>
                                        <td :width="49">绥滨县</td>
                                        <td :width="198">绥滨县通用机场连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137">新建</td>
                                        <td :width="47">2025</td>
                                        <td :width="47">2025</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">2.0</td>
                                        <td :width="56"></td>
                                        <td :width="56">2</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">2400</td>
                                        <td :width="66"></td>
                                        <td :width="69">1000</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">960</td>
                                        <td :width="67"></td>
                                        <td :width="67">400</td>
                                        <td :width="71">1440</td>
                                        <td :width="71">600</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">重要节点连接国省干线</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">26</td>
                                        <td :width="49">双鸭山</td>
                                        <td :width="49">宝清县</td>
                                        <td :width="198">宝清支线机场连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2025</td>
                                        <td :width="47">2025</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">2.4</td>
                                        <td :width="56"></td>
                                        <td :width="56">2.4</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">2873</td>
                                        <td :width="66"></td>
                                        <td :width="69">1197</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">1149</td>
                                        <td :width="67"></td>
                                        <td :width="67">479</td>
                                        <td :width="71">1724</td>
                                        <td :width="71">718</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">重要节点连接国省干线</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">27</td>
                                        <td :width="49">双鸭山</td>
                                        <td :width="49">宝清县</td>
                                        <td :width="198">宝清县经济开发区连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2025</td>
                                        <td :width="47">2025</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">10.9</td>
                                        <td :width="56"></td>
                                        <td :width="56">10.9</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">13080</td>
                                        <td :width="66"></td>
                                        <td :width="69">5450</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">5232</td>
                                        <td :width="67"></td>
                                        <td :width="67">2180</td>
                                        <td :width="71">7848</td>
                                        <td :width="71">3270</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">重要节点连接国省干线</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">28</td>
                                        <td :width="49">双鸭山</td>
                                        <td :width="49">集贤县</td>
                                        <td :width="198">双鸭山支线机场连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2025</td>
                                        <td :width="47">2026</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">2.0</td>
                                        <td :width="56"></td>
                                        <td :width="56">2.0</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">2400</td>
                                        <td :width="66"></td>
                                        <td :width="69">1000</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">960</td>
                                        <td :width="67"></td>
                                        <td :width="67">400</td>
                                        <td :width="71">1440</td>
                                        <td :width="71">600</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">重要节点连接国省干线</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">29</td>
                                        <td :width="49">黑河</td>
                                        <td :width="49">逊克县</td>
                                        <td :width="198">逊克通用机场连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">15.0</td>
                                        <td :width="56"></td>
                                        <td :width="56">15.0</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">18000</td>
                                        <td :width="66"></td>
                                        <td :width="69">7500</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">7200</td>
                                        <td :width="67"></td>
                                        <td :width="67">3000</td>
                                        <td :width="71">10800</td>
                                        <td :width="71">4500</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">重要节点连接国省干线</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">30</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">同江市</td>
                                        <td :width="198">同江公铁换装联运物流产业园区连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">4.3</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">4.3</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">3870</td>
                                        <td :width="66"></td>
                                        <td :width="69">1505</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">1548</td>
                                        <td :width="67"></td>
                                        <td :width="67">602</td>
                                        <td :width="71">2322</td>
                                        <td :width="71">903</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">重要节点连接国省干线</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">31</td>
                                        <td :width="49">绥化</td>
                                        <td :width="49">北林区</td>
                                        <td :width="198">哈伊铁客专绥化南站连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137">新建</td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">4.0</td>
                                        <td :width="56"></td>
                                        <td :width="56">4.0</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">4800</td>
                                        <td :width="66"></td>
                                        <td :width="69">2000</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">1920</td>
                                        <td :width="67"></td>
                                        <td :width="67">800</td>
                                        <td :width="71">2880</td>
                                        <td :width="71">1200</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">重要节点连接国省干线</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">32</td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">穆棱市</td>
                                        <td :width="198">省道方正至珲春（长岭）公路东光村至省界段</td>
                                        <td :width="47">S206</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2026</td>
                                        <td :width="47">2027</td>
                                        <td :width="58">10.8</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">10.8</td>
                                        <td :width="58"></td>
                                        <td :width="64">10.8</td>
                                        <td :width="56"></td>
                                        <td :width="56">10.8</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">12960</td>
                                        <td :width="66"></td>
                                        <td :width="69">5400</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">5184</td>
                                        <td :width="67"></td>
                                        <td :width="67">2160</td>
                                        <td :width="71">7776</td>
                                        <td :width="71">3240</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">出省通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">33</td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">海林市</td>
                                        <td :width="198">省道海林至山河镇公路海宁界至苗圃段</td>
                                        <td :width="47">S316</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2027</td>
                                        <td :width="47">2028</td>
                                        <td :width="58">1.9</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">1.9</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">1.9</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">1.9</td>
                                        <td :width="65"></td>
                                        <td :width="69">1140</td>
                                        <td :width="66"></td>
                                        <td :width="69">532</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">456</td>
                                        <td :width="67"></td>
                                        <td :width="67">213</td>
                                        <td :width="71">684</td>
                                        <td :width="71">319</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">34</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">同江</td>
                                        <td :width="198">省道同江至汪清公路华鸿家居路至曙平村段</td>
                                        <td :width="47">S203</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2025</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">8.0</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">8.0</td>
                                        <td :width="64">8.0</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">8.0</td>
                                        <td :width="65"></td>
                                        <td :width="69">4774</td>
                                        <td :width="66"></td>
                                        <td :width="69">2228</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">1910</td>
                                        <td :width="67"></td>
                                        <td :width="67">891</td>
                                        <td :width="71">2865</td>
                                        <td :width="71">1337</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">35</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">尚志</td>
                                        <td :width="198">省道伊春至牡丹江公路尚志宝青木材检查站至宝石村段</td>
                                        <td :width="47">S207</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2025</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">15.4</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">15.4</td>
                                        <td :width="64">15.4</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">15.4</td>
                                        <td :width="65"></td>
                                        <td :width="69">9260</td>
                                        <td :width="66"></td>
                                        <td :width="69">4321</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">3704</td>
                                        <td :width="67"></td>
                                        <td :width="67">1728</td>
                                        <td :width="71">5556</td>
                                        <td :width="71">2593</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">36</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">宾县</td>
                                        <td :width="198">省道绥化至尚志公路平房宾州界至全永段</td>
                                        <td :width="47">S209</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2025</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">3.2</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">3.2</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">3.2</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">3.2</td>
                                        <td :width="65"></td>
                                        <td :width="69">1936</td>
                                        <td :width="66"></td>
                                        <td :width="69">903</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">774</td>
                                        <td :width="67"></td>
                                        <td :width="67">361</td>
                                        <td :width="71">1161</td>
                                        <td :width="71">542</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">37</td>
                                        <td :width="49">绥化</td>
                                        <td :width="49">兰西</td>
                                        <td :width="198">省道黑河至双城公路张全北至兰西肇东界段</td>
                                        <td :width="47">S211</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2025</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">6.9</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">6.9</td>
                                        <td :width="64">6.9</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">6.9</td>
                                        <td :width="65"></td>
                                        <td :width="69">4115</td>
                                        <td :width="66"></td>
                                        <td :width="69">1921</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">1646</td>
                                        <td :width="67"></td>
                                        <td :width="67">768</td>
                                        <td :width="71">2469</td>
                                        <td :width="71">1152</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">38</td>
                                        <td :width="49">黑河</td>
                                        <td :width="49">嫩江</td>
                                        <td :width="198">省道黑河至加格达奇公路卧都河至嫩江加格达奇界段</td>
                                        <td :width="47">S302</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2025</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">1.9</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">1.9</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">1.9</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">1.9</td>
                                        <td :width="65"></td>
                                        <td :width="69">1127</td>
                                        <td :width="66"></td>
                                        <td :width="69">526</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">451</td>
                                        <td :width="67"></td>
                                        <td :width="67">210</td>
                                        <td :width="71">676</td>
                                        <td :width="71">316</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">39</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">抚远</td>
                                        <td :width="198">省道四合至勤得利农场公路鸭南鸭绿河界至鸭绿河农场五区段</td>
                                        <td :width="47">S306</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2025</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">8.3</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">8.3</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">8.3</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">8.3</td>
                                        <td :width="65"></td>
                                        <td :width="69">4956</td>
                                        <td :width="66"></td>
                                        <td :width="69">2313</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">1982</td>
                                        <td :width="67"></td>
                                        <td :width="67">925</td>
                                        <td :width="71">2974</td>
                                        <td :width="71">1388</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">40</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">富锦</td>
                                        <td :width="198">省道小佳河至亮子河公路大兴农场入点至C749段</td>
                                        <td :width="47">S308</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2025</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">2.0</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">2.0</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">2.0</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">2.0</td>
                                        <td :width="65"></td>
                                        <td :width="69">1223</td>
                                        <td :width="66"></td>
                                        <td :width="69">571</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">489</td>
                                        <td :width="67"></td>
                                        <td :width="67">228</td>
                                        <td :width="71">734</td>
                                        <td :width="71">343</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">41</td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">穆棱</td>
                                        <td :width="198">省道三岔口至石岩镇公路南金厂至头道沟经营所段</td>
                                        <td :width="47">S317</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2025</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">6.3</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">6.3</td>
                                        <td :width="58"></td>
                                        <td :width="64">6.3</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">6.3</td>
                                        <td :width="65"></td>
                                        <td :width="69">3772</td>
                                        <td :width="66"></td>
                                        <td :width="69">1760</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">1509</td>
                                        <td :width="67"></td>
                                        <td :width="67">704</td>
                                        <td :width="71">2263</td>
                                        <td :width="71">1056</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">42</td>
                                        <td :width="49">绥化</td>
                                        <td :width="49">北林区</td>
                                        <td :width="198">省道上集镇至秦家镇公路北林区绥棱界至三河镇段</td>
                                        <td :width="47">S510</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2025</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">6.6</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">6.6</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">6.6</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">6.6</td>
                                        <td :width="65"></td>
                                        <td :width="69">3941</td>
                                        <td :width="66"></td>
                                        <td :width="69">1839</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">1577</td>
                                        <td :width="67"></td>
                                        <td :width="67">736</td>
                                        <td :width="71">2365</td>
                                        <td :width="71">1104</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">43</td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">宁安市</td>
                                        <td :width="198">省道海林至山河镇公路福荣加油站至北湖1号桥段</td>
                                        <td :width="47">S316</td>
                                        <td :width="137">升级改造、原级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">80.0</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">54.827</td>
                                        <td :width="58">9.5</td>
                                        <td :width="58">15.648</td>
                                        <td :width="64">80.0</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>80.0</td>
                                        <td></td>
                                        <td :width="69">47985</td>
                                        <td :width="66"></td>
                                        <td :width="69">22400</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">19194</td>
                                        <td :width="67"></td>
                                        <td :width="67">8960</td>
                                        <td :width="71">28791</td>
                                        <td :width="71">13440</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">区域内连通农林场、乡镇、砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">44</td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">宁安市</td>
                                        <td :width="198">三岔口至石岩镇穆棱镇宁安穆棱界至鹤大公路段</td>
                                        <td :width="47">S317</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">83.0</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">83.0</td>
                                        <td :width="58"></td>
                                        <td :width="64">83.0</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>83.0</td>
                                        <td></td>
                                        <td :width="69">49800</td>
                                        <td :width="66"></td>
                                        <td :width="69">23240</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">19920</td>
                                        <td :width="67"></td>
                                        <td :width="67">9296</td>
                                        <td :width="71">29880</td>
                                        <td :width="71">13944</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路、跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">45</td>
                                        <td :width="49">大庆</td>
                                        <td :width="49">杜蒙县</td>
                                        <td :width="198">省道绥化至扎赉特旗公路杜蒙段</td>
                                        <td :width="47">S311</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">90.4</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">90.4</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">90.4</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">90.4</td>
                                        <td :width="65"></td>
                                        <td :width="69">54240</td>
                                        <td :width="66"></td>
                                        <td :width="69">25312</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">21696</td>
                                        <td :width="67"></td>
                                        <td :width="67">10125</td>
                                        <td :width="71">32544</td>
                                        <td :width="71">15187</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路、跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">46</td>
                                        <td :width="49">双鸭山</td>
                                        <td :width="49">集贤县</td>
                                        <td :width="198">省道名山至兴凯湖公路集贤段</td>
                                        <td :width="47">S202</td>
                                        <td :width="137">升级改造、原级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">17.0</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">8.0</td>
                                        <td :width="58">5.0</td>
                                        <td :width="58">4.0</td>
                                        <td :width="64">17.0</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">17.0</td>
                                        <td :width="65"></td>
                                        <td :width="69">10200</td>
                                        <td :width="66"></td>
                                        <td :width="69">4760</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">4080</td>
                                        <td :width="67"></td>
                                        <td :width="67">1904</td>
                                        <td :width="71">6120</td>
                                        <td :width="71">2856</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路、跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">47</td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">东宁市</td>
                                        <td :width="198">省道三岔口至石岩镇公路东宁至东宁穆棱界段</td>
                                        <td :width="47">S317</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2025</td>
                                        <td :width="47">2027</td>
                                        <td :width="58">122.7</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">122.7</td>
                                        <td :width="58"></td>
                                        <td :width="64">122.7</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>122.7</td>
                                        <td></td>
                                        <td :width="69">73601</td>
                                        <td :width="66"></td>
                                        <td :width="69">34347</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">29441</td>
                                        <td :width="67"></td>
                                        <td :width="67">13739</td>
                                        <td :width="71">44161</td>
                                        <td :width="71">20608</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">砂石路、跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">48</td>
                                        <td :width="49">黑河</td>
                                        <td :width="49">嫩江市</td>
                                        <td :width="198">省道联兴乡至科洛镇</td>
                                        <td :width="47">S515</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2025</td>
                                        <td :width="47">2027</td>
                                        <td :width="58">45.4</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">45.4</td>
                                        <td :width="58"></td>
                                        <td :width="64">45.4</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">45.4</td>
                                        <td :width="65"></td>
                                        <td :width="69">27240</td>
                                        <td :width="66"></td>
                                        <td :width="69">12712</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">10896</td>
                                        <td :width="67"></td>
                                        <td :width="67">5085</td>
                                        <td :width="71">16344</td>
                                        <td :width="71">7627</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">区域内连通农林场、乡镇、砂石路</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">49</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">拜泉县</td>
                                        <td :width="198">省道前进至胡吉吐莫公路依拜界至北拜界段</td>
                                        <td :width="47">S214</td>
                                        <td :width="137">升级改造、原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">74.4</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">63.4</td>
                                        <td :width="58">11.0</td>
                                        <td :width="58"></td>
                                        <td :width="64">74.4</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">74.4</td>
                                        <td :width="65"></td>
                                        <td :width="69">44654</td>
                                        <td :width="66"></td>
                                        <td :width="69">20832</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">17862</td>
                                        <td :width="67"></td>
                                        <td :width="67">8333</td>
                                        <td :width="71">26793</td>
                                        <td :width="71">12499</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">50</td>
                                        <td :width="49">大兴安岭</td>
                                        <td :width="49">新林、呼中</td>
                                        <td :width="198">省道连兴公路林海经呼中至内蒙界段</td>
                                        <td :width="47">S218</td>
                                        <td :width="137">原级改造<br>
                                            升级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">177.0</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">177.0</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">177.0</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">177.0</td>
                                        <td :width="65"></td>
                                        <td :width="69">106200</td>
                                        <td :width="66"></td>
                                        <td :width="69">49560</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">31860</td>
                                        <td :width="67"></td>
                                        <td :width="67">14868</td>
                                        <td :width="71">74340</td>
                                        <td :width="71">34692</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">51</td>
                                        <td :width="49">大兴安岭</td>
                                        <td :width="49">呼中、漠河</td>
                                        <td :width="198">省道连崟至兴华公路呼中至阿木尔段</td>
                                        <td :width="47">S218</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">94.0</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">94.0</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">94.0</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">94.0</td>
                                        <td :width="65"></td>
                                        <td :width="69">56400</td>
                                        <td :width="66"></td>
                                        <td :width="69">26320</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">16920</td>
                                        <td :width="67"></td>
                                        <td :width="67">7896</td>
                                        <td :width="71">39480</td>
                                        <td :width="71">18424</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">52</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">同江市</td>
                                        <td :width="198">省道东安镇至同江公路勤得利农场至三村段</td>
                                        <td :width="47">S307</td>
                                        <td :width="137">路面改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">46.8</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">46.8</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">46.8</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">46.8</td>
                                        <td :width="65"></td>
                                        <td :width="69">14040</td>
                                        <td :width="66"></td>
                                        <td :width="69">3978</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">4212</td>
                                        <td :width="67"></td>
                                        <td :width="67">1193</td>
                                        <td :width="71">9828</td>
                                        <td :width="71">2785</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">区域内连通农林场、乡镇</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">53</td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">林口县</td>
                                        <td :width="198">省道绥芬河至柳树公路柳树镇至鹤大公路段</td>
                                        <td :width="47">S315</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">4.7</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">4.7</td>
                                        <td :width="58"></td>
                                        <td :width="64">4.7</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>4.7</td>
                                        <td></td>
                                        <td :width="69">2847</td>
                                        <td :width="66"></td>
                                        <td :width="69">1329</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">854</td>
                                        <td :width="67"></td>
                                        <td :width="67">399</td>
                                        <td :width="71">1993</td>
                                        <td :width="71">930</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">区域内连通农林场、乡镇</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">54</td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">爱民区</td>
                                        <td :width="198">省道同江至汪清公路柴河镇至三道关一村段</td>
                                        <td :width="47">S203</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">7.4</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">7.4</td>
                                        <td :width="58"></td>
                                        <td :width="64">7.4</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>7.4</td>
                                        <td></td>
                                        <td :width="69">4440</td>
                                        <td :width="66"></td>
                                        <td :width="69">2072</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">1332</td>
                                        <td :width="67"></td>
                                        <td :width="67">622</td>
                                        <td :width="71">3108</td>
                                        <td :width="71">1450</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">55</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">桦川县</td>
                                        <td :width="198">省道名山至1兴凯湖公路291一分场19队入点至291一分场30队出点</td>
                                        <td :width="47">S202</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">14.5</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">14.5</td>
                                        <td :width="58"></td>
                                        <td :width="64">14.5</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>14.5</td>
                                        <td></td>
                                        <td :width="69">8700</td>
                                        <td :width="66"></td>
                                        <td :width="69">4060</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">2610</td>
                                        <td :width="67"></td>
                                        <td :width="67">1218</td>
                                        <td :width="71">6090</td>
                                        <td :width="71">2842</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">56</td>
                                        <td :width="49">鸡西</td>
                                        <td :width="49">密山市</td>
                                        <td :width="198">省道虎林至鸡西公路八五六八五七界至农垦总局杨木界段</td>
                                        <td :width="47">S314</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">13.4</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">13.4</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">13.4</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>13.4</td>
                                        <td></td>
                                        <td :width="69">8040</td>
                                        <td :width="66"></td>
                                        <td :width="69">3752</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">2412</td>
                                        <td :width="67"></td>
                                        <td :width="67">1126</td>
                                        <td :width="71">5628</td>
                                        <td :width="71">2626</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">57</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">抚远市</td>
                                        <td :width="198">省道四合至勤得利农场公路四合哨所至海青段</td>
                                        <td :width="47">S306</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">42.4</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">42.4</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">42.4</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">42.4</td>
                                        <td :width="65"></td>
                                        <td :width="69">25440</td>
                                        <td :width="66"></td>
                                        <td :width="69">11872</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">7632</td>
                                        <td :width="67"></td>
                                        <td :width="67">3562</td>
                                        <td :width="71">17808</td>
                                        <td :width="71">8310</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">区域内连通农林场、乡镇</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">58</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">抚远市</td>
                                        <td :width="198">省道四合至勤得利农场公路寒葱沟至鸭南鸭绿河界段</td>
                                        <td :width="47">S306</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">18.8</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">18.8</td>
                                        <td :width="58"></td>
                                        <td :width="64">18.8</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">18.8</td>
                                        <td :width="65"></td>
                                        <td :width="69">11280</td>
                                        <td :width="66"></td>
                                        <td :width="69">5264</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">3384</td>
                                        <td :width="67"></td>
                                        <td :width="67">1579</td>
                                        <td :width="71">7896</td>
                                        <td :width="71">3685</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">区域内连通农林场、乡镇</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr style="font-weight: bold">
                                        <td colspan="4" :width="331">二、备选项目</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td :width="58">1040.8</td>
                                        <td :width="53"></td>
                                        <td :width="58">215.4</td>
                                        <td :width="58">693.0</td>
                                        <td :width="58">132.4</td>
                                        <td :width="58"></td>
                                        <td :width="64">1039.9</td>
                                        <td :width="56">188.2</td>
                                        <td :width="56">87.0</td>
                                        <td :width="61">85.4</td>
                                        <td :width="65">679.3</td>
                                        <td :width="65">55</td>
                                        <td :width="69">1341498</td>
                                        <td :width="66"></td>
                                        <td :width="69">451750</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">1341498</td>
                                        <td :width="67"></td>
                                        <td :width="67">451750</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">1</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">宾县</td>
                                        <td :width="198">省道哈尔滨至北安公路西河大桥至绥尚公路段</td>
                                        <td :width="47">S101</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">19.7</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">19.7</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">19.7</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">19.7</td>
                                        <td></td>
                                        <td :width="69">11820</td>
                                        <td :width="66"></td>
                                        <td :width="69">5516</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">11820</td>
                                        <td :width="67"></td>
                                        <td :width="67">5516</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">2</td>
                                        <td :width="49">黑河</td>
                                        <td :width="49">逊克县</td>
                                        <td :width="198">省道逊克至孙吴公路靠山村至逊河镇段</td>
                                        <td :width="47">S516</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">45.0</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">45.0</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">45.0</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">45.0</td>
                                        <td :width="65"></td>
                                        <td :width="69">27000</td>
                                        <td :width="66"></td>
                                        <td :width="69">12600</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">27000</td>
                                        <td :width="67"></td>
                                        <td :width="67">12600</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">3</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">双城区</td>
                                        <td :width="198">省道黑河至双城公路双城段</td>
                                        <td :width="47">S211</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">36.4</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">36.4</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">36.4</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">36.4</td>
                                        <td></td>
                                        <td :width="69">21840</td>
                                        <td :width="66"></td>
                                        <td :width="69">10192</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">21840</td>
                                        <td :width="67"></td>
                                        <td :width="67">10192</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">4</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">方正县</td>
                                        <td :width="198">省道伊春至牡丹江公路后谭家至方延界段</td>
                                        <td :width="47">S207</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">17.5</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">17.5</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">17.5</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">17.5</td>
                                        <td></td>
                                        <td :width="69">10500</td>
                                        <td :width="66"></td>
                                        <td :width="69">4900</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">10500</td>
                                        <td :width="67"></td>
                                        <td :width="67">4900</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">5</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">宾县</td>
                                        <td :width="198">省道绥化至尚志公路南阳村庆荣屯至尚志界段</td>
                                        <td :width="47">S209</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">27.0</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">27.0</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">27.0</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">26.985</td>
                                        <td></td>
                                        <td :width="69">16191</td>
                                        <td :width="66"></td>
                                        <td :width="69">7556</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">16191</td>
                                        <td :width="67"></td>
                                        <td :width="67">7556</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">6</td>
                                        <td :width="49">大庆</td>
                                        <td :width="49">肇州县</td>
                                        <td :width="198">省道绥化至扎赉特旗公路肇州段</td>
                                        <td :width="47">S311</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">22.0</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">22.0</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">22.0</td>
                                        <td :width="56"></td>
                                        <td :width="56">22.0</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">26370</td>
                                        <td :width="66"></td>
                                        <td :width="69">10988</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">26370</td>
                                        <td :width="67"></td>
                                        <td :width="67">10988</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">瓶颈路段，跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">7</td>
                                        <td :width="49">大庆</td>
                                        <td :width="49">大同区</td>
                                        <td :width="198">省道绥化至扎赉特旗公路大同段</td>
                                        <td :width="47">S311</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">42.2</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">42.2</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">42.2</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">42.2</td>
                                        <td :width="65"></td>
                                        <td :width="69">25320</td>
                                        <td :width="66"></td>
                                        <td :width="69">11816</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">25320</td>
                                        <td :width="67"></td>
                                        <td :width="67">11816</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">8</td>
                                        <td :width="49">双鸭山市</td>
                                        <td :width="49">集贤县</td>
                                        <td :width="198">省道名山至兴凯湖公路二九一至桦川界段</td>
                                        <td :width="47">S202</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">24.2</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">24.2</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">24.2</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">24.2</td>
                                        <td :width="65"></td>
                                        <td :width="69">14520</td>
                                        <td :width="66"></td>
                                        <td :width="69">6776</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">14520</td>
                                        <td :width="67"></td>
                                        <td :width="67">6776</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">9</td>
                                        <td :width="49">双鸭山</td>
                                        <td :width="49">饶河县</td>
                                        <td :width="198">省道东安镇至同江公路胜利农场至红卫农场32队段</td>
                                        <td :width="47">S307</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">52.9</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">52.9</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">52.9</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">52.9</td>
                                        <td :width="65"></td>
                                        <td :width="69">31740</td>
                                        <td :width="66"></td>
                                        <td :width="69">14812</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">31740</td>
                                        <td :width="67"></td>
                                        <td :width="67">14812</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">区域内连通农林场、乡镇</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">10</td>
                                        <td :width="49">黑河</td>
                                        <td :width="49">嫩江市</td>
                                        <td :width="198">省道黑河至双城公路重复路段始跃进修配厂[跃进农场入点]</td>
                                        <td>S211</td>
                                        <td>升级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">25.0</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">25.0</td>
                                        <td :width="58"></td>
                                        <td :width="64">25.0</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>25.0</td>
                                        <td></td>
                                        <td :width="69">15000</td>
                                        <td :width="66"></td>
                                        <td :width="69">7000</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">15000</td>
                                        <td :width="67"></td>
                                        <td :width="67">7000</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">区域内连通农林场、乡镇</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">11</td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">汤原</td>
                                        <td :width="198">省道南岔至孟家岗公路香兰至竹帘段</td>
                                        <td :width="47">S205</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">12.4</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">12.4</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">12.4</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">12.4</td>
                                        <td :width="65"></td>
                                        <td :width="69">7440</td>
                                        <td :width="66"></td>
                                        <td :width="69">3472</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">7440</td>
                                        <td :width="67"></td>
                                        <td :width="67">3472</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">12</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">甘南县</td>
                                        <td :width="198">省道甘南至龙江公路中兴乡至龙甘界段</td>
                                        <td :width="47">S509</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">4.7</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">4.7</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">4.7</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">4.7</td>
                                        <td :width="65"></td>
                                        <td :width="69">2820</td>
                                        <td :width="66"></td>
                                        <td :width="69">1316</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">2820</td>
                                        <td :width="67"></td>
                                        <td :width="67">1316</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">13</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">依安县</td>
                                        <td :width="198">省道北安至富裕公路依安段</td>
                                        <td :width="47">S305</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">52.1</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">52.1</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">52.1</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">52.1</td>
                                        <td :width="65"></td>
                                        <td :width="69">31260</td>
                                        <td :width="66"></td>
                                        <td :width="69">14588</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">31260</td>
                                        <td :width="67"></td>
                                        <td :width="67">14588</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">14</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">巴彦县</td>
                                        <td :width="198">省道绥化至尚志公路巴彦至绥巴界段</td>
                                        <td :width="47">S209</td>
                                        <td :width="137">升级改造、原级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">81.2</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">34.7</td>
                                        <td :width="58">46.5</td>
                                        <td :width="58"></td>
                                        <td :width="64">81.2</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">81.2</td>
                                        <td :width="65"></td>
                                        <td :width="69">48720</td>
                                        <td :width="66"></td>
                                        <td :width="69">22736</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">48720</td>
                                        <td :width="67"></td>
                                        <td :width="67">22736</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">15</td>
                                        <td :width="49">双鸭山</td>
                                        <td :width="49">友谊县</td>
                                        <td :width="198">省道名山至兴凯湖公路红兴隆至集贤界段</td>
                                        <td :width="47">S202</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">10.5</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">10.5</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">10.5</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">10.5</td>
                                        <td :width="65"></td>
                                        <td :width="69">6300</td>
                                        <td :width="66"></td>
                                        <td :width="69">2940</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">6300</td>
                                        <td :width="67"></td>
                                        <td :width="67">2940</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">16</td>
                                        <td :width="49">鸡西</td>
                                        <td :width="49">密山市</td>
                                        <td :width="198">省道虎林至鸡西公路密山段</td>
                                        <td :width="47">S314</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">86.2</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">86.2</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">86.2</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">86.2</td>
                                        <td :width="65"></td>
                                        <td :width="69">51720</td>
                                        <td :width="66"></td>
                                        <td :width="69">24136</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">51720</td>
                                        <td :width="67"></td>
                                        <td :width="67">24136</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">17</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">尚志市</td>
                                        <td :width="198">省道哈尔滨至亚布力公路亚布力镇至鱼池乡段</td>
                                        <td :width="47">S102</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">21.6</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">21.6</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">21.6</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">21.6</td>
                                        <td :width="65"></td>
                                        <td :width="69">12960</td>
                                        <td :width="66"></td>
                                        <td :width="69">6048</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">12960</td>
                                        <td :width="67"></td>
                                        <td :width="67">6048</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">区域内连通农林场、乡镇</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">18</td>
                                        <td :width="49">鸡西</td>
                                        <td :width="49">虎林市</td>
                                        <td :width="198">省道珍宝岛至当壁镇公路珍宝岛乡至五林洞镇段</td>
                                        <td :width="47">S201</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">20.0</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">20.0</td>
                                        <td :width="58"></td>
                                        <td :width="64">20.0</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">20.0</td>
                                        <td :width="65"></td>
                                        <td :width="69">12000</td>
                                        <td :width="66"></td>
                                        <td :width="69">5600</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">12000</td>
                                        <td :width="67"></td>
                                        <td :width="67">5600</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">19</td>
                                        <td :width="49">双鸭山</td>
                                        <td :width="49">宝山区(双鸭山农场)</td>
                                        <td :width="198">省道名山至兴凯湖公路重复路段至三合村段</td>
                                        <td :width="47">S202</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2025</td>
                                        <td :width="47">2026</td>
                                        <td :width="58">9.8</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">9.8</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">9.8</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">9.8</td>
                                        <td :width="65"></td>
                                        <td :width="69">5880</td>
                                        <td :width="66"></td>
                                        <td :width="69">2744</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">5880</td>
                                        <td :width="67"></td>
                                        <td :width="67">2744</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">20</td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">道外区</td>
                                        <td :width="198">省道哈尔滨至北安公路道外段</td>
                                        <td :width="47">S101</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2025</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">36.2</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">36.2</td>
                                        <td :width="58"></td>
                                        <td :width="64">36.2</td>
                                        <td :width="56">1.2</td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">35</td>
                                        <td></td>
                                        <td :width="69">25800</td>
                                        <td :width="66"></td>
                                        <td :width="69">11000</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">25800</td>
                                        <td :width="67"></td>
                                        <td :width="67">11000</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">21</td>
                                        <td :width="49">大兴安岭</td>
                                        <td :width="49">塔河县</td>
                                        <td :width="198">省道丹阿公路至依西肯乡段</td>
                                        <td :width="47">S518</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2025</td>
                                        <td :width="47">2027</td>
                                        <td :width="58">55.9</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">51.2</td>
                                        <td :width="58">4.7</td>
                                        <td :width="58"></td>
                                        <td :width="64">56.0</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65">55.9</td>
                                        <td :width="65">55.0</td>
                                        <td :width="69">33540</td>
                                        <td :width="66"></td>
                                        <td :width="69">15652</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">33540</td>
                                        <td :width="67"></td>
                                        <td :width="67">15652</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">区域内连通农林场、乡镇</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">22</td>
                                        <td :width="49">大兴安岭</td>
                                        <td :width="49">漠河</td>
                                        <td :width="198">省道漠河至满归公路</td>
                                        <td :width="47">S219</td>
                                        <td :width="137">原级改造</td>
                                        <td :width="47">2025</td>
                                        <td :width="47">2027</td>
                                        <td :width="58">85.4</td>
                                        <td :width="53"></td>
                                        <td :width="58">85.388</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">85.4</td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61">85.4</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">76849</td>
                                        <td :width="66"></td>
                                        <td :width="69">29886</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">76849</td>
                                        <td :width="67"></td>
                                        <td :width="67">29886</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">出省通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">23</td>
                                        <td :width="49">大庆</td>
                                        <td :width="49">肇源</td>
                                        <td :width="198">省道哈尔滨至大安公路肇源段</td>
                                        <td :width="47">S104</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">123</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">123.0</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">122.0</td>
                                        <td :width="56">57.0</td>
                                        <td :width="56">65.0</td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">306000</td>
                                        <td :width="66"></td>
                                        <td :width="69">89500</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">306000</td>
                                        <td :width="67"></td>
                                        <td :width="67">89500</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">出省通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">24</td>
                                        <td :width="49">绥化</td>
                                        <td :width="49">兰西</td>
                                        <td :width="198">省道绥化至扎赉特旗公路兰西段</td>
                                        <td :width="47">S311</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">56.477</td>
                                        <td :width="53"></td>
                                        <td :width="58">56.5</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">56.5</td>
                                        <td :width="56">56.5</td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">225908</td>
                                        <td :width="66"></td>
                                        <td :width="69">56477</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">225908</td>
                                        <td :width="67"></td>
                                        <td :width="67">56477</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">25</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">拜泉县</td>
                                        <td :width="198">省道黑河至双城公路克拜界至拜泉段</td>
                                        <td :width="47">S211</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">35.5</td>
                                        <td :width="53"></td>
                                        <td :width="58">35.5</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">35.5</td>
                                        <td :width="56">35.5</td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">142000</td>
                                        <td :width="66"></td>
                                        <td :width="69">35500</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">142000</td>
                                        <td :width="67"></td>
                                        <td :width="67">35500</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35">26</td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">龙江县</td>
                                        <td :width="198">省道扎龙至景星公路共和至龙江段</td>
                                        <td :width="47">S310</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">38</td>
                                        <td :width="53"></td>
                                        <td :width="58">38.0</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64">38.0</td>
                                        <td :width="56">38.0</td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69">152000</td>
                                        <td :width="66"></td>
                                        <td :width="69">38000</td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67">152000</td>
                                        <td :width="67"></td>
                                        <td :width="67">38000</td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144">跨区域通道</td>
                                        <td :width="166"></td>
                                    </tr>
                                </tbody>
                                <tbody class="bg-info">
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">巴彦县</td>
                                        <td :width="198">省道凤山至兴隆公路兴隆镇至巴木界段</td>
                                        <td :width="47">S312</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2025</td>
                                        <td :width="47">2026</td>
                                        <td :width="58">71.4</td>
                                        <td :width="53"></td>
                                        <td :width="58">18.2</td>
                                        <td :width="58">36.8</td>
                                        <td :width="58">16.4</td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">巴彦县</td>
                                        <td :width="198">省道哈尔滨至北安公路大顶子山至巴彦镇段</td>
                                        <td :width="47">S101</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">15.9</td>
                                        <td :width="53"></td>
                                        <td :width="58">11.4</td>
                                        <td :width="58">4.5</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">巴彦正式行文。</td>
                                        <td :width="144"></td>
                                        <td :width="166">工可已完成审查，正在办理土地预审审批手续</td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">阿城区</td>
                                        <td :width="198">省道哈尔滨至亚布力公路阿城红星水库绕行线</td>
                                        <td :width="47">S102</td>
                                        <td :width="137">新建</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">阿城正式行文</td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">阿城区</td>
                                        <td :width="198">省道哈尔滨至亚布力公路松峰山镇至平山镇段</td>
                                        <td :width="47">S102</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">14.9</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">14.9</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">阿城区</td>
                                        <td :width="198">省道阿城至扶余公路阿城至哈五路段</td>
                                        <td :width="47">S210</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">21.7</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">21.7</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">阿城区</td>
                                        <td :width="198">省道阿扶公路蜚克图至阿城段</td>
                                        <td :width="47">S210</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">24.1</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">24.1</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">阿城区</td>
                                        <td :width="198">省道哈尔滨至亚布力公路新华过境段</td>
                                        <td :width="47">S102</td>
                                        <td :width="137">路面改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2022</td>
                                        <td :width="58">1.3</td>
                                        <td :width="53"></td>
                                        <td :width="58">1.3</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">通河县</td>
                                        <td :width="198">通河县通用机场连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">通河县</td>
                                        <td :width="198">省道凤山至兴隆公路青峰林场岔口至通木界段</td>
                                        <td :width="47">S312</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">6.3</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">6.3</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">哈尔滨</td>
                                        <td :width="49">木兰县</td>
                                        <td :width="198">省道凤山至兴隆公路通木界至新春段</td>
                                        <td :width="47">S312</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">3.9</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">3.9</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">鹤岗</td>
                                        <td :width="49">萝北县</td>
                                        <td :width="198">鹤岗民用机场连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58">2.07</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">2.1</td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">鹤岗</td>
                                        <td :width="49">绥滨县</td>
                                        <td :width="198">绥滨县物流园区连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">黑河</td>
                                        <td :width="49">嫩江市</td>
                                        <td :width="198">嫩江墨尔根通用机场</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58">4</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">4.0</td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">七台河</td>
                                        <td :width="49">勃利县</td>
                                        <td :width="198">七台河市通用机场连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">双鸭山</td>
                                        <td :width="49">尖山区</td>
                                        <td :width="198">双鸭山市公路综合客运枢纽中心连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">绥化</td>
                                        <td :width="49">北林区</td>
                                        <td :width="198">省道哈尔滨至北安公路郭家至关家窝棚段</td>
                                        <td :width="47">S101</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2022</td>
                                        <td :width="58">1.3</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">1.3</td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">绥化</td>
                                        <td :width="49">北林区</td>
                                        <td :width="198">省道绥化至尚志公路二龙村至巴北界段</td>
                                        <td :width="47">S209</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2022</td>
                                        <td :width="58">1.8</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">1.8</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">绥化</td>
                                        <td :width="49">海伦市</td>
                                        <td :width="198">省道建兴至新林公路绥海界至海伦段</td>
                                        <td>S309</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">22.0</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">22.0</td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166">工可完成审查</td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">绥化</td>
                                        <td :width="49">北林区</td>
                                        <td :width="198">省道上集镇至秦家镇公路绥棱界至绥化段</td>
                                        <td :width="47">S510</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">41.3</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">18.9</td>
                                        <td :width="58">22.4</td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">鸡西</td>
                                        <td :width="49">密山市</td>
                                        <td :width="198">省道名山至兴凯湖公路密山至兴凯湖博物馆段</td>
                                        <td :width="47">S202</td>
                                        <td :width="137">路面改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">48.2</td>
                                        <td :width="53"></td>
                                        <td :width="58">48.2</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">穆棱市</td>
                                        <td :width="198">穆棱市兴源公路综合客运枢纽站连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">林口县</td>
                                        <td :width="198">林口县高铁站连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">林口县</td>
                                        <td :width="198">省道方正至珲春公路兴华村至G11岔道段</td>
                                        <td :width="47">S206</td>
                                        <td :width="137">路面改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">9.7</td>
                                        <td :width="53">9.7</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">穆棱市</td>
                                        <td :width="198">省道三岔口至石岩镇公路东宁穆棱界至穆棱宁安界</td>
                                        <td :width="47">S317</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2026</td>
                                        <td :width="47">2027</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">23.7</td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">东安区、穆棱市</td>
                                        <td :width="198">省道同江至汪清公路牡丹峰至省道方珲公路段</td>
                                        <td :width="47">S203</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2026</td>
                                        <td :width="47">2027</td>
                                        <td :width="58">46.2</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">20.8</td>
                                        <td :width="58">25.4</td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">爱民区、西安区</td>
                                        <td :width="198">省道同江至汪清公路牡丹江师范学院北门至环堤路段</td>
                                        <td :width="47">S203</td>
                                        <td :width="137"></td>
                                        <td :width="47">2026</td>
                                        <td :width="47">2027</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">海林市</td>
                                        <td :width="198">牡丹江军民合用机场连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47">2027</td>
                                        <td :width="47">2028</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">牡丹江</td>
                                        <td :width="49">海林市</td>
                                        <td :width="198">海林经济技术开发区连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47">2027</td>
                                        <td :width="47">2028</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">伊春</td>
                                        <td :width="49">乌翠区</td>
                                        <td :width="198">伊春生态经济开发区连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">甘南县</td>
                                        <td :width="198">绥满高速甘南互通连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">梅里斯区</td>
                                        <td :width="198">东北亚冷链物流园区连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58">1.2</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">1.2</td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">梅里斯达斡尔族区</td>
                                        <td :width="198">齐齐哈尔国家高新区梅里斯产业园区连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">昂昂溪区</td>
                                        <td :width="198">昂昂溪区水师中小企业园区连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">齐齐哈尔</td>
                                        <td :width="49">昂昂溪区</td>
                                        <td :width="198">齐齐哈尔化工园区连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">大兴安岭</td>
                                        <td :width="49">塔河县</td>
                                        <td :width="198">塔河县经济开发区连接线</td>
                                        <td :width="47"></td>
                                        <td :width="137"></td>
                                        <td :width="47"></td>
                                        <td :width="47"></td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">大兴安岭</td>
                                        <td :width="49">漠河市</td>
                                        <td :width="198">省道连崟至兴华公路古城村至依林林场段</td>
                                        <td :width="47">S218</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">94.6</td>
                                        <td :width="53"></td>
                                        <td :width="58">29.0</td>
                                        <td :width="58">65.6</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">大庆</td>
                                        <td :width="49">林甸县</td>
                                        <td :width="198">G10高速林甸出口至林甸经济开发区公路</td>
                                        <td :width="47"></td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2021</td>
                                        <td :width="47">2022</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">28.3</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">佳木斯市</td>
                                        <td :width="49">富锦市(建三江)</td>
                                        <td :width="198">省道小佳河至亮子河公路大兴农场段</td>
                                        <td :width="47">S308</td>
                                        <td :width="137">升级改造<br>
                                            路面改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2026</td>
                                        <td :width="58">44.3</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">44.3</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">佳木斯市</td>
                                        <td :width="49">抚远市、同江市(建三江)</td>
                                        <td :width="198">省道四合至勤得利农场公路鸭绿河至浓江农场段</td>
                                        <td :width="47">S306</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2026</td>
                                        <td :width="58">38.9</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">38.9</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">佳木斯市</td>
                                        <td :width="49">同江市(建三江)</td>
                                        <td :width="198">省道四合至勤得利农场公路浓江农场段</td>
                                        <td :width="47">S306</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2025</td>
                                        <td :width="58">25.7</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">25.7</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">富锦市(建三江)</td>
                                        <td :width="198">省道东安镇至同江公路创业农场段</td>
                                        <td :width="47">S307</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2023</td>
                                        <td :width="58">17.1</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">1.2</td>
                                        <td :width="58">15.9</td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">佳木斯</td>
                                        <td :width="49">同江市</td>
                                        <td :width="198">省道东安镇至同江公路前进农场至勤得利农场段</td>
                                        <td :width="47">S307</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2024</td>
                                        <td :width="58">53.2</td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">53.2</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                </tbody>
                                <tbody class="bg-success">
                                    <tr>
                                        <td></td>
                                        <td :width="49">双鸭山</td>
                                        <td :width="49">饶河县</td>
                                        <td :width="198">省道东安镇至同江公路八五九东安镇至胜利农场段</td>
                                        <td :width="47">S307</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2024</td>
                                        <td :width="47">2025</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58">31.4</td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">黑河</td>
                                        <td :width="49">五大连池市</td>
                                        <td :width="198">省道长水河农场至讷河公路五大连池至北兴段</td>
                                        <td :width="47">S303</td>
                                        <td :width="137">升级改造</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2024</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58">29.5</td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87">黑河交通局行文。</td>
                                        <td :width="144"></td>
                                        <td :width="166">工可编制中</td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">绥化</td>
                                        <td :width="49">绥化市</td>
                                        <td :width="198">省道依四公路四方台至庆安县致富乡段</td>
                                        <td :width="47"></td>
                                        <td :width="137">新建</td>
                                        <td :width="47">2023</td>
                                        <td :width="47">2024</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">绥化、大庆</td>
                                        <td :width="49">青冈县、安达市、大庆市</td>
                                        <td :width="198">青冈至大庆机场公路段</td>
                                        <td :width="47"></td>
                                        <td :width="137">新建</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2024</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                    <tr>
                                        <td :width="35"></td>
                                        <td :width="49">绥化</td>
                                        <td :width="49">绥化市</td>
                                        <td :width="198">望奎火箭至兰西河口至鹤哈高速赵家出口公路段</td>
                                        <td :width="47"></td>
                                        <td :width="137">新建</td>
                                        <td :width="47">2022</td>
                                        <td :width="47">2025</td>
                                        <td :width="58"></td>
                                        <td :width="53"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="64"></td>
                                        <td :width="56"></td>
                                        <td :width="56"></td>
                                        <td :width="61"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="69"></td>
                                        <td :width="66"></td>
                                        <td :width="69"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="67"></td>
                                        <td :width="71"></td>
                                        <td :width="71"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="58"></td>
                                        <td :width="87"></td>
                                        <td :width="144"></td>
                                        <td :width="166"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </el-tab-pane>
                </el-tabs>

            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    name: "Home",
    components: {},
    data() {
        return {
            activeName: "first",
        };
    },
    mounted() {},
    methods: {
        wordDetail(e) {
            // 微软提供的方法
            if(e ==1){
                window.open("http://zzgroup.hljzztech.com/十四五高速公路项目.xlsx");
            }else if(e == 2){
                window.open("http://zzgroup.hljzztech.com/20211101十四五普通国道项目表（投资有调整）.xls");
            }else{
                window.open("http://zzgroup.hljzztech.com/20211030省道.xlsx");
            }
            
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
    },
    watch: {},
};
</script>

<style lang="less">
</style>