<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
         
          <el-tab-pane label="规划项目库" name="first">
            <h2 style="margin-bottom: 10px; text-align: center"> 黑龙江省“十四五”水运规划建设项目表 <span @click="wordDetail()" class="xzecl" >黑龙江省“十四五”水运规划建设项目表</span> </h2>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <td :width="44">序号</td>
                    <td :width="194">类型</td>
                    <td :width="462">建设内容</td>
                    <td :width="181">&ldquo;十四五&rdquo;投资（亿元）</td>
                    <td>备注</td>
                  </tr>
                </thead>
                <tbody>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="238">合计</td>
                    <td :width="462"></td>
                    <td :width="181">101</td>
                    <td></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="238">一、续建项目</td>
                    <td :width="462"></td>
                    <td :width="181">1</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td :width="44">1</td>
                    <td :width="194">支持系统</td>
                    <td :width="462">中俄界河航道维护管理装备建造项目</td>
                    <td :width="181">1.1</td>
                    <td></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="238">二、&ldquo;十四五&rdquo;新开工项目</td>
                    <td :width="462"></td>
                    <td :width="181">29</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td :width="44">1</td>
                    <td :width="194">航道项目</td>
                    <td :width="462">中俄界河松阿察河航标测量和重点河段航道建设工程</td>
                    <td :width="181">0.8</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td :width="44">2</td>
                    <td :width="194">航道项目</td>
                    <td :width="462">松花江下游重点浅滩航道建设工程</td>
                    <td :width="181">8.0</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td :width="44">3</td>
                    <td :width="194">航道项目</td>
                    <td :width="462">智能航道建设工程</td>
                    <td :width="181">3.4</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td :width="44">4</td>
                    <td :width="194">航道项目</td>
                    <td :width="462">乌苏里江松阿察河河口至虎头航道建设工程</td>
                    <td :width="181">0.5</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td :width="44">5</td>
                    <td :width="194">航道项目</td>
                    <td :width="462">嫩江三岔河至洮儿河口航道建设工程</td>
                    <td :width="181">1.3</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td :width="44">6</td>
                    <td :width="194">航道项目</td>
                    <td :width="462">黑龙江上游乌苏里至鸥浦航道建设工程</td>
                    <td :width="181">0.4</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td :width="44">7</td>
                    <td :width="194">港口项目</td>
                    <td :width="462">黑河自贸区港、佳木斯港同江港区哈鱼岛作业区石油化工码头、佳木斯港抚远港区莽吉塔作业区金良码头、哈尔滨港哈尔滨港区阿勒锦岛客运码头、哈尔滨港方正港区沙河子作业区矿建码头（改扩建工程）、界河旅游客运码头、哈尔滨港哈尔滨港区呼兰河作业区双达货运码头（二期工程）、哈尔滨港哈尔滨港区阿什河作业区（改扩建工程）等8项</td>
                    <td :width="181">10.4</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td :width="44">8</td>
                    <td :width="194">支持系统</td>
                    <td :width="462">中俄界河航道维护管理装备</td>
                    <td :width="181">1.5</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td :width="44">9</td>
                    <td :width="194">支持系统</td>
                    <td :width="462">中俄界河应急救涝浮吊船及配套船舶</td>
                    <td :width="181">0.9</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td :width="44">10</td>
                    <td :width="194">支持系统</td>
                    <td :width="462">中俄界河水上交通救援基地（应急救援浮码头）建造</td>
                    <td :width="181">0.1</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td :width="44">11</td>
                    <td :width="194">航电枢纽</td>
                    <td :width="462">松花江大顶子山航电枢纽公益设施功能完善项目</td>
                    <td :width="181">0.8</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td :width="44">12</td>
                    <td :width="194">航电枢纽</td>
                    <td :width="462">松花江大顶子山航电枢纽大坝病险消除项目</td>
                    <td :width="181">0.7</td>
                    <td></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="238">三、&ldquo;十四五&rdquo;跨&ldquo;十五五&rdquo;项目</td>
                    <td :width="462"></td>
                    <td :width="181">2</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td :width="44">1</td>
                    <td :width="194">航道项目</td>
                    <td :width="462">抚远水道航道整治工程</td>
                    <td :width="181">1.8</td>
                    <td></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="238">四、谋划项目</td>
                    <td :width="462"></td>
                    <td :width="181">69.8</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td :width="44">1</td>
                    <td :width="194">松花江梯级开发航电枢纽</td>
                    <td :width="462">推进依兰等航电枢纽前期工作</td>
                    <td :width="181">69.8</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-tab-pane>
		<el-tab-pane label="建设项目库" name="second">
            <h2 style="margin-bottom: 10px; text-align: center"> 2022年—2024年全省水运及支持系统项目库建议计划表 <span @click="wordDetail()" class="xzecl" >黑龙江省“十四五”水运规划建设项目表</span> </h2>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <td rowspan="3" :width="48">序号</td>
                    <td rowspan="3" :width="181">项目名称</td>
                    <td colspan="5" :width="377">总的建设要求</td>
                    <td rowspan="3" :width="49">累计下达中央投资（万元）</td>
                    <td rowspan="3" :width="54">2022年建议中央 投资（万元）</td>
                    <td colspan="3" rowspan="2" :width="224">前期工作情况</td>
                    <td rowspan="3" :width="65">备注</td>
                  </tr>
                  <tr>
                    <td rowspan="2">建设规模</td>
                    <td colspan="2">总投资（万元）</td>
                    <td colspan="2">建设年</td>
                  </tr>
                  <tr>
                    <td>合计</td>
                    <td :width="58">其中：中央</td>
                    <td>开工年</td>
                    <td>完工年</td>
                    <td :width="84">工研批复或核准文号</td>
                    <td :width="68">设计批复文号</td>
                    <td :width="72">承诺函批复文号</td>
                  </tr>
                </thead>
                <tbody>
                  <tr style="font-weight: bold">
                    <td></td>
                    <td>合计</td>
                    <td></td>
                    <td>74285</td>
                    <td>74285</td>
                    <td></td>
                    <td></td>
                    <td>4700</td>
                    <td>3344</td>
                    <td :width="84"></td>
                    <td :width="68"></td>
                    <td :width="72"></td>
                    <td></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td>一</td>
                    <td>支持系统项目</td>
                    <td></td>
                    <td>27875</td>
                    <td>27875</td>
                    <td></td>
                    <td></td>
                    <td>4700</td>
                    <td>3344</td>
                    <td :width="84"></td>
                    <td :width="68"></td>
                    <td :width="72"></td>
                    <td></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td>（一）</td>
                    <td>&ldquo;十四五&rdquo;续建项目</td>
                    <td></td>
                    <td>7850</td>
                    <td>7850</td>
                    <td></td>
                    <td></td>
                    <td>4700</td>
                    <td>3344</td>
                    <td :width="84"></td>
                    <td :width="68"></td>
                    <td :width="72"></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td :width="48">1</td>
                    <td :width="181">中俄界河乌苏里江航道维护抓斗挖泥船及配套船舶建造项目</td>
                    <td :width="158">建造4方抓斗挖泥船1艘、对开泥驳2艘、辅助拖轮2艘、宿舍船1艘、锚艇1艘</td>
                    <td>7850</td>
                    <td>7850</td>
                    <td>2019</td>
                    <td>2023</td>
                    <td>4700</td>
                    <td>1484</td>
                    <td :width="84">交规划函〔2018〕902号</td>
                    <td :width="68">厅规划字〔2013〕279号(定型船）</td>
                    <td :width="72">交规划函〔2018〕902号</td>
                    <td :width="65">2021年1270万元计划未下达</td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td :width="48">（二）</td>
                    <td :width="181">&ldquo;十四五&rdquo;新开工项目</td>
                    <td :width="158"></td>
                    <td>20025</td>
                    <td>20025</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td :width="84"></td>
                    <td :width="68"></td>
                    <td :width="72"></td>
                    <td :width="65"></td>
                  </tr>
                  <tr>
                    <td :width="48">1</td>
                    <td :width="181">黑龙江省航道局航道维护辅助用船项目</td>
                    <td :width="158">建造松阿察河浅吃水航道维护测量船1艘，界河应急清障船辅助驳船1艘，40米级趸船1艘，20米级趸船4艘，购置快速交通艇2艘</td>
                    <td>2900</td>
                    <td>2900</td>
                    <td>2022</td>
                    <td>2023</td>
                    <td></td>
                    <td>1500</td>
                    <td :width="84">交规划函〔2019〕365号</td>
                    <td :width="68">黑交发〔2020〕120号</td>
                    <td :width="72">交规划函〔2019〕365号</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td :width="48">2</td>
                    <td :width="181">中俄界河松阿察河浅吃水航标船建造项目</td>
                    <td :width="158">浅吃水航标船2艘</td>
                    <td>625</td>
                    <td>625</td>
                    <td>2022</td>
                    <td>2023</td>
                    <td></td>
                    <td>360</td>
                    <td :width="84">交规划函〔2020〕50号</td>
                    <td :width="68">黑交发〔2020〕303号</td>
                    <td :width="72">交规划函〔2020〕50号</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td :width="48">3</td>
                    <td :width="181">自航应急清障船建造项目</td>
                    <td :width="158">自航应急清障船1艘</td>
                    <td>3540</td>
                    <td>3540</td>
                    <td>2022</td>
                    <td>2024</td>
                    <td></td>
                    <td></td>
                    <td :width="84">交规划函〔2020〕870号</td>
                    <td :width="68"></td>
                    <td :width="72">交规划函〔2020〕870号</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td :width="48">4</td>
                    <td :width="181">中俄界河应急救涝浮吊船及配套船舶建造项目</td>
                    <td :width="158">建造浮吊船1艘,推轮1艘、驳船1艘、救涝工作船1艘</td>
                    <td>9000</td>
                    <td>9000</td>
                    <td>2023</td>
                    <td>2025</td>
                    <td></td>
                    <td></td>
                    <td :width="84"></td>
                    <td :width="68"></td>
                    <td :width="72"></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td :width="48">5</td>
                    <td :width="181">中俄界河航标船配套工作艇建造项目</td>
                    <td :width="158">建造浅吃水航标工作艇9艘。</td>
                    <td>3150</td>
                    <td>3150</td>
                    <td>2023</td>
                    <td>2025</td>
                    <td></td>
                    <td></td>
                    <td :width="84"></td>
                    <td :width="68"></td>
                    <td :width="72"></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td :width="48">6</td>
                    <td :width="181">中俄界河航道段配套趸船建造项目</td>
                    <td :width="158">建造趸船3艘。</td>
                    <td>810</td>
                    <td>810</td>
                    <td>2023</td>
                    <td>2025</td>
                    <td></td>
                    <td></td>
                    <td :width="84"></td>
                    <td :width="68"></td>
                    <td :width="72"></td>
                    <td></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td>二</td>
                    <td>航道建设项目</td>
                    <td></td>
                    <td>46410</td>
                    <td>46410</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td>（一）</td>
                    <td>&ldquo;十四五&rdquo;新开工</td>
                    <td></td>
                    <td>46410</td>
                    <td>46410</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td :width="181">中俄界河松阿察河航标、测量及重点河段航道建设工程</td>
                    <td :width="158">按六级航道标准建设航标、测量设施，对重点河段航道治理</td>
                    <td :width="66">8,410</td>
                    <td :width="58">8,410</td>
                    <td :width="46">2023</td>
                    <td :width="46">2025</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td :width="181">智能航道建设工程</td>
                    <td :width="158">黑龙江同江至黑中29公里河段进行智能航道试验段建设。</td>
                    <td :width="66">34,000</td>
                    <td :width="58">34,000</td>
                    <td :width="46">2022</td>
                    <td :width="46">2025</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td :width="181">黑龙江上游乌苏里至鸥浦航道建设工程</td>
                    <td :width="158">按三级航道标准整治航道重点浅滩</td>
                    <td :width="66">4,000</td>
                    <td :width="58">4,000</td>
                    <td :width="46">2023</td>
                    <td :width="46">2024</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
    name: "Home",
    components: {},
    data() {
        return {
            activeName: "first",
        };
    },
    mounted() {},
    methods: {
        wordDetail() {
            // 微软提供的方法
            window.open(
                "http://zzgroup.hljzztech.com/黑龙江省“十四五”水运规划建设项目表.xls"
            );
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
    },
    watch: {},
};
</script>
<style lang="less">
</style>