<template>
    <div>
        <el-container class="ova-y">
            <el-main class="p0">
                <el-row>
                    <el-col :span="24">
                        <div class="box" ref="init">
                            <div class="box-content">
                                <el-form :inline="true" class="form-inline formBox">
                                    <el-form-item label="项目名称">
                                        <el-input style="width: 200px" size="medium" v-model="xmmc" placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="项目年度">
                                        <el-select v-model="xmnd" placeholder="“十四五”规划" style="width: 200px">
                                            <el-option label="" checked value="">“十四五”规划</el-option>
                                            <el-option label="2021" value="2021">2021年度计划</el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="项目性质">
                                        <el-cascader size="medium" style="width: 200px" v-model="checkedJsxz" :options="jsxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目类型">
                                        <el-cascader size="medium" style="width: 200px" v-model="checkedXmlx" :options="xmlxList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>

                                    <el-button type="primary" style="margin-top:19px" size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                    <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">清空</el-button>
                                </el-form>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="plr12">
                    <el-col :span="24">
                        <div class="box">
                            <div class="box-title">
                                <span class="fright">
                                    <el-button @click="exportData" type="primary" size="mini" round icon="el-icon-upload2">导出</el-button>
                                    <el-button @click="baobiao = true" type="primary2" size="mini" round icon="el-icon-share">报表</el-button>
                                    <el-button @click="drawer = true;getTotal();" type="primary" size="mini" round icon="el-icon-s-data">汇总</el-button>
                                </span>
                                <h3 class="tit">机场列表</h3>
                            </div>
                            <div class="box-content">
                                <div class="tj">
                                    <div class="total-item total-item-1">
                                        <img src="../../assets/icon/tj1.png" alt="">
                                        <div class="t">
                                            <h5>项目数量<small>（个）</small></h5>
                                            <b class="date-num">{{ xmsl }}</b>
                                        </div>
                                    </div>

                                    <div class="total-item total-item-5">
                                        <img src="../../assets/icon/tj5.png" alt="">
                                        <div class="t">
                                            <h5>十四五投资<small>（亿元）</small></h5>
                                            <b class="date-num">{{ sswtz.toFixed(1) }}</b>
                                        </div>
                                    </div>
                                </div>

                                <el-table @sort-change="changeSort" :data="tableData" size="small " :height="tableHeight" border :default-sort="{ prop: 'XH', order: 'ascending' }" style="width: 100%">
                                    <!-- <el-table-column prop="SZDS" label="所在地市" :width="100" sortable show-overflow-tooltip></el-table-column> -->
                                    <el-table-column prop="XMMC" label="项目名称" show-overflow-tooltip :width="320" sortable></el-table-column>
                                    <el-table-column prop="JSXZ" label="建设性质" :width="150" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="XMLX" label="项目类型" :width="150" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="SSWTZ" label="“十四五”投资（亿元）" :width="180" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="JSNR" label="建设内容" show-overflow-tooltip></el-table-column>
                                </el-table>
                                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                                </el-pagination>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
        <el-drawer title="汇总" :visible.sync="drawer" :direction="direction" size="550px">
            <Hz :totalXmlx="totalXmlx" :totalJsxz="totalJsxz" :totalXzqh="totalXzqh" />
        </el-drawer>
        <el-drawer title="报表" :visible.sync="baobiao" :direction="'rtl'" size="100%">
            <BbAirport v-if="baobiao" />
        </el-drawer>
    </div>
</template>
<script>
import Hz from "@/components/Query/Airport/Hz.vue"; //汇总
import { exportData } from "../../js/util/export.js"; //导出表格
import BbAirport from "./BbAirports.vue";
export default {
    name: "Home",
    components: {
        Hz,
        BbAirport,
    },
    data() {
        return {
            sortData: {
                field: "XH",
                sort: "asc",
            },
            tableHeight: 0, //表格高度
            activeIndex: true, //显隐高级搜索
            baobiao: false,
            /**查询条件 begin*/
            xmmc: "", //项目名称
            xmnd: "", //项目年度
            checkedJsxz: [], //建设性质
            checkedXmlx: [], //项目类型
            /**查询条件 end*/
            jsxzList: [
                {
                    value: "续建项目",
                    label: "续建项目",
                    disabled: false,
                },
                {
                    value: "新开工项目",
                    label: "新开工项目",
                    disabled: false,
                },
                {
                    value: "谋划项目",
                    label: "谋划项目",
                    disabled: false,
                },
            ],
            xmlxList: [
                {
                    value: "枢纽机场",
                    label: "枢纽机场",
                    disabled: false,
                },
                {
                    value: "民用运输机场",
                    label: "民用运输机场",
                    disabled: false,
                },
                {
                    value: "通用机场",
                    label: "通用机场",
                    disabled: false,
                },
                {
                    value: "支线机场",
                    label: "支线机场",
                    disabled: false,
                },
            ],
            currentPage: 1, //当前页
            pageSize: 30, //每页记录数
            isIndeterminateJsxz: true,
            isIndeterminateXmlx: true,
            drawer: false,
            direction: "rtl",
            props: { multiple: true },

            tableData: [], //请求列表
            total: 0, //请求记录数
            sswtz: 0,
            totalXmlx: [], //汇总按项目类型
            totalJsxz: [], //汇总按建设性质
            totalXzqh: [], //汇总按行政区划
            xmsl: 0,
        };
    },
    mounted() {
        var that = this;
        let height = this.$refs.init.offsetHeight;
        setTimeout(() => {
            that.tableHeight = document.body.offsetHeight - height - 265;
        }, 100);
        this.search();
    },
    methods: {
        changeSort(val) {
            if (val.order) {
                this.sortData = {
                    field: val.prop,
                    sort: val.order == "ascending" ? "asc" : "desc",
                };
            } else {
                this.sortData = {
                    field: "XMXH",
                    sort: "asc",
                };
            }
            this.tableData = [];
            this.search();
        },
        exportData() {
            exportData("Plan_airport", this.postData());
        },
        //查询
        search() {
            this.getPageTotal();
            this.http
                .post(
                    "/api/Plan_airport/GetPageData",
                    this.postData(),
                    "正在请求数据，请稍候..."
                )
                .then((res) => {
                    if (res.status == 0) {
                        this.total = res.total;
                        this.tableData = res.rows;

                        console.log(res);
                    }
                });
        },
        //汇总
        getPageTotal() {
            this.http
                .post("/api/Plan_airport/GetPageTotal", this.postData())
                .then((res) => {
                    console.log(res, 123123);
                    this.jsgm = res.jsgm;
                    this.jsqmqs = res.jsqmqs;
                    this.ztz = res.ztz;
                    this.sswtz = res.sswtz;
                    this.xmsl = res.xmsl;
                });
        },
        postData() {
            //项目名称
            var query_xmmc = {
                Name: "XMMC",
                Value: this.xmmc,
                DisplayType: "like",
            };

            //项目年度
            var query_xmnd = {
                Name: "XMND",
                Value: this.xmnd,
                DisplayType: "Equal",
            };

            //建设性质
            var query_jsxz = {
                Name: "JSXZ",
                Value: this.checkedJsxz.join(","),
                DisplayType: "checkbox",
            };

            //项目类型
            var xmlxArray = [];
            this.checkedXmlx.forEach((element) => {
                xmlxArray.push(element);
            });
            var query_xmlx = {
                Name: "XMLX",
                Value: xmlxArray.join(","),
                DisplayType: "checkbox",
            };

            var postData = {
                page: this.currentPage,
                rows: this.pageSize,
                Sort: this.sortData.field,
                Order: this.sortData.sort,
                wheres: JSON.stringify([
                    query_xmmc,
                    query_xmnd,
                    query_jsxz,
                    query_xmlx,
                ]),
            };
            return postData;
        },
        clearCondition() {
            this.xmmc = "";
            this.xmnd = "";
            this.checkedJsxz = [];
            this.checkedXmlx = [];
        },
        getTotal() {
            //汇总
            this.http
                .post("/api/Plan_airport/GetTotal", this.postData())
                .then((total) => {
                    // console.log(total);
                    this.totalJsxz = total.jsxz;
                    this.totalXmlx = total.xmlx;
                    this.totalXzqh = total.xzqh;

                    this.totalXmlx.forEach((res, index) => {
                        res.sscs = "合计";
                        res.xmsl = 0;
                        res.sswtz = 0;
                        res.key = "";
                        res.children = res.xmlx;
                        res.id = (index + 1) * 10;
                        res.children.forEach((v, i) => {
                            v.id = parseInt(index + 1 + "" + (i + 1));
                            v.ssds = "";
                            res.sswtz += v.sswtz;
                            res.xmsl += v.xmsl;
                            if (!v.key) v.key = "无";
                        });
                    });
                    this.totalJsxz.forEach((res, index) => {
                        res.sscs = "合计";
                        res.xmsl = 0;
                        res.sswtz = 0;
                        res.key = "";
                        res.children = res.jsxz;
                        res.id = (index + 1) * 10;
                        res.children.forEach((v, i) => {
                            v.id = parseInt(index + 1 + "" + (i + 1));
                            v.ssds = "";
                            res.sswtz += v.sswtz;
                            res.xmsl += v.xmsl;
                        });
                    });
                    console.log(this.totalJsxz);
                });
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.search();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.search();
        },
    },
    watch: {},
};
</script>
