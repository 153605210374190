<template>
    <div>
        <Header />

        <div class="navbar">
            <el-link @click="activeIndex = 1" :class="{ active: activeIndex == 1 }" :underline="false">公路
            </el-link>
            <el-link @click="activeIndex = 2" :class="{ active: activeIndex == 2 }" :underline="false">铁路
            </el-link>
            <el-link @click="activeIndex = 3" :class="{ active: activeIndex == 3 }" :underline="false">水运
            </el-link>
            <el-link @click="activeIndex = 4" :class="{ active: activeIndex == 4 }" :underline="false">机场
            </el-link>
            <el-link @click="activeIndex = 5" :class="{ active: activeIndex == 5 }" :underline="false">枢纽站场
            </el-link>
        </div>
        <Road v-if="activeIndex == 1" />
        <Railway v-if="activeIndex == 2" />
        <Water v-if="activeIndex == 3" />
        <Airport v-if="activeIndex == 4" />
        <Hinge v-if="activeIndex == 5" />
    </div>
</template>
<script>
import Road from "./Road.vue";
import Railway from "./Railway.vue";
import Water from "./Water.vue";
import Airport from "./Airport.vue";
import Hinge from "./Hinge.vue";
import Header from "@/views/Home/Header.vue";

export default {
    name: "Query",
    data() {
        return {
            activeIndex: 1,
        };
    },
    watch: {
        $route: "getPathFun",
    },
    mounted() {
        if (this.$route.query.c) {
            this.activeIndex = this.$route.query.c;
        }
    },
    methods: {
        getPathFun() {
            if (this.$route.query.c) {
                this.activeIndex = this.$route.query.c;
            }
        },
    },
    components: {
        Road,
        Airport,
        Hinge,
        Railway,
        Water,
        Header,
    },
};
</script>
