<template>
    <div>
        <el-row>
            <el-col :span="24">
                <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
                    <el-tab-pane label="客运枢纽" name="first">
                        <h2 style="margin-bottom: 10px; text-align: center">
                            表1 客运枢纽建设计划表
                            <span @click="wordDetail()" class="xzecl" >十四五运输场站项目表</span>
                        </h2>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <td rowspan="2" :width="25">序号</td>
                                        <td rowspan="2" :width="49">分类</td>
                                        <td rowspan="2" :width="65">所属城市</td>
                                        <td rowspan="2" :width="125">综合客运枢纽名称</td>
                                        <td rowspan="2" :width="138">衔接的机场/港口/铁路线路及火车站名称/位置</td>
                                        <td rowspan="2" :width="38">衔接方式</td>
                                        <td rowspan="2" :width="41">建设性质</td>
                                        <td colspan="3" :width="196">枢纽规模(平方米)</td>
                                        <td :width="73">设计发送量</td>
                                        <td rowspan="2" :width="66">公路客运站场级别</td>
                                        <td :width="80">计划实施</td>
                                        <td colspan="2" :width="210">集疏运系统（填写要求见备注）</td>
                                        <td rowspan="2" :width="70">总投资<br>
                                            （万元）</td>
                                        <td rowspan="2" :width="70">项目分类</td>
                                        <td rowspan="2" :width="78">项目来源</td>
                                        <td rowspan="2" :width="78">与十三五规划的关系</td>
                                        <td colspan="9" :width="936">项目前期工作情况</td>
                                    </tr>
                                    <tr>
                                        <td :width="65">枢纽总占地面积</td>
                                        <td :width="65">公路客运占地面积</td>
                                        <td :width="65">公路客运建筑面积</td>
                                        <td :width="73">（人/日）</td>
                                        <td :width="80">年限</td>
                                        <td :width="105">系统构成</td>
                                        <td :width="105">建设计划</td>
                                        <td :width="62">可研报告是否编制完成</td>
                                        <td :width="108">项目是否立项（如立项请填写立项批复或备案文号）</td>
                                        <td :width="121">审批制项目初步设计完成情况（已批复填写初步设计文号）</td>
                                        <td :width="153">土地落实情况（注明土地落实面积，已划拨、有招牌挂确任书、有不动产权登记证的土地各多少）</td>
                                        <td :width="108">建设用地规划许可证落实情况（落实的面积及文号）</td>
                                        <td :width="100">建设工程规划许可证落实情况（落实的面积及文号）</td>
                                        <td :width="89">施工许可证落实情况（落实的面积及文号）</td>
                                        <td :width="97">环评批复情况（已批复请填写文号）</td>
                                        <td :width="96">节能审查情况（已批复请填写文号）</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="bg-success">
                                        <td :width="25">1</td>
                                        <td rowspan="10" :width="49" class="bg-inherit">综合客运枢纽</td>
                                        <td :width="65">大庆市</td>
                                        <td :width="125">大庆西城公路客运综合枢纽站</td>
                                        <td :width="138">大庆西站</td>
                                        <td :width="38">公铁</td>
                                        <td :width="41">新建</td>
                                        <td :width="65"></td>
                                        <td :width="65">67698</td>
                                        <td :width="65">13132</td>
                                        <td :width="73">10000</td>
                                        <td :width="66">一级</td>
                                        <td :width="80">2019-2021</td>
                                        <td :width="105">班线客运、出租车、社会车辆</td>
                                        <td :width="105">2019-2021</td>
                                        <td :width="70">15700</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78"></td>
                                        <td :width="78">十三五规划项目</td>
                                        <td :width="62">是</td>
                                        <td :width="108">庆发改发〔2016〕171号</td>
                                        <td :width="121">庆发改发〔2017〕175号</td>
                                        <td :width="153">是</td>
                                        <td :width="108">6.769公顷地字第230600201700012号</td>
                                        <td :width="100">13132.26㎡地字第230600201800018号</td>
                                        <td :width="89">(2306641905100106-SX-009)<br>
                                            (230604202101070101)</td>
                                        <td :width="97">让环建审〔2017〕001号</td>
                                        <td :width="96">节能登记表2016005</td>
                                    </tr>
                                    <tr class="bg-success">
                                        <td :width="25">2</td>
                                        <td :width="65">七台河市</td>
                                        <td :width="125">七台河市综合客运枢纽站</td>
                                        <td :width="138">七台河西站/牡佳高铁</td>
                                        <td :width="38">公铁</td>
                                        <td :width="41">新建</td>
                                        <td :width="65"></td>
                                        <td :width="65">30205</td>
                                        <td :width="65">6666</td>
                                        <td :width="73">8000</td>
                                        <td :width="66">一级</td>
                                        <td :width="80">2020-2022</td>
                                        <td :width="105"></td>
                                        <td :width="105"></td>
                                        <td :width="70">7243</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78"></td>
                                        <td :width="78">十三五规划项目</td>
                                        <td :width="62">完成</td>
                                        <td :width="108">勃发改字【2020】7号</td>
                                        <td :width="121">勃交发【2020】3号</td>
                                        <td :width="153">勃自然资预审字【2019】34号</td>
                                        <td :width="108">是</td>
                                        <td :width="100">是</td>
                                        <td :width="89">是</td>
                                        <td :width="97">勃环审【2020】3号</td>
                                        <td :width="96">年耗能未达到备案标准，项目建设单位出具承诺书备案即可</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">3</td>
                                        <td :width="65">哈尔滨</td>
                                        <td :width="125">哈尔滨火车站北站综合交通枢纽</td>
                                        <td :width="138">哈尔滨火车站北站</td>
                                        <td :width="38">公铁</td>
                                        <td :width="41">新建</td>
                                        <td :width="65">160000</td>
                                        <td :width="65">13000</td>
                                        <td :width="65">2500</td>
                                        <td :width="73">11820</td>
                                        <td :width="66">一级</td>
                                        <td :width="80">2024-2025</td>
                                        <td :width="105">常规公交、出租车、私家车</td>
                                        <td :width="105">2021-2023</td>
                                        <td :width="70">6000</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78"></td>
                                        <td :width="62">否</td>
                                        <td :width="108">否</td>
                                        <td :width="121">否</td>
                                        <td :width="153">否</td>
                                        <td :width="108">否</td>
                                        <td :width="100">否</td>
                                        <td :width="89">否</td>
                                        <td :width="97">否</td>
                                        <td :width="96">否</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">4</td>
                                        <td :width="65">牡丹江</td>
                                        <td :width="125">牡丹江综合交通枢纽</td>
                                        <td :width="138">牡丹江/哈牡高铁</td>
                                        <td :width="38">公铁</td>
                                        <td :width="41">新建</td>
                                        <td :width="65"></td>
                                        <td :width="65">8800</td>
                                        <td :width="65">9700</td>
                                        <td :width="73">5000</td>
                                        <td :width="66">一级</td>
                                        <td :width="80">2020-2021</td>
                                        <td :width="105">常规公交、出租车、私家车。</td>
                                        <td :width="105"></td>
                                        <td>9736</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78"></td>
                                        <td :width="62">是</td>
                                        <td :width="108">牡行项目审批[2020]8号</td>
                                        <td :width="121">是</td>
                                        <td :width="153">是</td>
                                        <td :width="108">是</td>
                                        <td :width="100">是</td>
                                        <td :width="89">是</td>
                                        <td :width="97">是</td>
                                        <td :width="96">是</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">5</td>
                                        <td>双鸭山</td>
                                        <td :width="125">双鸭山高铁公路综合客运枢纽中心</td>
                                        <td :width="138">双鸭山火车站/牡佳高铁</td>
                                        <td :width="38">公铁</td>
                                        <td :width="41">新建</td>
                                        <td :width="65"></td>
                                        <td :width="65">46683.2</td>
                                        <td :width="65">6714.22</td>
                                        <td :width="73">6000</td>
                                        <td :width="66">一级</td>
                                        <td :width="80">2020-2022</td>
                                        <td :width="105">公交车、出租车、私家车</td>
                                        <td :width="105">二级公路、双向四车道、长度3公里</td>
                                        <td>8829.07</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78">十三五规划项目</td>
                                        <td :width="62">完成</td>
                                        <td :width="108">否</td>
                                        <td :width="121">否</td>
                                        <td :width="153">否</td>
                                        <td :width="108">否</td>
                                        <td :width="100">否</td>
                                        <td :width="89">否</td>
                                        <td :width="97">否</td>
                                        <td :width="96">否</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">6</td>
                                        <td :width="65">佳木斯市</td>
                                        <td :width="125">佳木斯市公铁联运客运枢纽站</td>
                                        <td :width="138">佳木斯市火车站/牡佳高铁/哈佳高铁</td>
                                        <td :width="38">公铁</td>
                                        <td :width="41">新建</td>
                                        <td :width="65"></td>
                                        <td :width="65">40194</td>
                                        <td :width="65">54515</td>
                                        <td :width="73">20000</td>
                                        <td :width="66">一级</td>
                                        <td :width="80">2022-2023</td>
                                        <td :width="105">常规公交、出租车、私家车</td>
                                        <td :width="105">2021-2022</td>
                                        <td>15989</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">厅规划办提供，电话与佳木斯核实</td>
                                        <td :width="78">十三五规划项目</td>
                                        <td :width="62">是</td>
                                        <td :width="108">佳发改交通【2016】6号</td>
                                        <td :width="121">佳交函【2018】44号</td>
                                        <td :width="153">39636㎡佳市政府国用【2016】第20160202号</td>
                                        <td :width="108">40195㎡地字第230800201600036号</td>
                                        <td :width="100">40195㎡建字第230800201800015号</td>
                                        <td :width="89"></td>
                                        <td :width="97">佳环建审【2016】21号</td>
                                        <td :width="96">佳工咨能评【2016】1号</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">7</td>
                                        <td :width="65">大兴安岭</td>
                                        <td :width="125">加格达奇公铁联运客运枢纽</td>
                                        <td :width="138">加格达奇区</td>
                                        <td :width="38">公铁</td>
                                        <td :width="41">新建</td>
                                        <td :width="65">17442.75</td>
                                        <td :width="65"></td>
                                        <td :width="65">10900</td>
                                        <td :width="73">2000</td>
                                        <td :width="66">一级</td>
                                        <td :width="80">2022-2025</td>
                                        <td :width="105"></td>
                                        <td :width="105"></td>
                                        <td>16000</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78"></td>
                                        <td :width="62">否</td>
                                        <td :width="108">否</td>
                                        <td :width="121">否</td>
                                        <td :width="153">否</td>
                                        <td :width="108">否</td>
                                        <td :width="100">否</td>
                                        <td :width="89">否</td>
                                        <td :width="97">否</td>
                                        <td :width="96">否</td>
                                    </tr>

                                    <tr>
                                        <td :width="25">8</td>
                                        <td :width="65">黑河</td>
                                        <td :width="125">北安市公铁联运枢纽</td>
                                        <td :width="138">黑河市北安市</td>
                                        <td :width="38">公铁</td>
                                        <td :width="41">新建</td>
                                        <td :width="65">154000</td>
                                        <td :width="65">80000</td>
                                        <td :width="65">5000</td>
                                        <td :width="73">4000</td>
                                        <td :width="66">一级</td>
                                        <td :width="80">2023-2025</td>
                                        <td :width="105">常规公交、出租车、私家车。</td>
                                        <td :width="105">建设公铁客运枢纽及客运、货运停发车广场，物流站场，修建铁路跨线天桥联通南北站房。</td>
                                        <td>16652</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78"></td>
                                        <td :width="62">否</td>
                                        <td :width="108">否</td>
                                        <td :width="121">否</td>
                                        <td :width="153">否</td>
                                        <td :width="108">否</td>
                                        <td :width="100">否</td>
                                        <td :width="89">否</td>
                                        <td :width="97">否</td>
                                        <td :width="96">否</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">9</td>
                                        <td :width="65">绥化</td>
                                        <td :width="125">绥化南站综合客运枢纽</td>
                                        <td :width="138">绥化南站/哈伊高铁</td>
                                        <td :width="38">公铁</td>
                                        <td :width="41">新建</td>
                                        <td :width="65"></td>
                                        <td :width="65">36000</td>
                                        <td :width="65">6000</td>
                                        <td :width="73">5000</td>
                                        <td :width="66">一级</td>
                                        <td :width="80">2022-2023</td>
                                        <td :width="105"></td>
                                        <td :width="105"></td>
                                        <td :width="70">5000</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">高铁布局分析应建</td>
                                        <td :width="78"></td>
                                        <td :width="62">否</td>
                                        <td :width="108">否</td>
                                        <td :width="121">否</td>
                                        <td :width="153">否</td>
                                        <td :width="108">否</td>
                                        <td :width="100">否</td>
                                        <td :width="89">否</td>
                                        <td :width="97">否</td>
                                        <td :width="96">否</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">10</td>
                                        <td :width="65">伊春</td>
                                        <td :width="125">伊春西站综合客运枢纽</td>
                                        <td :width="138">伊春西站/哈伊高铁</td>
                                        <td :width="38">公铁</td>
                                        <td :width="41">新建</td>
                                        <td :width="65"></td>
                                        <td :width="65">39800</td>
                                        <td :width="65">6343</td>
                                        <td :width="73">5000</td>
                                        <td :width="66">一级</td>
                                        <td :width="80">2022-2024</td>
                                        <td :width="105"></td>
                                        <td :width="105"></td>
                                        <td :width="70">10884</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">高铁布局分析应建</td>
                                        <td :width="78"></td>
                                        <td :width="62">是</td>
                                        <td :width="108">否</td>
                                        <td :width="121">否</td>
                                        <td :width="153">否</td>
                                        <td :width="108">否</td>
                                        <td :width="100">否</td>
                                        <td :width="89">否</td>
                                        <td :width="97">否</td>
                                        <td :width="96">否</td>
                                    </tr>
                                    <tr class="bg-success">
                                        <td :width="25">11</td>
                                        <td rowspan="29" :width="49" class="bg-inherit">县级客运站</td>
                                        <td :width="65">哈尔滨</td>
                                        <td :width="125">哈尔滨南城客运中心</td>
                                        <td :width="138">哈尔滨市道里区</td>
                                        <td :width="38"></td>
                                        <td :width="41">新建</td>
                                        <td :width="65"></td>
                                        <td :width="65">23000</td>
                                        <td :width="65">127000</td>
                                        <td :width="73">10000</td>
                                        <td :width="66">一级</td>
                                        <td :width="80">2020-2021</td>
                                        <td :width="105"></td>
                                        <td :width="105"></td>
                                        <td>9700</td>
                                        <td :width="70"></td>
                                        <td :width="78"></td>
                                        <td :width="78"></td>
                                        <td :width="62"></td>
                                        <td :width="108"></td>
                                        <td :width="121"></td>
                                        <td :width="153"></td>
                                        <td :width="108"></td>
                                        <td :width="100"></td>
                                        <td :width="89"></td>
                                        <td :width="97"></td>
                                        <td :width="96"></td>
                                    </tr>
                                    <tr class="bg-success">
                                        <td :width="25">12</td>
                                        <td :width="65">哈尔滨</td>
                                        <td :width="125">方正县公铁综合客运中心</td>
                                        <td :width="138">哈尔滨市方正县</td>
                                        <td :width="38"></td>
                                        <td :width="41">新建</td>
                                        <td :width="65"></td>
                                        <td :width="65">17503</td>
                                        <td :width="65">3908</td>
                                        <td :width="73">2000</td>
                                        <td :width="66">二级</td>
                                        <td :width="80">2018-2021</td>
                                        <td :width="105"></td>
                                        <td :width="105"></td>
                                        <td>3655</td>
                                        <td :width="70"></td>
                                        <td :width="78"></td>
                                        <td :width="78"></td>
                                        <td :width="62"></td>
                                        <td :width="108"></td>
                                        <td :width="121"></td>
                                        <td :width="153"></td>
                                        <td :width="108"></td>
                                        <td :width="100"></td>
                                        <td :width="89"></td>
                                        <td :width="97"></td>
                                        <td :width="96"></td>
                                    </tr>
                                    <tr class="bg-success">
                                        <td :width="25">13</td>
                                        <td :width="65">哈尔滨</td>
                                        <td :width="125">依兰县客运南站</td>
                                        <td :width="138">依兰县火车站站前广场西</td>
                                        <td :width="38"></td>
                                        <td :width="41">新建</td>
                                        <td :width="65"></td>
                                        <td :width="65">7556</td>
                                        <td :width="65">3659</td>
                                        <td :width="73">3500</td>
                                        <td :width="66">二级</td>
                                        <td :width="80">2020-2021</td>
                                        <td :width="105">常规公交、出租车、私家车</td>
                                        <td :width="105"></td>
                                        <td :width="70">2545</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78"></td>
                                        <td :width="62">是</td>
                                        <td :width="108">是</td>
                                        <td :width="121">是</td>
                                        <td :width="153">是</td>
                                        <td :width="108">是</td>
                                        <td :width="100">是</td>
                                        <td :width="89">是</td>
                                        <td :width="97">是</td>
                                        <td :width="96">是</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">14</td>
                                        <td :width="65">哈尔滨</td>
                                        <td :width="125">尚志市公路客运北站</td>
                                        <td :width="138">尚志市铁通公路、北一道街与乌珠河围合处</td>
                                        <td :width="38"></td>
                                        <td :width="41">新建</td>
                                        <td :width="65"></td>
                                        <td :width="65">14878.3</td>
                                        <td :width="65">1038.02</td>
                                        <td :width="73">2000</td>
                                        <td :width="66">二级</td>
                                        <td :width="80">2022-2023</td>
                                        <td :width="105">常规公交、出租车、私家车</td>
                                        <td :width="105">无</td>
                                        <td :width="70">1862.1</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78"></td>
                                        <td :width="62">是</td>
                                        <td :width="108">否</td>
                                        <td :width="121">尚发改[2021]58号</td>
                                        <td :width="153">14878.3㎡尚自然资呈[2021]68号</td>
                                        <td :width="108">14878.3㎡第230183202100008号</td>
                                        <td :width="100">无</td>
                                        <td :width="89">无</td>
                                        <td :width="97">无</td>
                                        <td :width="96">无</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">15</td>
                                        <td :width="65">哈尔滨</td>
                                        <td :width="125">宾县公路客运站</td>
                                        <td :width="138">哈尔滨市宾县</td>
                                        <td :width="38"></td>
                                        <td :width="41">改扩建</td>
                                        <td :width="65"></td>
                                        <td :width="65">11200</td>
                                        <td :width="65">3348</td>
                                        <td :width="73">6500</td>
                                        <td :width="66">一级</td>
                                        <td :width="80">2021-2022</td>
                                        <td :width="105">班线客运、场站服务</td>
                                        <td :width="105">升级改造</td>
                                        <td :width="70">818.37</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78"></td>
                                        <td :width="62">宾发改审批【2020】43号</td>
                                        <td :width="108">已立项、宾发改审批（2020）43号</td>
                                        <td :width="121">无</td>
                                        <td :width="153">无</td>
                                        <td :width="108">无</td>
                                        <td :width="100">无</td>
                                        <td :width="89">无</td>
                                        <td :width="97">无</td>
                                        <td :width="96">无</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">16</td>
                                        <td :width="65">哈尔滨</td>
                                        <td :width="125">亚布力客运西站</td>
                                        <td :width="138">尚志市亚布力高铁站附近</td>
                                        <td :width="38"></td>
                                        <td :width="41">新建</td>
                                        <td :width="65"></td>
                                        <td :width="65">14436.49</td>
                                        <td :width="65">4062.6</td>
                                        <td :width="73">2000</td>
                                        <td :width="66">一级</td>
                                        <td :width="80">2022-2023</td>
                                        <td :width="105">常规公交、出租车、私家车</td>
                                        <td :width="105">无</td>
                                        <td :width="70">3411</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">省交投集团提供</td>
                                        <td :width="78">-</td>
                                        <td :width="62">是</td>
                                        <td :width="108">是</td>
                                        <td :width="121">无</td>
                                        <td :width="153">无</td>
                                        <td :width="108">无</td>
                                        <td :width="100">无</td>
                                        <td :width="89">无</td>
                                        <td :width="97">无</td>
                                        <td :width="96">无</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">17</td>
                                        <td :width="65">齐齐哈尔</td>
                                        <td :width="125">讷河市客运总站</td>
                                        <td :width="138">讷河市火车站旁</td>
                                        <td :width="38"></td>
                                        <td :width="41">新建</td>
                                        <td :width="65"></td>
                                        <td :width="65">263000</td>
                                        <td :width="65">6423.1</td>
                                        <td :width="73">5000</td>
                                        <td :width="66">一级</td>
                                        <td :width="80">2021-2022</td>
                                        <td :width="105">常规公交、出租车、私家车</td>
                                        <td :width="105">建设一条地下通道长度长115米宽6米高4.5米</td>
                                        <td :width="70">8000</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78"></td>
                                        <td :width="62">是</td>
                                        <td :width="108">讷发改审批（2019）245号</td>
                                        <td :width="121">初设完成、正进行施工图纸设计</td>
                                        <td :width="153">已划拨</td>
                                        <td :width="108">土地置换</td>
                                        <td :width="100">地字第230281202002-20号</td>
                                        <td :width="89">16080平方米（讷河政府与哈尔滨铁路局协议:土方部20200001号）</td>
                                        <td :width="97">备案号201923028100000034</td>
                                        <td :width="96">节能承诺书已报</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">18</td>
                                        <td :width="65">牡丹江</td>
                                        <td :width="125">雪乡客运站</td>
                                        <td :width="138">雪乡景区门口附近</td>
                                        <td :width="38"></td>
                                        <td :width="41">新建</td>
                                        <td :width="65"></td>
                                        <td :width="65">263000</td>
                                        <td :width="65">2196.73</td>
                                        <td :width="73">2000</td>
                                        <td :width="66">一级</td>
                                        <td :width="80">2022-2023</td>
                                        <td :width="105"></td>
                                        <td :width="105"></td>
                                        <td :width="70">2000</td>
                                        <td :width="70"></td>
                                        <td :width="78">交投集团提供</td>
                                        <td :width="78"></td>
                                        <td :width="62">是</td>
                                        <td :width="108">否</td>
                                        <td :width="121">否</td>
                                        <td :width="153">否</td>
                                        <td :width="108">否</td>
                                        <td :width="100">否</td>
                                        <td :width="89">否</td>
                                        <td :width="97">否</td>
                                        <td :width="96">否</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">19</td>
                                        <td :width="65">绥化</td>
                                        <td :width="125">安达市公路客运站</td>
                                        <td :width="138">安达市滨洲北路西侧，火车站北侧</td>
                                        <td :width="38"></td>
                                        <td :width="41">新建</td>
                                        <td :width="65"></td>
                                        <td :width="65">22618</td>
                                        <td :width="65">5245</td>
                                        <td :width="73">10000</td>
                                        <td :width="66">一级</td>
                                        <td :width="80">2024-2025</td>
                                        <td :width="105">常规公交、出租车、私家车</td>
                                        <td :width="105">无</td>
                                        <td :width="70">3727</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78">-</td>
                                        <td :width="62">是</td>
                                        <td :width="108">是</td>
                                        <td :width="121">无</td>
                                        <td :width="153">是</td>
                                        <td :width="108">是</td>
                                        <td :width="100">无</td>
                                        <td :width="89">无</td>
                                        <td :width="97">无</td>
                                        <td :width="96">无</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">20</td>
                                        <td :width="65">绥化</td>
                                        <td :width="125">青冈县客运站</td>
                                        <td :width="138">青冈县城区南部</td>
                                        <td :width="38"></td>
                                        <td :width="41">改建</td>
                                        <td :width="65"></td>
                                        <td :width="65">13854</td>
                                        <td :width="65">3686</td>
                                        <td :width="73">3200</td>
                                        <td :width="66">二级</td>
                                        <td :width="80">2021-2022</td>
                                        <td :width="105">公路</td>
                                        <td :width="105">无</td>
                                        <td :width="70">1500</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78"></td>
                                        <td :width="62">是</td>
                                        <td :width="108">是，2020-231223-54-01-094380</td>
                                        <td :width="121">否</td>
                                        <td :width="153">否</td>
                                        <td :width="108">20835平方米，用字第2312232020000041号</td>
                                        <td :width="100">否</td>
                                        <td :width="89">否</td>
                                        <td :width="97">否</td>
                                        <td :width="96">否</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">21</td>
                                        <td :width="65">鹤岗市</td>
                                        <td :width="125">萝北国际客运站</td>
                                        <td :width="138">北外环北侧</td>
                                        <td :width="38"></td>
                                        <td :width="41">新建</td>
                                        <td :width="65"></td>
                                        <td :width="65">35000</td>
                                        <td :width="65">5000</td>
                                        <td :width="73">3000</td>
                                        <td :width="66">二级</td>
                                        <td :width="80">2024-2025</td>
                                        <td :width="105"></td>
                                        <td :width="105"></td>
                                        <td :width="70">3000</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78"></td>
                                        <td :width="62">否</td>
                                        <td :width="108">否</td>
                                        <td :width="121">否</td>
                                        <td :width="153">否</td>
                                        <td :width="108">否</td>
                                        <td :width="100">否</td>
                                        <td :width="89">否</td>
                                        <td :width="97">否</td>
                                        <td :width="96">否</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">22</td>
                                        <td :width="65">鹤岗市</td>
                                        <td :width="125">鹤北客运站</td>
                                        <td :width="138">鹤北林业局</td>
                                        <td :width="38"></td>
                                        <td :width="41">新建</td>
                                        <td :width="65"></td>
                                        <td :width="65">10000</td>
                                        <td :width="65">1000</td>
                                        <td :width="73">2000</td>
                                        <td :width="66">二级</td>
                                        <td :width="80">2023-2024</td>
                                        <td :width="105"></td>
                                        <td :width="105"></td>
                                        <td :width="70">400</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78"></td>
                                        <td :width="62">否</td>
                                        <td :width="108">否</td>
                                        <td :width="121">否</td>
                                        <td :width="153">否</td>
                                        <td :width="108">否</td>
                                        <td :width="100">否</td>
                                        <td :width="89">否</td>
                                        <td :width="97">否</td>
                                        <td :width="96">否</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">23</td>
                                        <td :width="65">伊春</td>
                                        <td :width="125">南岔县公路客运站</td>
                                        <td :width="138">南岔县南岔镇</td>
                                        <td :width="38"></td>
                                        <td :width="41">新建</td>
                                        <td :width="65"></td>
                                        <td :width="65">9050</td>
                                        <td :width="65">3146</td>
                                        <td :width="73">2800</td>
                                        <td :width="66">二级</td>
                                        <td :width="80">2021-2022</td>
                                        <td :width="105">常规公交、出租车、私家车</td>
                                        <td :width="105">无</td>
                                        <td :width="70">2857</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78">-</td>
                                        <td :width="62">是</td>
                                        <td :width="108">南发改发[2021]4号</td>
                                        <td :width="121">南交发[2021]3号</td>
                                        <td :width="153">是</td>
                                        <td :width="108">南自然资函[2021]10号</td>
                                        <td :width="100">是</td>
                                        <td :width="89">是</td>
                                        <td :width="97">否</td>
                                        <td :width="96">否</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">24</td>
                                        <td :width="65">伊春</td>
                                        <td :width="125">大箐山县公路客运站</td>
                                        <td :width="138">大箐山县</td>
                                        <td :width="38"></td>
                                        <td :width="41">改建</td>
                                        <td :width="65"></td>
                                        <td :width="65">3600</td>
                                        <td :width="65">8500</td>
                                        <td :width="73">1000</td>
                                        <td :width="66">三级</td>
                                        <td :width="80">2024-2025</td>
                                        <td :width="105">常规公交、出租车、私家车</td>
                                        <td :width="105">无</td>
                                        <td :width="70">1500</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78">-</td>
                                        <td :width="62">未完成，办理中</td>
                                        <td :width="108">否，办理中</td>
                                        <td :width="121">未完成，办理中</td>
                                        <td :width="153">未落实，办理中</td>
                                        <td :width="108">未落实，办理中</td>
                                        <td :width="100">未落实，办理中</td>
                                        <td :width="89">未落实，办理中</td>
                                        <td :width="97">未批复，办理中</td>
                                        <td :width="96">未批复，办理中</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">25</td>
                                        <td :width="65">伊春</td>
                                        <td :width="125">伊美区客运站</td>
                                        <td :width="138">伊春市伊美区</td>
                                        <td :width="38"></td>
                                        <td :width="41">改建</td>
                                        <td :width="65"></td>
                                        <td :width="65">4000</td>
                                        <td :width="65">800</td>
                                        <td :width="73">3000</td>
                                        <td :width="66">二级</td>
                                        <td :width="80">2022-2023</td>
                                        <td :width="105">常规公交、出租车、私家车</td>
                                        <td :width="105">无</td>
                                        <td :width="70">40</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78">-</td>
                                        <td :width="62">无</td>
                                        <td :width="108">无</td>
                                        <td :width="121">无</td>
                                        <td :width="153">无</td>
                                        <td :width="108">无</td>
                                        <td :width="100">无</td>
                                        <td :width="89">无</td>
                                        <td :width="97">无</td>
                                        <td :width="96">无</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">26</td>
                                        <td :width="65">伊春</td>
                                        <td :width="125">伊春市友好区公路客运站</td>
                                        <td :width="138">伊春市友好区</td>
                                        <td :width="38"></td>
                                        <td :width="41">改建</td>
                                        <td :width="65"></td>
                                        <td :width="65">3500</td>
                                        <td :width="65">936</td>
                                        <td :width="73">3000</td>
                                        <td :width="66">二级</td>
                                        <td :width="80">2022-2023</td>
                                        <td :width="105">常规公交、出租车、私家车</td>
                                        <td :width="105">无</td>
                                        <td :width="70">30</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78">-</td>
                                        <td :width="62">无</td>
                                        <td :width="108">无</td>
                                        <td :width="121">无</td>
                                        <td :width="153">无</td>
                                        <td :width="108">无</td>
                                        <td :width="100">无</td>
                                        <td :width="89">无</td>
                                        <td :width="97">无</td>
                                        <td :width="96">无</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">27</td>
                                        <td :width="65">伊春</td>
                                        <td :width="125">铁力市公路客运站</td>
                                        <td :width="138">铁力市铁力镇</td>
                                        <td :width="38"></td>
                                        <td :width="41">改建</td>
                                        <td :width="65"></td>
                                        <td :width="65">16210.4</td>
                                        <td :width="65">4557.93</td>
                                        <td :width="73">5000</td>
                                        <td :width="66">一级</td>
                                        <td :width="80">2022-2023</td>
                                        <td :width="105">常规公交、出租车、私家车</td>
                                        <td :width="105">无</td>
                                        <td :width="70">200</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78">-</td>
                                        <td :width="62">无</td>
                                        <td :width="108">无</td>
                                        <td :width="121">无</td>
                                        <td :width="153">无</td>
                                        <td :width="108">无</td>
                                        <td :width="100">无</td>
                                        <td :width="89">无</td>
                                        <td :width="97">无</td>
                                        <td :width="96">无</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">28</td>
                                        <td :width="65">七台河市</td>
                                        <td :width="125">勃利县公路客运站</td>
                                        <td :width="138">勃利县</td>
                                        <td :width="38"></td>
                                        <td :width="41">改建</td>
                                        <td :width="65"></td>
                                        <td :width="65">10550</td>
                                        <td :width="65">2235</td>
                                        <td :width="73">5000</td>
                                        <td :width="66">二级</td>
                                        <td :width="80">2021</td>
                                        <td :width="105"></td>
                                        <td :width="105"></td>
                                        <td :width="70">437</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78"></td>
                                        <td :width="62">无</td>
                                        <td :width="108">无</td>
                                        <td :width="121">无</td>
                                        <td :width="153">无</td>
                                        <td :width="108">无</td>
                                        <td :width="100">无</td>
                                        <td :width="89">无</td>
                                        <td :width="97">无</td>
                                        <td :width="96">无</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">29</td>
                                        <td :width="65">双鸭山</td>
                                        <td :width="125">宝清县公路客运站</td>
                                        <td :width="138">宝清县宝清镇</td>
                                        <td :width="38"></td>
                                        <td :width="41">新建</td>
                                        <td :width="65"></td>
                                        <td :width="65">25600</td>
                                        <td :width="65">6132,29</td>
                                        <td :width="73">4500</td>
                                        <td :width="66">二级</td>
                                        <td :width="80">2022-2023</td>
                                        <td :width="105"></td>
                                        <td :width="105"></td>
                                        <td :width="70">3143</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78"></td>
                                        <td :width="62">是</td>
                                        <td :width="108">是</td>
                                        <td :width="121">省</td>
                                        <td :width="153">否</td>
                                        <td :width="108">发</td>
                                        <td :width="100">发</td>
                                        <td :width="89">发</td>
                                        <td :width="97">是</td>
                                        <td :width="96">否</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">30</td>
                                        <td :width="65">黑河</td>
                                        <td :width="125">逊克县国际公路客运站</td>
                                        <td :width="138">逊克县交通街与南山路交汇处东侧</td>
                                        <td :width="38"></td>
                                        <td :width="41">新建</td>
                                        <td :width="65"></td>
                                        <td :width="65">30928.4</td>
                                        <td :width="65">17013.13</td>
                                        <td :width="73">3000</td>
                                        <td :width="66">一级</td>
                                        <td :width="80">2022-2025</td>
                                        <td :width="105"></td>
                                        <td :width="105"></td>
                                        <td :width="70">11867</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78">-</td>
                                        <td :width="62">是</td>
                                        <td :width="108">否</td>
                                        <td :width="121">未批复</td>
                                        <td :width="153">未落实</td>
                                        <td :width="108">未落实</td>
                                        <td :width="100">未落实</td>
                                        <td :width="89">未落实</td>
                                        <td :width="97">未批复</td>
                                        <td :width="96">未批复</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">31</td>
                                        <td :width="65">黑河</td>
                                        <td :width="125">嫩江市近郊公路汽车站</td>
                                        <td :width="138">原址重建</td>
                                        <td :width="38"></td>
                                        <td :width="41">新建</td>
                                        <td :width="65"></td>
                                        <td :width="65">2800</td>
                                        <td :width="65">400</td>
                                        <td :width="73">1500</td>
                                        <td :width="66">三级</td>
                                        <td :width="80">2022-2025</td>
                                        <td :width="105"></td>
                                        <td :width="105"></td>
                                        <td :width="70">300</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78">-</td>
                                        <td :width="62">是</td>
                                        <td :width="108"></td>
                                        <td :width="121"></td>
                                        <td :width="153"></td>
                                        <td :width="108"></td>
                                        <td :width="100"></td>
                                        <td :width="89"></td>
                                        <td :width="97"></td>
                                        <td :width="96"></td>
                                    </tr>
                                    <tr>
                                        <td :width="25">32</td>
                                        <td :width="65">黑河</td>
                                        <td :width="125">五大连池市公路客运站</td>
                                        <td :width="138">待定</td>
                                        <td :width="38"></td>
                                        <td :width="41">新建</td>
                                        <td :width="65"></td>
                                        <td :width="65">5000</td>
                                        <td :width="65">1000</td>
                                        <td :width="73">2500</td>
                                        <td :width="66">二级</td>
                                        <td :width="80">2024-2025</td>
                                        <td :width="105"></td>
                                        <td :width="105"></td>
                                        <td :width="70">2000</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78">-</td>
                                        <td :width="62">否</td>
                                        <td :width="108"></td>
                                        <td :width="121"></td>
                                        <td :width="153"></td>
                                        <td :width="108"></td>
                                        <td :width="100"></td>
                                        <td :width="89"></td>
                                        <td :width="97"></td>
                                        <td :width="96"></td>
                                    </tr>
                                    <tr>
                                        <td :width="25">33</td>
                                        <td :width="65">黑河</td>
                                        <td :width="125">孙吴县公路客运站</td>
                                        <td :width="138">现公路客运站原址</td>
                                        <td :width="38"></td>
                                        <td :width="41">改建</td>
                                        <td :width="65"></td>
                                        <td :width="65">17000</td>
                                        <td :width="65">7146</td>
                                        <td :width="73">2000</td>
                                        <td :width="66">二级</td>
                                        <td :width="80">2023</td>
                                        <td :width="105"></td>
                                        <td :width="105"></td>
                                        <td :width="70">680</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78">-</td>
                                        <td :width="62">是</td>
                                        <td :width="108">孙发改[2020]73号</td>
                                        <td :width="121">未批复</td>
                                        <td :width="153">未落实</td>
                                        <td :width="108">未落实</td>
                                        <td :width="100">未落实</td>
                                        <td :width="89">未落实</td>
                                        <td :width="97">未批复</td>
                                        <td :width="96">未批复</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">34</td>
                                        <td :width="65">大兴安岭</td>
                                        <td :width="125">呼玛县客运服务中心</td>
                                        <td :width="138">呼玛客运站南侧</td>
                                        <td :width="38"></td>
                                        <td :width="41">改扩建</td>
                                        <td :width="65"></td>
                                        <td :width="65">850</td>
                                        <td :width="65">3400</td>
                                        <td :width="73">1000</td>
                                        <td :width="66">二级</td>
                                        <td :width="80">2022-2023</td>
                                        <td :width="105"></td>
                                        <td :width="105"></td>
                                        <td :width="70">1860</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78">-</td>
                                        <td :width="62">是</td>
                                        <td :width="108">呼发改[2020]90号</td>
                                        <td :width="121">未完成，办理中</td>
                                        <td :width="153">否</td>
                                        <td :width="108">否</td>
                                        <td :width="100">否</td>
                                        <td :width="89">否</td>
                                        <td :width="97">否</td>
                                        <td :width="96">否</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">35</td>
                                        <td :width="65">大兴安岭</td>
                                        <td :width="125">松岭区公路客运站</td>
                                        <td :width="138">松岭区</td>
                                        <td :width="38"></td>
                                        <td :width="41">改扩建</td>
                                        <td :width="65"></td>
                                        <td :width="65">12112</td>
                                        <td :width="65">3499</td>
                                        <td :width="73">500</td>
                                        <td :width="66">三级</td>
                                        <td :width="80">2021-2022</td>
                                        <td :width="105"></td>
                                        <td :width="105"></td>
                                        <td :width="70">600</td>
                                        <td :width="70"></td>
                                        <td :width="78"></td>
                                        <td :width="78"></td>
                                        <td :width="62">是</td>
                                        <td :width="108">松发改【2021】4号-2102-230702-04-01-411422</td>
                                        <td :width="121">松发改【2021】10号</td>
                                        <td :width="153">否</td>
                                        <td :width="108">否</td>
                                        <td :width="100">否</td>
                                        <td :width="89">否</td>
                                        <td :width="97">否</td>
                                        <td :width="96">否</td>
                                    </tr>
                                    <tr>
                                        <td :width="25">36</td>
                                        <td :width="65">佳木斯市</td>
                                        <td :width="125">佳木斯市枢纽客运站</td>
                                        <td :width="138">佳木斯市向阳区</td>
                                        <td :width="38"></td>
                                        <td :width="41">改建</td>
                                        <td :width="65"></td>
                                        <td :width="65">12000</td>
                                        <td :width="65">14636</td>
                                        <td :width="73">5000</td>
                                        <td :width="66">一级</td>
                                        <td :width="80">2021-2022</td>
                                        <td :width="105"></td>
                                        <td :width="105"></td>
                                        <td :width="70">1000</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78"></td>
                                        <td :width="62"></td>
                                        <td :width="108"></td>
                                        <td :width="121"></td>
                                        <td :width="153"></td>
                                        <td :width="108"></td>
                                        <td :width="100"></td>
                                        <td :width="89"></td>
                                        <td :width="97"></td>
                                        <td :width="96"></td>
                                    </tr>
                                    <tr>
                                        <td :width="25">37</td>
                                        <td :width="65">佳木斯市</td>
                                        <td :width="125">佳木斯市中山客运站及客服场站</td>
                                        <td :width="138">佳木斯市向阳区站前路572号</td>
                                        <td :width="38"></td>
                                        <td :width="41">改建</td>
                                        <td :width="65"></td>
                                        <td :width="65">21527</td>
                                        <td :width="65">5900</td>
                                        <td :width="73">5000</td>
                                        <td :width="66">一级</td>
                                        <td :width="80">2022-2023</td>
                                        <td :width="105"></td>
                                        <td :width="105"></td>
                                        <td :width="70">913</td>
                                        <td :width="70"></td>
                                        <td :width="78"></td>
                                        <td :width="78"></td>
                                        <td :width="62"></td>
                                        <td :width="108"></td>
                                        <td :width="121"></td>
                                        <td :width="153"></td>
                                        <td :width="108"></td>
                                        <td :width="100"></td>
                                        <td :width="89"></td>
                                        <td :width="97"></td>
                                        <td :width="96"></td>
                                    </tr>
                                    <tr>
                                        <td :width="25">38</td>
                                        <td :width="65">佳木斯市</td>
                                        <td :width="125">抚远市国际客运站</td>
                                        <td :width="138">抚远市正阳路47号</td>
                                        <td :width="38"></td>
                                        <td :width="41">改建</td>
                                        <td :width="65"></td>
                                        <td :width="65">4952</td>
                                        <td :width="65">1621</td>
                                        <td :width="73">3000</td>
                                        <td :width="66">二级</td>
                                        <td :width="80">2022</td>
                                        <td :width="105"></td>
                                        <td :width="105"></td>
                                        <td :width="70">300</td>
                                        <td :width="70"></td>
                                        <td :width="78"></td>
                                        <td :width="78"></td>
                                        <td :width="62"></td>
                                        <td :width="108"></td>
                                        <td :width="121"></td>
                                        <td :width="153"></td>
                                        <td :width="108"></td>
                                        <td :width="100"></td>
                                        <td :width="89"></td>
                                        <td :width="97"></td>
                                        <td :width="96"></td>
                                    </tr>
                                    <tr>
                                        <td :width="25">39</td>
                                        <td :width="65">鸡西市</td>
                                        <td :width="125">鸡东公路客运站</td>
                                        <td :width="138">中心大街225号</td>
                                        <td :width="38"></td>
                                        <td :width="41">改扩建</td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="65"></td>
                                        <td :width="73"></td>
                                        <td :width="66">二级</td>
                                        <td :width="80">2023-2024</td>
                                        <td :width="105"></td>
                                        <td :width="105"></td>
                                        <td :width="70">2851</td>
                                        <td :width="70"></td>
                                        <td :width="78">地市提供</td>
                                        <td :width="78"></td>
                                        <td :width="62"></td>
                                        <td :width="108"></td>
                                        <td :width="121"></td>
                                        <td :width="153"></td>
                                        <td :width="108"></td>
                                        <td :width="100"></td>
                                        <td :width="89"></td>
                                        <td :width="97"></td>
                                        <td :width="96"></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p class="mt15">备注：绿色为“十三五”续建项目、黄色为备选项目</p>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="货运枢纽" name="second">
                        <h2 style="margin-bottom: 10px; text-align: center">表8 货运枢纽物流园区建设计划表<span @click="wordDetail()" class="xzecl" >十四五运输场站项目表</span></h2>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <td rowspan="2" :width="32">序号</td>
                                        <td rowspan="2" :width="49">分类</td>
                                        <td rowspan="2" :width="62">所属城市</td>
                                        <td rowspan="2" :width="126">项目名称</td>
                                        <td rowspan="2" :width="125">位 置</td>
                                        <td rowspan="2" :width="49">建设性质</td>
                                        <td rowspan="2" :width="46">衔接方式</td>
                                        <td rowspan="2" :width="96">依托的港口/机场/铁路货场名称</td>
                                        <td rowspan="2" :width="62">占地面积<br>
                                            (亩）</td>
                                        <td rowspan="2" :width="68">建筑面积<br>
                                            (平方米)</td>
                                        <td rowspan="2" :width="96">主体货类</td>
                                        <td rowspan="2" :width="70">设计吞吐能力(万吨/年)</td>
                                        <td rowspan="2" :width="76">计划实施年限</td>
                                        <td colspan="2" :width="132">集疏运系统（填写要求见备注）</td>
                                        <td rowspan="2" :width="84">总投资（万元）</td>
                                        <td rowspan="2" :width="70">项目分类</td>
                                        <td rowspan="2" :width="80">项目来源</td>
                                        <td rowspan="2" :width="66">与十三五规划的关系</td>
                                        <td rowspan="2" :width="73">项目级别</td>
                                        <td colspan="2" :width="82">项目前期工作情况</td>
                                    </tr>
                                    <tr>
                                        <td :width="60">系统构成</td>
                                        <td :width="72">建设计划</td>
                                        <td :width="82">可研报告是否编制完成</td>
                                        <td :width="118">节能审查情况（已批复请填写文号）</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="bg-success">
                                        <td :width="32">1</td>
                                        <td rowspan="12" :width="49" class="bg-inherit">多式联运型货运枢纽（物流园区）</td>
                                        <td :width="62">鸡西</td>
                                        <td :width="126">鸡西泛华货运枢纽(物流园区)</td>
                                        <td :width="125">鸡西市鸡冠区</td>
                                        <td :width="49">新建</td>
                                        <td :width="46">公铁</td>
                                        <td :width="96">铁路专用线</td>
                                        <td :width="62">507.3</td>
                                        <td :width="68">81900</td>
                                        <td :width="96"></td>
                                        <td :width="70"></td>
                                        <td :width="76">2016-2021</td>
                                        <td :width="60"></td>
                                        <td :width="72"></td>
                                        <td :width="84">49800</td>
                                        <td :width="70"></td>
                                        <td :width="80"></td>
                                        <td :width="66"></td>
                                        <td :width="73"></td>
                                        <td :width="82"></td>
                                        <td :width="118"></td>
                                    </tr>
                                    <tr>
                                        <td :width="32">2</td>
                                        <td :width="62">哈尔滨</td>
                                        <td :width="126">国际陆港跨境公铁联运集疏运中心</td>
                                        <td :width="125">哈尔滨新区利民文旅商贸园区，紧邻中国（黑龙江）自贸试验区哈尔滨片区，呼兰大道13号</td>
                                        <td :width="49">改建、扩建</td>
                                        <td :width="46">公铁</td>
                                        <td :width="96">哈尔滨北站</td>
                                        <td :width="62">720</td>
                                        <td :width="68">45000</td>
                                        <td :width="96">农资、粮食、木材、煤炭、矿产、集装箱</td>
                                        <td :width="70">300</td>
                                        <td :width="76">2023-2025</td>
                                        <td :width="60">公路、铁路</td>
                                        <td :width="72"></td>
                                        <td :width="84">17000</td>
                                        <td :width="70">规划研究项目</td>
                                        <td :width="80">省交投集团提供</td>
                                        <td :width="66"></td>
                                        <td :width="73">省级</td>
                                        <td>无</td>
                                        <td>无</td>
                                    </tr>
                                    <tr>
                                        <td :width="32">3</td>
                                        <td :width="62">齐齐哈尔</td>
                                        <td :width="126">齐齐哈尔国际物流园区</td>
                                        <td :width="125">昂昂溪区三间房</td>
                                        <td :width="49">新建</td>
                                        <td :width="46">公铁</td>
                                        <td :width="96">三家子机场、平齐线</td>
                                        <td :width="62">1230</td>
                                        <td :width="68">255000</td>
                                        <td :width="96">进口肉类、奶制品，出口水果和蔬菜</td>
                                        <td :width="70">500</td>
                                        <td :width="76">2021-2024</td>
                                        <td :width="60">公路、铁路</td>
                                        <td :width="72">引入铁路专用线3公里</td>
                                        <td :width="84">62000</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="80">省交投集团、地市提供</td>
                                        <td :width="66"></td>
                                        <td :width="73">国家级</td>
                                        <td>完成</td>
                                        <td>预计12月份</td>
                                    </tr>
                                    <tr>
                                        <td :width="32">4</td>
                                        <td :width="62">大庆</td>
                                        <td :width="126">大庆国际物流枢纽</td>
                                        <td :width="125">大庆市高新区</td>
                                        <td :width="49">新建</td>
                                        <td :width="46">公铁</td>
                                        <td :width="96">安达至大庆国际物流枢纽铁路专用线</td>
                                        <td :width="62">3000</td>
                                        <td :width="68">200000</td>
                                        <td :width="96">汽车、新材料、化工等</td>
                                        <td :width="70">3500</td>
                                        <td :width="76">2023-2025</td>
                                        <td :width="60"></td>
                                        <td :width="72"></td>
                                        <td :width="84">85800</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="80">地市提供</td>
                                        <td :width="66"></td>
                                        <td :width="73">国家级</td>
                                        <td>是</td>
                                        <td>无</td>
                                    </tr>
                                    <tr>
                                        <td :width="32">5</td>
                                        <td :width="62">大庆</td>
                                        <td :width="126">大庆市天鹤航空物流产业园</td>
                                        <td :width="125">大庆萨尔图机场西侧，绥满高速东侧</td>
                                        <td :width="49">新建</td>
                                        <td :width="46">公航</td>
                                        <td :width="96">大庆市萨尔图机场</td>
                                        <td :width="62">2250</td>
                                        <td :width="68">800000</td>
                                        <td :width="96">航空快递、日用百货、生鲜等</td>
                                        <td :width="70">500</td>
                                        <td :width="76">2023-2025</td>
                                        <td :width="60"></td>
                                        <td :width="72"></td>
                                        <td :width="84">101000</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="80">地市提供</td>
                                        <td :width="66"></td>
                                        <td :width="73">国家级</td>
                                        <td>是</td>
                                        <td>否</td>
                                    </tr>
                                    <tr>
                                        <td :width="32">6</td>
                                        <td :width="62">黑河</td>
                                        <td :width="126">黑河自贸区公铁水联运物流园区</td>
                                        <td :width="125">黑河市爱辉区</td>
                                        <td :width="49">新建</td>
                                        <td :width="46">公铁水</td>
                                        <td :width="96">黑河新港/铁路专用线</td>
                                        <td :width="62">240</td>
                                        <td :width="68">9,000</td>
                                        <td :width="96">木材、煤炭、粮食、石油</td>
                                        <td :width="70">400</td>
                                        <td :width="76">2024-2025</td>
                                        <td :width="60">公路、集疏运铁路</td>
                                        <td :width="72"></td>
                                        <td :width="84">17940</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="80">省交投集团提供</td>
                                        <td :width="66"></td>
                                        <td>国家级</td>
                                        <td>否</td>
                                        <td>否</td>
                                    </tr>
                                    <tr>
                                        <td :width="32">7</td>
                                        <td :width="62">黑河</td>
                                        <td :width="126">嫩江市公铁联运物流园区</td>
                                        <td :width="125">嫩江县城东北侧4公里至6公里范围内</td>
                                        <td :width="49">新建</td>
                                        <td :width="46">公铁</td>
                                        <td :width="96">嫩黑线、嫩林线、富嫩线</td>
                                        <td :width="62">900</td>
                                        <td :width="68">30000</td>
                                        <td :width="96">煤炭、矿石、粮食、农副产品、快递</td>
                                        <td :width="70">1200</td>
                                        <td :width="76">2022-2025</td>
                                        <td :width="60"></td>
                                        <td :width="72"></td>
                                        <td :width="84">13500</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="80">地市提供</td>
                                        <td :width="66"></td>
                                        <td :width="73">国家级</td>
                                        <td>否</td>
                                        <td>否</td>
                                    </tr>
                                    <tr>
                                        <td :width="32">8</td>
                                        <td :width="62">佳木斯</td>
                                        <td :width="126">同江公铁换装联运物流园区</td>
                                        <td :width="125">同街公路东侧，同江北换装站西侧</td>
                                        <td :width="49">新建</td>
                                        <td :width="46">公铁</td>
                                        <td :width="96">同江北换装站/同江线</td>
                                        <td :width="62">1530</td>
                                        <td :width="68">256000</td>
                                        <td :width="96">煤炭、矿石、粮食等</td>
                                        <td :width="70">550</td>
                                        <td :width="76">2021-2025</td>
                                        <td :width="60">集疏运体系铁路建设</td>
                                        <td :width="72">专用线1条，总长度3公里</td>
                                        <td :width="84">65400</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="80">省交投集团提供</td>
                                        <td :width="66">-</td>
                                        <td :width="73">国家级</td>
                                        <td>预计2021年开始</td>
                                        <td>预计2021年开始</td>
                                    </tr>
                                    <tr>
                                        <td :width="32">9</td>
                                        <td :width="62">佳木斯</td>
                                        <td :width="126">佳木斯公铁联运物流园区</td>
                                        <td :width="125">佳木斯市高新区</td>
                                        <td :width="49">新建</td>
                                        <td :width="46">公铁</td>
                                        <td :width="96">佳木斯站/图佳线</td>
                                        <td :width="62">736</td>
                                        <td :width="68">131200</td>
                                        <td :width="96">粮食等大宗商品货物</td>
                                        <td :width="70">400</td>
                                        <td :width="76">2024-2025</td>
                                        <td :width="60"></td>
                                        <td :width="72"></td>
                                        <td :width="84">78500</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="80">省交投集团提供</td>
                                        <td :width="66">-</td>
                                        <td :width="73">国家级</td>
                                        <td>预计2021年开始</td>
                                        <td>预计2021年开始</td>
                                    </tr>
                                    <tr>
                                        <td :width="32">10</td>
                                        <td :width="62">牡丹江</td>
                                        <td :width="126">绥芬河集疏运平台国际物流产业园</td>
                                        <td :width="125">绥芬河市综合保税区西侧</td>
                                        <td :width="49">新建</td>
                                        <td :width="46">公铁</td>
                                        <td :width="96">绥芬河火车站四货区</td>
                                        <td :width="62">1,650</td>
                                        <td :width="68"></td>
                                        <td :width="96">粮食、煤炭、水产品、木材</td>
                                        <td :width="70">1,800</td>
                                        <td :width="76">2022-2024</td>
                                        <td :width="60">集疏运体系铁路建设</td>
                                        <td :width="72">宽标轨铁路线8.3公里</td>
                                        <td :width="84">126700</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="80">地市提供</td>
                                        <td :width="66"></td>
                                        <td :width="73">国家级</td>
                                        <td :width="82">是</td>
                                        <td :width="118">否</td>
                                    </tr>
                                    <tr>
                                        <td :width="32">11</td>
                                        <td :width="62">七台河</td>
                                        <td :width="126">七台河公铁联运综合物流园区</td>
                                        <td :width="125">七台河市桃山区</td>
                                        <td :width="49">新建</td>
                                        <td :width="46">公铁</td>
                                        <td :width="96">七台河火车站/图佳线</td>
                                        <td :width="62">495</td>
                                        <td :width="68">60,000</td>
                                        <td :width="96">农产品、快消品、电商物流、粮、钢材、集装箱、危险品、冷链服务、快递、汽配等</td>
                                        <td :width="70">300</td>
                                        <td :width="76">2023-2025</td>
                                        <td :width="60"></td>
                                        <td :width="72"></td>
                                        <td :width="84">35,000</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="80">地市提供</td>
                                        <td :width="66"></td>
                                        <td :width="73">省级</td>
                                        <td :width="82"></td>
                                        <td :width="118"></td>
                                    </tr>
                                    <tr>
                                        <td :width="32">12</td>
                                        <td :width="62">绥化</td>
                                        <td :width="126">绥化综合物流园区</td>
                                        <td :width="125">绥庆路与中直北路交叉口</td>
                                        <td :width="49">新建</td>
                                        <td :width="46">公铁</td>
                                        <td :width="96">绥化火车站</td>
                                        <td :width="62">300</td>
                                        <td :width="68">70000</td>
                                        <td :width="96">快递、区域分拨</td>
                                        <td :width="70">150</td>
                                        <td :width="76">2022-2025</td>
                                        <td :width="60">公路、城市道路</td>
                                        <td :width="72">无</td>
                                        <td :width="84">30000</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="80">省交投集团、地市提供</td>
                                        <td :width="66">-</td>
                                        <td :width="73">省级</td>
                                        <td>预计2021年开始</td>
                                        <td>预计2021年开始</td>
                                    </tr>
                                    <tr class="bg-success">
                                        <td :width="32">13</td>
                                        <td rowspan="10" :width="49" class="bg-inherit">集散通用型货运枢纽（物流园区）</td>
                                        <td :width="62">牡丹江</td>
                                        <td :width="126">牡丹江华晟国运物流中心</td>
                                        <td :width="125">牡丹江市阳明区</td>
                                        <td :width="49">新建</td>
                                        <td :width="46"></td>
                                        <td :width="96"></td>
                                        <td :width="62">376</td>
                                        <td :width="68">131963.96</td>
                                        <td :width="96"></td>
                                        <td :width="70">200</td>
                                        <td :width="76">2014-2021</td>
                                        <td :width="60"></td>
                                        <td :width="72"></td>
                                        <td :width="84">30000</td>
                                        <td :width="70"></td>
                                        <td :width="80"></td>
                                        <td :width="66"></td>
                                        <td :width="73"></td>
                                        <td :width="82"></td>
                                        <td :width="118"></td>
                                    </tr>
                                    <tr class="bg-success">
                                        <td :width="32">14</td>
                                        <td :width="62">牡丹江</td>
                                        <td :width="126">绥芬河国际综合货运枢纽</td>
                                        <td :width="125">牡丹江市绥芬河市</td>
                                        <td :width="49">新建</td>
                                        <td :width="46"></td>
                                        <td :width="96"></td>
                                        <td :width="62"></td>
                                        <td :width="68"></td>
                                        <td :width="96"></td>
                                        <td :width="70"></td>
                                        <td :width="76">2016-2021</td>
                                        <td :width="60"></td>
                                        <td :width="72"></td>
                                        <td :width="84">39890</td>
                                        <td :width="70"></td>
                                        <td :width="80"></td>
                                        <td :width="66"></td>
                                        <td :width="73"></td>
                                        <td :width="82"></td>
                                        <td :width="118"></td>
                                    </tr>
                                    <tr>
                                        <td :width="32">15</td>
                                        <td :width="62">哈尔滨</td>
                                        <td :width="126">哈东综合保税区物流园区</td>
                                        <td :width="125">哈尔滨综合保税区A-06和A-10地块</td>
                                        <td :width="49">新建</td>
                                        <td :width="46"></td>
                                        <td :width="96"></td>
                                        <td :width="62">433</td>
                                        <td :width="68">179000</td>
                                        <td :width="96">冰鲜、粮食、食用油等</td>
                                        <td :width="70">400</td>
                                        <td :width="76">2020-2023</td>
                                        <td :width="60"></td>
                                        <td :width="72"></td>
                                        <td :width="84">86700</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="80">省交投集团提供</td>
                                        <td :width="66"></td>
                                        <td :width="73">国家级</td>
                                        <td :width="82">已完成，未评审</td>
                                        <td :width="118">预计8月份</td>
                                    </tr>
                                    <tr>
                                        <td :width="32">16</td>
                                        <td :width="62">哈尔滨</td>
                                        <td :width="126">哈尔滨嘉圣物流园区二期</td>
                                        <td :width="125">香福路和天兴街交汇处东南角</td>
                                        <td :width="49">新建</td>
                                        <td :width="46"></td>
                                        <td :width="96"></td>
                                        <td :width="62">614.6</td>
                                        <td :width="68">237947.36</td>
                                        <td :width="96">打造辐射省市地区的物流、快递集散中心。</td>
                                        <td :width="70">200</td>
                                        <td :width="76">2021-2022</td>
                                        <td :width="60"></td>
                                        <td :width="72"></td>
                                        <td :width="84">95000</td>
                                        <td :width="70">规划研究项目</td>
                                        <td :width="80">地市提供（与黑龙江省香坊实验农场合作）</td>
                                        <td :width="66"></td>
                                        <td :width="73"></td>
                                        <td>完成</td>
                                        <td>否</td>
                                    </tr>
                                    <tr>
                                        <td :width="32">17</td>
                                        <td :width="62">哈尔滨</td>
                                        <td :width="126">哈尔滨自贸片区B型保税物流园区</td>
                                        <td :width="125">哈尔滨市松北区哈大高速（大耿家收费站以内）与北站街交汇处</td>
                                        <td :width="49">新建</td>
                                        <td :width="46"></td>
                                        <td :width="96"></td>
                                        <td :width="62">120</td>
                                        <td :width="68">36000</td>
                                        <td :width="96">物流保税和跨境电商</td>
                                        <td :width="70">100</td>
                                        <td :width="76">2021-2023</td>
                                        <td :width="60"></td>
                                        <td :width="72"></td>
                                        <td>29500</td>
                                        <td></td>
                                        <td>省交投集团提供</td>
                                        <td>已开工</td>
                                        <td>省级</td>
                                        <td>已编制（未评审）</td>
                                        <td>预计10月份</td>
                                    </tr>
                                    <tr>
                                        <td :width="32">18</td>
                                        <td :width="62">大庆</td>
                                        <td :width="126">大同区现代农产品物流园</td>
                                        <td :width="125">大庆市大同区</td>
                                        <td :width="49">新建</td>
                                        <td :width="46"></td>
                                        <td :width="96"></td>
                                        <td>1500</td>
                                        <td>100000</td>
                                        <td :width="96">水产品、肉类、水果、蔬菜</td>
                                        <td>2000</td>
                                        <td>2024-2025</td>
                                        <td></td>
                                        <td></td>
                                        <td>80000</td>
                                        <td :width="70">规划项目</td>
                                        <td>地市提供</td>
                                        <td>-</td>
                                        <td>省级</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td :width="32">19</td>
                                        <td :width="62">佳木斯</td>
                                        <td :width="126">抚远冷链物流园区</td>
                                        <td :width="125">抚远市通港路10号</td>
                                        <td :width="49">新建</td>
                                        <td :width="46"></td>
                                        <td :width="96"></td>
                                        <td :width="62">259.5</td>
                                        <td :width="68">75500</td>
                                        <td :width="96">进口俄罗斯水产品，出口水果、蔬菜等</td>
                                        <td :width="70">100</td>
                                        <td :width="76">2020-2024</td>
                                        <td :width="60"></td>
                                        <td :width="72"></td>
                                        <td>30000</td>
                                        <td :width="70">规划项目</td>
                                        <td>省交投集团提供</td>
                                        <td>十三五规划项目</td>
                                        <td>国家级</td>
                                        <td>是</td>
                                        <td>预计10月份</td>
                                    </tr>
                                    <tr>
                                        <td :width="32">20</td>
                                        <td :width="62">佳木斯</td>
                                        <td :width="126">佳木斯综合物流园区</td>
                                        <td :width="125">佳木斯市友谊路</td>
                                        <td :width="49">新建</td>
                                        <td :width="46"></td>
                                        <td :width="96"></td>
                                        <td :width="62">975</td>
                                        <td :width="68">80,000</td>
                                        <td :width="96">城市快消品，快递</td>
                                        <td :width="70">150</td>
                                        <td :width="76">2022-2024</td>
                                        <td :width="60"></td>
                                        <td :width="72"></td>
                                        <td :width="84">70000</td>
                                        <td :width="70">规划研究项目</td>
                                        <td :width="80">省交投集团提供-意向</td>
                                        <td :width="66"></td>
                                        <td :width="73">省级</td>
                                        <td>无</td>
                                        <td>无</td>
                                    </tr>
                                    <tr>
                                        <td :width="32">21</td>
                                        <td :width="62">大兴安岭</td>
                                        <td :width="126">加格达奇冷链物流园</td>
                                        <td :width="125">加格达奇区</td>
                                        <td :width="49">新建</td>
                                        <td :width="46"></td>
                                        <td :width="96"></td>
                                        <td :width="62">41</td>
                                        <td :width="68">27000</td>
                                        <td :width="96">综合仓储、综合服务、商品交易、冷链、综合办公区</td>
                                        <td :width="70">150</td>
                                        <td :width="76">2022-2024</td>
                                        <td :width="60"></td>
                                        <td :width="72"></td>
                                        <td :width="84">12000</td>
                                        <td :width="70">规划项目</td>
                                        <td :width="80">地市提供</td>
                                        <td :width="66"></td>
                                        <td :width="73">省级</td>
                                        <td :width="82">否</td>
                                        <td :width="118">否</td>
                                    </tr>
                                    <tr>
                                        <td :width="32">22</td>
                                        <td :width="62">齐齐哈尔</td>
                                        <td :width="126">齐齐哈尔综合物流园区</td>
                                        <td :width="125">齐齐哈尔城区内</td>
                                        <td :width="49">新建</td>
                                        <td :width="46"></td>
                                        <td :width="96"></td>
                                        <td :width="62">225</td>
                                        <td :width="68">80000</td>
                                        <td :width="96">打造辐射我省西部及内蒙古东部地区的物流、快递分拨中心</td>
                                        <td :width="70">150</td>
                                        <td :width="76">2023-2024</td>
                                        <td :width="60"></td>
                                        <td :width="72"></td>
                                        <td :width="84">62500</td>
                                        <td :width="70">规划研究项目</td>
                                        <td :width="80">省交投集团提供-意向</td>
                                        <td :width="66"></td>
                                        <td :width="73">省级</td>
                                        <td>否</td>
                                        <td>否</td>
                                    </tr>
                                    <tr>
                                        <td :width="32">23</td>
                                        <td rowspan="5" :width="49">县（区）货运站场</td>
                                        <td :width="62">牡丹江</td>
                                        <td :width="126">东宁市绥阳镇物流园区</td>
                                        <td :width="125">东宁市绥阳镇</td>
                                        <td :width="49">新建</td>
                                        <td :width="46"></td>
                                        <td :width="96"></td>
                                        <td :width="62">117</td>
                                        <td :width="68">78000</td>
                                        <td :width="96">粮食、煤炭、果菜</td>
                                        <td :width="70">80</td>
                                        <td :width="76">2022-2024</td>
                                        <td :width="60">公路</td>
                                        <td :width="72">无</td>
                                        <td>30000</td>
                                        <td></td>
                                        <td>地市提供</td>
                                        <td>-</td>
                                        <td>一般</td>
                                        <td>否</td>
                                        <td>否</td>
                                    </tr>
                                    <tr>
                                        <td :width="32">24</td>
                                        <td>双鸭山</td>
                                        <td :width="126">宝清县公路货运站</td>
                                        <td :width="125">宝清县宝清镇</td>
                                        <td :width="49">新建</td>
                                        <td :width="46"></td>
                                        <td :width="96"></td>
                                        <td :width="62">900</td>
                                        <td :width="68">80000</td>
                                        <td :width="96">粮、煤炭、矿、钢材、集装箱、农产品、快递、外贸物资</td>
                                        <td :width="70">500</td>
                                        <td :width="76">2024-2025</td>
                                        <td :width="60">公路</td>
                                        <td :width="72"></td>
                                        <td>47600</td>
                                        <td></td>
                                        <td>地市提供</td>
                                        <td></td>
                                        <td>省级</td>
                                        <td>否</td>
                                        <td>否</td>
                                    </tr>
                                    <tr>
                                        <td :width="32">25</td>
                                        <td :width="62">鹤岗</td>
                                        <td :width="126">萝北县龙翔国际物流园区</td>
                                        <td :width="125">萝北县凤翔镇</td>
                                        <td :width="49">新建</td>
                                        <td :width="46"></td>
                                        <td :width="96"></td>
                                        <td :width="62">244</td>
                                        <td :width="68">71000</td>
                                        <td :width="96">粮食、矿产、外贸物资</td>
                                        <td :width="70">100</td>
                                        <td :width="76">2020-2023</td>
                                        <td :width="60"></td>
                                        <td :width="72"></td>
                                        <td>15000</td>
                                        <td></td>
                                        <td>地市提供</td>
                                        <td></td>
                                        <td></td>
                                        <td>是</td>
                                        <td>否</td>
                                    </tr>
                                    <tr>
                                        <td :width="32">26</td>
                                        <td :width="62">伊春</td>
                                        <td :width="126">南岔县县级农村物流中心</td>
                                        <td :width="125">南岔县东出口，G222国道西侧，南岔格润药业东侧，铁路绥佳线北侧</td>
                                        <td :width="49">新建</td>
                                        <td :width="46"></td>
                                        <td :width="96"></td>
                                        <td :width="62">120</td>
                                        <td :width="68">2500</td>
                                        <td :width="96">木材、粮食、生活用品</td>
                                        <td :width="70">60</td>
                                        <td>2024-2025</td>
                                        <td>公路、城市道路</td>
                                        <td :width="72">无</td>
                                        <td>2300</td>
                                        <td></td>
                                        <td>地市提供</td>
                                        <td>-</td>
                                        <td>省级</td>
                                        <td>未完成，办理中</td>
                                        <td>未批复，办理中</td>
                                    </tr>
                                    <tr>
                                        <td :width="32">27</td>
                                        <td :width="62">大庆</td>
                                        <td :width="126">杜尔伯特县物流园</td>
                                        <td :width="125">大庆市杜蒙县</td>
                                        <td :width="49">新建</td>
                                        <td :width="46"></td>
                                        <td :width="96"></td>
                                        <td :width="62">195</td>
                                        <td :width="68">30000</td>
                                        <td :width="96">水产品冷库，特色农畜产品、杂粮杂豆、工业产品</td>
                                        <td :width="70">100</td>
                                        <td :width="76">2023-2024</td>
                                        <td :width="60"></td>
                                        <td :width="72"></td>
                                        <td>21000</td>
                                        <td></td>
                                        <td>地市提供</td>
                                        <td>-</td>
                                        <td>省级</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p class="mt15">备注：绿色为“十三五”续建项目、黄色为备选项目</p>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="乡镇运输服务站" name="third">
                        <h2 style="margin-bottom: 10px; text-align: center">表7 乡镇运输服务站建设计划表<span @click="wordDetail()" class="xzecl" >十四五运输场站项目表</span></h2>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <td rowspan="3" :width="72">序号</td>
                                        <td rowspan="3" :width="72">客运站名称</td>
                                        <td rowspan="3" :width="72">位 置</td>
                                        <td rowspan="3" :width="72">建设性质</td>
                                        <td rowspan="3" :width="72">总投资（万元）</td>
                                        <td rowspan="3" :width="72">站场级别</td>
                                        <td colspan="2" :width="144">建筑规模(平方米)</td>
                                        <td :width="72">设计发送量</td>
                                        <td rowspan="3" :width="72">计划实施年限</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">占地面积</td>
                                        <td rowspan="2" :width="72">建筑面积(平方米)</td>
                                        <td :width="72">（人/日）</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">(平方米)</td>
                                        <td :width="72"></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style="font-weight: bold; text-align: center">
                                        <td colspan="10" :width="720">齐齐哈尔</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">1</td>
                                        <td :width="72">克东宝泉客运站</td>
                                        <td :width="72">克东宝泉镇火车站前</td>
                                        <td :width="72">扩建</td>
                                        <td :width="72">80</td>
                                        <td :width="72">5</td>
                                        <td :width="72">510</td>
                                        <td :width="72">341</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">2</td>
                                        <td :width="72">克东宝泉客运北站</td>
                                        <td :width="72">克东宝泉镇城北</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">80</td>
                                        <td :width="72">5</td>
                                        <td :width="72">378</td>
                                        <td :width="72">210</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">3</td>
                                        <td :width="72">克东乾丰客运站</td>
                                        <td :width="72">克东乾丰镇城边靠202国道</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">360</td>
                                        <td :width="72">5</td>
                                        <td :width="72">12170</td>
                                        <td :width="72">380</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">2020</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">4</td>
                                        <td :width="72">克东双庆客运站</td>
                                        <td :width="72">克东原双庆乡直现建设村</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">80</td>
                                        <td :width="72">5</td>
                                        <td :width="72">1288</td>
                                        <td :width="72">191</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">5</td>
                                        <td :width="72">克东玉岗客运站</td>
                                        <td :width="72">克东玉岗镇直碾北公路边</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">80</td>
                                        <td :width="72">5</td>
                                        <td :width="72">393</td>
                                        <td :width="72">232</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">6</td>
                                        <td :width="72">克东昌盛客运站</td>
                                        <td :width="72">克东昌盛乡直通北公路边</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">80</td>
                                        <td :width="72">5</td>
                                        <td :width="72">379</td>
                                        <td :width="72">213</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">2022</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">7</td>
                                        <td :width="72">克东润津客运站</td>
                                        <td :width="72">克东润津乡直克通公路边</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">80</td>
                                        <td :width="72">5</td>
                                        <td :width="72">380</td>
                                        <td :width="72">182</td>
                                        <td :width="72">800</td>
                                        <td :width="72">2022</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">8</td>
                                        <td :width="72">克东蒲峪路镇客运站</td>
                                        <td :width="72">克东蒲峪路镇直302国道边</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">100</td>
                                        <td :width="72">5</td>
                                        <td :width="72">1173</td>
                                        <td :width="72">203</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">2022</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">9</td>
                                        <td :width="72">克东爱国客运站</td>
                                        <td :width="72">克东原爱国乡直</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">80</td>
                                        <td :width="72">5</td>
                                        <td :width="72">205</td>
                                        <td :width="72">116</td>
                                        <td :width="72">600</td>
                                        <td :width="72">2022</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">10</td>
                                        <td :width="72">克东名山客运站</td>
                                        <td :width="72">克东原名山乡直202国道边</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">80</td>
                                        <td :width="72">5</td>
                                        <td :width="72">675</td>
                                        <td :width="72">190</td>
                                        <td :width="72">600</td>
                                        <td :width="72">2022</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">11</td>
                                        <td :width="72">克东新农客运站</td>
                                        <td :width="72">克东原新农乡直</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">100</td>
                                        <td :width="72">5</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">200</td>
                                        <td :width="72">800</td>
                                        <td :width="72">2022</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">12</td>
                                        <td :width="72">克东金南客运站</td>
                                        <td :width="72">克东原金南乡直</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">100</td>
                                        <td :width="72">5</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">200</td>
                                        <td :width="72">800</td>
                                        <td :width="72">2022</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">13</td>
                                        <td :width="72">克东建业农场客运站</td>
                                        <td :width="72">克东黑龙江省建业农场场直</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">130</td>
                                        <td :width="72">5</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">200</td>
                                        <td :width="72">800</td>
                                        <td :width="72">2022</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">14</td>
                                        <td :width="72">克东一粮农场客运站</td>
                                        <td :width="72">克东县一粮农场场直</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">130</td>
                                        <td :width="72">5</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">200</td>
                                        <td :width="72">800</td>
                                        <td :width="72">2022</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">15</td>
                                        <td :width="72">克东东兴林场客运站</td>
                                        <td :width="72">克东县东兴林场场直</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">130</td>
                                        <td :width="72">5</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">200</td>
                                        <td :width="72">800</td>
                                        <td :width="72">2022</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">16</td>
                                        <td :width="72">克东发展林场客运站</td>
                                        <td :width="72">克东县发展林场场直</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">130</td>
                                        <td :width="72">5</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">200</td>
                                        <td :width="72">800</td>
                                        <td :width="72">2022</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">17</td>
                                        <td :width="72">克东爱华林场客运站</td>
                                        <td :width="72">克东县爱华林场场直</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">130</td>
                                        <td :width="72">5</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">200</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">2022</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">18</td>
                                        <td :width="72">讷河市学田乡客运综合服务站</td>
                                        <td :width="72">讷河市学田镇</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">130</td>
                                        <td :width="72">五级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">232.2</td>
                                        <td :width="72">200</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">19</td>
                                        <td :width="72">讷河市老莱乡客运综合服务站</td>
                                        <td :width="72">讷河市老莱镇</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">130</td>
                                        <td :width="72">五级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">232.2</td>
                                        <td :width="72">200</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">20</td>
                                        <td :width="72">讷河市进化乡客运综合服务站</td>
                                        <td :width="72">讷河市孔国乡进化村</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">130</td>
                                        <td :width="72">五级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">232.2</td>
                                        <td :width="72">200</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">21</td>
                                        <td :width="72">讷河市拉哈乡客运综合服务站</td>
                                        <td :width="72">讷河市拉哈镇</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">130</td>
                                        <td :width="72">五级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">232.2</td>
                                        <td :width="72">200</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">22</td>
                                        <td :width="72">讷河市长发乡客运综合服务站</td>
                                        <td :width="72">讷河市长发镇</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">130</td>
                                        <td :width="72">五级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">232.2</td>
                                        <td :width="72">200</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">23</td>
                                        <td :width="72">讷河市二克浅乡客运综合服务站</td>
                                        <td :width="72">讷河市二克浅镇</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">130</td>
                                        <td :width="72">五级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">232.2</td>
                                        <td :width="72">200</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">24</td>
                                        <td :width="72">讷河市龙河乡客运综合服务站</td>
                                        <td :width="72">讷河市龙河镇</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">130</td>
                                        <td :width="72">五级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">232.2</td>
                                        <td :width="72">200</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">25</td>
                                        <td :width="72">讷河市讷南乡客运综合服务站</td>
                                        <td :width="72">讷河市讷南镇</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">130</td>
                                        <td :width="72">五级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">232.2</td>
                                        <td :width="72">200</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">26</td>
                                        <td :width="72">讷河市九井乡客运综合服务站</td>
                                        <td :width="72">讷河市九井镇</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">100</td>
                                        <td :width="72">五级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">232.2</td>
                                        <td :width="72">200</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">27</td>
                                        <td :width="72">讷河市兴旺乡客运综合服务站</td>
                                        <td :width="72">讷河市兴旺乡</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">100</td>
                                        <td :width="72">五级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">232.2</td>
                                        <td :width="72">200</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">28</td>
                                        <td :width="72">讷河市通南乡客运综合服务站</td>
                                        <td :width="72">讷河市通南镇</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">100</td>
                                        <td :width="72">五级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">232.2</td>
                                        <td :width="72">200</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">29</td>
                                        <td :width="72">讷河市和盛乡客运综合服务站</td>
                                        <td :width="72">讷河市和盛乡</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">100</td>
                                        <td :width="72">五级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">232.2</td>
                                        <td :width="72">200</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">30</td>
                                        <td :width="72">讷河市同义乡客运综合服务站</td>
                                        <td :width="72">讷河市同义乡</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">100</td>
                                        <td :width="72">五级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">232.2</td>
                                        <td :width="72">200</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">31</td>
                                        <td :width="72">讷河市孔国乡客运综合服务站</td>
                                        <td :width="72">讷河市孔国乡</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">130</td>
                                        <td :width="72">五级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">232.2</td>
                                        <td :width="72">200</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">32</td>
                                        <td :width="72">富裕县富海镇综合服务站</td>
                                        <td :width="72">富裕县富海镇</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">500</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">2000</td>
                                        <td :width="72">300</td>
                                        <td :width="72">300</td>
                                        <td :width="72">2020</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">33</td>
                                        <td :width="72">富裕县二道湾镇综合服务站</td>
                                        <td :width="72">富裕县二道湾镇</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">500</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">2000</td>
                                        <td :width="72">300</td>
                                        <td :width="72">300</td>
                                        <td :width="72">2020</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">34</td>
                                        <td :width="72">富裕县绍文乡综合服务站</td>
                                        <td :width="72">富裕县绍文乡</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">500</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">2000</td>
                                        <td :width="72">300</td>
                                        <td :width="72">300</td>
                                        <td :width="72">2020</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">35</td>
                                        <td :width="72">富裕县忠厚乡综合服务站</td>
                                        <td :width="72">富裕县忠厚乡</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">500</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">2000</td>
                                        <td :width="72">300</td>
                                        <td :width="72">300</td>
                                        <td :width="72">2020</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">36</td>
                                        <td :width="72">富裕县繁荣乡综合服务站</td>
                                        <td :width="72">富裕县繁荣乡</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">500</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">2000</td>
                                        <td :width="72">300</td>
                                        <td :width="72">300</td>
                                        <td :width="72">2020</td>
                                    </tr>
                                    <tr style="font-weight: bold; text-align: center">
                                        <td colspan="10" :width="720">牡丹江</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">37</td>
                                        <td :width="72">老黑山镇客运站</td>
                                        <td :width="72">老黑山镇</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">60</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">3000</td>
                                        <td :width="72">350</td>
                                        <td :width="72"></td>
                                        <td :width="72">2020-2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">38</td>
                                        <td :width="72">大肚川镇客运站</td>
                                        <td :width="72">大肚川镇</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">50</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">1200</td>
                                        <td :width="72">300</td>
                                        <td :width="72"></td>
                                        <td :width="72">2020-2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">39</td>
                                        <td :width="72">绥阳镇客运站</td>
                                        <td :width="72">绥阳镇</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">150</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">3000</td>
                                        <td :width="72">350</td>
                                        <td :width="72"></td>
                                        <td :width="72">2020-2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">40</td>
                                        <td :width="72">道河镇客运站</td>
                                        <td :width="72">道河镇</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">150</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">3000</td>
                                        <td :width="72">300</td>
                                        <td :width="72"></td>
                                        <td :width="72">2020-2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">41</td>
                                        <td :width="72">三岔口镇客运站</td>
                                        <td :width="72">三岔口镇</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">150</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">3000</td>
                                        <td :width="72">300</td>
                                        <td :width="72"></td>
                                        <td :width="72">2020-2021</td>
                                    </tr>
                                    <tr style="font-weight: bold; text-align: center">
                                        <td colspan="10" :width="720">鸡西</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">42</td>
                                        <td :width="72">宝东客运综合服务站</td>
                                        <td :width="72">宝东镇</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">59</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">675</td>
                                        <td :width="72">120</td>
                                        <td :width="72">500</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">43</td>
                                        <td :width="72">阿北客运综合服务站</td>
                                        <td :width="72">阿北乡</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">57</td>
                                        <td :width="72">五级</td>
                                        <td :width="72">780</td>
                                        <td :width="72">120</td>
                                        <td :width="72">300</td>
                                        <td :width="72">2022</td>
                                    </tr>
                                    <tr style="font-weight: bold; text-align: center">
                                        <td colspan="10" :width="720">伊春</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">44</td>
                                        <td :width="72">朗乡镇小白农村综合服务站</td>
                                        <td :width="72"></td>
                                        <td :width="72">新建</td>
                                        <td :width="72">100</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">200</td>
                                        <td :width="72">800</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">45</td>
                                        <td :width="72">带岭镇永兴农村综合服务站</td>
                                        <td :width="72"></td>
                                        <td :width="72">新建</td>
                                        <td :width="72">100</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">200</td>
                                        <td :width="72">800</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">46</td>
                                        <td :width="72">带岭镇双兴农村综合服务站</td>
                                        <td :width="72"></td>
                                        <td :width="72">新建</td>
                                        <td :width="72">100</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">200</td>
                                        <td :width="72">800</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">47</td>
                                        <td :width="72">朗乡镇达里农村综合服务站</td>
                                        <td :width="72"></td>
                                        <td :width="72">新建</td>
                                        <td :width="72">100</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">200</td>
                                        <td :width="72">800</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">48</td>
                                        <td :width="72">朗乡胜利农村综合服务站</td>
                                        <td :width="72"></td>
                                        <td :width="72">新建</td>
                                        <td :width="72">100</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">200</td>
                                        <td :width="72">800</td>
                                        <td :width="72">2022</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">49</td>
                                        <td colspan="2" :width="144">南岔县27个行政建制村（国庆村、先锋村、沙山村、梧桐村、中兴村、振兴村、双河村、艾林村、松青村、红旗村、浩良河村、五七村、北阳村、永林村、奋斗村、秋冷村、亮子河村、大吉星村、小吉星村、宝泉村、月明村、星星村、沙岭村、晨明村、创业村、桦阳村、东红村）农村物流服务点项目建设。各投资60万元（其中，中央车购税投资50万元，地方配套10万元）。</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">1620</td>
                                        <td :width="72">五级</td>
                                        <td :width="72">600</td>
                                        <td :width="72">120</td>
                                        <td :width="72">500</td>
                                        <td :width="72">2021-2025</td>
                                    </tr>
                                    <tr style="font-weight: bold; text-align: center">
                                        <td colspan="10" :width="720">黑河</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">50</td>
                                        <td :width="72">嫩江乡镇综合服务站</td>
                                        <td :width="72">13个乡镇</td>
                                        <td :width="72">改建</td>
                                        <td :width="72"></td>
                                        <td :width="72"></td>
                                        <td :width="72">农产品、快递</td>
                                        <td :width="72"></td>
                                        <td :width="72"></td>
                                        <td :width="72">2021-2025</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">51</td>
                                        <td :width="72">嫩江建制村物流服务点</td>
                                        <td :width="72">135个建制村</td>
                                        <td :width="72">改建</td>
                                        <td :width="72"></td>
                                        <td :width="72"></td>
                                        <td :width="72">农产品、快递</td>
                                        <td :width="72"></td>
                                        <td :width="72"></td>
                                        <td :width="72">2021-2025</td>
                                    </tr>
                                    <tr style="font-weight: bold; text-align: center">
                                        <td colspan="10" :width="720">大兴安岭</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">52</td>
                                        <td :width="72">漠河市西林吉镇公路客运站</td>
                                        <td :width="72">漠河市西林吉镇</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">280</td>
                                        <td :width="72">二级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">300</td>
                                        <td :width="72">3000</td>
                                        <td :width="72">2020-2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">53</td>
                                        <td :width="72">漠河市北极镇公路客运站</td>
                                        <td :width="72">漠河市北极镇</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">150</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">200</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">2020-2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">54</td>
                                        <td :width="72">漠河市兴安镇公路客运站</td>
                                        <td :width="72">漠河市兴安镇</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">150</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">200</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">2020-2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">55</td>
                                        <td :width="72">漠河市图强镇客运站改造</td>
                                        <td :width="72">漠河市图强镇</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">150</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">200</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">2020-2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">56</td>
                                        <td :width="72">漠河市阿木尔镇客运站改造</td>
                                        <td :width="72">漠河市阿木尔镇</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">150</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">200</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">2020-2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">57</td>
                                        <td :width="72">漠河市古莲镇公路客运站</td>
                                        <td :width="72">漠河市古莲镇</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">150</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">200</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">2020-2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">58</td>
                                        <td :width="72">松岭区小扬气镇公路客运站</td>
                                        <td :width="72">小扬气镇</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">1400</td>
                                        <td :width="72">三级</td>
                                        <td :width="72">2400</td>
                                        <td :width="72">620</td>
                                        <td :width="72">500</td>
                                        <td :width="72">2020-2021</td>
                                    </tr>
                                    <tr style="font-weight: bold; text-align: center; font-size: 16px">
                                        <td colspan="10" :width="720">以下为新增项目</td>
                                    </tr>
                                    <tr style="font-weight: bold; text-align: center">
                                        <td colspan="10" :width="720">哈尔滨</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">59</td>
                                        <td :width="72">尚志市庆阳镇公路客运站</td>
                                        <td :width="72">尚志市庆阳镇内</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">50</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">770</td>
                                        <td :width="72">185</td>
                                        <td :width="72">700</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">60</td>
                                        <td :width="72">尚志市亚布力镇公路客运站</td>
                                        <td :width="72">尚志市亚布力镇内</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">50</td>
                                        <td :width="72">三级</td>
                                        <td :width="72">2367.42</td>
                                        <td :width="72">719.82</td>
                                        <td :width="72">2000</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">61</td>
                                        <td :width="72">尚志市一面坡镇公路客运站</td>
                                        <td :width="72">尚志市一面坡镇内</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">50</td>
                                        <td :width="72">三级</td>
                                        <td :width="72">5127.2</td>
                                        <td :width="72">621.08</td>
                                        <td :width="72">1200</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">62</td>
                                        <td :width="72">尚志市元宝镇公路客运站</td>
                                        <td :width="72">尚志市元宝镇内</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">50</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">480</td>
                                        <td :width="72">272</td>
                                        <td :width="72">1200</td>
                                        <td :width="72">2022</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">63</td>
                                        <td :width="72">尚志市黑龙宫镇公路客运站</td>
                                        <td :width="72">尚志市黑龙宫镇内</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">50</td>
                                        <td :width="72">四级</td>
                                        <td :width="72">1001</td>
                                        <td :width="72">120</td>
                                        <td :width="72">600</td>
                                        <td :width="72">2022</td>
                                    </tr>
                                    <tr style="font-weight: bold; text-align: center">
                                        <td colspan="10" :width="720">黑河</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">64</td>
                                        <td :width="72">孙吴县农村物流站</td>
                                        <td :width="72">1个</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">2000</td>
                                        <td :width="72"></td>
                                        <td :width="72">农产品、快递</td>
                                        <td :width="72"></td>
                                        <td :width="72"></td>
                                        <td :width="72">2021-2025</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">65</td>
                                        <td :width="72">孙吴乡镇综合服务站</td>
                                        <td :width="72">9个乡镇</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">1080</td>
                                        <td :width="72"></td>
                                        <td :width="72">农产品、快递</td>
                                        <td :width="72"></td>
                                        <td :width="72"></td>
                                        <td :width="72">2021-2025</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">66</td>
                                        <td :width="72">孙吴建制村物流服务站</td>
                                        <td :width="72">97个建制村</td>
                                        <td :width="72">改建</td>
                                        <td :width="72">188</td>
                                        <td :width="72"></td>
                                        <td :width="72">农产品、快递</td>
                                        <td :width="72"></td>
                                        <td :width="72"></td>
                                        <td :width="72">2021-2025</td>
                                    </tr>
                                    <tr style="font-weight: bold; text-align: center">
                                        <td colspan="10" :width="720">齐齐哈尔</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">67</td>
                                        <td :width="72">碾子山区丰荣村客运站</td>
                                        <td :width="72">碾子山区丰荣村</td>
                                        <td :width="72">新建</td>
                                        <td :width="72">80</td>
                                        <td :width="72">5</td>
                                        <td :width="72">400</td>
                                        <td :width="72">280</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                    <tr>
                                        <td :width="72">68</td>
                                        <td :width="72">碾子山区三江省村客运站</td>
                                        <td :width="72">碾子山区三江省村</td>
                                        <td :width="72">扩建</td>
                                        <td :width="72">80</td>
                                        <td :width="72">5</td>
                                        <td :width="72">400</td>
                                        <td :width="72">280</td>
                                        <td :width="72">1000</td>
                                        <td :width="72">2021</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="市（地）汇总" name="fourth">
                        <h2 style="margin-bottom: 10px; text-align: center">各市（地）枢纽场站汇总<span @click="wordDetail()" class="xzecl" >十四五运输场站项目表</span></h2>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <td rowspan="2" :width="88">序号</td>
                                        <td rowspan="2" :width="88">地市</td>
                                        <td colspan="2" :width="188">客运枢纽</td>
                                        <td colspan="2" :width="176">货运枢纽</td>
                                        <td colspan="2" :width="249">乡镇运输服务站</td>
                                        <td colspan="2" :width="176">小计</td>
                                    </tr>
                                    <tr>
                                        <td>客运枢纽个数</td>
                                        <td>投资（亿元）</td>
                                        <td>货运枢纽</td>
                                        <td>投资（亿元）</td>
                                        <td>乡镇运输服务站</td>
                                        <td>投资（亿元）</td>
                                        <td>项目个数</td>
                                        <td>地市投资</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td align="right">1</td>
                                        <td>大庆</td>
                                        <td align="right">1</td>
                                        <td align="right">1.57</td>
                                        <td align="right">4</td>
                                        <td align="right">28.78</td>
                                        <td align="right">0</td>
                                        <td></td>
                                        <td align="right">5</td>
                                        <td align="right">30.35</td>
                                    </tr>
                                    <tr>
                                        <td align="right">2</td>
                                        <td>大小兴安岭</td>
                                        <td align="right">3</td>
                                        <td align="right">1.846</td>
                                        <td align="right">1</td>
                                        <td align="right">1.2</td>
                                        <td align="right">7</td>
                                        <td align="right">0.243</td>
                                        <td align="right">11</td>
                                        <td align="right">3.289</td>
                                    </tr>
                                    <tr>
                                        <td align="right">3</td>
                                        <td>哈尔滨</td>
                                        <td align="right">7</td>
                                        <td align="right">2.799147</td>
                                        <td align="right">4</td>
                                        <td align="right">22.82</td>
                                        <td align="right">5</td>
                                        <td align="right">0.025</td>
                                        <td align="right">16</td>
                                        <td align="right">25.644147</td>
                                    </tr>
                                    <tr>
                                        <td align="right">4</td>
                                        <td>鹤岗</td>
                                        <td align="right">2</td>
                                        <td align="right">0.34</td>
                                        <td align="right">1</td>
                                        <td align="right">1.5</td>
                                        <td align="right">0</td>
                                        <td></td>
                                        <td align="right">3</td>
                                        <td align="right">1.84</td>
                                    </tr>
                                    <tr>
                                        <td align="right">5</td>
                                        <td>黑河</td>
                                        <td align="right">5</td>
                                        <td align="right">3.1499</td>
                                        <td align="right">2</td>
                                        <td align="right">3.144</td>
                                        <td align="right">5</td>
                                        <td align="right">0.3268</td>
                                        <td align="right">12</td>
                                        <td align="right">6.6207</td>
                                    </tr>
                                    <tr>
                                        <td align="right">6</td>
                                        <td>鸡西</td>
                                        <td align="right">1</td>
                                        <td align="right">0.2851</td>
                                        <td align="right">1</td>
                                        <td align="right">4.98</td>
                                        <td align="right">2</td>
                                        <td align="right">0.0116</td>
                                        <td align="right">4</td>
                                        <td align="right">5.2767</td>
                                    </tr>
                                    <tr>
                                        <td align="right">7</td>
                                        <td>佳木斯</td>
                                        <td align="right">4</td>
                                        <td align="right">1.8202</td>
                                        <td align="right">4</td>
                                        <td align="right">24.39</td>
                                        <td align="right">0</td>
                                        <td></td>
                                        <td align="right">8</td>
                                        <td align="right">26.2102</td>
                                    </tr>
                                    <tr>
                                        <td align="right">8</td>
                                        <td>牡丹江</td>
                                        <td align="right">2</td>
                                        <td align="right">1.1736</td>
                                        <td align="right">4</td>
                                        <td align="right">22.659</td>
                                        <td align="right">5</td>
                                        <td align="right">0.056</td>
                                        <td align="right">11</td>
                                        <td align="right">23.8886</td>
                                    </tr>
                                    <tr>
                                        <td align="right">9</td>
                                        <td>七台河</td>
                                        <td align="right">2</td>
                                        <td align="right">0.768</td>
                                        <td align="right">1</td>
                                        <td align="right">3.5</td>
                                        <td align="right">0</td>
                                        <td></td>
                                        <td align="right">3</td>
                                        <td align="right">4.268</td>
                                    </tr>
                                    <tr>
                                        <td align="right">10</td>
                                        <td>齐齐哈尔</td>
                                        <td align="right">1</td>
                                        <td align="right">0.8</td>
                                        <td align="right">2</td>
                                        <td align="right">12.45</td>
                                        <td align="right">38</td>
                                        <td align="right">0.628</td>
                                        <td align="right">41</td>
                                        <td align="right">13.878</td>
                                    </tr>
                                    <tr>
                                        <td align="right">11</td>
                                        <td>双鸭山</td>
                                        <td align="right">2</td>
                                        <td align="right">1.197207</td>
                                        <td align="right">1</td>
                                        <td align="right">4.76</td>
                                        <td align="right">0</td>
                                        <td></td>
                                        <td align="right">3</td>
                                        <td align="right">5.957207</td>
                                    </tr>
                                    <tr>
                                        <td align="right">12</td>
                                        <td>绥化</td>
                                        <td align="right">3</td>
                                        <td align="right">1.0227</td>
                                        <td align="right">1</td>
                                        <td align="right">3</td>
                                        <td align="right">0</td>
                                        <td></td>
                                        <td align="right">4</td>
                                        <td align="right">4.0227</td>
                                    </tr>
                                    <tr>
                                        <td align="right">13</td>
                                        <td>伊春</td>
                                        <td align="right">6</td>
                                        <td align="right">1.5511</td>
                                        <td align="right">1</td>
                                        <td align="right">0.23</td>
                                        <td align="right">6</td>
                                        <td align="right">0.212</td>
                                        <td align="right">13</td>
                                        <td align="right">1.9931</td>
                                    </tr>
                                    <tr style="font-weight: bold">
                                        <td>合计</td>
                                        <td align="right">134</td>
                                        <td align="right">39</td>
                                        <td align="right">18.322954</td>
                                        <td align="right">27</td>
                                        <td align="right">133.413</td>
                                        <td align="right">68</td>
                                        <td align="right">1.5024</td>
                                        <td align="right">134</td>
                                        <td align="right">153.238354</td>
                                    </tr>
                                    <tr>
                                        <td :width="88"><strong>总投资</strong></td>
                                        <td :width="88"><strong>153.238354</strong></td>
                                        <td colspan="2" :width="188">1、综合客运枢纽：10个，总投资11.203307亿<br>
                                            2、县级客运站：29个，总投资7.119647亿</td>
                                        <td colspan="2" :width="176">1、多式联运型货运枢纽（物流园区）12个，总投资68.246亿<br>
                                            2、集散通用型货运枢纽（物流园区）10个，总投资53.559亿<br>
                                            3、县（区）货运站场5个，总投资11.59</td>
                                        <td colspan="2" :width="249"></td>
                                        <td></td>
                                        <td :width="88">规划目标：<br>
                                            200亿</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    name: "Home",
    components: {},
    data() {
        return {
            activeName: "first",
        };
    },
    mounted() {},
    methods: {
        wordDetail() {
            // 微软提供的方法
            window.open(
                "http://zzgroup.hljzztech.com/十四五运输场站项目表20211021（下发汇总）.xlsx"
            );
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
    },
    watch: {},
};
</script>

<style lang="less">
</style>