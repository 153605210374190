<template>
    <div>
        <el-dialog :title="dialogDetails.XMMC" :visible.sync="dialogTableVisible" class="mapdialog" width="50%">

            <div class="mb15" v-if="isedits">
                <el-button type="primary" v-if="btnviewshow" @click="handleChange1" size="small">完善信息</el-button>
                <el-button type="primary" v-if="btneditshow" @click="handleChange2,submit()" size="small">保存</el-button>
                <el-button v-if="btneditshow" size="small" @click="handleChange2">取消</el-button>
            </div>
            <div class="view-card-info" v-if="viewshow">
                <div class="mycard-lg">
                    <table class="table">
                        <tr>
                            <th width="150">项目名称</th>
                            <td colspan="3">{{ dialogDetails.XMMC||'无' }}</td>
                        </tr>
                        <tr>
                            <th>建设内容</th>
                            <td>{{ dialogDetails.JSNR }}</td>
                        </tr>
                        <tr>
                            <th>十四五总投资（亿元）</th>
                            <td>{{ dialogDetails.SSWTZ }}</td>
                        </tr>

                    </table>
                </div>
            </div>
            <div class="edit-card-info" v-if="editshow&&isedits">
                <el-alert title="填报完信息记得点击保存按钮" type="error" class="mb15">
                </el-alert>
                <div class="mycard-lg">
                    <el-form ref="formdata" :model="formdata">
                        <table class="table">
                            <tr>
                                <th width="150">项目名称</th>
                                <td colspan="3">
                                    <el-input v-model="formdata.XMMC" disabled></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th width="150">建设内容</th>
                                <td>
                                    <el-input v-model="formdata.JSNR"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>十四五总投资（亿元）</th>
                                <td>
                                    <el-input v-model="formdata.SSWTZ"></el-input>
                                </td>

                            </tr>

                        </table>
                    </el-form>
                </div>
            </div>

            <!-- <div class="titles">备注：</div> -->
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: ["data", "round", "isedits"],
    name: "",
    components: {},
    data() {
        return {
            formdata: {
                KYZMC: "",
                WZ: "",
                JSXZ: "",
                ZTZ: "",
                ZCJB: "",
                ZDMJ: "",
                JZMJ: "",
                SJFSL: "",
                JHSSNX: "",
            },
            dialogTableVisible: false,
            dialogDetails: {},
            activecollapse: "1",
            isedit: false,
            viewshow: true,
            editshow: false,
            btnviewshow: true,
            btneditshow: false,
        };
    },
    mounted() {
        this.dialogDetails = this.data;
        this.setData();
    },
    methods: {
        submit() {
            this.http
                .post(
                    "/api/Plan_township_transportation/UpdateTownship",
                    this.formdata
                )
                .then((res) => {
                    if (res.message == "修改成功") {
                        this.$message("修改成功");
                        this.dialogDetails = this.formdata;
                        this.handleChange2();
                        this.$emit("updatemessage");
                    }
                });
        },
        setData() {
            this.dialogTableVisible = false;
            this.activecollapse = "1";
            this.dialogTableVisible = true;
            this.formdata = JSON.parse(JSON.stringify(this.dialogDetails));
        },
        handleChange(value) {
            console.log(value);
        },
        handleChange1() {
            this.viewshow = false;
            this.editshow = true;
            this.btneditshow = true;
            this.btnviewshow = false;
        },
        handleChange2() {
            this.viewshow = true;
            this.editshow = false;
            this.btneditshow = false;
            this.btnviewshow = true;
        },
    },
    watch: {
        round() {
            this.setData();
            console.log(1);
            this.isedit = false;
            this.handleChange2();
        },
        data(e) {
            console.log(1123);
            this.dialogDetails = e;
            this.setData();
            this.handleChange2();
        },
    },
};
</script>

<style lang="less">
</style>